// Import React dependencies.
import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
  useMemo,
} from 'react';
import { Transforms } from 'slate';
import { useSlate, ReactEditor } from 'slate-react';
import { Button, Spin, Popconfirm, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  PlayCircleOutlined,
  RedoOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import * as monaco from 'monaco-editor';
import { useApolloClient } from '@apollo/client';
import { format as formatSQL } from 'sql-formatter';
import { v4 as uuid } from 'uuid';
import debounce from 'debounce';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import MonacoEditor from '../../components/editor/MonacoEditor';
import Spinner from '../../components/common/Spinner';
import ResultView from './ResultView';
import BlockToolbar from './BlockToolbar';
import { addOrUpdateBlockResult as addOrUpdateBlockResultHelper } from '../../store/worksheet/actions';
import BlockIndex from './BlockIndex';
import { formatTimeReceived, formatWorkerStatuses } from '../../formatter';
import { EXECUTE_SQL_LIMIT } from '../../setup/config';
import ExplainPlanModal from '../../components/modal/ExplainPlanModal';
import { useIsReadOnly } from './utils';
import ReadOnlyDivider from './ReadOnlyDivider';
import CollapsedBlock from './CollapsedBlock';
import SqlSuggestionContext from './SqlSuggestionContext';
import UDFStatus from '../../containers/jobs/UDFStatus';
import {
  FILES_UPDATE_SQL_PATTERNS,
  TABLES_UPDATE_SQL_PATTERNS,
  CUSTOM_STATUS_MAPPING,
} from '../../constants';
import useEvent, { EVENT_TYPES } from '../../hooks/useEvent';
import { GET_ENDPOINT_JOBS } from '../../graphql/schema/jobs';
import { UserContext } from '../../context';

const EDITOR_MIN_HEIGHT = 80;
const KEYSTROKE_DELAY = 175;

export default function CodeBlock({
  element,
  attributes,
  children,
  blocks,
  runAllFromBlock,
  toggleSqlVisibility,
  toggleBlockCollapsed,
  toggleBlockVisibility,
  toggleSqlGpt,
  updateQueryResult,
  workbook,
  worksheet,
}) {
  const dispatch = useDispatch();
  const slateEditor = useSlate();
  const editorRef = useRef();
  const userMe = useContext(UserContext);
  const graphqlClient = useApolloClient();
  const { emit: emitTables } = useEvent(EVENT_TYPES.TABLES_UPDATE);
  const { emit: emitFiles } = useEvent(EVENT_TYPES.FILES_UPDATE);
  const analytics = useAnalytics();
  const readOnly = useIsReadOnly();
  const [executeSql] = GraphQLServices.SqlQueries.useExecuteSql();
  const [explainPlan, { loading: explainPlanLoading }] =
    GraphQLServices.SqlQueries.useExplainPlan();
  const [cancelJobsByIds] =
    GraphQLServices.EndpointJobs.useAlterEndpointJobsById();
  const [editorHeight, setEditorHeight] = useState(EDITOR_MIN_HEIGHT);
  const ignoreEvent = useRef();
  const {
    queryResponse = null,
    queryRunning = false,
    queryError = null,
    queryID = null,
  } = useSelector(({ worksheet }) => {
    if (worksheet.blockResults[element.id]) {
      return worksheet.blockResults[element.id];
    } else if (element.data?.response) {
      return {
        queryResponse: {
          response: element.data?.response,
          responses: [],
        },
      };
    }
    return {};
  });

  const [showExplainPlan, setShowExplainPlan] = useState(false);
  const [plan, setPlan] = useState(undefined);
  const [showLegend, setShowLegend] = useState(false);
  const [loading, setLoading] = useState(false);

  const [udfName, setUDFName] = useState(null);
  const [isValidProc, setIsValidProc] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);

  const [jobs, setJobs] = useState([]);
  const [isFetchingJobs, setIsFetchingJobs] = useState(false);
  const [isJobsPanelOpen, setIsJobsPanelOpen] = useState(false);
  const [isJobCancelling, setIsJobCancelling] = useState(false);

  useEffect(_ => {
    let changeSubscription = null;
    if (editorRef.current) {
      // Initialize editor to initial code state.
      editorRef.current.editor.setValue(element.content);

      // Resize editor to size of content. This is so editor never has to scroll.
      changeSubscription = editorRef.current.editor.onDidContentSizeChange(
        e => {
          if (ignoreEvent.current || !editorRef.current) {
            return;
          }

          const editor = editorRef.current.editor;
          const height = Math.max(EDITOR_MIN_HEIGHT, editor.getContentHeight());

          try {
            ignoreEvent.current = true;
            setEditorHeight(height);
          } finally {
            ignoreEvent.current = false;
          }
        }
      );
    }

    return _ => {
      if (changeSubscription) {
        changeSubscription.dispose();
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(
    _ => {
      if (editorRef.current) {
        editorRef.current.editor.updateOptions({ readOnly });
      }
    },
    [readOnly]
  );

  const updateSlateEditor = debounce((slateEditor, path, content) => {
    Transforms.setNodes(slateEditor, { content }, { at: path });
  }, KEYSTROKE_DELAY);

  useEffect(
    _ => {
      let changeSubscription = null;
      if (editorRef.current) {
        changeSubscription = editorRef.current.editor.onDidChangeModelContent(
          e => {
            if (editorRef.current) {
              const content = editorRef.current.editor.getModel().getValue();
              const path = ReactEditor.findPath(slateEditor, element);
              updateSlateEditor(slateEditor, path, content);
            }
          }
        );
      }

      return _ => {
        if (changeSubscription) {
          changeSubscription.dispose();
        }
      };
    },
    [slateEditor, element, updateSlateEditor]
  );

  const enableSQLAssistant = useMemo(
    _ => {
      if (
        userMe &&
        userMe.settings &&
        userMe.settings.some(setting => setting.key === 'enable_sql_assistant')
      ) {
        const setting = userMe.settings.find(
          setting => setting.key === 'enable_sql_assistant'
        );
        return setting.value === 'true';
      }
      return true;
    },
    [userMe]
  );

  const emitTablesRefreshIfRequired = useCallback(
    content => {
      const required = TABLES_UPDATE_SQL_PATTERNS.some(pattern => {
        return content.toLowerCase().includes(pattern);
      });
      if (required) {
        emitTables();
      }
    },
    [emitTables]
  );

  const emitFilesRefreshIfRequired = useCallback(
    content => {
      const required = FILES_UPDATE_SQL_PATTERNS.some(pattern => {
        return content.toLowerCase().includes(pattern);
      });
      if (required) {
        emitFiles();
      }
    },
    [emitFiles]
  );

  const handleClose = useCallback(
    _ => {
      const path = ReactEditor.findPath(slateEditor, element);
      Transforms.delete(slateEditor, { at: path });
      Transforms.deselect(slateEditor);
    },
    [slateEditor, element]
  );

  useEffect(
    _ => {
      let subscription = null;
      if (editorRef.current) {
        subscription = editorRef.current.editor.addAction({
          id: 'delete-block',
          label: 'Delete Block',
          keybindings: [
            monaco.KeyMod.WinCtrl | monaco.KeyCode.Backspace,
            monaco.KeyMod.WinCtrl | monaco.KeyCode.Delete,
          ],
          run: handleClose,
        });
      }

      return _ => {
        if (subscription) {
          subscription.dispose();
        }
      };
    },
    [handleClose]
  );

  const addOrUpdateBlockResult = useCallback(
    (elem, result) => {
      updateQueryResult(elem, result);
      return addOrUpdateBlockResultHelper(elem.id, result);
    },
    [updateQueryResult]
  );

  const handleRun = useCallback(
    sql => {
      analytics.track(analytics.EVENT_TYPES.RUN_BLOCK)(
        workbook.is_example ? { title: workbook.name } : {}
      );

      // Generate unique ID to track query job
      const query_id = uuid();

      dispatch(
        addOrUpdateBlockResult(element, {
          queryError: null,
          queryResponse: null,
          queryRunning: true,
          queryID: query_id,
        })
      )
        .then(_ => {
          const udf_regex = /EXECUTE FUNCTION (.*)\(/gi;
          const match = udf_regex.exec(
            sql && typeof sql === 'string' ? sql : element.content
          );
          if (match && match.length === 2) {
            setAutoRefresh(true);
            setUDFName(match[1].trim());
          } else {
            setAutoRefresh(false);
            setUDFName(null);
          }
          return executeSql({
            variables: {
              statement: sql && typeof sql === 'string' ? sql : element.content,
              limit: EXECUTE_SQL_LIMIT,
              query_id,
            },
          });
        })
        .then(async res => {
          setAutoRefresh(false);

          // If not a select query, refresh explorer data for any updates
          if (res?.data?.executeSql?.response?.total_number_of_records === -1) {
            await graphqlClient.query({
              query: GraphQLServices.DataObjects.GET_DATA_OBJECTS,
            });
          }

          // Check if tables list requires refresh
          emitTablesRefreshIfRequired(element.content);

          // Check if files list requires refresh
          emitFilesRefreshIfRequired(element.content);

          return dispatch(
            addOrUpdateBlockResult(element, {
              queryError: res?.errors?.[0],
              queryResponse: res.data.executeSql,
              queryRunning: false,
              queryID: query_id,
            })
          );
        })
        .catch(err =>
          dispatch(
            addOrUpdateBlockResult(element, {
              queryError: err,
              queryResponse: null,
              queryRunning: false,
              queryID: query_id,
            })
          )
        );
    },
    [
      element,
      executeSql,
      dispatch,
      graphqlClient,
      analytics,
      workbook,
      emitTablesRefreshIfRequired,
      emitFilesRefreshIfRequired,
      addOrUpdateBlockResult,
    ]
  );

  const handleIntervalRun = useCallback(() => {
    // Generate unique ID to track query job
    const query_id = uuid();

    executeSql({
      variables: {
        statement: element.content,
        limit: EXECUTE_SQL_LIMIT,
        query_id,
      },
    })
      .then(async res => {
        // Check if tables list requires refresh
        emitTablesRefreshIfRequired(element.content);

        // Check if files list requires refresh
        emitFilesRefreshIfRequired(element.content);

        return dispatch(
          addOrUpdateBlockResult(element, {
            queryError: res?.errors?.[0],
            queryResponse: res.data.executeSql,
            queryRunning: false,
            queryID: query_id,
          })
        );
      })
      .catch(err =>
        dispatch(
          addOrUpdateBlockResult(element, {
            queryError: err,
            queryResponse: null,
            queryRunning: false,
            queryID: query_id,
          })
        )
      );
  }, [
    element,
    executeSql,
    dispatch,
    emitTablesRefreshIfRequired,
    emitFilesRefreshIfRequired,
    addOrUpdateBlockResult,
  ]);

  const handleFormatSQL = _ => {
    if (editorRef.current) {
      const current = editorRef.current.editor.getValue();
      editorRef.current.editor.setValue(
        formatSQL(current, {
          language: 'postgresql',
          keywordCase: 'upper',
          dataTypeCase: 'upper',
          functionCase: 'upper',
          logicalOperatorNewline: 'after',
          expressionWidth: 80,
          indentStyle: 'standard',
          tabWidth: 4,
          useTabs: false,
        })
      );
    }
  };

  const updateSQLBlock = sql => {
    if (editorRef.current) {
      editorRef.current.editor.setValue(sql);
    }
  };

  const handleExplainPlan = useCallback(
    async (run, callback) => {
      setShowLegend(run);
      setShowExplainPlan(true);
      const resp = await explainPlan({
        variables: {
          statement: element.content,
          limit: EXECUTE_SQL_LIMIT,
          run,
        },
      });
      try {
        if (resp?.errors) {
          throw new Error(resp?.errors[0]?.message);
        }
        const { data } = resp?.data?.explainPlan?.response;
        const plan = JSON.parse(data?.column_1[data?.column_1.length - 1]);
        setPlan(plan);
      } catch (err) {
        setPlan({ error: err?.message });
      } finally {
        if (callback) {
          callback();
        }
      }
    },
    [element, setPlan, explainPlan]
  );

  useEffect(
    _ => {
      let subscription = null;
      if (editorRef.current) {
        subscription = editorRef.current.editor.addAction({
          id: 'run-block',
          label: 'Run Block',
          keybindings: [
            monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
            monaco.KeyMod.WinCtrl | monaco.KeyCode.Enter,
          ],
          run: _ => {
            handleRun(editorRef.current.editor.getValue());
          },
        });
      }

      return _ => {
        if (subscription) {
          subscription.dispose();
        }
      };
    },
    [handleRun]
  );

  useEffect(
    _ => {
      let subscription = null;
      if (editorRef.current) {
        subscription = editorRef.current.editor.addAction({
          id: 'format-sql',
          label: 'Format SQL',
          keybindings: [monaco.KeyMod.Alt | monaco.KeyCode.KeyF],
          run: handleFormatSQL,
        });
      }

      return _ => {
        if (subscription) {
          subscription.dispose();
        }
      };
    },
    [handleRun]
  );

  const index = useMemo(
    _ => {
      const { id } = element;
      return blocks.findIndex(e => e.id === id) + 1;
    },
    [blocks, element]
  );

  const monacoScrollbar = useMemo(_ => {
    return { alwaysConsumeMouseWheel: false };
  }, []);

  const monacoMinimap = useMemo(_ => {
    return {
      enabled: false,
    };
  }, []);

  const { isSqlVisible, isBlockVisible, isSqlGptEnabled, isBlockCollapsed } =
    useMemo(
      _ => {
        const {
          isSqlVisible = true,
          isBlockVisible = true,
          isSqlGptEnabled = false,
          isBlockCollapsed = false,
        } = element?.config ?? {};
        return {
          isSqlVisible,
          isBlockVisible,
          isSqlGptEnabled,
          isBlockCollapsed,
        };
      },
      [element]
    );

  const runningJobs = useMemo(
    _ => {
      return jobs.filter(job => {
        const QUERY_ID_REGEX =
          /-- QUERY ID ([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12})/gi;
        const { user_data = '' } = job;
        const match = QUERY_ID_REGEX.exec(user_data);
        return match && match.length === 2 && queryID === match[1];
      });
    },
    [jobs, queryID]
  );

  const handleJobDelete = job_id => async _ => {
    setIsJobCancelling(true);

    cancelJobsByIds({
      variables: {
        job_ids: [Number(job_id)],
        action: 'cancel',
      },
    })
      .then(resp => {
        toggleQueryStatus(true)();
      })
      .finally(_ => {
        setIsJobCancelling(false);
      });
  };

  const jobs_columns = [
    {
      key: 'job_id',
      title: 'Job ID',
      dataIndex: 'job_id',
      width: 90,
    },
    {
      key: 'endpoint_name',
      title: 'Endpoint',
      dataIndex: 'endpoint_name',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      width: 120,
      render: text => {
        return CUSTOM_STATUS_MAPPING[text] ?? text;
      },
    },
    {
      key: 'worker_statuses',
      title: 'Ranks',
      dataIndex: 'worker_statuses',
      render: formatWorkerStatuses,
    },
    {
      key: 'time_received',
      title: 'Received',
      dataIndex: 'time_received',
      render: formatTimeReceived,
    },
    {
      key: 'job_id',
      title: '',
      dataIndex: 'job_id',
      width: 60,
      render: job_id => {
        return (
          <Popconfirm
            title="Are you sure you want to cancel this job?"
            onConfirm={handleJobDelete(job_id)}
          >
            <Button type="danger" size="small">
              Cancel
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const toggleQueryStatus = useCallback(
    (override = false) =>
      async _ => {
        setIsJobsPanelOpen(override || !isJobsPanelOpen);

        if (override || !isJobsPanelOpen) {
          try {
            setIsFetchingJobs(true);
            const { data } = await graphqlClient.query({
              query: GET_ENDPOINT_JOBS,
              fetchPolicy: 'no-cache',
            });
            if (data.endpoint_jobs) {
              setJobs(data.endpoint_jobs);
            } else {
              setJobs([]);
            }
          } catch (error) {
            setJobs([]);
          }
        } else {
          setJobs([]);
        }
      },
    [graphqlClient, isJobsPanelOpen]
  );

  const jobsInterval = useRef(0);

  useEffect(
    _ => {
      if (isJobsPanelOpen && !queryResponse) {
        setIsFetchingJobs(true);
        jobsInterval.current = setInterval(() => {
          toggleQueryStatus(true)();
        }, 3000);
      } else {
        setIsFetchingJobs(false);
        clearInterval(jobsInterval.current);
      }
      return _ => {
        clearInterval(jobsInterval.current);
      };
    },
    [isJobsPanelOpen, queryResponse, toggleQueryStatus]
  );

  useEffect(() => {
    if (udfName) {
      const check = async () => {
        const {
          data: { udf_exists },
        } = await graphqlClient.query({
          query: GraphQLServices.UDFs.GET_UDF_EXISTS,
          variables: {
            proc_name: udfName,
          },
        });
        setIsValidProc(udf_exists && udf_exists.proc_exists);
      };
      check();
    } else {
      setIsValidProc(false);
    }
  }, [graphqlClient, udfName]);

  if (readOnly && !isBlockVisible) {
    return null;
  }

  return (
    <div
      {...attributes}
      contentEditable={false}
      style={
        readOnly
          ? {
              margin: '0px',
              position: 'relative',
              padding: '0px 5px',
            }
          : {
              margin: '8px 0px 26px',
              position: 'relative',
              border: '1px dotted #3700b322',
              borderLeft: '4px solid #3700b333',
              padding: '5px',
            }
      }
    >
      {readOnly && <ReadOnlyDivider index={index} />}
      {!readOnly && <BlockIndex index={index} />}
      <BlockToolbar
        element={element}
        blocks={blocks}
        showExplainPlan={handleExplainPlan}
        formatSQL={handleFormatSQL}
        runAllFromBlock={runAllFromBlock}
        toggleSqlVisibility={toggleSqlVisibility}
        toggleBlockCollapsed={toggleBlockCollapsed}
        toggleBlockVisibility={toggleBlockVisibility}
        toggleSqlGpt={toggleSqlGpt}
        toggleQueryStatus={toggleQueryStatus(false)}
        loading={loading}
        setLoading={setLoading}
        queryID={queryID}
        queryRunning={queryRunning}
        queryResponse={queryResponse}
        enableSQLAssistant={enableSQLAssistant}
      />
      {isBlockCollapsed && (
        <CollapsedBlock
          element={element}
          handleRun={handleRun}
          loading={loading}
          queryRunning={queryRunning}
        />
      )}
      {enableSQLAssistant &&
        !isBlockCollapsed &&
        !readOnly &&
        !workbook.is_example &&
        isSqlGptEnabled &&
        worksheet && (
          <SqlSuggestionContext
            workbook={workbook}
            worksheet={worksheet}
            block={element}
            handleResult={updateSQLBlock}
            handleClose={toggleSqlGpt}
            disabled={false}
          />
        )}
      {!isBlockCollapsed && (
        <Spin indicator={<Spinner />} spinning={loading || queryRunning}>
          {!readOnly || isSqlVisible ? (
            <MonacoEditor
              ref={editorRef}
              language="kinetica-sql"
              contextmenu={false}
              renderLineHighlight={'none'}
              scrollBeyondLastLine={false}
              scrollbar={monacoScrollbar}
              overviewRulerLanes={0}
              automaticLayout={true}
              acceptSuggestionOnEnter={'on'}
              quickSuggestionsDelay={400}
              matchOnWordStartOnly={false}
              tabCompletion={'off'}
              minimap={monacoMinimap}
              style={{
                width: 'calc(100% - 50px)',
                height: `${editorHeight}px`,
                marginTop: '10px',
                marginLeft: '10px',
                pointerEvents: 'all',
              }}
            ></MonacoEditor>
          ) : (
            <div
              style={{
                margin: '3px 5px 0px 40px',
                padding: '1px 8px',
                color: '#cccccc',
                backgroundColor: '#f6f6f6',
                borderRadius: '5px',
              }}
            >
              SQL Hidden
            </div>
          )}
          <div
            style={{
              position: 'absolute',
              top: '-5px',
              height: `${editorHeight}px`,
              left: '0px',
            }}
          >
            <div
              style={{
                position: 'sticky',
                float: 'right',
                width: 'auto',
                top: '0px',
                left: '0px',
                zIndex: 1,
                pointerEvents: 'all',
              }}
            >
              <Button
                type="text"
                onClick={handleRun}
                icon={
                  <PlayCircleOutlined
                    style={{ fontSize: '25px', color: '#3700b399' }}
                  />
                }
              ></Button>
            </div>
          </div>
          {element?.id && (
            <div style={{ padding: '5px', pointerEvents: 'all' }}>
              <ResultView
                element={element}
                queryResponse={queryResponse}
                queryError={queryError}
                readOnly={readOnly}
                run={handleIntervalRun}
                updateQueryResult={updateQueryResult}
              ></ResultView>
            </div>
          )}
          <div
            contentEditable={false}
            style={{
              display: 'none',
              userSelect: 'none',
              pointerEvents: 'none',
            }}
          >
            {children}
          </div>
        </Spin>
      )}
      {udfName && isValidProc && (
        <div className="udfs">
          <UDFStatus
            proc_name={udfName}
            auto_refresh={autoRefresh ? 5000 : 0}
            is_block={true}
            style={{ padding: '10px', pointerEvents: 'all' }}
          />
        </div>
      )}
      {isJobsPanelOpen && queryID && !queryResponse && (
        <div className="jobs" style={{ padding: '10px', pointerEvents: 'all' }}>
          <Button
            key="close"
            onClick={toggleQueryStatus()}
            icon={<CloseOutlined />}
            style={{ float: 'right', marginBottom: '10px', marginLeft: '5px' }}
            size="small"
          ></Button>
          <Button
            key="refresh"
            onClick={toggleQueryStatus(true)}
            icon={<RedoOutlined />}
            loading={isFetchingJobs || isJobCancelling}
            style={{ float: 'right', marginBottom: '10px' }}
            size="small"
          ></Button>
          <h4 style={{ display: 'inline-block' }}>Query Status</h4>
          <Table
            columns={jobs_columns}
            dataSource={runningJobs}
            rowKey="job_id"
            pagination={false}
            scroll={{
              x: 'max-content',
            }}
            locale={{
              emptyText: <span>No running jobs found</span>,
            }}
            size="small"
          />
        </div>
      )}
      {showExplainPlan && (
        <ExplainPlanModal
          visible={showExplainPlan}
          setVisible={visible => {
            setPlan(undefined);
            setShowLegend(false);
            setShowExplainPlan(visible);
          }}
          plan={plan}
          handleExplainPlan={handleExplainPlan}
          legend={showLegend}
          loading={explainPlanLoading}
          width={window.innerWidth - 150}
          height={window.innerHeight - 250}
        />
      )}
    </div>
  );
}
