// Imports
import React, { useCallback, useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Checkbox,
  Transfer,
  Table,
  Empty,
  Popover,
  Collapse,
  Badge,
} from 'antd';
import { useApolloClient } from '@apollo/client';

// App Imports
import GraphQLServices from '../../graphql/services';
import {
  useRoleOptions,
  // useResourceGroupOptions,
  useSchemaOptions,
  useSchemaTableOptions,
  useSqlProcedureOptions,
  useDatasourceOptions,
  useDatasinkOptions,
  usePermissionOptions,
  useFolderFilesOptions,
  checkUserAdminRequired,
  waitFor,
} from './helper';
import {
  SYSTEM_PERMISSIONS,
  PROC_PERMISSIONS,
  RESOURCE_GROUP_DEFAULT_NAME,
  DEFAULT_SCHEMA,
  TABLE_PERMISSIONS,
  DATASOURCE_PERMISSIONS,
  DATASINK_PERMISSIONS,
  USERNAME_BLACKLIST,
  FILES_PERMISSIONS,
  K8S_USER_CR_CHECK_MAXWAIT,
  K8S_USER_CR_CHECK_WAITINTERVAL,
} from '../../constants';
import {
  USER_CREATE_TYPE,
  K8S_EXTERNAL_USERNAME_PREFIX,
} from '../../setup/config';
import { checkPassword, displaySuccess, displayError } from '../../helper';

const { Column } = Table;
const { Panel } = Collapse;

const UserCreateModal = ({
  cluster,
  visible,
  close,
  callback,
  width,
  height,
}) => {
  const [createUser] = GraphQLServices.Users.useCreateUser();
  const [addRoleByName] = GraphQLServices.Users.useAddRoleByName();
  const [alterUserByName] = GraphQLServices.Users.useAlterUserByName();
  const [grantPermission] = GraphQLServices.Users.useGrantPermissionByName();
  const [grantFilesPermission] =
    GraphQLServices.Files.useGrantFolderPermission();
  const [grantTablePermission] =
    GraphQLServices.TablePermissions.useGrantTablePermission();
  const [grantProcPermission] =
    GraphQLServices.ProcPermissions.useGrantProcPermission();
  const [grantSqlProcPermission] =
    GraphQLServices.SqlProcPermissions.useGrantSqlProcPermission();
  const [grantDatasourcePermission] =
    GraphQLServices.DatasourcePermissions.useGrantDatasourcePermission();
  const [grantDatasinkPermission] =
    GraphQLServices.DatasinkPermissions.useGrantDatasinkPermission();
  const [createK8SKineticaGrant] =
    GraphQLServices.K8sKineticaGrants.useCreateK8SKineticaGrant();

  const grantK8sSystemPermission = useCallback(
    (name, permission, ringName) => {
      return createK8SKineticaGrant({
        variables: {
          name: `${name}_${permission}_system_permission`,
          body: {
            spec: {
              addGrantPermissionRequest: {
                name: `${K8S_EXTERNAL_USERNAME_PREFIX}${name}`,
                systemPermission: {
                  name: `${K8S_EXTERNAL_USERNAME_PREFIX}${name}`,
                  permission,
                },
              },
              ringName,
            },
          },
        },
      });
    },
    [createK8SKineticaGrant]
  );

  const { data: { permissions = [] } = {} } =
    GraphQLServices.Permissions.useGetPermissions();
  const {
    data: {
      roles = [],
      // resource_groups = [],
      schemas = [],
      sql_procedures = [],
      datasources = [],
      datasinks = [],
      tables = [],
      folders = [],
    } = {},
  } = GraphQLServices.Helpers.useGetUserRoleOptions();

  const { data: { auth_providers = [] } = {} } =
    GraphQLServices.AuthProviders.useGetAuthProviders();

  const [isCreating, setIsCreating] = useState(false);
  const [targetPermissions, setTargetPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [targetSqlProcedures, setTargetSqlProcedures] = useState([]);
  const [selectedSqlProcedures, setSelectedSqlProcedures] = useState([]);
  const [tablePermissions, setTablePermissions] = useState([]);
  const [filesPermissions, setFilesPermissions] = useState([]);
  const [datasourcePermissions, setDatasourcePermissions] = useState([]);
  const [datasinkPermissions, setDatasinkPermissions] = useState([]);

  const graphqlClient = useApolloClient();

  const [form] = Form.useForm();

  const roleOptions = useRoleOptions(roles);
  // const resourceGroupOptions = useResourceGroupOptions(resource_groups);
  const schemaOptions = useSchemaOptions(schemas);
  const schemaTableOptions = useSchemaTableOptions(
    schemas,
    tables,
    tablePermissions
  );
  const sqlProcedureOptions = useSqlProcedureOptions(sql_procedures);
  const datasourceOptions = useDatasourceOptions(datasources);
  const datasinkOptions = useDatasinkOptions(datasinks);
  const permissionOptions = usePermissionOptions(permissions);
  const folderFilesOptions = useFolderFilesOptions(folders);

  const onFieldsChange = async (changedFields, allFields) => {
    // Check if user/role workbench permission selected
    // that required system permission selected
    if (
      changedFields.some(field => {
        return ['permissions', 'system_permissions'].some(f =>
          field.name.includes(f)
        );
      })
    ) {
      const userAdminRequired = checkUserAdminRequired(form);
      form.setFields([
        {
          name: 'system_permissions',
          errors: userAdminRequired
            ? [
                'Admin or User Admin system permission required for user workbench permissions',
              ]
            : [],
        },
      ]);
    }
  };

  const onFinish = async values => {
    const {
      username,
      password,
      first_name = 'Kinetica',
      last_name = 'User',
      roles = [],
      resource_group,
      default_schema,
      permissions = [],
      system_permissions = [],
      proc_permissions = [],
      sql_proc_permissions = [],
    } = values;

    setIsCreating(true);

    const createUserResp = await createUser({
      variables: {
        username: username.toLowerCase(),
        password,
        first_name,
        last_name,
        type: USER_CREATE_TYPE,
        auth_provider_id: auth_providers[0]?.id,
        ring: cluster?.metadata?.name,
      },
    });
    if (createUserResp?.errors) {
      setIsCreating(false);
      return;
    }

    // Wait until user has been created
    const result = await waitFor(
      async _ => {
        const resp = await graphqlClient.query({
          query: GraphQLServices.K8sKineticaUsers.GET_K8S_KINETICAUSER,
          variables: {
            username,
          },
        });
        const status = resp?.data?.k8s_kineticauser?.status ?? {};
        const { db_response, ldap_response } = status;
        console.table({ username, db_response, ldap_response });
        return status && db_response?.status === 'OK' && ldap_response === 'OK';
      },
      K8S_USER_CR_CHECK_MAXWAIT,
      K8S_USER_CR_CHECK_WAITINTERVAL
    );

    if (!result) {
      const message = `User ${username} provision check failed!`;
      displayError(message);
      if (callback) {
        callback(new Error(message), null);
      }

      setIsCreating(false);
      return;
    }

    await Promise.all(
      roles.map(role_name => {
        return addRoleByName({
          variables: {
            username: username.toLowerCase(),
            role_name,
            ring: cluster?.metadata?.name,
          },
        });
      })
    );

    await Promise.all(
      permissions.map(permission_name => {
        return grantPermission({
          variables: {
            username: username.toLowerCase(),
            permission_name,
          },
        });
      })
    );

    await Promise.all(
      system_permissions.map(permission => {
        return grantK8sSystemPermission(
          username.toLowerCase(),
          permission,
          cluster?.metadata?.name
        );
      })
    );

    await alterUserByName({
      variables: {
        name: username.toLowerCase(),
        action: 'set_resource_group',
        value:
          resource_group === RESOURCE_GROUP_DEFAULT_NAME ? '' : resource_group,
      },
    });

    await alterUserByName({
      variables: {
        name: username.toLowerCase(),
        action: 'set_default_schema',
        value: default_schema ?? '',
      },
    });

    await Promise.all(
      proc_permissions.map(permission => {
        return grantProcPermission({
          variables: {
            name: username.toLowerCase(),
            permission,
            proc_name: '',
          },
        });
      })
    );

    await Promise.all(
      sql_proc_permissions.map(sql_proc_name => {
        return grantSqlProcPermission({
          variables: {
            name: username.toLowerCase(),
            sql_proc_name,
          },
        });
      })
    );

    await Promise.all(
      datasourcePermissions.reduce((acc, cur) => {
        acc.push(
          DATASOURCE_PERMISSIONS.filter(permission => {
            return cur[permission.value];
          }).map(permission => {
            return grantDatasourcePermission({
              variables: {
                name: username.toLowerCase(),
                permission: permission.value.replace(/datasource_/g, ''),
                datasource_name: cur.datasource,
              },
            });
          })
        );
        return acc;
      }, [])
    );

    await Promise.all(
      datasinkPermissions.reduce((acc, cur) => {
        acc.push(
          DATASINK_PERMISSIONS.filter(permission => {
            return cur[permission.value];
          }).map(permission => {
            return grantDatasinkPermission({
              variables: {
                name: username.toLowerCase(),
                permission: permission.value.replace(/datasink_/g, ''),
                datasink_name: cur.datasink,
              },
            });
          })
        );
        return acc;
      }, [])
    );

    await Promise.all(
      tablePermissions.reduce((acc, cur) => {
        acc.push(
          TABLE_PERMISSIONS.filter(permission => {
            return cur[permission.value];
          }).map(permission => {
            return grantTablePermission({
              variables: {
                name: username,
                permission: permission.value,
                table_name: cur.table,
                filter_expression: '',
              },
            });
          })
        );
        return acc;
      }, [])
    );

    await Promise.all(
      filesPermissions.reduce((acc, cur) => {
        acc.push(
          FILES_PERMISSIONS.filter(permission => {
            return cur[permission.value];
          }).map(permission => {
            return grantFilesPermission({
              variables: {
                name: username,
                permission: permission.value,
                directory_name: cur.folder !== '<ROOT>' ? cur.folder : '',
              },
            });
          })
        );
        return acc;
      }, [])
    );

    setIsCreating(false);
    displaySuccess(`User ${username} created successfully!`);

    if (callback) {
      callback(null, createUserResp);
    }
  };

  const create = async _ => {
    let hasError = false;
    const username = form.getFieldValue('username') || '';
    const password = form.getFieldValue('password') || '';

    // Check username is all lowercase
    const regex = /^[a-z_][a-z0-9_]*/g;
    const match = username.match(regex);
    if (match === null || (match.length > 0 && match[0] !== username)) {
      form.setFields([
        {
          name: 'username',
          errors: [
            'Username must start with a-z or _ and complete with a-z, 0-9 or _',
          ],
        },
      ]);
      hasError = true;
    }

    // Check username blacklist
    if (USERNAME_BLACKLIST.includes(username)) {
      form.setFields([
        {
          name: 'username',
          errors: ['Username is restricted!'],
        },
      ]);
      hasError = true;
    }

    // Check if username already exists
    const respUser = await graphqlClient.query({
      query: GraphQLServices.Users.CHECK_USER_BY_USERNAME,
      variables: {
        username,
      },
    });
    if (respUser?.data?.user) {
      form.setFields([
        {
          name: 'username',
          errors: ['Username already exists!'],
        },
      ]);
      hasError = true;
    }

    // Check if role name already exists
    const respRole = await graphqlClient.query({
      query: GraphQLServices.Roles.CHECK_ROLE_BY_NAME,
      variables: {
        name: username,
      },
    });
    if (respRole?.data?.role) {
      form.setFields([
        {
          name: 'username',
          errors: ['Role already exists with username!'],
        },
      ]);
      hasError = true;
    }

    // Check for 'strong' password
    if (!checkPassword(password)) {
      form.setFields([
        {
          name: 'password',
          errors: [
            'At least 8 characters containing one lower case, one upper case, and one number are required',
          ],
        },
      ]);
      hasError = true;
    }

    // Check if user/role workbench permission selected
    // that required system permission selected
    const userAdminRequired = checkUserAdminRequired(form);
    if (userAdminRequired) {
      form.setFields([
        {
          name: 'system_permissions',
          errors: [
            'Admin or User Admin system permission required for user workbench permissions',
          ],
        },
      ]);
      hasError = true;
    }

    if (!hasError) {
      form.submit();
    }
  };

  const handlePermissionChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetPermissions(nextTargetKeys);
  };

  const handlePermissionSelectChange = (
    sourceSelectedKeys,
    targetSelectedKeys
  ) => {
    setSelectedPermissions([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleSqlProcedureChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetSqlProcedures(nextTargetKeys);
  };

  const handleSqlProcedureSelectChange = (
    sourceSelectedKeys,
    targetSelectedKeys
  ) => {
    setSelectedSqlProcedures([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleAddDatasourcePermission = _ => {
    const datasource = form.getFieldValue('datasource_permission_datasource');
    if (datasource) {
      setDatasourcePermissions([
        ...datasourcePermissions,
        {
          datasource,
          ...DATASOURCE_PERMISSIONS.reduce((acc, cur) => {
            acc[cur.value] = false;
            return acc;
          }, {}),
        },
      ]);
      form.setFieldsValue({ datasource_permission_datasource: '' });
    }
  };

  const handleDeleteDatasourcePermission =
    ({ datasource }) =>
    _ => {
      setDatasourcePermissions([
        ...datasourcePermissions.filter(perm => perm.datasource !== datasource),
      ]);
    };

  const handleDatasourcePermissionChange =
    ({ datasource }, permType) =>
    e => {
      setDatasourcePermissions(
        datasourcePermissions.map(perm => {
          if (perm.datasource === datasource) {
            return {
              ...perm,
              [permType]: e.target.checked,
            };
          }
          return perm;
        })
      );
    };

  const handleAddDatasinkPermission = _ => {
    const datasink = form.getFieldValue('datasink_permission_datasink');
    if (datasink) {
      setDatasinkPermissions([
        ...datasinkPermissions,
        {
          datasink,
          ...DATASINK_PERMISSIONS.reduce((acc, cur) => {
            acc[cur.value] = false;
            return acc;
          }, {}),
        },
      ]);
      form.setFieldsValue({ datasink_permission_datasink: '' });
    }
  };

  const handleDeleteDatasinkPermission =
    ({ datasink }) =>
    _ => {
      setDatasinkPermissions([
        ...datasinkPermissions.filter(perm => perm.datasink !== datasink),
      ]);
    };

  const handleDatasinkPermissionChange =
    ({ datasink }, permType) =>
    e => {
      setDatasinkPermissions(
        datasinkPermissions.map(perm => {
          if (perm.datasink === datasink) {
            return {
              ...perm,
              [permType]: e.target.checked,
            };
          }
          return perm;
        })
      );
    };

  const handleAddTablePermission = _ => {
    const table = form.getFieldValue('table_permission_table');
    if (table) {
      setTablePermissions([
        ...tablePermissions,
        {
          table,
          ...TABLE_PERMISSIONS.reduce((acc, cur) => {
            acc[cur.value] = false;
            return acc;
          }, {}),
        },
      ]);
      form.setFieldsValue({ table_permission_table: '' });
    }
  };

  const handleDeleteTablePermission =
    ({ table }) =>
    _ => {
      setTablePermissions([
        ...tablePermissions.filter(perm => perm.table !== table),
      ]);
    };

  const handleTablePermissionChange =
    ({ table }, permType) =>
    e => {
      setTablePermissions(
        tablePermissions.map(perm => {
          if (perm.table === table) {
            return {
              ...perm,
              [permType]: e.target.checked,
            };
          }
          return perm;
        })
      );
    };

  const handleAddFilesPermission = _ => {
    const folder = form.getFieldValue('files_permission_folder');
    if (folder) {
      setFilesPermissions([
        ...filesPermissions,
        {
          folder,
          ...FILES_PERMISSIONS.reduce((acc, cur) => {
            acc[cur.value] = false;
            return acc;
          }, {}),
        },
      ]);
      form.setFieldsValue({ files_permission_folder: '' });
    }
  };

  const handleDeleteFilesPermission =
    ({ folder }) =>
    _ => {
      setFilesPermissions([
        ...filesPermissions.filter(perm => perm.folder !== folder),
      ]);
    };

  const handleFilesPermissionChange =
    ({ folder }, permType) =>
    e => {
      setFilesPermissions(
        filesPermissions.map(perm => {
          if (perm.folder === folder) {
            return {
              ...perm,
              [permType]: e.target.checked,
            };
          }
          return perm;
        })
      );
    };

  const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 17 },
  };

  const formLayoutSingle = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };

  const panelStyle = {
    border: '1px solid #eeeeee',
    backgroundColor: '#f9f9f9',
    marginBottom: '10px',
    overflow: 'hidden',
  };

  const panelActiveStyle = {
    border: '1px solid #dddddd',
    backgroundColor: '#eeeeee',
    marginBottom: '10px',
    overflow: 'hidden',
  };

  const badgeStyle = { backgroundColor: '#108ee9' };

  return (
    <Modal
      title="Create User"
      open={visible}
      width={width}
      height={height}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={create}
          loading={isCreating}
        >
          Create
        </Button>,
      ]}
      onCancel={close}
      maskClosable={false}
      destroyOnClose
      centered
    >
      <div
        style={{
          height: height - 180,
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        <Form
          {...formLayout}
          form={form}
          name="user"
          layout="horizontal"
          initialValues={{}}
          onFieldsChange={onFieldsChange}
          onFinish={onFinish}
          colon={false}
          preserve={false}
        >
          <Row gutter={0}>
            <Col span={11}>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input user username!',
                    whitespace: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Password"
                name="password"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                rules={[
                  {
                    required: true,
                    message: 'Please input user password!',
                  },
                ]}
              >
                <Input type="password" autoComplete="new-password" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={0}>
            <Col span={11}>
              <Form.Item label="Roles" name="roles">
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select role(s)"
                >
                  {roleOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Default Schema"
                name="default_schema"
                initialValue={DEFAULT_SCHEMA}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                rules={[
                  {
                    required: true,
                    message: 'Please select a default schema!',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select role(s)"
                >
                  {schemaOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={0}>
            <Col span={14}>
              <Form.Item
                label="Resource Group"
                name="resource_group"
                initialValue={RESOURCE_GROUP_DEFAULT_NAME}
                rules={[
                  {
                    required: true,
                    message: 'Please select a resource group!',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select resource group"
                >
                  {resourceGroupOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={9}>
              
            </Col>
          </Row> */}
          <Row gutter={0}>
            <Col span={20} offset={3}>
              <h3 style={{ marginLeft: '-5px' }}>Permissions</h3>
            </Col>
          </Row>
          <Row gutter={0}>
            <Col span={12}>
              <Form.Item
                label="System"
                name="system_permissions"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
              >
                <Checkbox.Group options={SYSTEM_PERMISSIONS} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Proc"
                name="proc_permissions"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
              >
                <Checkbox.Group options={PROC_PERMISSIONS} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={2}></Col>
            <Col span={21}>
              <div style={{ marginLeft: '30px' }}>
                <Collapse
                  style={{ border: '0px', backgroundColor: 'transparent' }}
                >
                  <Panel
                    header="Workbench"
                    key="workbench"
                    style={
                      targetPermissions.length > 0
                        ? panelActiveStyle
                        : panelStyle
                    }
                    extra={
                      <Badge
                        count={targetPermissions.length}
                        style={badgeStyle}
                      />
                    }
                  >
                    <Form.Item name="permissions" {...formLayoutSingle}>
                      <Transfer
                        dataSource={permissionOptions}
                        titles={['Available', 'Selected']}
                        targetKeys={targetPermissions}
                        selectedKeys={selectedPermissions}
                        onChange={handlePermissionChange}
                        onSelectChange={handlePermissionSelectChange}
                        render={item => item.title}
                        listStyle={{ width: '100%' }}
                        locale={{
                          notFoundContent: (
                            <Empty description="No Permissions" />
                          ),
                        }}
                      />
                    </Form.Item>
                  </Panel>
                  <Panel
                    header="Table/Schema"
                    key="table"
                    style={
                      tablePermissions.length > 0
                        ? panelActiveStyle
                        : panelStyle
                    }
                    extra={
                      <Badge
                        count={tablePermissions.length}
                        style={badgeStyle}
                      />
                    }
                  >
                    <Row gutter={10}>
                      <Col span={20}>
                        <Form.Item
                          name="table_permission_table"
                          {...formLayoutSingle}
                        >
                          <Select
                            allowClear
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select table/schema"
                          >
                            {schemaTableOptions}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Button
                          type="primary"
                          onClick={handleAddTablePermission}
                          ghost
                          block
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <Table
                      dataSource={tablePermissions}
                      rowKey="table"
                      size="small"
                    >
                      <Column
                        key="table"
                        title="Table/Schema"
                        dataIndex="table"
                      ></Column>
                      {TABLE_PERMISSIONS.map(perm => {
                        return (
                          <Column
                            key={perm.value}
                            title={
                              perm.content ? (
                                <Popover
                                  title={perm.label}
                                  content={
                                    <ul>
                                      {perm.content.map((line, idx) => (
                                        <li key={idx}>{line}</li>
                                      ))}
                                    </ul>
                                  }
                                >
                                  {perm.label}
                                </Popover>
                              ) : (
                                perm.label
                              )
                            }
                            dataIndex={perm.value}
                            align="center"
                            width={70}
                            render={(text, record, index) => {
                              return (
                                <Checkbox
                                  onChange={handleTablePermissionChange(
                                    record,
                                    perm.value
                                  )}
                                />
                              );
                            }}
                          ></Column>
                        );
                      })}
                      <Column
                        key="delete"
                        title="Action"
                        dataIndex="delete"
                        width={70}
                        render={(text, record, index) => {
                          return (
                            <Button
                              size="small"
                              onClick={handleDeleteTablePermission(record)}
                              danger
                              block
                            >
                              Delete
                            </Button>
                          );
                        }}
                      ></Column>
                    </Table>
                  </Panel>
                  <Panel
                    header="Data Source"
                    key="datasource"
                    style={
                      datasourcePermissions.length > 0
                        ? panelActiveStyle
                        : panelStyle
                    }
                    extra={
                      <Badge
                        count={datasourcePermissions.length}
                        style={badgeStyle}
                      />
                    }
                  >
                    <Row gutter={10}>
                      <Col span={20}>
                        <Form.Item
                          name="datasource_permission_datasource"
                          {...formLayoutSingle}
                        >
                          <Select
                            allowClear
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select data source"
                          >
                            {datasourceOptions}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Button
                          type="primary"
                          onClick={handleAddDatasourcePermission}
                          ghost
                          block
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <Table
                      dataSource={datasourcePermissions}
                      rowKey="datasource"
                      size="small"
                    >
                      <Column
                        key="datasource"
                        title="Data Source"
                        dataIndex="datasource"
                      ></Column>
                      {DATASOURCE_PERMISSIONS.map(perm => {
                        return (
                          <Column
                            key={perm.value}
                            title={
                              perm.content ? (
                                <Popover
                                  title={perm.label}
                                  content={
                                    <ul>
                                      {perm.content.map((line, idx) => (
                                        <li key={idx}>{line}</li>
                                      ))}
                                    </ul>
                                  }
                                >
                                  {perm.label}
                                </Popover>
                              ) : (
                                perm.label
                              )
                            }
                            dataIndex={perm.value}
                            align="center"
                            width={70}
                            render={(text, record, index) => {
                              return (
                                <Checkbox
                                  onChange={handleDatasourcePermissionChange(
                                    record,
                                    perm.value
                                  )}
                                />
                              );
                            }}
                          ></Column>
                        );
                      })}
                      <Column
                        key="delete"
                        title="Action"
                        dataIndex="delete"
                        width={70}
                        render={(text, record, index) => {
                          return (
                            <Button
                              size="small"
                              onClick={handleDeleteDatasourcePermission(record)}
                              danger
                              block
                            >
                              Delete
                            </Button>
                          );
                        }}
                      ></Column>
                    </Table>
                  </Panel>
                  <Panel
                    header="Data Sink"
                    key="datasink"
                    style={
                      datasinkPermissions.length > 0
                        ? panelActiveStyle
                        : panelStyle
                    }
                    extra={
                      <Badge
                        count={datasinkPermissions.length}
                        style={badgeStyle}
                      />
                    }
                  >
                    <Row gutter={10}>
                      <Col span={20}>
                        <Form.Item
                          name="datasink_permission_datasink"
                          {...formLayoutSingle}
                        >
                          <Select
                            allowClear
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select data sink"
                          >
                            {datasinkOptions}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Button
                          type="primary"
                          onClick={handleAddDatasinkPermission}
                          ghost
                          block
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <Table
                      dataSource={datasinkPermissions}
                      rowKey="datasink"
                      size="small"
                    >
                      <Column
                        key="datasink"
                        title="Data Sink"
                        dataIndex="datasink"
                      ></Column>
                      {DATASINK_PERMISSIONS.map(perm => {
                        return (
                          <Column
                            key={perm.value}
                            title={
                              perm.content ? (
                                <Popover
                                  title={perm.label}
                                  content={
                                    <ul>
                                      {perm.content.map((line, idx) => (
                                        <li key={idx}>{line}</li>
                                      ))}
                                    </ul>
                                  }
                                >
                                  {perm.label}
                                </Popover>
                              ) : (
                                perm.label
                              )
                            }
                            dataIndex={perm.value}
                            align="center"
                            width={70}
                            render={(text, record, index) => {
                              return (
                                <Checkbox
                                  onChange={handleDatasinkPermissionChange(
                                    record,
                                    perm.value
                                  )}
                                />
                              );
                            }}
                          ></Column>
                        );
                      })}
                      <Column
                        key="delete"
                        title="Action"
                        dataIndex="delete"
                        width={70}
                        render={(text, record, index) => {
                          return (
                            <Button
                              size="small"
                              onClick={handleDeleteDatasinkPermission(record)}
                              danger
                              block
                            >
                              Delete
                            </Button>
                          );
                        }}
                      ></Column>
                    </Table>
                  </Panel>
                  <Panel
                    header="Folder"
                    key="folder"
                    style={
                      filesPermissions.length > 0
                        ? panelActiveStyle
                        : panelStyle
                    }
                    extra={
                      <Badge
                        count={filesPermissions.length}
                        style={badgeStyle}
                      />
                    }
                  >
                    <Row gutter={10}>
                      <Col span={20}>
                        <Form.Item
                          name="files_permission_folder"
                          {...formLayoutSingle}
                        >
                          <Select
                            allowClear
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select folder"
                          >
                            {folderFilesOptions}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Button
                          type="primary"
                          onClick={handleAddFilesPermission}
                          ghost
                          block
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <Table
                      dataSource={filesPermissions}
                      rowKey="folder"
                      size="small"
                    >
                      <Column
                        key="folder"
                        title="Folder"
                        dataIndex="folder"
                      ></Column>
                      {FILES_PERMISSIONS.map(perm => {
                        return (
                          <Column
                            key={perm.value}
                            title={
                              perm.content ? (
                                <Popover
                                  title={perm.label}
                                  content={
                                    <ul>
                                      {perm.content.map((line, idx) => (
                                        <li key={idx}>{line}</li>
                                      ))}
                                    </ul>
                                  }
                                >
                                  {perm.label}
                                </Popover>
                              ) : (
                                perm.label
                              )
                            }
                            dataIndex={perm.value}
                            align="center"
                            width={100}
                            render={(text, record, index) => {
                              return (
                                <Checkbox
                                  onChange={handleFilesPermissionChange(
                                    record,
                                    perm.value
                                  )}
                                />
                              );
                            }}
                          ></Column>
                        );
                      })}
                      <Column
                        key="delete"
                        title="Action"
                        dataIndex="delete"
                        width={70}
                        render={(text, record, index) => {
                          return (
                            <Button
                              size="small"
                              onClick={handleDeleteFilesPermission(record)}
                              danger
                              block
                            >
                              Delete
                            </Button>
                          );
                        }}
                      ></Column>
                    </Table>
                  </Panel>
                  <Panel
                    header="SQL Proc"
                    key="sqlproc"
                    style={
                      targetSqlProcedures.length > 0
                        ? panelActiveStyle
                        : panelStyle
                    }
                    extra={
                      <Badge
                        count={targetSqlProcedures.length}
                        style={badgeStyle}
                      />
                    }
                  >
                    <Form.Item
                      name="sql_proc_permissions"
                      {...formLayoutSingle}
                    >
                      <Transfer
                        dataSource={sqlProcedureOptions}
                        titles={['Available', 'Selected']}
                        targetKeys={targetSqlProcedures}
                        selectedKeys={selectedSqlProcedures}
                        onChange={handleSqlProcedureChange}
                        onSelectChange={handleSqlProcedureSelectChange}
                        render={item => item.title}
                        listStyle={{ width: '100%' }}
                        locale={{
                          notFoundContent: (
                            <Empty description="No Permissions" />
                          ),
                        }}
                      />
                    </Form.Item>
                  </Panel>
                </Collapse>
              </div>
            </Col>
            <Col span={1}></Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default UserCreateModal;
