// Imports
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as monaco from 'monaco-editor';

// App Imports
import GraphQLServices from '../../graphql/services';
import {
  language as kineticaSql,
  conf,
  defaultSuggestions,
} from '../../components/editor/kinetica-sql';
import WorkbookEditor from './WorkbookEditor';
import WorkbookCatalog from './WorkbookCatalog';

monaco.languages.register({
  id: 'kinetica-sql',
});
monaco.languages.setMonarchTokensProvider('kinetica-sql', kineticaSql);
monaco.languages.setLanguageConfiguration('kinetica-sql', conf);

// Component
const DataExplore = () => {
  const { workbookId } = useParams();

  const { data: { tableTokens } = [] } =
    GraphQLServices.Tables.useGetTableTokens();

  const {
    loading: worksbooksLoading,
    data: { workbooks = undefined } = {},
    refetch: refetchWorkbooks,
  } = GraphQLServices.Workbooks.useGetWorkbooksOnly();

  useEffect(
    _ => {
      let disposable;
      if (tableTokens) {
        if (disposable && disposable.dispose) {
          disposable.dispose();
        }

        disposable = monaco.languages.registerCompletionItemProvider(
          'kinetica-sql',
          {
            provideCompletionItems: model => {
              const unique = new Set();

              tableTokens.tables.forEach(table => {
                if (table) {
                  unique.add(table);
                  if (table.includes('.')) {
                    const [schema, name] = table.split('.');
                    unique.add(schema);
                    unique.add(name);
                  }
                }
              });

              tableTokens.columns.forEach(column => {
                if (column) {
                  unique.add(column);
                }
              });

              const dynamicSuggestions = Array.from(unique)
                .filter(val => val)
                .map(val => {
                  return {
                    label: val,
                    kind: monaco.languages.CompletionItemKind.Value,
                    insertText: val,
                  };
                });

              return {
                suggestions: [...defaultSuggestions, ...dynamicSuggestions],
              };
            },
          }
        );
      }

      return _ => {
        if (disposable && disposable.dispose) {
          disposable.dispose();
        }
      };
    },
    [tableTokens]
  );

  return (
    <>
      {workbookId && (
        <WorkbookEditor
          workbookId={workbookId}
          refetchWorkbooks={refetchWorkbooks}
        />
      )}
      {workbooks && !workbookId && (
        <WorkbookCatalog
          workbooks={workbooks}
          loading={worksbooksLoading}
          refetch={refetchWorkbooks}
        />
      )}
    </>
  );
};

export default DataExplore;
