// Imports
import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import {
  Input,
  AutoComplete,
  Modal,
  Tooltip,
  Button,
  Space,
  notification,
  Divider,
  Table,
  Transfer,
  Checkbox,
  Empty,
  Popconfirm,
  Alert,
  Spin,
} from 'antd';
import {
  BulbOutlined,
  LoadingOutlined,
  ClearOutlined,
  InfoCircleOutlined,
  CloseOutlined,
  CommentOutlined,
  ConsoleSqlOutlined,
  SettingOutlined,
  DeleteOutlined,
  DislikeOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import store from 'store2';
import { v4 as uuid } from 'uuid';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import useSqlGPT from '../../hooks/useSqlGPT';
import { displayWarning } from '../../helper';
import Spinner from '../../components/common/Spinner';

const { Column } = Table;
const { TextArea } = Input;

const EXCLUDE_CONVERSATION_KEY = 'exclude_conversation';
const CHAT_QUERY_HISTORY_KEY = 'chat_query_history';
const CHAT_PROMPT_PANEL_HEIGHT = 135;
const CHAT_MESSAGES_TYPES = {
  USER: 'user',
  SYSTEM: 'system',
};
const CHAT_RESPONSE_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
};
const RATING = {
  GOOD: 1,
  BAD: -1,
};

const wbStore = store.namespace('workbench');

const MessageSpinner = _ => {
  return (
    <Spinner fontSize="28px" top="calc(50% - 14px)" left="calc(50% - 14px)" />
  );
};

const SqlSuggestionContext = ({
  workbook,
  worksheet,
  handleResult,
  block,
  handleClose,
  isChat = false,
  topBarCollapsed = false,
  toggleChat,
  resetWorksheet,
  disabled = true,
}) => {
  const {
    config: { chatgpt_context: workbooks_contexts = [] },
  } = workbook;
  const {
    config: { contexts: worksheet_contexts = [] },
  } = worksheet;

  const { data: { contexts = [] } = {} } =
    GraphQLServices.Contexts.useGetContexts();

  const [updateWorksheetById] =
    GraphQLServices.Worksheets.useUpdateWorksheetById();

  const [createContext] = GraphQLServices.Contexts.useCreateContext();
  const [deleteContextByName] =
    GraphQLServices.Contexts.useDeleteContextByName();

  const [chatQueryHistory, setChatQueryHistory] = useState(
    wbStore.get(CHAT_QUERY_HISTORY_KEY) ?? []
  );

  const [excludeConversation, setExcludeConversation] = useState(
    wbStore.get(EXCLUDE_CONVERSATION_KEY) ?? false
  );

  const [chatMessages, setChatMessages] = useState(
    worksheet?.config?.chat_messages &&
      Array.isArray(worksheet?.config?.chat_messages)
      ? worksheet?.config?.chat_messages.map(message => {
          if (message.type === CHAT_MESSAGES_TYPES.USER) {
            const response = worksheet?.config?.chat_messages.find(
              m => m.parent_id === message.id
            );
            if (response) {
              return {
                ...message,
                rating: response.rating,
                loading: false,
              };
            }
          }
          return {
            ...message,
            loading: false,
          };
        })
      : []
  );

  const analytics = useAnalytics();
  const chatBottomRef = useRef(null);

  const { query: askChatGPT, querying } = useSqlGPT();
  const [isUpdatingContext, setIsUpdatingContext] = useState(false);
  const [chatQuery, setChatQuery] = useState('');
  const [isChatConfigOpen, setIsChatConfigOpen] = useState(false);
  const [isLegacyChatConfigOpen, setIsLegacyChatConfigOpen] = useState(false);
  const [isChatInfoOpen, setIsChatInfoOpen] = useState(false);
  const [currentChatContext, setCurrentChatContext] = useState(
    worksheet_contexts.map((context, idx) => {
      return {
        ...context,
        id: idx,
      };
    })
  );

  const [targetContexts, setTargetContexts] = useState(
    worksheet_contexts.map((context, idx) => {
      return context.context_name;
    })
  );
  const [selectedContexts, setSelectedContexts] = useState([]);

  useEffect(
    _ => {
      setTimeout(_ => {
        if (chatBottomRef.current) {
          chatBottomRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 100);
    },
    [chatMessages]
  );

  const updateWorksheetChatMessages = useCallback(
    async chat_messages => {
      if (worksheet.id) {
        await updateWorksheetById({
          variables: {
            id: worksheet.id,
            config: {
              ...worksheet.config,
              chat_messages,
            },
          },
        });
      }
    },
    [updateWorksheetById, worksheet]
  );

  const handleQueryEnter = evt => {
    if (evt.ctrlKey) {
      handleChatQuery(evt);
    }
  };

  const buildSamples = chatMessages => {
    return chatMessages
      .filter(
        chatMessage =>
          chatMessage.type === CHAT_MESSAGES_TYPES.SYSTEM &&
          chatMessage.status === CHAT_RESPONSE_STATUS.SUCCESS &&
          chatMessage.rating === RATING.GOOD &&
          chatMessage.payload !== '' &&
          chatMessages.some(
            parentChatMessage => parentChatMessage.id === chatMessage.parent_id
          )
      )
      .map(systemMessage => {
        const parent = chatMessages.find(
          parentChatMessage => parentChatMessage.id === systemMessage.parent_id
        );
        return {
          question: parent.payload.replace(/'/gi, "''"),
          answer: systemMessage.payload.replace(/'/gi, "''"),
        };
      });
  };

  const handleReplayMessage = message => async _ => {
    const tempContextName = `context_temp_${uuid().split('-')[0]}`;

    const { id } = message;
    const promptIdx = chatMessages.findIndex(message => message.id === id);
    const prompt = chatMessages[promptIdx];
    const responseIdx = chatMessages.findIndex(
      message => message.parent_id === id
    );
    const response = chatMessages[responseIdx];

    let messages = chatMessages.map(message => {
      if (message.id === response.id) {
        return {
          ...message,
          payload: '',
          loading: true,
          status: CHAT_RESPONSE_STATUS.PENDING,
          rating: RATING.GOOD,
        };
      }
      return message;
    });

    setChatMessages(messages);

    const samples = !excludeConversation
      ? buildSamples(chatMessages.slice(0, promptIdx))
      : [];

    try {
      const tempContext = [];
      if (samples.length > 0) {
        const { data } = await createContext({
          variables: {
            context_name: tempContextName,
            definitions: [
              {
                type: 'samples',
                config: {
                  samples,
                },
              },
            ],
          },
        });
        tempContext.push({
          context_name: data?.contextCreate[0]?.context_name,
        });
      }

      const answer = await askChatGPT(prompt.payload.trim(), [
        ...currentChatContext,
        ...tempContext,
      ]);

      if (answer) {
        messages = messages.map(message => {
          if (message.id === response.id) {
            return {
              ...message,
              payload: answer,
              loading: false,
              status: CHAT_RESPONSE_STATUS.SUCCESS,
            };
          } else {
            return message;
          }
        });
        setChatMessages(messages);
      } else {
        messages = messages.map(message => {
          if (message.id === response.id) {
            return {
              ...message,
              payload: 'Unable to generate SQL',
              loading: false,
              status: CHAT_RESPONSE_STATUS.FAILED,
            };
          } else {
            return message;
          }
        });
        setChatMessages(messages);
      }
    } catch (error) {
      if (error.message.includes('No SQL-GPT context selected')) {
        setIsLegacyChatConfigOpen(false);
        setIsChatConfigOpen(true);
      }
      messages = messages.map(message => {
        if (message.id === response.id) {
          return {
            ...message,
            payload: error.message,
            loading: false,
            status: CHAT_RESPONSE_STATUS.FAILED,
          };
        } else {
          return message;
        }
      });
      setChatMessages(messages);
    } finally {
      if (samples.length > 0) {
        await deleteContextByName({
          variables: {
            context_name: tempContextName,
          },
        });
      }

      analytics.track(analytics.EVENT_TYPES.GENERATE_SQL)({});
    }
  };

  const handleChatQuery = useCallback(
    async _ => {
      if (chatQuery.trim() !== '') {
        const tempContextName = `context_temp_${uuid().split('-')[0]}`;

        const promptID = uuid();
        const responseID = uuid();

        let messages = [
          ...chatMessages,
          {
            id: promptID,
            type: CHAT_MESSAGES_TYPES.USER,
            payload: chatQuery.trim(),
            loading: false,
          },
          {
            id: responseID,
            parent_id: promptID,
            type: CHAT_MESSAGES_TYPES.SYSTEM,
            payload: '',
            loading: true,
            status: CHAT_RESPONSE_STATUS.PENDING,
            rating: RATING.GOOD,
          },
        ];

        setChatMessages(messages);

        const samples = !excludeConversation ? buildSamples(chatMessages) : [];

        try {
          setChatQuery('');

          const tempContext = [];
          if (samples.length > 0) {
            const { data } = await createContext({
              variables: {
                context_name: tempContextName,
                definitions: [
                  {
                    type: 'samples',
                    config: {
                      samples,
                    },
                  },
                ],
              },
            });
            tempContext.push({
              context_name: data?.contextCreate[0]?.context_name,
            });
          }

          const answer = await askChatGPT(chatQuery.trim(), [
            ...currentChatContext,
            ...tempContext,
          ]);
          if (answer) {
            messages = messages.map(message => {
              if (message.id === responseID) {
                return {
                  ...message,
                  payload: answer,
                  loading: false,
                  status: CHAT_RESPONSE_STATUS.SUCCESS,
                };
              } else {
                return message;
              }
            });
            setChatMessages(messages);
          } else {
            messages = messages.map(message => {
              if (message.id === responseID) {
                return {
                  ...message,
                  payload: 'Unable to generate SQL',
                  loading: false,
                  status: CHAT_RESPONSE_STATUS.FAILED,
                };
              } else {
                return message;
              }
            });
            setChatMessages(messages);
          }
        } catch (error) {
          if (error.message.includes('No SQL-GPT context selected')) {
            setIsLegacyChatConfigOpen(false);
            setIsChatConfigOpen(true);
          }
          messages = messages.map(message => {
            if (message.id === responseID) {
              return {
                ...message,
                payload: error.message,
                loading: false,
                status: CHAT_RESPONSE_STATUS.FAILED,
              };
            } else {
              return message;
            }
          });
          setChatMessages(messages);
        } finally {
          if (samples.length > 0) {
            await deleteContextByName({
              variables: {
                context_name: tempContextName,
              },
            });
          }

          analytics.track(analytics.EVENT_TYPES.GENERATE_SQL)({});
          setTimeout(_ => {
            if (chatBottomRef.current) {
              chatBottomRef.current.scrollIntoView({
                behavior: 'smooth',
              });
            }
          }, 100);
        }
      }
    },
    [
      chatQuery,
      chatMessages,
      excludeConversation,
      askChatGPT,
      currentChatContext,
      createContext,
      analytics,
      deleteContextByName,
    ]
  );

  const handleQuery = useCallback(
    async _ => {
      if (chatQuery.trim() !== '') {
        try {
          setChatQueryHistory(
            [
              chatQuery.trim(),
              ...chatQueryHistory.filter(q => q.trim() !== chatQuery.trim()),
            ].slice(0, 15)
          );

          const answer = await askChatGPT(chatQuery.trim(), currentChatContext);
          if (answer) {
            handleResult(`-- ${chatQuery.trim()}\n${answer}`);
          } else {
            handleResult(
              `-- ${chatQuery.trim()}\n-- Unable to generate an answer`
            );
          }
        } catch (error) {
          if (error.message.includes('No SQL-GPT context selected')) {
            setIsLegacyChatConfigOpen(false);
            if (!block) {
              // Only open config automatically from the main bar
              setIsChatConfigOpen(true);
            }
          }
          displayWarning(error.message);
        } finally {
          analytics.track(analytics.EVENT_TYPES.GENERATE_SQL)({});
        }
      }
    },
    [
      chatQuery,
      chatQueryHistory,
      askChatGPT,
      currentChatContext,
      handleResult,
      block,
      analytics,
    ]
  );

  React.useEffect(() => {
    wbStore.set(CHAT_QUERY_HISTORY_KEY, chatQueryHistory);
  }, [chatQueryHistory]);

  React.useEffect(() => {
    updateWorksheetChatMessages(chatMessages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessages]);

  const handleClearChatHistory = _ => {
    wbStore.remove(CHAT_QUERY_HISTORY_KEY);
    setChatQueryHistory([]);
    notification.success({
      message: 'Success',
      description: 'History has been cleared',
    });
  };

  const handleChatQueryUpdate = evt => {
    if (typeof evt === 'string') {
      setChatQuery(evt);
    } else {
      setChatQuery(evt.target.value);
    }
  };

  const handleUpdateChatContext = useCallback(() => {
    setIsUpdatingContext(true);
    updateWorksheetById({
      variables: {
        id: worksheet.id,
        config: {
          ...worksheet.config,
          contexts: targetContexts
            .filter(context => context)
            .map(context_name => {
              return {
                context_name,
              };
            }),
        },
      },
    })
      .then(() => {
        setCurrentChatContext(
          targetContexts
            .filter(context => context)
            .map(context_name => {
              return {
                context_name,
              };
            })
        );

        if (resetWorksheet) {
          resetWorksheet();
        }

        setTimeout(() => {
          setIsChatConfigOpen(false);
          setIsLegacyChatConfigOpen(false);
          setIsUpdatingContext(false);
        }, 1000);

        notification.success({
          message: 'Success',
          description: 'Context configuration updated',
        });
      })
      .catch(err => {
        setIsUpdatingContext(false);
      });
  }, [targetContexts, updateWorksheetById, worksheet, resetWorksheet]);

  const handleCancelChatContext = useCallback(() => {
    setCurrentChatContext(
      (worksheet.config.contexts ?? []).map((context, idx) => {
        return {
          ...context,
          id: idx,
        };
      })
    );
    setIsChatConfigOpen(false);
    setIsLegacyChatConfigOpen(false);
  }, [worksheet]);

  const handleCloseChatInfo = _ => {
    setIsChatInfoOpen(false);
  };

  const contextOptions = useMemo(() => {
    if (contexts) {
      return contexts.map(context => ({
        key: context.context_name,
        label: context.context_name,
        value: context.context_name,
        ...context,
      }));
    }
    return [];
  }, [contexts]);

  const handleContextChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetContexts(nextTargetKeys);
  };

  const handleContextSelectChange = (
    sourceSelectedKeys,
    targetSelectedKeys
  ) => {
    setSelectedContexts([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleGenerateSQLBlock = useCallback(
    (message, run = false) =>
      _ => {
        const parent = chatMessages.find(
          chatMessage =>
            message.parent_id && chatMessage.id === message.parent_id
        );
        if (parent && parent.payload) {
          handleResult(`-- ${parent.payload}\n${message.payload}`, null, run);
        } else {
          handleResult(message.payload, null, run);
        }
      },
    [chatMessages, handleResult]
  );

  const handleDeleteAllMessages = _ => {
    setChatMessages([]);
  };

  const handleDeleteMessage = useCallback(
    message => _ => {
      const parent = chatMessages.find(
        chatMessage => message.parent_id && chatMessage.id === message.parent_id
      );
      const deleteIDs = [message.id];
      if (parent) {
        deleteIDs.push(parent.id);
      }
      setChatMessages(
        chatMessages.filter(chatMessage => !deleteIDs.includes(chatMessage.id))
      );
    },
    [chatMessages]
  );

  const handleToggleDownvoteMessage = useCallback(
    message => _ => {
      setChatMessages(
        chatMessages.map(chatMessage => {
          if (chatMessage.id === message.parent_id) {
            return {
              ...chatMessage,
              rating: message.rating ? message.rating * -1 : RATING.BAD,
            };
          } else if (chatMessage.id === message.id) {
            return {
              ...chatMessage,
              rating: message.rating ? message.rating * -1 : RATING.BAD,
            };
          }
          return chatMessage;
        })
      );
    },
    [chatMessages]
  );

  const handleExcludeConversationToggle = _ => {
    setExcludeConversation(!excludeConversation);
    wbStore.set(EXCLUDE_CONVERSATION_KEY, !excludeConversation);
  };

  const pageSize = useMemo(_ => {
    return Math.floor((window.innerHeight - 580) / 100);
  }, []);

  return (
    <>
      {!isChat ? (
        <div
          style={{
            padding: block ? 5 : 20,
            borderRadius: 5,
            backgroundColor: block || disabled ? '#cccccc22' : '#3700b322',
            width: block ? 'calc(100% - 10px)' : '100%',
            margin: block ? '15px 5px' : '9px 0',
            pointerEvents: 'all',
          }}
        >
          <Input.Group
            style={{
              width: block ? 'calc(100% - 35px)' : 'calc(100% - 180px)',
              display: 'inline-block',
            }}
          >
            <Tooltip
              title={
                disabled &&
                'SQL-GPT is not available for read-only workbooks. Please copy or create a new workbook.'
              }
            >
              <AutoComplete
                options={chatQueryHistory.map(query => {
                  return {
                    value: query ? query.trim() : '',
                    label: (
                      <span style={{ marginLeft: 20 }}>
                        {query ? query.trim() : ''}
                      </span>
                    ),
                  };
                })}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                onSelect={handleChatQueryUpdate}
                style={{
                  width: block ? 'calc(100% - 140px)' : 'calc(100% - 160px)',
                  float: 'left',
                }}
                disabled={disabled}
                allowClear
              >
                <Input
                  prefix={
                    querying ? (
                      <LoadingOutlined />
                    ) : (
                      <Tooltip title="SQL-GPT">
                        <BulbOutlined />
                      </Tooltip>
                    )
                  }
                  onChange={handleChatQueryUpdate}
                  placeholder="Ask a question and click Generate SQL"
                />
              </AutoComplete>
            </Tooltip>
            <Button
              type="primary"
              onClick={handleQuery}
              loading={querying}
              disabled={disabled}
              ghost={block}
              style={{
                width: 140,
                display: 'inline-block',
              }}
            >
              Generate SQL
            </Button>
          </Input.Group>
          {handleClose && (
            <Button
              type="link"
              icon={
                <CloseOutlined style={{ fontSize: '16px', marginBottom: -1 }} />
              }
              onClick={handleClose(block)}
              style={{
                width: 30,
                marginLeft: 5,
                display: 'inline-block',
                backgroundColor: 'transparent',
              }}
            ></Button>
          )}
          {!block && (
            <>
              <Tooltip
                title={
                  !disabled &&
                  'Provide more context and rules on desired tables for better results'
                }
              >
                <Button
                  type="primary"
                  onClick={_ => {
                    setIsLegacyChatConfigOpen(false);
                    setIsChatConfigOpen(true);
                  }}
                  style={{
                    width: 100,
                    display: 'inline-block',
                    backgroundColor: '#ffffff',
                    marginRight: 10,
                  }}
                  disabled={disabled}
                  ghost
                >
                  Configure
                </Button>
              </Tooltip>
              {toggleChat && (
                <Tooltip title="Toggle chat mode">
                  <Button
                    type="primary"
                    icon={
                      <CommentOutlined
                        style={{ fontSize: '16px', marginBottom: -1 }}
                      />
                    }
                    onClick={_ => {
                      toggleChat(!isChat);
                    }}
                    style={{
                      width: 30,
                      display: 'inline-block',
                      backgroundColor: '#ffffff',
                      marginRight: 10,
                    }}
                    ghost
                  ></Button>
                </Tooltip>
              )}
              <Tooltip title="Learn more about SQL-GPT">
                <Button
                  type="primary"
                  icon={
                    <InfoCircleOutlined
                      style={{ fontSize: '16px', marginBottom: -1 }}
                    />
                  }
                  onClick={_ => {
                    setIsChatInfoOpen(true);
                  }}
                  style={{
                    width: 30,
                    display: 'inline-block',
                    backgroundColor: '#ffffff',
                  }}
                  ghost
                ></Button>
              </Tooltip>
            </>
          )}
        </div>
      ) : (
        <div
          style={{
            padding: 15,
            borderRadius: 5,
            backgroundColor: block || disabled ? '#cccccc22' : '#3700b322',
            width: '100%',
            height: topBarCollapsed
              ? `calc(100vh - ${275 - 50}px)`
              : `calc(100vh - 275px)`,
            margin: '0px 0px 0px 0px',
            pointerEvents: 'all',
          }}
        >
          <div
            style={{
              height: topBarCollapsed
                ? `calc(100vh - ${320 + CHAT_PROMPT_PANEL_HEIGHT - 50}px)`
                : `calc(100vh - ${320 + CHAT_PROMPT_PANEL_HEIGHT}px)`,
              backgroundColor: '#ffffff66',
              marginBottom: 15,
              paddingBottom: 20,
              overflowY: 'auto',
            }}
          >
            <Space
              direction="vertical"
              size="middle"
              style={{ width: '100%', marginTop: 10 }}
            >
              {chatMessages.length === 0 && (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No chat messages"
                />
              )}
              {chatMessages.map(message => {
                const disabled =
                  message.rating && message.rating === RATING.BAD;
                const color = disabled ? '#CCCCCC' : '#333333';
                const opacity = disabled ? 0.4 : 1.0;
                if (message.type === 'user') {
                  return (
                    <div
                      key={message.id}
                      style={{
                        color,
                        opacity,
                        fontSize: '14px',
                        lineHeight: '16px',
                        backgroundColor: '#f9f9f9',
                        borderLeft: '5px solid #899CFA',
                        borderRadius: 5,
                        padding: '10px 12px',
                        margin: '0 10px',
                        width: '90%',
                        minHeight: 45,
                        boxShadow:
                          'rgba(50, 50, 93, 0.25) 0px 4px 8px -5px,rgba(0, 0, 0, 0.3) 0px 4px 8px -4px',
                      }}
                    >
                      <Spin
                        indicator={<MessageSpinner />}
                        spinning={message.loading}
                        style={{ opacity: '20%' }}
                      >
                        <Button
                          type="default"
                          onClick={handleReplayMessage(message)}
                          icon={<ReloadOutlined />}
                          size="small"
                          style={{ float: 'right' }}
                        ></Button>
                        {message.payload}
                      </Spin>
                    </div>
                  );
                } else if (message.type === 'system') {
                  return (
                    <div
                      key={message.id}
                      style={{
                        opacity,
                        backgroundColor: '#ffffff',
                        borderRight:
                          message.status === CHAT_RESPONSE_STATUS.FAILED
                            ? '5px solid #FF0000'
                            : '5px solid #CD81F5',
                        borderRadius: 5,
                        padding: '10px 12px',
                        margin: '0 10px 0 25px',
                        width: '90%',
                        boxShadow:
                          'rgba(50, 50, 93, 0.25) 0px 4px 8px -5px,rgba(0, 0, 0, 0.3) 0px 4px 8px -4px',
                      }}
                    >
                      <Spin
                        indicator={<MessageSpinner />}
                        spinning={message.loading}
                        style={{
                          opacity: '20%',
                        }}
                      >
                        <div style={{ minHeight: message.loading ? 80 : 30 }}>
                          {!message.loading && (
                            <>
                              {message.status ===
                                CHAT_RESPONSE_STATUS.SUCCESS && (
                                <pre
                                  style={{
                                    fontSize: 11,
                                    marginBottom: 10,
                                    color,
                                    overflow: disabled ? 'hidden' : 'auto',
                                  }}
                                >
                                  {message.payload}
                                </pre>
                              )}
                              {message.status !==
                                CHAT_RESPONSE_STATUS.SUCCESS && (
                                <div
                                  style={{
                                    fontSize: '13px',
                                    lineHeight: '16px',
                                    marginBottom: 10,
                                  }}
                                >
                                  {message.payload}
                                </div>
                              )}
                              <Tooltip
                                title="Vote down to exclude from context if response was not relevant"
                                mouseEnterDelay={1}
                              >
                                <DislikeOutlined
                                  onClick={handleToggleDownvoteMessage(message)}
                                  style={{
                                    float: 'left',
                                    margin: '5px 3px',
                                    color: disabled ? '#FF0000' : '#CCCCCC',
                                  }}
                                />
                              </Tooltip>
                              <Space style={{ float: 'right' }}>
                                {message.status ===
                                  CHAT_RESPONSE_STATUS.SUCCESS &&
                                  message.payload && (
                                    <Button
                                      type="default"
                                      onClick={handleGenerateSQLBlock(
                                        message,
                                        true
                                      )}
                                      icon={<ConsoleSqlOutlined />}
                                      size="small"
                                      style={{ color }}
                                    >
                                      Run In SQL Block
                                    </Button>
                                  )}
                                <Button
                                  type="default"
                                  onClick={handleDeleteMessage(message)}
                                  icon={<DeleteOutlined />}
                                  size="small"
                                  style={{ color }}
                                ></Button>
                              </Space>
                              <div style={{ clear: 'both' }}></div>
                            </>
                          )}
                        </div>
                      </Spin>
                    </div>
                  );
                }
                return null;
              })}
            </Space>
            <div ref={chatBottomRef}></div>
          </div>
          <div
            style={{
              height: CHAT_PROMPT_PANEL_HEIGHT - 25,
              border: '1px solid #cccccc',
              overflow: 'hidden',
            }}
          >
            <TextArea
              value={chatQuery}
              onChange={handleChatQueryUpdate}
              onPressEnter={handleQueryEnter}
              placeholder="Ask a question and click Generate SQL"
              rows={3}
              disabled={disabled}
              style={{ height: 75, border: 0, padding: '5px 10px' }}
              size="small"
            />
            <Button
              type="primary"
              onClick={handleChatQuery}
              loading={querying}
              disabled={disabled}
              style={{ width: 'calc(100% - 140px)' }}
            >
              Generate SQL
            </Button>
            <Tooltip
              title={
                !disabled &&
                'Provide more context and rules on desired tables for better results'
              }
            >
              <Button
                type="primary"
                icon={
                  <SettingOutlined
                    style={{ fontSize: '16px', marginBottom: -1 }}
                  />
                }
                onClick={_ => {
                  setIsLegacyChatConfigOpen(false);
                  setIsChatConfigOpen(true);
                }}
                style={{
                  display: 'inline-block',
                  backgroundColor: '#ffffff',
                  borderLeft: 0,
                  width: 35,
                }}
                disabled={disabled}
                ghost
              ></Button>
            </Tooltip>
            <Popconfirm
              title="Are you sure you want to clear all chat messages?"
              onConfirm={handleDeleteAllMessages}
              placement="topRight"
              disabled={disabled}
            >
              <Button
                type="primary"
                icon={
                  <ClearOutlined
                    style={{ fontSize: '16px', marginBottom: -1 }}
                  />
                }
                style={{
                  display: 'inline-block',
                  backgroundColor: '#ffffff',
                  borderLeft: 0,
                  width: 35,
                }}
                ghost
              ></Button>
            </Popconfirm>
            <Tooltip title="Toggle chat mode">
              <Button
                type="primary"
                icon={
                  <CommentOutlined
                    style={{ fontSize: '16px', marginBottom: -1 }}
                  />
                }
                onClick={_ => {
                  toggleChat(!isChat);
                }}
                style={{
                  display: 'inline-block',
                  backgroundColor: '#ffffff',
                  borderLeft: 0,
                  width: 35,
                }}
                ghost
              ></Button>
            </Tooltip>
            <Tooltip title="Learn more about SQL-GPT">
              <Button
                type="primary"
                icon={
                  <InfoCircleOutlined
                    style={{ fontSize: '16px', marginBottom: -1 }}
                  />
                }
                onClick={_ => {
                  setIsChatInfoOpen(true);
                }}
                style={{
                  display: 'inline-block',
                  backgroundColor: '#ffffff',
                  borderLeft: 0,
                  width: 35,
                }}
                ghost
              ></Button>
            </Tooltip>
          </div>
          <span
            style={{ height: 25, fontSize: '12px', alignContent: 'center' }}
          >
            <Checkbox
              checked={excludeConversation}
              onChange={handleExcludeConversationToggle}
              style={{
                color: '#999999',
                display: 'flex',
                marginTop: 5,
                marginLeft: 50,
              }}
            >
              Exclude converstion from context
            </Checkbox>
          </span>
        </div>
      )}
      {isChatInfoOpen && (
        <Modal
          title="SQL-GPT Quickstart"
          open={isChatInfoOpen}
          onCancel={handleCloseChatInfo}
          footer={[
            <Button key="close" onClick={handleCloseChatInfo}>
              Close
            </Button>,
          ]}
          width={780}
          height={window.innerHeight - 250}
          centered
        >
          <div
            style={{
              border: '1px solid #e3e3e3',
              height: 317,
              marginBottom: 25,
            }}
          >
            <div
              style={{
                display: 'inline',
                float: 'left',
                width: 560,
                height: 315,
              }}
            >
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/AHGQvjbrDy4"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                style={{ border: 0 }}
              ></iframe>
            </div>
            <div
              style={{
                display: 'inline',
                float: 'left',
                width: 170,
                height: 315,
                padding: '15px 15px 15px 20px',
                borderLeft: '1px solid #eeeeee',
                backgroundColor: '#f6f6f6',
              }}
            >
              <p style={{ fontSize: '13px' }}>
                Use the configure button to provide ChatGPT information about
                the tables and any specific rules or preferences to follow when
                constructing queries. Watch the video above for a more detailed
                demonstration of how to use this feature.
              </p>
            </div>
          </div>
          <Divider dashed />
          <div
            style={{
              height: window.innerHeight - 780,
              overflowY: 'scroll',
              marginTop: 10,
            }}
          >
            <h3>Introduction</h3>
            <p>
              All workbooks in Kinetica now have a chat prompt. You can use this
              prompt to send analytical questions in plain English to ChatGPT.
              This will return a SQL query that references data in Kinetica,
              which is added as an executable code block to your worksheet.
            </p>
            <h3>Configuration</h3>
            <p>
              There are a few things to keep in mind when using the chat
              feature. The first is that we need to provide ChatGPT with enough
              context about the data so that it can generate queries that are
              specific to the tables inside Kinetica. We can do this by
              configuring the chat context.
            </p>
            <p>
              The first part of the configuration describes the tables in plain
              English. The Quick Start Guide already includes this
              configuration, but if you want to try it yourself with your own
              data or a different table, you will need to configure the context
              yourself. Behind the scenes, Kinetica also provides GPT with the
              data definition for each table. The data definition language (DDL)
              taken together with the description here provides enough context
              to GPT for it to generate meaningful queries that are specific to
              the data. Note that Kinetica does not send any data to ChatGPT,
              only metadata about the tables (DDL), such as column names and
              types.
            </p>
            <p>
              In addition to describing the tables, we can also specify
              comma-separated rules. Rules are a way to further refine query
              outputs from ChatGPT. These can include things that are specific
              to Kinetica or your own preferences for how the queries should be
              constructed.
            </p>
            <p>
              From our experience, the best way to configure rules is by trial
              and error. Try out a few different prompts and examine the queries
              that are returned. If you notice that something could be improved,
              then add that as a rule.
            </p>
            <h3>Tips</h3>
            <p>
              As with human conversations, there is some variability in the
              responses that we get from ChatGPT. The same prompts with the same
              context could yield different results. Similarly, prompts with
              slightly altered wording can change the SQL query that ChatGPT
              returns.
            </p>
            <p>
              So make sure to check the queries generated to ensure that they
              make sense and that the results are what you expected.
            </p>
          </div>
        </Modal>
      )}
      {isChatConfigOpen && (
        <Modal
          title={
            <>
              <strong>{worksheet.name}</strong> SQL-GPT Context Configuration
            </>
          }
          open={isChatConfigOpen}
          footer={
            workbooks_contexts.length === 0
              ? [
                  <Button key="cancel" onClick={handleCancelChatContext}>
                    Cancel
                  </Button>,
                  <Button
                    key="create"
                    type="primary"
                    onClick={handleUpdateChatContext}
                    loading={isUpdatingContext}
                  >
                    Update
                  </Button>,
                ]
              : [
                  <Button
                    key="legacy"
                    onClick={_ => setIsLegacyChatConfigOpen(true)}
                    style={{ float: 'left' }}
                  >
                    Legacy SQL-GPT Context
                  </Button>,
                  <Button key="cancel" onClick={handleCancelChatContext}>
                    Cancel
                  </Button>,
                  <Button
                    key="create"
                    type="primary"
                    onClick={handleUpdateChatContext}
                    loading={isUpdatingContext}
                  >
                    Update
                  </Button>,
                ]
          }
          onCancel={handleCancelChatContext}
          width={840}
          centered
        >
          <div
            style={{
              height: window.innerHeight - 400,
              minHeight: 300,
              maxHeight: 680,
            }}
          >
            <div style={{ height: 40 }}>
              <Space direction="horizontal" style={{ float: 'right' }}>
                <Popconfirm
                  title="Questions are global per browser session. Would you like to clear history?"
                  onConfirm={handleClearChatHistory}
                >
                  <Button
                    style={{ float: 'right' }}
                    icon={<ClearOutlined />}
                    size="small"
                  >
                    Clear History
                  </Button>
                </Popconfirm>
              </Space>
              <i style={{ display: 'block' }}>
                Select context(s) you would like to use for SQL query generation
              </i>
            </div>
            <Transfer
              dataSource={contextOptions}
              titles={['Available', 'Selected']}
              targetKeys={targetContexts}
              selectedKeys={selectedContexts}
              onChange={handleContextChange}
              onSelectChange={handleContextSelectChange}
              render={item => item.context_name}
              listStyle={{
                width: 380,
                height: window.innerHeight - 440,
                minHeight: 260,
                maxHeight: 640,
              }}
              locale={{
                notFoundContent: <Empty description="No Contexts" />,
              }}
              showSearch
            />
          </div>
        </Modal>
      )}
      {isLegacyChatConfigOpen && (
        <Modal
          title="Legacy SQL-GPT Context"
          open={isChatConfigOpen}
          onCancel={_ => setIsLegacyChatConfigOpen(false)}
          footer={[
            <Button key="close" onClick={_ => setIsLegacyChatConfigOpen(false)}>
              Close
            </Button>,
          ]}
          width={window.innerWidth - 200}
          centered
        >
          <div style={{ height: window.innerHeight - 400 }}>
            <Alert
              message="This context stored in the Workbook is no longer supported.  Use the Workbench UI or SQL-GPT API to migrate these tables, descriptions and rules to a new database context."
              type="warning"
              showIcon
              style={{ marginBottom: 10 }}
            />
            <Table
              rowKey="id"
              dataSource={workbooks_contexts.map((context, idx) => {
                return {
                  ...context,
                  id: idx,
                };
              })}
              pagination={{
                pageSize,
              }}
              size="small"
            >
              <Column
                title="Table"
                dataIndex="table"
                key="table"
                width={250}
                render={(table, record, idx) => (
                  <Input
                    value={table}
                    size="small"
                    style={{ marginBottom: 75 }}
                    readOnly
                  />
                )}
              />
              <Column
                title="Description"
                dataIndex="description"
                key="description"
                render={(description, record, idx) => (
                  <TextArea
                    value={description}
                    rows={4}
                    size="small"
                    readOnly
                  />
                )}
              />
              <Column
                title="Rules"
                dataIndex="rules"
                key="rules"
                render={(rules, record, idx) => (
                  <TextArea value={rules} rows={4} size="small" readOnly />
                )}
              />
            </Table>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SqlSuggestionContext;
