// Imports
import React, { useState, useMemo } from 'react';
import {
  Modal,
  Form,
  Input,
  Radio,
  Row,
  Col,
  Switch,
  Button,
  Cascader,
} from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import {
  IMPORT_CATALOG,
  DATASOURCE_LOCATIONS,
  DATASOURCE_LOCATION_AZURE,
  DATASOURCE_LOCATION_GCS,
  DATASOURCE_LOCATION_HDFS,
  DATASOURCE_LOCATION_S3,
  DATASOURCE_LOCATION_CONFLUENT,
  DATASOURCE_LOCATION_KAFKA,
  DATASOURCE_LOCATION_JDBC,
  DATASOURCE_S3_AUTH_TYPES,
  DATASOURCE_S3_AUTH_NO_AUTH,
  DATASOURCE_S3_AUTH_ACCESS_KEY,
  DATASOURCE_JDBC_AUTH_TYPES,
  DATASOURCE_JDBC_AUTH_PASSWORD,
  DATASOURCE_AZURE_AUTH_TYPES,
  DATASOURCE_AZURE_AUTH_NO_AUTH,
  DATASOURCE_AZURE_AUTH_PASSWORD,
  DATASOURCE_AZURE_AUTH_SAS_TOKEN,
  DATASOURCE_AZURE_AUTH_OAUTH_TOKEN,
  DATASOURCE_AZURE_AUTH_ACTIVE_DIRECTORY,
  DATASOURCE_GCS_AUTH_TYPES,
  DATASOURCE_GCS_AUTH_NO_AUTH,
  DATASOURCE_GCS_AUTH_USERID_KEY,
  DATASOURCE_GCS_AUTH_JSON_KEY,
  DATASOURCE_CONFLUENT_AUTH_TYPES,
  DATASOURCE_CONFLUENT_AUTH_NO_AUTH,
  DATASOURCE_CONFLUENT_AUTH_SASL_SSL,
  DATASOURCE_KAFKA_AUTH_TYPES,
  DATASOURCE_KAFKA_AUTH_NO_AUTH,
  DATASOURCE_KAFKA_AUTH_SASL_SSL,
  DATASOURCE_HDFS_AUTH_TYPES,
  DATASOURCE_HDFS_AUTH_PASSWORD,
  DATASOURCE_HDFS_AUTH_KERBEROS_TOKEN,
  DATASOURCE_HDFS_AUTH_KERBEROS_KEYTAB,
  DATASOURCE_DEFAULT_AUTH_TYPE,
  DATASOURCE_HDFS_PREFIX,
  DATASOURCE_CONFLUENT_PREFIX,
  DATASOURCE_KAFKA_PREFIX,
  DATASOURCE_JDBC_JAR_PATH_TYPES,
  DATASOURCE_JDBC_JAR_PATH_MANUAL,
  DATASOURCE_JDBC_JAR_PATH_KIFS,
} from '../../constants';
import { getTimestampKey, displaySuccess } from '../../helper';

const DatasourceCreateModal = ({ visible, close, width = 820, callback }) => {
  const [createCredential] = GraphQLServices.Credentials.useCreateCredential();
  const [createDatasource] = GraphQLServices.Datasources.useCreateDatasource();
  const { data: { folders = [] } = {} } = GraphQLServices.Files.useGetFolders();

  const [isCreating, setIsCreating] = useState(false);
  const [formValues, setFormValues] = useState({
    jdbc_auth_type: DATASOURCE_JDBC_AUTH_PASSWORD,
    jdbc_driver_jar_path_type: DATASOURCE_JDBC_JAR_PATH_MANUAL,
    azure_auth_type: DATASOURCE_AZURE_AUTH_NO_AUTH,
    gcs_auth_type: DATASOURCE_GCS_AUTH_NO_AUTH,
    s3_auth_type: DATASOURCE_S3_AUTH_NO_AUTH,
    hdfs_auth_type: DATASOURCE_DEFAULT_AUTH_TYPE,
    kakfa_auth_type: DATASOURCE_KAFKA_AUTH_NO_AUTH,
    confluent_auth_type: DATASOURCE_CONFLUENT_AUTH_NO_AUTH,
  });

  const [form] = Form.useForm();
  const analytics = useAnalytics();

  const onFinish = async values => {
    const variables = Object.keys(values).reduce((acc, cur) => {
      if (values[cur]) {
        acc[cur] = values[cur];
      }
      return acc;
    }, {});

    if (variables.location?.includes(DATASOURCE_LOCATION_HDFS)) {
      variables.location = DATASOURCE_HDFS_PREFIX + variables.location_hdfs;
    }
    if (variables.location?.includes(DATASOURCE_LOCATION_CONFLUENT)) {
      variables.location =
        DATASOURCE_CONFLUENT_PREFIX + variables.location_confluent;
    }
    if (variables.location?.includes(DATASOURCE_LOCATION_KAFKA)) {
      variables.location = DATASOURCE_KAFKA_PREFIX + variables.location_kafka;
    }
    if (variables.location?.includes(DATASOURCE_LOCATION_JDBC)) {
      variables.location = variables.location_jdbc;
    }

    setIsCreating(true);

    // Need to create credential first for Confluent stream
    if (
      variables.location?.includes(DATASOURCE_LOCATION_CONFLUENT) &&
      variables?.confluent_auth_type === DATASOURCE_CONFLUENT_AUTH_SASL_SSL
    ) {
      const name = `${variables.name}_credential_autogen_${getTimestampKey()}`;
      const variables2 = {
        name,
        type: DATASOURCE_LOCATION_CONFLUENT,
        identity: '',
        secret: '',
        security_protocol: DATASOURCE_CONFLUENT_AUTH_SASL_SSL.toUpperCase(),
        sasl_mechanism: 'PLAIN',
        sasl_username: variables.user_name,
        sasl_password: variables.password,
      };
      const createCredResp = await createCredential({
        variables: variables2,
      });
      if (createCredResp?.errors) {
        setIsCreating(false);
        return;
      }

      variables.credential = name;
      variables.user_name = undefined;
      variables.password = undefined;
    }

    // Create confluent schema registry credential
    if (
      variables.location?.includes(DATASOURCE_LOCATION_CONFLUENT) &&
      variables?.schema_registry_user_name !== '' &&
      variables?.schema_registry_password !== ''
    ) {
      const name = `${
        variables.name
      }_sr_credential_autogen_${getTimestampKey()}`;
      const variables3 = {
        name,
        type: DATASOURCE_LOCATION_CONFLUENT,
        identity: '',
        secret: '',
        security_protocol: DATASOURCE_CONFLUENT_AUTH_SASL_SSL.toUpperCase(),
        sasl_mechanism: 'PLAIN',
        sasl_username: variables.schema_registry_user_name,
        sasl_password: variables.schema_registry_password,
      };
      const createCredResp = await createCredential({
        variables: variables3,
      });
      if (createCredResp?.errors) {
        setIsCreating(false);
        return;
      }

      variables.schema_registry_credential = name;
      variables.schema_registry_user_name = undefined;
      variables.schema_registry_password = undefined;
    }

    // Need to create credential first for kafka stream
    if (
      variables.location?.includes(DATASOURCE_LOCATION_KAFKA) &&
      variables?.kafka_auth_type === DATASOURCE_KAFKA_AUTH_SASL_SSL
    ) {
      const name = `${variables.name}_credential_autogen_${getTimestampKey()}`;
      const variables2 = {
        name,
        type: DATASOURCE_LOCATION_KAFKA,
        identity: '',
        secret: '',
        security_protocol: DATASOURCE_KAFKA_AUTH_SASL_SSL.toUpperCase(),
        sasl_mechanism: 'PLAIN',
        sasl_username: variables.user_name,
        sasl_password: variables.password,
      };
      const createCredResp = await createCredential({
        variables: variables2,
      });
      if (createCredResp?.errors) {
        setIsCreating(false);
        return;
      }

      variables.credential = name;
      variables.user_name = undefined;
      variables.password = undefined;
    }

    // Create kafka schema registry credential
    if (
      variables.location?.includes(DATASOURCE_LOCATION_KAFKA) &&
      variables?.schema_registry_user_name !== '' &&
      variables?.schema_registry_password !== ''
    ) {
      const name = `${
        variables.name
      }_sr_credential_autogen_${getTimestampKey()}`;
      const variables3 = {
        name,
        type: DATASOURCE_LOCATION_KAFKA,
        identity: '',
        secret: '',
        security_protocol: DATASOURCE_KAFKA_AUTH_SASL_SSL.toUpperCase(),
        sasl_mechanism: 'PLAIN',
        sasl_username: variables.schema_registry_user_name,
        sasl_password: variables.schema_registry_password,
      };
      const createCredResp = await createCredential({
        variables: variables3,
      });
      if (createCredResp?.errors) {
        setIsCreating(false);
        return;
      }

      variables.schema_registry_credential = name;
      variables.schema_registry_user_name = undefined;
      variables.schema_registry_password = undefined;
    }

    // Need to create credential first for jdbc
    if (
      variables.location?.includes(DATASOURCE_LOCATION_JDBC) &&
      variables?.jdbc_auth_type === DATASOURCE_JDBC_AUTH_PASSWORD &&
      variables.user_name &&
      variables.password
    ) {
      const { user_name, password } = variables;
      const name = `${variables.name}_credential_autogen_${getTimestampKey()}`;
      const variables3 = {
        name,
        type: DATASOURCE_LOCATION_JDBC,
        identity: user_name,
        secret: password,
      };
      const createCredResp = await createCredential({
        variables: variables3,
      });
      if (createCredResp?.errors) {
        setIsCreating(false);
        return;
      }

      variables.credential = name;
      variables.user_name = undefined;
      variables.password = undefined;
    }

    // Set encryption algo when key set for S3
    if (
      variables.location?.includes(DATASOURCE_LOCATION_S3) &&
      variables?.s3_auth_type === DATASOURCE_S3_AUTH_ACCESS_KEY &&
      variables?.s3_encryption_customer_key !== ''
    ) {
      variables.s3_encryption_customer_algorithm = 'AES256';
    }

    createDatasource({
      variables: {
        ...variables,
        gcs_service_account_keys: variables.gcs_service_account_keys
          ? JSON.parse(variables.gcs_service_account_keys)
          : variables.gcs_service_account_keys,
      },
    })
      .then(resp => {
        setIsCreating(false);
        if (callback) {
          if (resp.errors) {
            callback(resp, null);
          } else {
            displaySuccess(`Data source ${variables.name} created.`);

            analytics.track(analytics.EVENT_TYPES.CREATED_DATA_SOURCE)({});

            callback(null, resp);
          }
        }
      })
      .catch(err => {
        setIsCreating(false);
        if (callback) {
          callback(err, null);
        }
      });
  };

  const create = _ => {
    form.submit();
  };

  const onValuesChange = (changedValues, allValues) => {
    setFormValues({
      ...allValues,
      jdbc_auth_type: allValues.jdbc_auth_type || DATASOURCE_JDBC_AUTH_PASSWORD,
      jdbc_driver_jar_path_type:
        allValues.jdbc_driver_jar_path_type || DATASOURCE_JDBC_JAR_PATH_MANUAL,
      azure_auth_type:
        allValues.azure_auth_type || DATASOURCE_AZURE_AUTH_NO_AUTH,
      gcs_auth_type: allValues.gcs_auth_type || DATASOURCE_GCS_AUTH_NO_AUTH,
      s3_auth_type: allValues.s3_auth_type || DATASOURCE_S3_AUTH_NO_AUTH,
      hdfs_auth_type: allValues.hdfs_auth_type || DATASOURCE_DEFAULT_AUTH_TYPE,
      kafka_auth_type:
        allValues.kafka_auth_type || DATASOURCE_KAFKA_AUTH_NO_AUTH,
      confluent_auth_type:
        allValues.confluent_auth_type || DATASOURCE_CONFLUENT_AUTH_NO_AUTH,
    });
  };

  const handleFileChange = file => {
    if (file.length === 2) {
      form.setFieldsValue({
        jdbc_driver_jar_path: `kifs://${file[0]}/${file[1]}`,
      });
      setFormValues({
        ...formValues,
        jdbc_driver_jar_path: `kifs://${file[0]}/${file[1]}`,
      });
    }
  };

  const handleFileFilter = (inputValue, path) => {
    return path.some(option =>
      option.value.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const fileOptions = useMemo(
    _ => {
      return folders
        ? folders
            .map(folder => {
              return {
                value: folder.name,
                label: folder.name,
                children: folder.files
                  .map(file => {
                    return {
                      value: file.name,
                      label: file.name,
                    };
                  })
                  .sort((file1, file2) => {
                    if (file1.value.toLowerCase() > file2.value.toLowerCase())
                      return 1;
                    if (file1.value.toLowerCase() < file2.value.toLowerCase())
                      return -1;
                    return 0;
                  }),
              };
            })
            .sort((folder1, folder2) => {
              if (folder1.value.toLowerCase() > folder2.value.toLowerCase())
                return 1;
              if (folder1.value.toLowerCase() < folder2.value.toLowerCase())
                return -1;
              return 0;
            })
        : [];
    },
    [folders]
  );

  const datasourceOptions = useMemo(_ => {
    return DATASOURCE_LOCATIONS.map(datasource => {
      const match = IMPORT_CATALOG.find(item => item.key === datasource.value);
      return {
        label: match ? (
          <>
            <img src={match?.img?.src} alt={match?.img?.alt} />
            <div className="label">{datasource.label}</div>
          </>
        ) : (
          datasource.label
        ),
        value: datasource.value,
      };
    });
  }, []);

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const formSingleLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  return (
    <Modal
      title="Create Data Source"
      open={visible}
      width={width}
      className="create-datasource-modal"
      bodyStyle={{ minHeight: '490px' }}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={create}
          loading={isCreating}
        >
          Create
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        {...formLayout}
        form={form}
        name="schema"
        layout="horizontal"
        initialValues={{}}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        colon={false}
        preserve={false}
      >
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              {...formSingleLayout}
              label="Name"
              name="name"
              wrapperCol={{ span: 11 }}
              rules={[
                {
                  required: true,
                  message: 'Please input data source name!',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              {...formSingleLayout}
              label="Location"
              name="location"
              className="location"
              rules={[
                {
                  required: true,
                  message: 'Please select data source location!',
                },
              ]}
            >
              <Radio.Group
                options={datasourceOptions}
                optionType="button"
                buttonStyle="solid"
                style={{ height: 80 }}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        {formValues?.location?.includes(DATASOURCE_LOCATION_JDBC) && (
          <>
            <Form.Item
              {...formSingleLayout}
              label="URL"
              name="location_jdbc"
              rules={[
                {
                  required: true,
                  message: 'Please input JDBC connection URL!',
                },
              ]}
            >
              <Input placeholder="<jdbc url connection string>" />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="jdbc_auth_type"
              initialValue={DATASOURCE_JDBC_AUTH_PASSWORD}
              hidden
            >
              <Radio.Group
                options={DATASOURCE_JDBC_AUTH_TYPES}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_AZURE) && (
          <>
            <Form.Item
              {...formSingleLayout}
              label="Container Name"
              name="azure_container_name"
              rules={[
                {
                  required: true,
                  message: 'Please input Azure Container Name!',
                },
              ]}
            >
              <Input placeholder="<azure container name>" />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="azure_auth_type"
              initialValue={DATASOURCE_AZURE_AUTH_NO_AUTH}
            >
              <Radio.Group
                options={DATASOURCE_AZURE_AUTH_TYPES}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_GCS) && (
          <>
            <Form.Item
              {...formSingleLayout}
              label="Bucket Name"
              name="gcs_bucket_name"
              rules={[
                {
                  required: true,
                  message: 'Please input GCS Bucket Name!',
                },
              ]}
            >
              <Input placeholder="<gcs bucket name>" />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Project ID"
              name="gcs_project_id"
            >
              <Input placeholder="<gcs project id>" />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="gcs_auth_type"
              initialValue={DATASOURCE_GCS_AUTH_NO_AUTH}
            >
              <Radio.Group
                options={DATASOURCE_GCS_AUTH_TYPES}
                optionType="button"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_HDFS) && (
          <>
            <Form.Item
              {...formSingleLayout}
              label="URL"
              name="location_hdfs"
              rules={[
                {
                  required: true,
                  message: 'Please input HDFS host:port!',
                },
              ]}
            >
              <Input
                addonBefore={DATASOURCE_HDFS_PREFIX}
                placeholder="<host>:<port>"
              />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="hdfs_auth_type"
              initialValue={DATASOURCE_DEFAULT_AUTH_TYPE}
            >
              <Radio.Group
                options={DATASOURCE_HDFS_AUTH_TYPES}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_S3) && (
          <>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="Bucket Name" name="s3_bucket_name">
                  <Input placeholder="<aws s3 bucket name>" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Region" name="s3_region">
                  <Input placeholder="<aws s3 region>" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="s3_auth_type"
              initialValue={DATASOURCE_S3_AUTH_NO_AUTH}
            >
              <Radio.Group
                options={DATASOURCE_S3_AUTH_TYPES}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_CONFLUENT) && (
          <>
            <Form.Item
              {...formSingleLayout}
              label="URL"
              name="location_confluent"
              rules={[
                {
                  required: true,
                  message: 'Please input Confluent Stream host:port!',
                },
              ]}
            >
              <Input
                addonBefore={DATASOURCE_CONFLUENT_PREFIX}
                placeholder="<host>:<port>"
              />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Topic Name"
              name="kafka_topic_name"
              rules={[
                {
                  required: true,
                  message: 'Please input Confluent Topic Name!',
                },
              ]}
            >
              <Input placeholder="<confluent stream topic name>" />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="confluent_auth_type"
              initialValue={DATASOURCE_CONFLUENT_AUTH_NO_AUTH}
            >
              <Radio.Group
                options={DATASOURCE_CONFLUENT_AUTH_TYPES}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_KAFKA) && (
          <>
            <Form.Item
              {...formSingleLayout}
              label="URL"
              name="location_kafka"
              rules={[
                {
                  required: true,
                  message: 'Please input Kafka Stream host:port!',
                },
              ]}
            >
              <Input
                addonBefore={DATASOURCE_KAFKA_PREFIX}
                placeholder="<host>:<port>"
              />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Topic Name"
              name="kafka_topic_name"
              rules={[
                {
                  required: true,
                  message: 'Please input Kafka Topic Name!',
                },
              ]}
            >
              <Input placeholder="<kafka stream topic name>" />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="kafka_auth_type"
              initialValue={DATASOURCE_KAFKA_AUTH_NO_AUTH}
            >
              <Radio.Group
                options={DATASOURCE_KAFKA_AUTH_TYPES}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_JDBC) &&
          formValues?.jdbc_auth_type === DATASOURCE_JDBC_AUTH_PASSWORD && (
            <>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item label="Username" name="user_name">
                    <Input placeholder="<jdbc connection username>" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Password" name="password">
                    <Input
                      type="password"
                      placeholder="<jdbc connection password>"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                {...formSingleLayout}
                label="Driver Class"
                name="jdbc_driver_class_name"
              >
                <Input placeholder="<jdbc driver class name>" />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="JAR Path Type"
                name="jdbc_driver_jar_path_type"
                initialValue={DATASOURCE_JDBC_JAR_PATH_MANUAL}
              >
                <Radio.Group
                  options={DATASOURCE_JDBC_JAR_PATH_TYPES}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="Driver JAR Path"
                name="jdbc_driver_jar_path"
                hidden={
                  formValues?.jdbc_driver_jar_path_type ===
                  DATASOURCE_JDBC_JAR_PATH_KIFS
                }
              >
                <Input placeholder="<path to jdbc driver jar>" />
              </Form.Item>
              {formValues?.jdbc_driver_jar_path_type ===
                DATASOURCE_JDBC_JAR_PATH_KIFS && (
                <Form.Item
                  {...formSingleLayout}
                  label="Driver JAR Path"
                  name="jdbc_driver_jar_path_kifs"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a file!',
                    },
                  ]}
                >
                  <Cascader
                    options={fileOptions}
                    onChange={handleFileChange}
                    showSearch={{ handleFileFilter }}
                    placeholder="Please search and select a file"
                    allowClear={false}
                  />
                </Form.Item>
              )}
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_CONFLUENT) &&
          formValues?.confluent_auth_type ===
            DATASOURCE_CONFLUENT_AUTH_SASL_SSL && (
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="API Key" name="user_name">
                  <Input placeholder="<confluent stream sasl api key>" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Secret" name="password">
                  <Input
                    type="password"
                    placeholder="<confluent stream sasl secret>"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_KAFKA) &&
          formValues?.kafka_auth_type === DATASOURCE_KAFKA_AUTH_SASL_SSL && (
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="API Key" name="user_name">
                  <Input placeholder="<kafka stream sasl api key>" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Secret" name="password">
                  <Input
                    type="password"
                    placeholder="<kafka stream sasl secret>"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        {(formValues?.location?.includes(DATASOURCE_LOCATION_CONFLUENT) ||
          formValues?.location?.includes(DATASOURCE_LOCATION_KAFKA)) && (
          <>
            <Row>
              <Col span={4}></Col>
              <Col span={20}>
                <h4>Schema Registry</h4>
              </Col>
            </Row>
            {formValues?.location?.includes(DATASOURCE_LOCATION_CONFLUENT) && (
              <Form.Item
                label="URL"
                name="schema_registry_location"
                rules={[
                  {
                    required: false,
                    message: 'Please input schema registry host!',
                  },
                ]}
                {...formSingleLayout}
              >
                <Input placeholder="<host>" />
              </Form.Item>
            )}
            {formValues?.location?.includes(DATASOURCE_LOCATION_KAFKA) && (
              <Form.Item
                label="URL"
                name="schema_registry_location"
                rules={[
                  {
                    required: false,
                    message: 'Please input schema registry host!',
                  },
                ]}
                {...formSingleLayout}
              >
                <Input placeholder="<host>" />
              </Form.Item>
            )}
            <Row>
              <Col span={12}>
                <Form.Item
                  label="API Key"
                  name="schema_registry_user_name"
                  rules={[
                    {
                      required: formValues.schema_registry_location,
                      message: 'Please input schema registry api key!',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder="<schema registry sasl api key>" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Secret"
                  name="schema_registry_password"
                  rules={[
                    {
                      required: formValues.schema_registry_location,
                      message: 'Please input schema registry secret!',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="<schema registry sasl secret>"
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_S3) &&
          formValues?.s3_auth_type === DATASOURCE_S3_AUTH_ACCESS_KEY && (
            <>
              <Form.Item
                {...formSingleLayout}
                label="Access ID"
                name="user_name"
              >
                <Input placeholder="<aws s3 access key id>" />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="Secret Key"
                name="password"
              >
                <Input
                  type="password"
                  placeholder="<aws s3 secret access key>"
                />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="Encryption Key"
                name="s3_encryption_customer_key"
              >
                <Input
                  type="password"
                  placeholder="<aws s3 customer encryption key>"
                />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_AZURE) &&
          formValues?.azure_auth_type === DATASOURCE_AZURE_AUTH_PASSWORD && (
            <>
              <Form.Item
                {...formSingleLayout}
                label="Storage Acct"
                name="user_name"
              >
                <Input placeholder="<azure storage account name>" />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="Access Key"
                name="password"
              >
                <Input
                  type="password"
                  placeholder="<azure storage account key>"
                />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_AZURE) &&
          formValues?.azure_auth_type === DATASOURCE_AZURE_AUTH_NO_AUTH && (
            <Form.Item
              {...formSingleLayout}
              label="Storage Acct"
              name="user_name"
            >
              <Input placeholder="<azure storage account name>" />
            </Form.Item>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_AZURE) &&
          formValues?.azure_auth_type === DATASOURCE_AZURE_AUTH_SAS_TOKEN && (
            <>
              <Form.Item
                {...formSingleLayout}
                label="Storage Acct"
                name="user_name"
              >
                <Input placeholder="<azure storage account name>" />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="SAS Token"
                name="azure_sas_token"
              >
                <Input placeholder="<azure sas token>" />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_AZURE) &&
          formValues?.azure_auth_type === DATASOURCE_AZURE_AUTH_OAUTH_TOKEN && (
            <>
              <Form.Item
                {...formSingleLayout}
                label="Storage Acct"
                name="user_name"
              >
                <Input placeholder="<azure storage account name>" />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="OAuth Token"
                name="azure_oauth_token"
              >
                <Input placeholder="<azure oauth token>" />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_AZURE) &&
          formValues?.azure_auth_type ===
            DATASOURCE_AZURE_AUTH_ACTIVE_DIRECTORY && (
            <>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item label="Client ID" name="user_name">
                    <Input placeholder="<ad client id>" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Secret Key" name="password">
                    <Input
                      type="password"
                      placeholder="<ad client secret key>"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                {...formSingleLayout}
                label="Storage Acct"
                name="azure_storage_account_name"
              >
                <Input placeholder="<azure storage account name>" />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="Tenant ID"
                name="azure_tenant_id"
              >
                <Input placeholder="<azure tenant id>" />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_GCS) &&
          formValues?.gcs_auth_type === DATASOURCE_GCS_AUTH_USERID_KEY && (
            <>
              <Form.Item
                {...formSingleLayout}
                label="Account ID"
                name="user_name"
              >
                <Input placeholder="<gcs account id>" />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="Private Key"
                name="password"
              >
                <Input
                  type="password"
                  placeholder="<gcs account private key>"
                />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_GCS) &&
          formValues?.gcs_auth_type === DATASOURCE_GCS_AUTH_JSON_KEY && (
            <>
              <Form.Item
                {...formSingleLayout}
                label="Service Acct Key"
                name="gcs_service_account_keys"
              >
                <Input.TextArea
                  placeholder="<gcs account json key text>"
                  rows={3}
                />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_HDFS) &&
          formValues?.hdfs_auth_type === DATASOURCE_HDFS_AUTH_PASSWORD && (
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="Username" name="user_name">
                  <Input placeholder="<hdfs username>" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Password" name="password">
                  <Input type="password" placeholder="<hdfs password>" />
                </Form.Item>
              </Col>
            </Row>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_HDFS) &&
          formValues?.hdfs_auth_type ===
            DATASOURCE_HDFS_AUTH_KERBEROS_TOKEN && (
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="Username" name="user_name">
                  <Input placeholder="<hdfs kerberos token username>" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Use Kerberos" name="hdfs_use_kerberos">
                  <Switch checked={formValues?.hdfs_use_kerberos} />
                </Form.Item>
              </Col>
            </Row>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_HDFS) &&
          formValues?.hdfs_auth_type ===
            DATASOURCE_HDFS_AUTH_KERBEROS_KEYTAB && (
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="Username" name="user_name">
                  <Input placeholder="<hdfs kerberos keytab username>" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Keytab File" name="hdfs_kerberos_keytab">
                  <Input placeholder="<keytab file/path>" />
                </Form.Item>
              </Col>
            </Row>
          )}
      </Form>
    </Modal>
  );
};

export default DatasourceCreateModal;
