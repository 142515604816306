// Imports
import React, { useMemo, useState } from 'react';
import {
  Layout,
  Descriptions,
  Button,
  Input,
  Form,
  Radio,
  Space,
  Tabs,
  Spin,
  Divider,
} from 'antd';
import { useSelector } from 'react-redux';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../../components/common/Spinner';
import { displayError, displaySuccess } from '../../helper';
import {
  DEFAULT_TABPANE_HEIGHT,
  DEFAULT_TABPANE_NOHEAD_HEIGHT,
} from '../../constants';

const { Content } = Layout;
const { TabPane } = Tabs;

// Component
const Settings = () => {
  const { data: { settings } = {}, loading } =
    GraphQLServices.Settings.useGetSettings();
  const [updateSetting] = GraphQLServices.Settings.useUpdateSettingById();

  const [form] = Form.useForm();

  const { topBarCollapsed } = useSelector(state => state.app);

  const [updating, setUpdating] = useState(false);

  const update = _ => {
    form.submit();
  };

  const onFinish = async values => {
    try {
      setUpdating(true);
      await Promise.all(
        Object.keys(values).map(key => {
          return new Promise(async (resolve, reject) => {
            const setting = settings.find(setting => setting.key === key);
            const resp = await updateSetting({
              variables: {
                id: setting.id,
                value: values[key],
              },
            });
            resolve(resp.data.settingUpdateById);
          });
        })
      );
      displaySuccess('Settings updated.');
    } catch (error) {
      displayError(error);
    } finally {
      setUpdating(false);
    }
  };

  const initialValues = useMemo(
    _ => {
      if (settings) {
        return settings.reduce((acc, cur) => {
          acc[cur.key] = cur.value;
          return acc;
        }, {});
      }
      return {};
    },
    [settings]
  );

  return (
    <>
      <h2>Workbench Settings</h2>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Spin indicator={<Spinner />} spinning={loading || updating}>
          <Tabs type="card" activeKey="settings">
            <TabPane tab="Settings" key="settings">
              <Content
                style={{
                  backgroundColor: '#ffffff',
                  padding: '20px',
                  height: topBarCollapsed
                    ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                    : DEFAULT_TABPANE_HEIGHT,
                  overflow: 'auto',
                }}
              >
                {!loading && (
                  <Form
                    form={form}
                    initialValues={initialValues}
                    onFinish={onFinish}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    colon={false}
                  >
                    <div
                      style={{
                        height: topBarCollapsed
                          ? 'calc(100vh - 225px)'
                          : 'calc(100vh - 275px)',
                        overflow: 'auto',
                      }}
                    >
                      <Descriptions
                        title="Features"
                        size="small"
                        column={1}
                        bordered
                      >
                        <Descriptions.Item
                          label="Enable SQL-GPT"
                          labelStyle={{ width: '200px' }}
                        >
                          <Form.Item
                            key="enable_sql_assistant"
                            name="enable_sql_assistant"
                            style={{
                              marginBottom: '0px',
                            }}
                          >
                            <Radio.Group
                              options={[
                                {
                                  label: 'Enabled',
                                  value: 'true',
                                },
                                {
                                  label: 'Disabled',
                                  value: 'false',
                                },
                              ]}
                              optionType="button"
                              buttonStyle="solid"
                            />
                          </Form.Item>
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider dashed />
                      <Descriptions
                        title="Maintenance"
                        size="small"
                        column={1}
                        bordered
                      >
                        <Descriptions.Item
                          label="System Message"
                          labelStyle={{ width: '200px' }}
                        >
                          <Form.Item
                            key="system_message"
                            name="system_message"
                            style={{
                              marginBottom: '0px',
                            }}
                          >
                            <Input />
                          </Form.Item>
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                    <div>
                      <Button
                        type="primary"
                        onClick={update}
                        style={{ float: 'right' }}
                        loading={updating}
                      >
                        Update
                      </Button>
                    </div>
                  </Form>
                )}
              </Content>
            </TabPane>
          </Tabs>
        </Spin>
      </Space>
    </>
  );
};

export default Settings;
