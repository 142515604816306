// Imports
import React, { useContext, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Space,
  Tooltip,
  Button,
  Spin,
  Popconfirm,
  Tabs,
  notification,
  Divider,
} from 'antd';
import {
  RedoOutlined,
  PauseOutlined,
  RightOutlined,
  LoadingOutlined,
  HistoryOutlined,
} from '@ant-design/icons';

// App Imports
import { ClusterContext, useEveryPermission } from '../../context';
import GraphQLServices from '../../graphql/services';
import Spinner from '../../components/common/Spinner';
import { DEPLOYMENT_TYPE, DEDICATED_SAAS } from '../../setup/config';
import Permission from '../../components/security/Permission';
import Overview from './Overview';
import Upgrade from './Upgrade';
import Snapshots from './Snapshots';
import AdminHistoryModal from '../../components/modal/AdminHistoryModal';
import { getWarehouseStatus } from '../../helper';
import useAnalytics from '../../hooks/useAnalytics';

const { TabPane } = Tabs;

// Component
const Warehouse = () => {
  const {
    loading: pendingUpgradesLoading,
    data: { k8s_kineticaclusterupgrades = [] } = {},
    refetch: refetchPendingUpgrades,
  } = GraphQLServices.K8sKineticaClusterUpgrades.useGetK8sKineticaClusterUpgrades();
  const { data: { upgrades = [] } = {} } =
    GraphQLServices.Upgrades.useGetUpgrades({
      variables: {
        deployment_type: DEPLOYMENT_TYPE,
      },
    });

  const [createK8SKineticaClusterAdmin] =
    GraphQLServices.K8sKineticaClusterAdmins.useCreateK8SKineticaClusterAdmin();

  const {
    clusters: k8s_kineticaclusters,
    loading: clusterLoading,
    refetch: refetchClusters,
  } = useContext(ClusterContext);

  const [showAdminHistoryModal, setShowAdminHistoryModal] = useState(false);
  const [isSuspending, setIsSuspending] = useState(false);
  const [isResuming, setIsResuming] = useState(false);

  const history = useHistory();
  const analytics = useAnalytics();

  const clusters = useMemo(
    _ => {
      if (k8s_kineticaclusters) {
        k8s_kineticaclusters.forEach(cluster => {
          const { phase } = getWarehouseStatus(cluster);
          if (phase === 'Suspending') {
            setIsSuspending(false);
          } else if (phase === 'Resuming') {
            setIsResuming(false);
          }
        });
        return k8s_kineticaclusters;
      }
      return [];
    },
    [k8s_kineticaclusters]
  );

  const handleSuspend = cluster => _ => {
    const { clusterName: name } = cluster?.spec?.gpudbCluster;

    notification.warn({
      message: 'Please wait until cluster suspend is completed.',
    });

    setIsSuspending(true);
    createK8SKineticaClusterAdmin({
      variables: {
        name,
        body: {
          spec: {
            kineticaClusterName: name,
            offline: {
              offline: true,
              options: {},
            },
          },
        },
      },
    }).then(resp => {
      analytics.track(analytics.EVENT_TYPES.SUSPEND_INITIATED)({});

      if (resp?.errors) {
        notification.error({
          message: 'Cluster suspend failed.',
          duration: 0,
        });
        console.error(resp?.errors);
      }

      refetchClusters();
      refetchPendingUpgrades();
    });
  };

  const handleResume = cluster => _ => {
    const { clusterName: name } = cluster?.spec?.gpudbCluster;

    notification.warn({
      message: 'Please wait until cluster resume is completed.',
    });

    setIsResuming(true);
    createK8SKineticaClusterAdmin({
      variables: {
        name,
        body: {
          spec: {
            kineticaClusterName: name,
            offline: {
              offline: false,
              options: {},
            },
          },
        },
      },
    }).then(resp => {
      analytics.track(analytics.EVENT_TYPES.RESUME_INITIATED)({});

      if (resp?.errors) {
        notification.error({
          message: 'Cluster resume failed.',
          duration: 0,
        });
        console.error(resp?.errors);
      }

      refetchClusters();
      refetchPendingUpgrades();
    });
  };

  const handleTabChange = tab => {
    history.push(`/warehouse/${tab}`);
  };

  const handleAdminHistoryClick = _ => {
    setShowAdminHistoryModal(true);
  };

  const [hasManagePermission] = useEveryPermission(['manage_warehouse']);
  // const hasManagePermission = true;
  const [hasUpgradePermission] = useEveryPermission(['manage_warehouse']);
  const [hasSnapshotPermission] = useEveryPermission(['manage_warehouse']);
  const {
    tabKey: currentTab = hasManagePermission
      ? 'overview'
      : hasUpgradePermission && !DEDICATED_SAAS
      ? 'upgrade'
      : hasSnapshotPermission && !DEDICATED_SAAS
      ? 'snapshots'
      : 'help',
  } = useParams();

  return (
    <>
      {clusters?.map((cluster, idx) => {
        // Check for existing upgrades
        const pendingUpgrades = k8s_kineticaclusterupgrades?.filter(
          kineticaClusterUpgrade => {
            return (
              kineticaClusterUpgrade?.spec?.kineticaClusterName ===
                cluster?.spec?.gpudbCluster?.clusterName &&
              kineticaClusterUpgrade?.status?.phase !== 'Completed'
            );
          }
        );

        const tabs = [];
        if (hasManagePermission) {
          tabs.push(
            <TabPane tab="Overview" key="overview">
              <Overview cluster={cluster} upgrades={upgrades} />
            </TabPane>
          );
        }

        if (hasUpgradePermission && !DEDICATED_SAAS) {
          tabs.push(
            <TabPane
              tab={
                cluster?.status?.phase.includes('Upgrading') ? (
                  <>
                    <LoadingOutlined style={{ color: '#1890ff' }} />
                    Upgrade
                  </>
                ) : (
                  'Upgrade'
                )
              }
              key="upgrade"
            >
              <Upgrade
                cluster={cluster}
                upgrades={upgrades}
                pendingUpgrades={pendingUpgrades}
                refresh={_ => {
                  refetchClusters();
                  refetchPendingUpgrades();
                }}
              />
            </TabPane>
          );
        }

        if (hasSnapshotPermission && !DEDICATED_SAAS) {
          tabs.push(
            <TabPane tab="Snapshots" key="snapshots">
              <Snapshots cluster={cluster} />
            </TabPane>
          );
        }

        const { phase, disableSuspend, disableResume } =
          getWarehouseStatus(cluster);

        return (
          <div key={`${idx}_${cluster?.spec?.gpudbCluster?.clusterName}`}>
            <Permission everyKeys={['manage_warehouse']}>
              <div style={{ float: 'right' }}>
                <Space>
                  <Popconfirm
                    title="Are you sure you want to suspend this cluster?"
                    onConfirm={handleSuspend(cluster)}
                    disabled={disableSuspend}
                  >
                    <Button
                      icon={<PauseOutlined />}
                      loading={phase === 'Suspending' || isSuspending}
                      disabled={disableSuspend}
                      danger
                    >
                      Suspend
                    </Button>
                  </Popconfirm>
                  <Popconfirm
                    title="Are you sure you want to resume this cluster?"
                    onConfirm={handleResume(cluster)}
                    disabled={disableResume}
                  >
                    <Button
                      icon={<RightOutlined />}
                      loading={phase === 'Resuming' || isResuming}
                      disabled={disableResume}
                      type="primary"
                    >
                      Resume
                    </Button>
                  </Popconfirm>
                  <Tooltip title="Suspend/Resume History">
                    <Button
                      icon={<HistoryOutlined />}
                      onClick={handleAdminHistoryClick}
                    />
                  </Tooltip>
                  <Divider type="vertical" />
                  <Tooltip title="Refresh">
                    <Button
                      icon={
                        <RedoOutlined
                          spin={clusterLoading || pendingUpgradesLoading}
                        />
                      }
                      onClick={() => {
                        refetchClusters();
                        refetchPendingUpgrades();
                      }}
                    />
                  </Tooltip>
                </Space>
              </div>
            </Permission>
            <h2>{cluster?.spec?.gpudbCluster?.clusterName}</h2>
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <Spin indicator={<Spinner />} spinning={clusterLoading}>
                <Tabs
                  type="card"
                  activeKey={currentTab}
                  onChange={handleTabChange}
                >
                  {tabs}
                </Tabs>
              </Spin>
            </Space>
            {showAdminHistoryModal && (
              <AdminHistoryModal
                visible={showAdminHistoryModal}
                pageSize={Math.floor((window.innerHeight - 460) / 40)}
                width={600}
                height={window.innerHeight - 350}
                close={_ => {
                  setShowAdminHistoryModal(false);
                }}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default Warehouse;
