// Imports
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { Alert, Spin } from 'antd';
// import { useIdleTimer } from 'react-idle-timer';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import store from 'store2';

// App Imports
import { UserContext, ClusterContext } from '../context';
import useWebappStatus from '../hooks/useWebappStatus';
// import { USER_IDLE_TIMEOUT } from '../setup/config';
import GraphQLServices from '../graphql/services';
import { routes } from '../setup/routes';
import { getUser } from '../store/auth/actions';
import ProtectedRoute from '../components/common/ProtectedRoute';
import Login from '../components/auth/Login';
import Logout from '../components/auth/Logout';
import Landing from '../containers/landing/Landing';
import DataExplore from '../containers/dataexplore/DataExplore';
import DataExploreEmbed from '../containers/dataexplore/DataExploreEmbed';
import DataExplorePrint from '../containers/dataexplore/DataExplorePrint';
import ImportExport from '../containers/importexport/ImportExport';
import Jobs from '../containers/jobs/Jobs';
import Warehouse from '../containers/warehouse/Warehouse';
import Metrics from '../containers/metrics/Metrics';
import Debug from '../containers/debug/Debug';
import Settings from '../containers/settings/Settings';
import Preferences from '../containers/preferences/Preferences';
import Users from '../containers/users/Users';
import CreateTable from '../containers/createtable/CreateTable';
import CreateExternalTable from '../containers/createexternaltable/CreateExternalTable';
import CreateGraph from '../containers/creategraph/CreateGraph';
import SolveGraph from '../containers/solvegraph/SolveGraph';
import ImportModel from '../containers/importmodel/ImportModel';
import CreateStream from '../containers/createstream/CreateStream';
import ConfigureModel from '../containers/configuremodel/ConfigureModel';
import ExecuteModel from '../containers/executemodel/ExecuteModel';
import Permission from '../components/security/Permission';
import Loading from './Loading';
// import { logout } from '../store/auth/actions';
// import { formatTimestamp } from '../formatter';
import { getPermissionList, sleep } from '../helper';
import {
  INTERCOM_APP_ID,
  CUSTOMER_INFO_JSON,
  DEPLOYMENT_TYPE,
  K8S_STATUS_POLL_INTERVAL,
} from '../setup/config';
import useAnalytics from '../hooks/useAnalytics';
import Spinner from '../components/common/Spinner';
import StandardLayout, {
  StandardLayoutContent,
} from './layouts/StandardLayout';
import './App.less';

Spin.setDefaultIndicator(Spinner);

const LAST_PATHNAME_KEY = 'last_pathname';

const wbStore = store.namespace('workbench');

const IntercomBoot = ({ user, children }) => {
  const { boot } = useIntercom();
  if (INTERCOM_APP_ID) {
    const {
      username,
      cloud_user_id = '',
      cloud_group_id = '',
      user_hash,
    } = user;
    try {
      const customerInfo = JSON.parse(CUSTOMER_INFO_JSON);
      if (Object.keys(customerInfo).length > 0) {
        delete customerInfo.name;
        boot({
          userId: cloud_user_id || customerInfo.managed_resource_group_id,
          email_addr: customerInfo.customer_email,
          userHash: user_hash,
          customAttributes: {
            ...customerInfo,
            kinetica_username: username,
            group_id: cloud_group_id,
          },
        });
      } else {
        throw new Error('No customer info');
      }
    } catch (err) {
      if (cloud_user_id && cloud_group_id) {
        boot({
          userId: cloud_user_id,
          userHash: user_hash,
          customAttributes: {
            kinetica_username: username,
            group_id: cloud_group_id,
          },
        });
      } else {
        boot({
          customAttributes: {
            kinetica_username: username,
          },
        });
      }
    }
  }
  return children;
};

const IntercomWrapper = ({ children }) => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={false}>
      <UserContext.Consumer>
        {userMe => {
          return <IntercomBoot user={userMe}>{children}</IntercomBoot>;
        }}
      </UserContext.Consumer>
    </IntercomProvider>
  );
};

// Wrapper for analytics
const Analytics = ({ children }) => {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    if (!location.pathname.toLowerCase().includes('login')) {
      analytics.page();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return children;
};

// Wrapper for clusters
const Clusters = ({ children }) => {
  const {
    data: { k8s_kineticaclusters: clusters = [] } = {},
    loading,
    refetch,
  } = GraphQLServices.K8sKineticaClusters.useGetK8sKineticaClusters({
    variables: {
      deployment_type: DEPLOYMENT_TYPE,
    },
    pollInterval: K8S_STATUS_POLL_INTERVAL * 2,
  });

  return (
    <ClusterContext.Provider value={{ clusters, loading, refetch }}>
      {children}
    </ClusterContext.Provider>
  );
};

// Component
const App = () => {
  const { data: { userMe = {} } = {} } =
    GraphQLServices.Users.useGetLocalUserMe();

  const { isUserLoaded } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const analytics = useAnalytics();

  const location = useLocation();
  useEffect(
    _ => {
      // Save last visited path for login/redirect
      if (
        !location.pathname.toLowerCase().includes('login') &&
        !location.pathname.toLowerCase().includes('logout') &&
        location.pathname.toLowerCase() !== '/'
      ) {
        wbStore.set(LAST_PATHNAME_KEY, location.pathname);
      }

      if (!location.pathname.includes('/dataexplore/workbook/')) {
        document.title = 'Kinetica Workbench';
      }
    },
    [location]
  );

  const callOnActivate = useCallback(
    setActive => {
      history.push(routes.root);
      window.location.reload();
    },
    [history]
  );
  const { isActive: isWebappActive } = useWebappStatus(callOnActivate);

  // On mount
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  // const handleOnIdle = event => {
  //   console.debug(
  //     'User became idle since',
  //     formatTimestamp(getLastActiveTime() / 1000)
  //   );
  //   dispatch(logout()).then(success => {
  //     if (success) {
  //       console.debug('User logged out', success);
  //       analytics.track(analytics.EVENT_TYPES.LOGGED_OUT)(_ => {
  //         window.location.reload();
  //       });
  //     }
  //   });
  // };

  // const { getLastActiveTime } = useIdleTimer({
  //   timeout: USER_IDLE_TIMEOUT * 60 * 1000,
  //   onIdle: handleOnIdle,
  //   debounce: 500,
  //   eventsThrottle: 500,
  //   crossTab: {
  //     emitOnAllTabs: true,
  //   },
  // });

  useEffect(
    _ => {
      const init = async () => {
        if (userMe?.username) {
          const {
            username,
            isGlobalAdmin: isAdmin,
            isDbStatusOk: isGpudbUser,
            cloud_user_id = '',
            cloud_group_id = '',
          } = userMe;
          console.groupCollapsed('User Info');
          console.table({
            username,
            isAdmin,
            isGpudbUser,
            cloud_user_id,
            cloud_group_id,
          });
          console.table(getPermissionList(userMe).map(perm => perm.name));
          console.groupEnd();

          if (userMe.cloud_user_id && userMe.cloud_group_id) {
            analytics.identify(userMe.cloud_user_id);
            await sleep(200);
            analytics.group(userMe.cloud_group_id);
          } else if (window.WORKBENCH_ENV.CUSTOMER_INFO_JSON) {
            try {
              var customerInfo = JSON.parse(
                window.WORKBENCH_ENV.CUSTOMER_INFO_JSON
              );
              if (Object.keys(customerInfo).length > 0) {
                analytics.identify(customerInfo.managed_resource_group_id);
              }
            } catch (err) {
              console.error(err);
            }
          }
        }
      };
      init();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userMe]
  );

  const loginMatch = useRouteMatch(routes.login);
  const logoutMatch = useRouteMatch(routes.logout);
  const embedMatch = useRouteMatch(routes.dataexplore.embed);
  const printMatch = useRouteMatch(routes.dataexplore.print);

  return isUserLoaded &&
    isWebappActive &&
    (!userMe || Object.keys(userMe).length > 0) ? (
    <UserContext.Provider value={userMe}>
      <div className="app">
        <Analytics>
          {loginMatch || logoutMatch ? (
            <Switch>
              {/* Auth */}
              <Route path={routes.login} exact>
                <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={false}>
                  <Login />
                </IntercomProvider>
              </Route>
              <Route path={routes.logout} exact>
                <Logout />
              </Route>
            </Switch>
          ) : !userMe ? (
            <Redirect to={routes.login} />
          ) : embedMatch || printMatch ? (
            <Clusters>
              <Switch>
                {/* Workbook Embed */}
                <ProtectedRoute path={routes.dataexplore.embed}>
                  <DataExploreEmbed />
                </ProtectedRoute>

                {/* Worksheet Print */}
                <ProtectedRoute path={routes.dataexplore.print}>
                  <DataExplorePrint />
                </ProtectedRoute>
              </Switch>
            </Clusters>
          ) : (
            <Clusters>
              <StandardLayout>
                <Switch>
                  {/* Root */}
                  <ProtectedRoute path={routes.root} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="landing">
                        <Landing />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Home */}
                  <ProtectedRoute path={routes.home} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="landing">
                        <Landing />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Explore */}
                  <ProtectedRoute path={routes.dataexplore.home} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="dataexplore">
                        <DataExplore />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Workbook */}
                  <ProtectedRoute path={routes.dataexplore.workbook}>
                    <IntercomWrapper>
                      <StandardLayoutContent className="dataexplore">
                        <DataExplore />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Create Table */}
                  <ProtectedRoute path={routes.dataexplore.createtable} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="createtable">
                        <CreateTable />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Edit Table */}
                  <ProtectedRoute path={routes.dataexplore.edittable} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="createtable">
                        <CreateTable />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Create External Table */}
                  <ProtectedRoute
                    path={routes.dataexplore.createexternaltable}
                    exact
                  >
                    <IntercomWrapper>
                      <StandardLayoutContent className="createexternaltable">
                        <CreateExternalTable />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Create Graph */}
                  <ProtectedRoute path={routes.dataexplore.creategraph} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="creategraph">
                        <CreateGraph />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Solve Graph */}
                  <ProtectedRoute path={routes.dataexplore.solvegraph} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="solvegraph">
                        <SolveGraph />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Import Model */}
                  <ProtectedRoute path={routes.dataexplore.importmodel} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="importmodel">
                        <ImportModel />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Configure Model */}
                  <ProtectedRoute
                    path={routes.dataexplore.configuremodel}
                    exact
                  >
                    <IntercomWrapper>
                      <StandardLayoutContent className="configuremodel">
                        <ConfigureModel />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Execute Model */}
                  <ProtectedRoute path={routes.dataexplore.executemodel} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="executemodel">
                        <ExecuteModel />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Create Stream */}
                  <ProtectedRoute path={routes.dataexplore.createstream} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="createstream">
                        <CreateStream />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Import/Export */}
                  <ProtectedRoute path={routes.importexport.home} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="importexport">
                        <ImportExport />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Import/Export Sources */}
                  <ProtectedRoute path={routes.importexport.source}>
                    <IntercomWrapper>
                      <StandardLayoutContent className="importexport">
                        <ImportExport />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Jobs */}
                  <ProtectedRoute path={routes.jobs.home} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="jobs">
                        <Jobs />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Warehouse */}
                  <ProtectedRoute path={routes.warehouse.home} exact>
                    <Permission everyKeys={['manage_warehouse']}>
                      <IntercomWrapper>
                        <StandardLayoutContent className="warehouse">
                          <Warehouse />
                        </StandardLayoutContent>
                      </IntercomWrapper>
                    </Permission>
                  </ProtectedRoute>

                  {/* Warehouse Tabs */}
                  <ProtectedRoute path={routes.warehouse.tab}>
                    <Permission everyKeys={['manage_warehouse']}>
                      <IntercomWrapper>
                        <StandardLayoutContent className="warehouse">
                          <Warehouse />
                        </StandardLayoutContent>
                      </IntercomWrapper>
                    </Permission>
                  </ProtectedRoute>

                  {/* Metrics */}
                  <ProtectedRoute path={routes.metrics.home} exact>
                    <Permission everyKeys={['manage_warehouse']}>
                      <IntercomWrapper>
                        <StandardLayoutContent className="metrics">
                          <Metrics />
                        </StandardLayoutContent>
                      </IntercomWrapper>
                    </Permission>
                  </ProtectedRoute>

                  {/* Users */}
                  <ProtectedRoute path={routes.users.home} exact>
                    <Permission everyKeys={['manage_user']}>
                      <IntercomWrapper>
                        <StandardLayoutContent className="users">
                          <Users />
                        </StandardLayoutContent>
                      </IntercomWrapper>
                    </Permission>
                  </ProtectedRoute>

                  {/* User Tabs */}
                  <ProtectedRoute path={routes.users.tab}>
                    <Permission everyKeys={['manage_user']}>
                      <IntercomWrapper>
                        <StandardLayoutContent className="users">
                          <Users />
                        </StandardLayoutContent>
                      </IntercomWrapper>
                    </Permission>
                  </ProtectedRoute>

                  {/* Settings */}
                  <ProtectedRoute path={routes.settings.home} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="settings">
                        <Settings />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Preferences */}
                  <ProtectedRoute path={routes.preferences.home} exact>
                    <IntercomWrapper>
                      <StandardLayoutContent className="preferences">
                        <Preferences />
                      </StandardLayoutContent>
                    </IntercomWrapper>
                  </ProtectedRoute>

                  {/* Debug */}
                  <ProtectedRoute path={routes.debug.home} exact>
                    <Permission everyKeys={['manage_warehouse']}>
                      <IntercomWrapper>
                        <StandardLayoutContent className="debug">
                          <Debug />
                        </StandardLayoutContent>
                      </IntercomWrapper>
                    </Permission>
                  </ProtectedRoute>

                  {/* Default */}
                  <Route>
                    <Redirect to={routes.root} />
                  </Route>
                </Switch>
              </StandardLayout>
            </Clusters>
          )}
        </Analytics>
      </div>
    </UserContext.Provider>
  ) : (
    <div
      style={{
        height: '100vh',
      }}
    >
      {!isWebappActive && (
        <Alert
          message={
            'Workbench is currently unavailable. ' +
            'This page will refresh once it becomes available.'
          }
          banner
        />
      )}
      <Loading />
    </div>
  );
};

export default App;
