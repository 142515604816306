// Imports
import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Space,
  Button,
  Tree,
  Tooltip,
  Input,
  Spin,
  Dropdown,
  Popconfirm,
  notification,
  Modal,
} from 'antd';
import {
  DownOutlined,
  RedoOutlined,
  PlusOutlined,
  DeleteOutlined,
  ExportOutlined,
  SearchOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { useResizeDetector } from 'react-resize-detector';
import debounce from 'debounce';

// App Imports
import { setExplorerPanelWorkbooksExpandedKeys } from '../../store/app/actions';
import GraphQLServices from '../../graphql/services';
import { GET_WORKBOOKS_BY_SEARCH_TERMS } from '../../graphql/schema/workbooks';
import { GET_WORKSHEET_BY_ID } from '../../graphql/schema/worksheets';
import TypeObject from './objects/TypeObject';
import WorkbookFolderObject from './objects/WorkbookFolderObject';
import WorkbookObject from './objects/WorkbookObject';
import WorksheetObject from './objects/WorksheetObject';
import { objHasMatchArray, sleep } from '../../helper';
import Spinner from '../common/Spinner';
import { NAV_ROUTE_DATAEXPLORE_WORKBOOK } from '../../constants';
import WorkbookCreateModal from '../modal/WorkbookCreateModal';
import WorkbookEditModal from '../modal/WorkbookEditModal';
import WorkbookImportModal from '../modal/WorkbookImportModal';
import WorkbookMoveModal from '../modal/WorkbookMoveModal';
import WorkbookFolderEditModal from '../modal/WorkbookFolderEditModal';
import WorksheetEditModal from '../modal/WorksheetEditModal';
import MultiSelectContext from './MultiSelectContext';
import useAnalytics from '../../hooks/useAnalytics';
import { useExportAllJsonWorkbooks } from '../../containers/dataexplore/utils';
import useEvent, { EVENT_TYPES } from '../../hooks/useEvent';
import { EXPLORER_PANEL_WIDTH } from '../../constants';
import { UserContext } from '../../context';

const { confirm, info } = Modal;

const KEYSTROKE_DELAY = 500;

const WorkbookExplorer = ({ offset = 0, refetchUsage = () => {} }) => {
  const { pathname } = useLocation();
  const matches = pathname.match(/dataexplore\/workbook\/(.*)/i);
  const workbookId = matches && matches.length === 2 ? matches[1] : null;

  const userMe = useContext(UserContext);

  const {
    loading: workbooksLoading,
    data: objects,
    refetch: refetchWorkbooks,
  } = GraphQLServices.Workbooks.useGetWorkbooksOnly();
  const [removeWorkbookById, { loading: removingWorkbook }] =
    GraphQLServices.Workbooks.useRemoveWorkbookById();
  const exportAllWorkbooks = useExportAllJsonWorkbooks();

  const { emit: emitWorkbook } = useEvent(EVENT_TYPES.WORKBOOK_UPDATE);

  const [isSearching, setIsSearching] = useState(false);
  const [entityFilterStr, setEntityFilterStr] = useState('');
  const [entityFilterMatches, setEntityFilterMatches] = useState([]);

  const [workbooksData, setWorkbooksData] = useState([]);
  const [exampleWorkbooksData, setExampleWorkbooksData] = useState([]);
  const [sharedWorkbooksData, setSharedWorkbooksData] = useState([]);

  const { explorerPanelExpandedKeys } = useSelector(state => state.app);
  const [expandedKeys, setExpandedKeys] = useState([
    'data.workbooks.personal',
    'data.workbooks.examples',
    'data.workbooks.shared',
    ...explorerPanelExpandedKeys.workbooks,
  ]);

  const [showWorkbookCreateModal, setShowWorkbookCreateModal] = useState(false);
  const [showWorkbookEditModal, setShowWorkbookEditModal] = useState(false);
  const [showWorkbookImportModal, setShowWorkbookImportModal] = useState(false);
  const [currentEditWorkbook, setCurrentEditWorkbook] = useState(undefined);
  const [showWorksheetEditModal, setShowWorksheetEditModal] = useState(false);
  const [currentEditWorksheet, setCurrentEditWorksheet] = useState(undefined);

  const [moveWorkbook, setMoveWorkbook] = useState(undefined);
  const [showWorkbookMove, setShowWorkbookMove] = useState(false);

  const [editFolder, setEditFolder] = useState(undefined);
  const [showFolderEdit, setShowFolderEdit] = useState(false);

  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const { height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 200,
  });

  const analytics = useAnalytics();
  const graphqlClient = useApolloClient();

  const filterWorkbook = (data, filters = [], contentMatches = []) => {
    if (filters.length === 0 && contentMatches.length === 0) {
      return data;
    }
    const strMatches = data
      .reduce((acc, cur) => {
        if (cur.children && cur.children.length > 0) {
          return acc.concat(cur.children);
        }
        return acc.concat([cur]);
      }, [])
      .map(item => {
        return Object.keys(item).reduce((acc, cur) => {
          if (Array.isArray(item[cur])) {
            acc[cur] = item[cur].filter(item2 => {
              return objHasMatchArray(item2, filters, [
                'name',
                'description',
                'username',
              ]);
            });
          } else {
            acc[cur] = item[cur];
          }
          return acc;
        }, {});
      })
      .filter(item => {
        return objHasMatchArray(item, filters, [
          'name',
          'description',
          'username',
        ]);
      });

    const uniqueIDs = Array.from(
      new Set([
        ...strMatches.map(obj => obj.id),
        ...contentMatches.map(obj => obj.id),
      ])
    );

    return data.reduce((acc, cur) => {
      if (cur.children && cur.children.length > 0) {
        if (cur.children.some(workbook => uniqueIDs.includes(workbook.id))) {
          acc.push({
            ...cur,
            children: cur.children.filter(workbook2 =>
              uniqueIDs.includes(workbook2.id)
            ),
          });
        }
      } else if (uniqueIDs.includes(cur.id)) {
        acc.push(cur);
      }
      return acc;
    }, []);
  };

  useEffect(
    _ => {
      const workbooksData =
        objects && objects.workbooks && userMe
          ? objects.workbooks
              .filter(
                workbook =>
                  workbook.user.id === userMe.id && !workbook.is_example
              )
              .reduce(
                (acc, cur) => {
                  if (cur.folder && cur.folder.trim() !== '') {
                    return Array.from(new Set([...acc, cur.folder]));
                  }
                  return acc;
                },
                ['default']
              )
              .sort((a, b) => {
                if (a.toLowerCase() > b.toLowerCase()) return 1;
                if (a.toLowerCase() < b.toLowerCase()) return -1;
                return 0;
              })
              .map(folder => {
                return {
                  id: `folder_${folder}`,
                  title: folder,
                  key: `folder.${folder}`,
                  children: objects.workbooks
                    .filter(
                      workbook =>
                        workbook.user.id === userMe.id &&
                        !workbook.is_example &&
                        (workbook.folder === folder ||
                          (folder === 'default' &&
                            (!workbook.folder ||
                              workbook.folder.trim() === '')))
                    )
                    .map(workbook => {
                      return {
                        id: workbook.id,
                        title: workbook.name || '< root >',
                        key: `workbook.${workbook.id}`,
                        children: [],
                        source: workbook,
                        selectable: false,
                      };
                    }, []),
                  source: folder,
                  selectable: false,
                };
              })
          : [];
      setWorkbooksData(workbooksData);

      const exampleWorkbookData =
        objects && objects.workbooks && userMe
          ? objects.workbooks
              .filter(
                workbook =>
                  workbook.user.id === userMe.id && workbook.is_example
              )
              .reduce((acc, cur) => {
                acc.push({
                  id: cur.id,
                  title: cur.name || '< root >',
                  key: `workbook.${cur.id}`,
                  children: [],
                  source: cur,
                  selectable: false,
                  disableCheckbox: true,
                });
                return acc;
              }, [])
              .sort((a, b) => {
                if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                return 0;
              })
              .sort((a, b) => {
                if (
                  a.title.toLowerCase().includes('quick start') &&
                  !b.title.toLowerCase().includes('quick start')
                )
                  return -1;
                if (
                  !a.title.toLowerCase().includes('quick start') &&
                  b.title.toLowerCase().includes('quick start')
                )
                  return 1;
                return 0;
              })
          : [];
      setExampleWorkbooksData(exampleWorkbookData);

      const sharedWorkbookData =
        objects && objects.workbooks && userMe
          ? objects.workbooks
              .filter(
                workbook => workbook.user.id !== userMe.id && workbook.is_shared
              )
              .reduce((acc, cur) => {
                acc.push({
                  id: cur.id,
                  title: cur.name || '< root >',
                  key: `workbook.${cur.id}`,
                  children: [],
                  source: cur,
                  sharedSection: true,
                  selectable: false,
                  disableCheckbox: true,
                });
                return acc;
              }, [])
          : [];
      setSharedWorkbooksData(sharedWorkbookData);
    },
    [objects, userMe]
  );

  const treeData = [
    {
      title: 'My Workbooks',
      key: 'data.workbooks.personal',
      children: filterWorkbook(
        workbooksData,
        entityFilterStr
          .trim()
          .split(' ')
          .map(v => v.trim())
          .filter(term => term.length >= 2),
        entityFilterMatches
      ),
      selectable: false,
      checkable: false,
    },
    {
      title: 'Examples',
      key: 'data.workbooks.examples',
      children: filterWorkbook(
        exampleWorkbooksData,
        entityFilterStr
          .trim()
          .split(' ')
          .map(v => v.trim())
          .filter(term => term.length >= 2),
        entityFilterMatches
      ),
      selectable: false,
      checkable: false,
    },
    {
      title: 'Shared With Me',
      key: 'data.workbooks.shared',
      children: filterWorkbook(
        sharedWorkbooksData,
        entityFilterStr
          .trim()
          .split(' ')
          .map(v => v.trim())
          .filter(term => term.length >= 2),
        entityFilterMatches
      ),
      selectable: false,
      checkable: false,
    },
  ];

  const handleTreeCheck = (checkedKeys, e) => {
    setCheckedKeys(checkedKeys);
  };

  const handleMultiSelect = useCallback(
    node => {
      setCheckedKeys([node.key]);
      setIsMultiSelect(!isMultiSelect);
    },
    [isMultiSelect]
  );

  const handleCancelMultiSelect = _ => {
    setCheckedKeys([]);
    setIsMultiSelect(false);
  };

  const handleDeleteSelected = _ => {
    Promise.all(
      checkedKeys.map(key => {
        const type = key.split('.')[0];
        if (type === 'workbook') {
          const id = key.split('.')[1];
          return removeWorkbookById({
            variables: {
              id,
            },
          });
        }
        return Promise.resolve();
      })
    )
      .then(responses => {
        notification.success({
          message: 'Success',
          description: `Selected workbook(s) deleted successfully!`,
        });
        refetchWorkbooks();
        handleCancelMultiSelect();
      })
      .catch(errors => {
        console.error(errors);
      });
  };

  const handleAddMenuClick = type => {
    switch (type.key) {
      case 'newfolder': {
        info({
          title:
            'To create a new folder, simply create a new workbook or move an existing workbook.',
          icon: <InfoCircleOutlined />,
          width: 600,
          centered: true,
        });
        return;
      }
      case 'newworkbook': {
        setShowWorkbookCreateModal(true);
        return;
      }
      case 'importworkbook': {
        setShowWorkbookImportModal(true);
        return;
      }
      default: {
        return;
      }
    }
  };

  const handleEditMenuClick = useCallback(
    workbook => e => {
      setCurrentEditWorkbook(workbook);
      setShowWorkbookEditModal(true);
    },
    []
  );

  const handleEditWorksheetMenuClick = useCallback(
    worksheet => e => {
      setCurrentEditWorksheet(worksheet);
      setShowWorksheetEditModal(true);
    },
    []
  );

  const handleTreeExpand = useCallback(
    (current, { expanded, node }) => {
      if (expanded) {
        // Add to expandedKeys
        const added = [...expandedKeys, node.key];
        const update = [...new Set(added)];
        setExpandedKeys(update);
        dispatch(setExplorerPanelWorkbooksExpandedKeys(update));
      } else {
        // Remove from expandedKeys
        const copy = [...expandedKeys];
        copy.splice(copy.indexOf(node.key), 1);
        const update = [...copy];
        setExpandedKeys(update);
        dispatch(setExplorerPanelWorkbooksExpandedKeys(update));
      }
    },
    [expandedKeys, dispatch]
  );

  const handleWorkbookCreateCallback = async (err, resp) => {
    if (resp) {
      const block = resp?.data?.blockCreate;
      const { worksheet_id } = block;

      const worksheetResp = await graphqlClient.query({
        query: GET_WORKSHEET_BY_ID,
        variables: {
          id: worksheet_id,
        },
      });

      refetchWorkbooks().then(resp => {
        const currentWorkbook = resp?.data?.workbooks.find(
          workbook => workbook.id === worksheetResp?.data?.worksheet.workbook_id
        );
        setShowWorkbookCreateModal(false);

        analytics.track(analytics.EVENT_TYPES.CREATED_WORKBOOK)({});

        history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${currentWorkbook.id}`);
      });
    } else {
      console.error(err);
    }
  };

  const handleWorkbookEditCallback = (err, resp) => {
    if (resp) {
      emitWorkbook({ workbook_id: currentEditWorkbook.id });
      setCurrentEditWorkbook(undefined);
      setShowWorkbookEditModal(false);
    } else {
      console.error(err);
    }
  };

  const handleWorkbookImportCallback = (err, resp) => {
    if (resp) {
      if (resp?.data?.workbookImport) {
        const { id } = resp?.data?.workbookImport?.[0];
        refetchWorkbooks().then(_ => {
          setShowWorkbookImportModal(false);
          history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${id}`);
        });
      } else if (resp?.data?.workbookCreate) {
        const { id } = resp?.data?.workbookCreate;
        refetchWorkbooks().then(_ => {
          setShowWorkbookImportModal(false);
          history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${id}`);
        });
      }
    } else {
      console.error(err);
    }
  };

  const handleWorksheetEditCallback = (err, resp) => {
    if (resp) {
      setCurrentEditWorksheet(undefined);
      setShowWorksheetEditModal(false);
    } else {
      console.error(err);
    }
  };

  const resetContext = useCallback(() => {
    setMoveWorkbook(undefined);
    setShowWorkbookMove(false);

    setEditFolder(undefined);
    setShowFolderEdit(false);
  }, []);

  const handleWorkbookMove = useCallback(
    workbook => {
      resetContext();
      setMoveWorkbook(workbook);
      setShowWorkbookMove(true);
    },
    [resetContext]
  );

  const handleWorkbookMoveCallback = (err, resp) => {
    if (resp) {
      notification.open({
        message: 'Workbook Move',
        description: `${resp?.data?.workbookUpdateById?.name} moved successfully!`,
        type: 'success',
      });
      refetchWorkbooks().then(resp => {
        resetContext();
      });
    } else {
      console.error(err);
    }
  };

  const handleFolderEdit = useCallback(
    folder => {
      resetContext();
      setEditFolder(folder);
      setShowFolderEdit(true);
    },
    [resetContext]
  );

  const handleFolderEditCallback = async (err, folder) => {
    if (folder) {
      notification.open({
        message: 'Folder Rename',
        description: `${folder} renamed successfully!`,
        type: 'success',
      });

      // Add to expandedKeys
      const added = [...expandedKeys, `folder.${folder}`];
      const update = [...new Set(added)];
      setExpandedKeys(update);
      dispatch(setExplorerPanelWorkbooksExpandedKeys(update));

      await refetchWorkbooks();
      resetContext();
    } else {
      console.error(err);
    }
  };

  const handleFolderDelete = useCallback(
    folder => {
      const folderObj = workbooksData.find(item => item.title === folder);
      if (folderObj) {
        confirm({
          title: `Do you want to delete folder ${folderObj.title} and all workbooks within?`,
          icon: <ExclamationCircleOutlined />,
          onOk() {
            Promise.all(
              folderObj.children.map(workbook => {
                const { id } = workbook;
                return removeWorkbookById({
                  variables: {
                    id,
                  },
                });
              })
            )
              .then(resp => {
                notification.open({
                  message: 'Folder Delete',
                  description: `${folderObj.title} deleted successfully!`,
                  type: 'success',
                });

                refetchWorkbooks();
              })
              .catch(err => {
                console.error(err);
              });
          },
          onCancel() {
            // Do nothing
          },
          width: 600,
          centered: true,
        });
      }
    },
    [refetchWorkbooks, removeWorkbookById, workbooksData]
  );

  const handleTitleRender = useCallback(
    nodeData => {
      if (nodeData.key.startsWith('data.')) {
        return <TypeObject nodeData={nodeData} refresh={refetchWorkbooks} />;
      } else if (nodeData.key.startsWith('folder.')) {
        return (
          <WorkbookFolderObject
            nodeData={nodeData}
            refresh={refetchWorkbooks}
            handleFolderEdit={handleFolderEdit}
            handleFolderDelete={handleFolderDelete}
          />
        );
      } else if (nodeData.key.startsWith('workbook.')) {
        return (
          <WorkbookObject
            nodeData={nodeData}
            refresh={refetchWorkbooks}
            editClick={handleEditMenuClick}
            current={workbookId}
            handleWorkbookMove={handleWorkbookMove}
            handleMultiSelect={handleMultiSelect}
          />
        );
      } else if (nodeData.key.startsWith('worksheet.')) {
        return (
          <WorksheetObject
            nodeData={nodeData}
            refresh={refetchWorkbooks}
            editClick={handleEditWorksheetMenuClick}
          />
        );
      }
    },
    [
      refetchWorkbooks,
      handleFolderEdit,
      handleFolderDelete,
      handleEditMenuClick,
      workbookId,
      handleWorkbookMove,
      handleMultiSelect,
      handleEditWorksheetMenuClick,
    ]
  );

  const addMenu = useMemo(_ => {
    return {
      items: [
        {
          key: 'newworkbook',
          label: 'Add New Workbook',
        },
        {
          key: 'newfolder',
          label: 'Add New Folder',
        },
        {
          key: 'importworkbook',
          label: 'Import Workbook (JSON/SQL)',
        },
      ],
      onClick: handleAddMenuClick,
    };
  }, []);

  useEffect(
    _ => {
      if (objects) {
        const { workbooks } = objects;

        analytics.track(analytics.EVENT_TYPES.WORKBOOK_COUNT)({
          workbooks: workbooks.length,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objects]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchWorkbooks = useCallback(
    debounce(async terms => {
      setIsSearching(true);
      const filterMatches = await graphqlClient.query({
        query: GET_WORKBOOKS_BY_SEARCH_TERMS,
        variables: {
          terms,
        },
      });
      await sleep(KEYSTROKE_DELAY);
      setIsSearching(false);
      const { data: { workbooksBySearchTerms = [] } = {} } = filterMatches;
      setEntityFilterMatches(workbooksBySearchTerms);
    }, KEYSTROKE_DELAY),
    [setEntityFilterMatches]
  );

  const handleWorkbookSearch = e => {
    setEntityFilterStr(e.target.value);

    if (e.target.value.trim() === '') {
      setEntityFilterMatches([]);
      return;
    }

    const terms = e.target.value
      .trim()
      .split(' ')
      .map(value => value.trim())
      .filter(term => term.length >= 2);
    if (terms.length > 0) {
      searchWorkbooks(terms);
    } else {
      setEntityFilterMatches([]);
    }
  };

  return (
    <div
      className="workbooks-explorer"
      style={{
        padding: '15px',
        height: `calc(100vh - ${100 + offset}px)`,
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <div style={{ width: `${EXPLORER_PANEL_WIDTH - 38}px` }}>
          <Space>
            <Input
              placeholder="Workbook search"
              value={entityFilterStr}
              onChange={handleWorkbookSearch}
              suffix={
                isSearching ? (
                  <LoadingOutlined />
                ) : (
                  <Tooltip title="Search workbooks including workbook/worksheet names, descriptions, and contents of SQL, HTML and Text blocks. Search terms must be at least 2 characters.">
                    <SearchOutlined />
                  </Tooltip>
                )
              }
              allowClear
            />
            <Tooltip title="Refresh">
              <Button
                icon={<RedoOutlined spin={workbooksLoading} />}
                onClick={() => {
                  refetchWorkbooks();
                  refetchUsage();
                }}
              />
            </Tooltip>
            <Tooltip title="Export All Workbooks JSON">
              <Popconfirm
                title="Are you sure you want to export all workbooks?"
                onConfirm={exportAllWorkbooks}
              >
                <Button icon={<ExportOutlined />} />
              </Popconfirm>
            </Tooltip>
            <Dropdown menu={addMenu}>
              <Button icon={<PlusOutlined />} />
            </Dropdown>
          </Space>
        </div>
        {isMultiSelect && (
          <div style={{ width: `${EXPLORER_PANEL_WIDTH - 38}px` }}>
            <Space>
              <Popconfirm
                title="Are you sure you want to delete selected workbook(s)?"
                placement="topRight"
                onConfirm={() => handleDeleteSelected()}
                disabled={checkedKeys.length === 0 || removingWorkbook}
              >
                <Button
                  type="primary"
                  icon={<DeleteOutlined />}
                  style={{ width: `${EXPLORER_PANEL_WIDTH - 160}px` }}
                  loading={false}
                  disabled={checkedKeys.length === 0 || removingWorkbook}
                  danger
                >
                  Delete Selected
                </Button>
              </Popconfirm>
              <Button
                onClick={() => handleCancelMultiSelect()}
                style={{ width: `${EXPLORER_PANEL_WIDTH - 236}px` }}
                disabled={removingWorkbook}
                danger
              >
                Cancel
              </Button>
            </Space>
          </div>
        )}
        <Spin
          indicator={<Spinner />}
          spinning={workbooksLoading}
          style={{ opacity: '20%' }}
        >
          <div
            ref={ref}
            style={{
              height: isMultiSelect
                ? `calc(100vh - ${220 + offset}px)`
                : `calc(100vh - ${180 + offset}px)`,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {treeData.length > 0 && height !== undefined && height > 0 && (
              <MultiSelectContext.Provider value={isMultiSelect}>
                <Tree
                  showLine={{ showLeafIcon: false }}
                  switcherIcon={<DownOutlined />}
                  expandedKeys={expandedKeys}
                  onExpand={handleTreeExpand}
                  onCheck={handleTreeCheck}
                  checkedKeys={checkedKeys}
                  treeData={treeData}
                  height={height}
                  blockNode={true}
                  titleRender={handleTitleRender}
                  checkable={isMultiSelect}
                />
              </MultiSelectContext.Provider>
            )}
          </div>
        </Spin>
      </Space>
      {showWorkbookCreateModal && (
        <WorkbookCreateModal
          visible={showWorkbookCreateModal}
          close={_ => {
            setShowWorkbookCreateModal(false);
          }}
          callback={handleWorkbookCreateCallback}
        />
      )}
      {showWorkbookImportModal && (
        <WorkbookImportModal
          visible={showWorkbookImportModal}
          close={_ => {
            setShowWorkbookImportModal(false);
          }}
          callback={handleWorkbookImportCallback}
        />
      )}
      {currentEditWorkbook && (
        <WorkbookEditModal
          workbook={currentEditWorkbook}
          visible={showWorkbookEditModal}
          close={_ => {
            setCurrentEditWorkbook(undefined);
            setShowWorkbookEditModal(false);
          }}
          callback={handleWorkbookEditCallback}
        />
      )}
      {currentEditWorksheet && (
        <WorksheetEditModal
          worksheet={currentEditWorksheet}
          visible={showWorksheetEditModal}
          close={_ => {
            setCurrentEditWorksheet(undefined);
            setShowWorksheetEditModal(false);
          }}
          callback={handleWorksheetEditCallback}
        />
      )}
      {moveWorkbook && (
        <WorkbookMoveModal
          workbook={moveWorkbook}
          visible={showWorkbookMove}
          close={_ => {
            setShowWorkbookMove(false);
            setMoveWorkbook(undefined);
          }}
          callback={handleWorkbookMoveCallback}
        />
      )}
      {editFolder && (
        <WorkbookFolderEditModal
          folder={editFolder}
          workbooks={objects.workbooks.filter(
            workbook =>
              workbook.user.id === userMe.id &&
              !workbook.is_example &&
              workbook.folder === editFolder
          )}
          visible={showFolderEdit}
          close={_ => {
            setShowFolderEdit(false);
            setEditFolder(undefined);
          }}
          callback={handleFolderEditCallback}
        />
      )}
    </div>
  );
};

export default WorkbookExplorer;
