// Imports
import React, { useState, useContext, useMemo } from 'react';
import { Modal, Form, Input, Button, Row, Col, Alert } from 'antd';
import axios from 'axios';

// App Imports
import GraphQLServices from '../../graphql/services';
import { ClusterContext } from '../../context';
import {
  getWarehouseStatus,
  displaySuccess,
  displayError,
  checkPassword,
  sleep,
} from '../../helper';
import { APP_URL_API } from '../../setup/config';

const UserPasswordModal = ({
  cluster,
  user,
  visible,
  close,
  mypassword = false,
  callback,
}) => {
  const [updatePasswordByUsername] =
    GraphQLServices.Users.useUpdatePasswordByUsername();
  const [updateMyPassword] = GraphQLServices.Users.useUpdateMyPassword();

  const initialValues = {
    username: user?.username,
    current: '',
    password: '',
  };

  const { clusters } = useContext(ClusterContext);

  const [isUpdating, setIsUpdating] = useState(false);

  const [form] = Form.useForm();

  const clusterStatus = useMemo(
    _ => {
      if (clusters && clusters.length > 0) {
        return getWarehouseStatus(clusters[0]);
      }
      return null;
    },
    [clusters]
  );

  const onFinish = async values => {
    const { username, current, password } = values;
    setIsUpdating(true);
    await sleep(750);

    let updateResp;
    if (mypassword) {
      try {
        // Check if current password is correct
        await axios.post(
          `${APP_URL_API}/auth/verify`,
          {
            username,
            password: current,
          },
          {
            auth: {
              username,
              password: current,
            },
          }
        );

        updateResp = await updateMyPassword({
          variables: {
            password,
            ring: cluster?.metadata?.name,
          },
        });
      } catch (error) {
        form.setFields([
          {
            name: 'current',
            errors: ['Current password is invalid'],
          },
        ]);
      }
    } else {
      updateResp = await updatePasswordByUsername({
        variables: {
          username,
          password,
          ring: cluster?.metadata?.name,
        },
      });
    }

    setIsUpdating(false);
    if (updateResp && !updateResp?.errors) {
      displaySuccess(`Password updated.`);
      if (callback) {
        callback(null, password);
      }
    } else {
      if (callback) {
        displayError(`Password update failed!`);
        callback(new Error('Password update failed!'), null);
      }
    }
  };

  const update = _ => {
    let hasError = false;
    const password = form.getFieldValue('password') || '';
    const confirm = form.getFieldValue('confirm') || '';

    // Check for 'strong' password
    if (!checkPassword(password)) {
      form.setFields([
        {
          name: 'password',
          errors: [
            'At least 8 characters containing one lower case, one upper case, and one number are required',
          ],
        },
      ]);
      hasError = true;
    } else if (password !== confirm) {
      // Check that confirm matches new password
      form.setFields([
        {
          name: 'confirm',
          errors: ['Confirm password must match new password'],
        },
      ]);
      hasError = true;
    }

    if (!hasError) {
      form.submit();
    }
  };

  const disabled = useMemo(
    _ => {
      return !clusterStatus || clusterStatus.phase !== 'Running';
    },
    [clusterStatus]
  );

  return (
    <Modal
      title="Set Password"
      open={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={update}
          loading={isUpdating}
          disabled={disabled}
        >
          Update
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      {disabled && (
        <Alert
          type="warning"
          message="Database must be running"
          style={{ marginBottom: 10 }}
          banner
        />
      )}
      <Form
        form={form}
        name="password"
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        colon={false}
        disabled={disabled}
      >
        <Form.Item label="Username" name="username">
          <Input disabled />
        </Form.Item>
        {mypassword && (
          <Form.Item
            label="Current Password"
            name="current"
            rules={[
              {
                required: true,
                message: 'Please input current password!',
                whitespace: true,
              },
            ]}
          >
            <Input type="password" autoComplete="new-password" />
          </Form.Item>
        )}
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input new password!',
                  whitespace: true,
                },
              ]}
            >
              <Input type="password" autoComplete="new-password" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Confirm Password"
              name="confirm"
              rules={[
                {
                  required: true,
                  message: 'Please input confirm password!',
                  whitespace: true,
                },
              ]}
            >
              <Input type="password" autoComplete="new-password" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default UserPasswordModal;
