import { gql, useQuery, useMutation } from '@apollo/client';

export const GET_K8S_KINETICACLUSTER_ELASTICITIES = gql`
  query K8sKineticaClusterElasticities {
    k8s_kineticaclusterelasticities {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const GET_K8S_KINETICACLUSTERELASTICITY_BY_NAME = gql`
  query GetK8sKineticaClusterElasticityByName($name: String!) {
    k8s_kineticaclusterelasticity(name: $name) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const CREATE_K8S_KINETICACLUSTERELASTICITY = gql`
  mutation CreateK8sKineticaClusterElasticity($name: String!, $body: JSON!) {
    kineticaClusterElasticityCreate(name: $name, body: $body) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaClusterElasticities = () => useQuery(GET_K8S_KINETICACLUSTER_ELASTICITIES);

export const useCreateK8SKineticaClusterElasticity = () => {
  return useMutation(CREATE_K8S_KINETICACLUSTERELASTICITY);
};
