// Imports
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Card, notification } from 'antd';
import { SettingOutlined, GlobalOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

// App Imports
import CreateGraphForm from './CreateGraphForm';
import CreateOSMGraphForm from './CreateOSMGraphForm';
import { GET_TABLE_BY_NAME } from '../../graphql/schema/tables';
import TableWmsModal from '../../components/modal/TableWmsModal';
import {
  MIN_WMS_PREVIEW_MODAL_WIDTH,
  MAX_WMS_PREVIEW_MODAL_WIDTH_RATIO,
} from '../../constants';

const MODES = {
  DEFAULT: null,
  MANUAL: 'manual',
  OSM: 'osm',
};

const GRAPH_TABLE_REGEX = /(?:graph_table|'graph_table')\s*=\s*'([^']+)'/;

// Component
const CreateGraph = () => {
  const graphqlClient = useApolloClient();

  const [mode, setMode] = useState(MODES.DEFAULT);
  const [wmsTable, setWmsTable] = useState(undefined);
  const [wmsTableDefaults, setWmsTableDefaults] = useState(undefined);
  const [showTableWms, setShowTableWms] = useState(false);

  const { topBarCollapsed } = useSelector(state => state.app);

  const resetMode = _ => {
    setMode(MODES.DEFAULT);
    setWmsTable(undefined);
    setWmsTableDefaults(undefined);
    setShowTableWms(false);
  };

  const handleGraphWms = (table, defaults = {}) => {
    setWmsTable(table);
    setWmsTableDefaults(defaults);
    setShowTableWms(true);
  };

  const handleGraphPreview = async graph => {
    const { is_partitioned, graph_server_id, original_request } = graph;

    let graphTable = null;

    if (original_request?.statement) {
      const graphTableMatch = GRAPH_TABLE_REGEX.exec(
        original_request?.statement
      );
      graphTable =
        graphTableMatch && graphTableMatch.length > 1 && graphTableMatch[1];
    } else {
      graphTable = original_request?.options?.graph_table;
    }

    if (graphTable && graphTable !== '') {
      const split = graphTable.split('.');
      const serverIds = graph_server_id.split(',');
      const table =
        is_partitioned && serverIds.length > 0
          ? {
              full: `${graphTable}_${serverIds[0]}`,
              schema: split.length > 1 ? split[0] : '',
              name:
                split.length > 1
                  ? `${split[1]}_${serverIds[0]}`
                  : `${split[0]}_${serverIds[0]}`,
            }
          : {
              full: graphTable,
              schema: split.length > 1 ? split[0] : '',
              name: split.length > 1 ? split[1] : split[0],
            };

      const tableResp = await graphqlClient.query({
        query: GET_TABLE_BY_NAME,
        variables: {
          ...table,
        },
      });

      if (tableResp.errors) {
        notification.open({
          message: 'Graph Preview Error Occurred',
          description: tableResp.errors.map(e => e.message).join('\n'),
          type: 'error',
        });
        return;
      }

      const { schema, name } = tableResp?.data?.table;
      handleGraphWms({
        full: `${schema}.${name}`,
        schema,
        name,
      });
    }
  };

  return (
    <>
      <h2>Create Graph</h2>
      <div
        style={{
          padding: '20px 20px 0px',
          backgroundColor: '#ffffff',
          height: topBarCollapsed
            ? 'calc(100vh - 110px)'
            : 'calc(100vh - 160px)',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            top: '0px',
            left: '0px',
            padding: '10px',
          }}
        >
          {!mode && (
            <div>
              <h3 style={{ marginBottom: '30px' }}>Select a Mode</h3>
              <Row gutter={40}>
                <Col span={10} offset={2}>
                  <Card
                    title="Manual"
                    headStyle={{
                      color: '#4a00e0',
                      border: 0,
                      padding: '10px 40px',
                      fontSize: 24,
                      textAlign: 'center',
                    }}
                    bodyStyle={{
                      padding: '10px 35px 35px',
                      textAlign: 'center',
                    }}
                    onClick={_ => setMode(MODES.MANUAL)}
                    style={{
                      border: '1px solid #dddddd',
                      borderRadius: 8,
                    }}
                    hoverable
                  >
                    <SettingOutlined
                      style={{
                        fontSize: 100,
                        color: '#4a00e033',
                        display: 'block',
                        marginBottom: 40,
                      }}
                    />
                    Create a graph from a source table and configure each option
                    manually
                  </Card>
                </Col>
                <Col span={10}>
                  <Card
                    title="OSM"
                    headStyle={{
                      color: '#4a00e0',
                      border: 0,
                      padding: '10px 40px',
                      fontSize: 24,
                      textAlign: 'center',
                    }}
                    bodyStyle={{
                      padding: '10px 35px 35px',
                      textAlign: 'center',
                    }}
                    onClick={_ => setMode(MODES.OSM)}
                    style={{
                      border: '1px solid #dddddd',
                      borderRadius: 8,
                    }}
                    hoverable
                  >
                    <GlobalOutlined
                      style={{
                        fontSize: 100,
                        color: '#4a00e033',
                        display: 'block',
                        marginBottom: 40,
                      }}
                    />
                    Select a region and extract a graph from the open source OSM
                    dataset
                  </Card>
                </Col>
              </Row>
            </div>
          )}
          {mode && mode === MODES.MANUAL && (
            <CreateGraphForm
              resetMode={resetMode}
              showGraphPreview={handleGraphPreview}
            />
          )}
          {mode && mode === MODES.OSM && (
            <CreateOSMGraphForm
              resetMode={resetMode}
              showGraphPreview={handleGraphPreview}
            />
          )}
          {wmsTable && (
            <TableWmsModal
              table={wmsTable}
              defaults={wmsTableDefaults}
              visible={showTableWms}
              setVisible={visible => {
                setShowTableWms(visible);
                if (!visible) {
                  setWmsTable(undefined);
                  setWmsTableDefaults(undefined);
                }
              }}
              width={Math.max(
                Math.min(
                  window.innerWidth - 300,
                  MAX_WMS_PREVIEW_MODAL_WIDTH_RATIO * (window.innerHeight - 200)
                ),
                MIN_WMS_PREVIEW_MODAL_WIDTH
              )}
              height={window.innerHeight - 200}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CreateGraph;
