import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import DSFileBrowser from '../import/DataSourceFileBrowser';

const DataSourceFileBrowserModal = ({ visible, toggleFn, form }) => {
  const [dsData, setDsData] = useState({ filePath: '' });
  const [tempDsData, setTempDsData] = useState({ filePath: '' });

  // update from when dsData changes
  useEffect(() => {
    form.setFieldsValue(dsData);
  }, [dsData, form]);

  const handleSelect = path => {
    setTempDsData({
      filePath: `${
        form.getFieldValue('type') === 'kifs' ||
        form.getFieldValue('type') === 'upload'
          ? 'kifs://'
          : ''
      }${path}`,
      tableName: (path ?? '').split('/').pop().split('.').reverse().pop(),
    });
  };

  const handleOk = _ => {
    setDsData(tempDsData);
    setTimeout(() => {
      toggleFn();
    }, 200);
  };

  return (
    <Modal
      title="Data Source Select File"
      width={'80%'}
      open={visible}
      onOk={handleOk}
      okText="Select"
      onCancel={toggleFn}
      destroyOnClose
    >
      <div style={{ minHeight: 250 }}>
        <DSFileBrowser
          form={form}
          path={tempDsData.filePath}
          onSelect={handleSelect}
        />
      </div>
    </Modal>
  );
};

export default DataSourceFileBrowserModal;
