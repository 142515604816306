// Imports
import { Card, Row, Col } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

// App Imports
import YoutubeLogo from '../../images/youtube_logo.png';
import GithubLogo from '../../images/github_logo.png';
import KineticaLogo from '../../images/logo_dark.svg';
import SlackLogo from '../../images/slack_logo.png';

const { Meta } = Card;

const Resources = () => {
  const handleResourceClick = resource => _ => {
    if (resource === 'youtube') {
      window.open('https://www.youtube.com/@KineticaDB/videos');
    } else if (resource === 'github') {
      window.open('https://github.com/kineticadb/examples');
    } else if (resource === 'docs') {
      window.open('https://docs.kinetica.com/7.2/');
    } else if (resource === 'slack') {
      window.open('https://kinetica.com/slack');
    }
  };

  return (
    <Card
      title={
        <>
          <CopyOutlined style={{ marginRight: 10 }} />
          Resources
        </>
      }
      bordered={false}
      size="small"
      style={{
        height: '250px',
        overflow: 'hidden',
        padding: 0,
        borderRadius: 5,
      }}
      headStyle={{
        color: '#ffffff',
        fontSize: '20px',
        background:
          'linear-gradient(150deg, #9b45d899 15%, #5c48c199 70%, #5533ff99 94%)',
      }}
    >
      <div style={{ padding: '0px 20px 10px' }}>
        <Row gutter={8} style={{ marginBottom: 8 }}>
          <Col span={12}>
            <Card
              cover={
                <div style={{ overflow: 'hidden', height: 60, width: 150 }}>
                  <img
                    src={YoutubeLogo}
                    style={{ padding: '16px 15px', height: '97%' }}
                    alt="YouTube"
                  />
                </div>
              }
              onClick={handleResourceClick('youtube')}
              bodyStyle={{ padding: '0px 10px 10px' }}
              style={{ maxHeight: 85 }}
              size="small"
              hoverable
            >
              <Meta
                description="Video Tutorials"
                style={{ textAlign: 'center' }}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              cover={
                <div style={{ overflow: 'hidden', height: 60, width: 150 }}>
                  <img
                    src={GithubLogo}
                    style={{ padding: '10px 15px', height: '98%' }}
                    alt="GitHub"
                  />
                </div>
              }
              onClick={handleResourceClick('github')}
              bodyStyle={{ padding: '0px 10px 10px' }}
              style={{ maxHeight: 85 }}
              size="small"
              hoverable
            >
              <Meta description="Code Examples" />
            </Card>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Card
              cover={
                <div style={{ overflow: 'hidden', height: 60, width: 150 }}>
                  <img
                    src={KineticaLogo}
                    style={{ padding: '16px 15px', height: '90%' }}
                    alt="YouTube"
                  />
                </div>
              }
              onClick={handleResourceClick('docs')}
              bodyStyle={{ padding: '0px 10px 10px' }}
              style={{ maxHeight: 85 }}
              size="small"
              hoverable
            >
              <Meta
                description="Documentation"
                style={{ textAlign: 'center' }}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              cover={
                <div style={{ overflow: 'hidden', height: 60, width: 150 }}>
                  <img
                    src={SlackLogo}
                    style={{ padding: '12px 15px', width: '100%' }}
                    alt="GitHub"
                  />
                </div>
              }
              onClick={handleResourceClick('slack')}
              bodyStyle={{ padding: '0px 10px 10px' }}
              style={{ maxHeight: 85 }}
              size="small"
              hoverable
            >
              <Meta description="Community" />
            </Card>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default Resources;
