// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_ENDPOINT_JOBS = gql`
  query EndpointJobs {
    endpoint_jobs {
      job_id
      status
      endpoint_name
      time_received
      auth_id
      source_ip
      user_data
      worker_statuses {
        rank
        status
      }
      flag
    }
  }
`;

export const ALTER_ENDPOINT_JOB_BY_ID = gql`
  mutation AlterEndpointJobById($job_ids: JSON!, $action: String!) {
    endpointJobAlterById(job_ids: $job_ids, action: $action) {
      job_id
      status
    }
  }
`;

export const useGetEndpointJobs = () => {
  return useQuery(GET_ENDPOINT_JOBS);
};

export const useAlterEndpointJobsById = () => {
  return useMutation(ALTER_ENDPOINT_JOB_BY_ID);
};
