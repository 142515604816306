// Imports
import React, { useState, useMemo } from 'react';
import { Modal, Form, Input, Button, AutoComplete } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';

const { TextArea } = Input;

const WorkbookCreateModal = ({ visible, close, callback }) => {
  const [createWorkbookComplete] =
    GraphQLServices.Workbooks.useCreateWorkbookComplete();
  const { data } = GraphQLServices.Workbooks.useGetFolders();

  const { data: { userMe = {} } = {} } =
    GraphQLServices.Users.useGetLocalUserMe();

  const [isCreating, setIsCreating] = useState(false);

  const [form] = Form.useForm();

  const folders = useMemo(
    _ => {
      if (data) {
        const folders = data.workbook_folders
          .filter(workbook_folder => workbook_folder.folder)
          .map(workbook_folder => workbook_folder.folder.trim());
        return Array.from(new Set(['default', ...folders]))
          .filter(folder => {
            return folder !== '';
          })
          .map(folder => ({
            value: folder,
          }));
      }
      return [];
    },
    [data]
  );

  const onFinish = values => {
    const { name, description, folder } = values;
    setIsCreating(true);
    createWorkbookComplete({
      variables: {
        name,
        description: description || `Description for ${name}`,
        folder,
        config: {},
        user_id: userMe.id,
      },
    })
      .then(resp => {
        setIsCreating(false);
        if (callback) {
          callback(null, resp);
        }
      })
      .catch(err => {
        setIsCreating(false);
        if (callback) {
          callback(err, null);
        }
      });
  };

  const create = _ => {
    form.submit();
  };

  return (
    <Modal
      title="Create Workbook"
      open={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={create}
          loading={isCreating}
        >
          Create
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="workbook"
        layout="vertical"
        initialValues={{}}
        onFinish={onFinish}
        colon={false}
        preserve={false}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input workbook name!',
              whitespace: true,
            },
            {
              max: 50,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              max: 600,
            },
          ]}
        >
          <TextArea />
        </Form.Item>
        <Form.Item
          label="Folder"
          name="folder"
          rules={[
            {
              required: true,
              message: 'Please input folder name!',
            },
          ]}
        >
          <AutoComplete
            options={folders}
            filterOption={true}
            placeholder="Select existing folder, or enter new folder name"
          ></AutoComplete>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WorkbookCreateModal;
