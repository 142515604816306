// Imports
import React, { useMemo, useState } from 'react';
import { Button, Tooltip, Tabs, Spin, Table, Space, Badge } from 'antd';
import { useSelector } from 'react-redux';
import { RedoOutlined, LeftOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

// App Imports
import { useEveryPermission } from '../../context';
import GraphQLServices from '../../graphql/services';
import Spinner from '../../components/common/Spinner';
import { formatTimeReceived, formatWorkerStatuses } from '../../formatter';
import {
  formatK8sTimestamp,
  formatCapitalizeFirstLetter,
} from '../../formatter';
import { DEPLOYMENT_TYPE } from '../../setup/config';
import {
  DEFAULT_TABPANE_HEIGHT,
  DEFAULT_TABPANE_NOHEAD_HEIGHT,
  CUSTOM_STATUS_MAPPING,
} from '../../constants';
import { GET_UDF_STATUSES } from '../../graphql/schema/udfs';
import UDFStatus from './UDFStatus';

const JOB_FLAG_UNFLAGGED = {
  label: 'Running',
  value: '',
};
const JOB_FLAG_PERPETUAL = {
  label: 'Perpetual',
  value: 'perpetual',
};

const pageSize = 10;

const columns = [
  {
    key: 'job_id',
    title: 'Job ID',
    dataIndex: 'job_id',
    fixed: 'left',
  },
  {
    key: 'endpoint_name',
    title: 'Endpoint',
    dataIndex: 'endpoint_name',
    fixed: 'left',
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: text => {
      return CUSTOM_STATUS_MAPPING[text] ?? text;
    },
  },
  {
    key: 'worker_statuses',
    title: 'Ranks',
    dataIndex: 'worker_statuses',
    render: formatWorkerStatuses,
  },
  {
    key: 'time_received',
    title: 'Received',
    dataIndex: 'time_received',
    render: formatTimeReceived,
  },
  {
    key: 'auth_id',
    title: 'User',
    dataIndex: 'auth_id',
  },
  {
    key: 'source_ip',
    title: 'Source IP',
    dataIndex: 'source_ip',
  },
  {
    key: 'user_data',
    title: 'Job',
    dataIndex: 'user_data',
  },
];

// Component
const Jobs = () => {
  const {
    loading: jobsLoading,
    data: {
      endpoint_jobs: jobs,
      k8s_kineticaclusteradmins,
      k8s_kineticaclusterbackups,
      k8s_kineticaclusterrestores,
    } = {},
    refetch: refetchJobs,
  } = GraphQLServices.Jobs.useGetJobs({
    variables: {
      deployment_type: DEPLOYMENT_TYPE,
    },
  });

  const {
    loading: udfsLoading,
    data: { udfs } = { udfs: [] },
    refetch: refetchUDFs,
  } = GraphQLServices.UDFs.useGetUDFs();
  const {
    loading: udfStatusesLoading,
    data: { udf_statuses } = { udf_statuses: [] },
    refetch: refetchUDFStatuses,
  } = GraphQLServices.UDFs.useGetUDFStatuses();

  const [cancelJobsByIds] =
    GraphQLServices.EndpointJobs.useAlterEndpointJobsById();
  const [killProcById] = GraphQLServices.UDFs.useKillProcById();

  const [isCancelling, setIsCancelling] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState([]);

  const [isUDFClearing, setIsUDFClearing] = useState(false);
  const [isUDFCancelling, setIsUDFCancelling] = useState(false);
  const [selectedUDFs, setSelectedUDFs] = useState([]);

  const [currentUDF, setCurrentUDF] = useState(null);

  const [hasManagePermission] = useEveryPermission(['manage_warehouse']);

  const graphqlClient = useApolloClient();

  const { topBarCollapsed } = useSelector(state => state.app);

  const rowSelection = useMemo(
    _ => {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedJobs(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
          setSelectedJobs(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          setSelectedJobs(selectedRows);
        },
        selectedRowKeys: selectedJobs.map(job => job.job_id),
      };
    },
    [selectedJobs]
  );

  const udfRowSelection = useMemo(
    _ => {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedUDFs(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
          setSelectedUDFs(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          setSelectedUDFs(selectedRows);
        },
        selectedRowKeys: selectedUDFs.map(udf => udf.run_id),
      };
    },
    [selectedUDFs]
  );

  const clearCompletedSelected = async _ => {
    setIsUDFClearing(true);

    const run_ids = selectedUDFs.map(udf => Number(udf.run_id));
    if (run_ids.length > 0) {
      try {
        await Promise.all(
          run_ids.map(run_id => {
            return graphqlClient.query({
              query: GET_UDF_STATUSES,
              variables: {
                run_id,
                options: {
                  clear_complete: 'true',
                },
              },
            });
          })
        );
        refetchUDFs();
        refetchUDFStatuses();
        setSelectedUDFs([]);
      } catch (error) {
        console.error(error);
      } finally {
        setIsUDFClearing(false);
      }
    } else {
      setIsUDFClearing(false);
    }
  };

  const cancelSelected = async _ => {
    setIsCancelling(true);
    setIsUDFCancelling(true);

    const run_ids = selectedUDFs.map(udf => Number(udf.run_id));
    if (run_ids.length > 0) {
      try {
        await Promise.all(
          run_ids.map(run_id => {
            return killProcById({
              variables: {
                run_id,
              },
            });
          })
        );
        refetchUDFs();
        refetchUDFStatuses();
        setSelectedUDFs([]);
      } catch (error) {
        console.error(error);
      } finally {
        setIsUDFCancelling(false);
      }
    } else {
      setIsUDFCancelling(false);
    }

    const job_ids = selectedJobs.map(job => Number(job.job_id));
    if (job_ids.length > 0) {
      cancelJobsByIds({
        variables: {
          job_ids,
          action: 'cancel',
        },
      })
        .then(resp => {
          refetchJobs({
            variables: {
              deployment_type: DEPLOYMENT_TYPE,
            },
          });
          setSelectedJobs([]);
        })
        .finally(_ => {
          setIsCancelling(false);
        });
    } else {
      setIsCancelling(false);
    }
  };

  const handleUDFView = udf => _ => {
    setCurrentUDF(udf);
  };

  const baseUDFColumns = useMemo(_ => {
    return [
      {
        key: 'execution_mode',
        title: 'Execution',
        dataIndex: 'execution_mode',
      },
      {
        key: 'command',
        title: 'Command',
        dataIndex: 'command',
      },
      {
        key: 'args',
        title: 'Args',
        dataIndex: 'args',
        render: args => {
          return args.length > 0 ? (
            args.map(arg => {
              return <div key={arg}>{arg}</div>;
            })
          ) : (
            <i style={{ color: '#cccccc' }}>None</i>
          );
        },
      },
      {
        key: 'options',
        title: 'Options',
        dataIndex: 'options',
        render: options => {
          return Object.keys(options).length > 0 ? (
            Object.keys(options).map(key => {
              return (
                <div key={key}>
                  {key}: {options[key]}
                </div>
              );
            })
          ) : (
            <i style={{ color: '#cccccc' }}>None</i>
          );
        },
      },
    ];
  }, []);

  const udfColumns = useMemo(
    _ => {
      return [
        {
          key: 'proc_name',
          title: 'Name',
          dataIndex: 'proc_name',
          fixed: 'left',
        },
        ...baseUDFColumns,
        {
          key: 'status',
          title: 'Status',
          dataIndex: 'status',
          width: 70,
          render: (_, udf) => {
            const statuses = udf_statuses.filter(status => {
              return (
                status.proc_name === udf.proc_name &&
                status.overall_status === 'running'
              );
            });
            return (
              <Badge count={statuses.length} color="green">
                <Button onClick={handleUDFView(udf)} size="small">
                  View
                </Button>
              </Badge>
            );
          },
        },
      ];
    },
    [baseUDFColumns, udf_statuses]
  );

  const perpetualJobs = useMemo(
    _ => {
      return jobs
        ? jobs.filter(job => job.flag === JOB_FLAG_PERPETUAL.value)
        : [];
    },
    [jobs]
  );

  const runningJobs = useMemo(
    _ => {
      return jobs
        ? jobs.filter(
            job => job.flag === null || job.flag === JOB_FLAG_UNFLAGGED.value
          )
        : [];
    },
    [jobs]
  );

  const adminColumns = useMemo(_ => {
    return [
      {
        key: 'type',
        title: 'Activity',
        dataIndex: 'type',
        render: text => {
          return formatCapitalizeFirstLetter(text);
        },
      },
      {
        key: 'creationTimestamp',
        title: 'Initiated',
        dataIndex: 'creationTimestamp',
        width: 200,
        render: text => {
          return formatK8sTimestamp(text);
        },
      },
    ];
  }, []);

  const adminJobs = useMemo(
    _ => {
      return k8s_kineticaclusteradmins &&
        k8s_kineticaclusterbackups &&
        k8s_kineticaclusterrestores
        ? [
            ...k8s_kineticaclusteradmins,
            ...k8s_kineticaclusterbackups,
            ...k8s_kineticaclusterrestores,
          ]
            .map(cr => {
              let type = '';
              switch (cr?.kind) {
                case 'KineticaClusterBackup':
                  type = 'Backup';
                  break;
                case 'KineticaClusterRestore':
                  type = 'Restore';
                  break;
                case 'KineticaClusterAdmin':
                  type = cr?.spec?.offline?.offline ? 'suspend' : 'resume';
                  break;
                default:
                  type = 'Unknown';
              }
              const creationTimestamp = cr?.metadata?.creationTimestamp;
              return {
                id: `${type}_${creationTimestamp}`,
                name: cr?.metadata?.name,
                cluster: cr?.spec?.kineticaClusterName,
                type,
                creationTimestamp,
                source: cr,
              };
            })
            .sort((a, b) => {
              if (a.creationTimestamp > b.creationTimestamp) return -1;
              if (a.creationTimestamp < b.creationTimestamp) return 1;
              return 0;
            })
        : [];
    },
    [
      k8s_kineticaclusteradmins,
      k8s_kineticaclusterbackups,
      k8s_kineticaclusterrestores,
    ]
  );

  const isCloud = DEPLOYMENT_TYPE === 'cloud';

  const tabItems = useMemo(
    _ => {
      const items = [
        {
          key: JOB_FLAG_UNFLAGGED.value,
          label: `${JOB_FLAG_UNFLAGGED.label} (${runningJobs.length})`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflowY: 'auto',
              }}
            >
              <Table
                columns={columns}
                dataSource={runningJobs}
                // expandable={{
                //   expandedRowRender: ({ worker_statuses: workerStatuses }) => {
                //     return (
                //       <Table
                //         columns={workerColumns}
                //         dataSource={workerStatuses}
                //         rowKey="rank"
                //         pagination={false}
                //         size="small"
                //         style={{ marginLeft: 25 }}
                //       />
                //     );
                //   },
                //   rowExpandable: ({ worker_statuses }) =>
                //     worker_statuses.length > 0 &&
                //     worker_statuses.some(info => info.status !== null),
                // }}
                rowKey="job_id"
                rowSelection={{ ...rowSelection }}
                pagination={{
                  pageSize,
                }}
                scroll={{
                  x: 'max-content',
                }}
                size="small"
              />
            </div>
          ),
        },
        {
          key: JOB_FLAG_PERPETUAL.value,
          label: `${JOB_FLAG_PERPETUAL.label} (${perpetualJobs.length})`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflowY: 'auto',
              }}
            >
              <Table
                columns={columns}
                dataSource={perpetualJobs}
                rowKey="job_id"
                rowSelection={{ ...rowSelection }}
                pagination={{
                  pageSize,
                }}
                scroll={{
                  x: 'max-content',
                }}
                size="small"
              />
            </div>
          ),
        },
        {
          key: 'udfs',
          label: `UDFs (${udfs.length})`,
          children: (
            <div
              className="udfs"
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflowY: 'auto',
              }}
            >
              {!currentUDF ? (
                <Table
                  columns={udfColumns}
                  dataSource={udfs}
                  rowKey="proc_name"
                  pagination={{
                    pageSize,
                  }}
                  scroll={{
                    x: 'max-content',
                  }}
                  size="small"
                />
              ) : (
                <>
                  {!udfStatusesLoading && (
                    <UDFStatus
                      proc_name={currentUDF.proc_name}
                      rowSelection={udfRowSelection}
                      auto_refresh={5000}
                      actions={[
                        <Button
                          key="back"
                          onClick={_ => setCurrentUDF(null)}
                          icon={<LeftOutlined />}
                          style={{ float: 'right', marginBottom: '10px' }}
                          size="small"
                        >
                          Back
                        </Button>,
                      ]}
                    />
                  )}
                </>
              )}
            </div>
          ),
        },
      ];

      if (isCloud && hasManagePermission) {
        items.push({
          key: 'admin',
          label: `Admin (${adminJobs.length})`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: DEFAULT_TABPANE_HEIGHT,
                overflowY: 'auto',
              }}
            >
              <Table
                columns={adminColumns}
                dataSource={adminJobs}
                rowKey="id"
                pagination={{
                  pageSize,
                }}
                scroll={{
                  x: 'max-content',
                }}
                size="small"
              />
            </div>
          ),
        });
      }

      return items;
    },
    [
      perpetualJobs,
      topBarCollapsed,
      runningJobs,
      rowSelection,
      udfs,
      currentUDF,
      udfColumns,
      udfStatusesLoading,
      udfRowSelection,
      isCloud,
      hasManagePermission,
      adminJobs,
      adminColumns,
    ]
  );

  return (
    <>
      <h2>Jobs</h2>
      <div style={{ display: 'block', clear: 'both' }}>
        <Spin
          indicator={<Spinner />}
          spinning={jobsLoading || udfsLoading || udfStatusesLoading}
        >
          <div style={{ right: '0px', position: 'absolute', zIndex: 1 }}>
            <Space>
              {selectedUDFs.length > 0 && (
                <Button
                  key="clear"
                  type="primary"
                  onClick={clearCompletedSelected}
                  loading={isUDFClearing}
                  ghost
                >
                  Clear Selected (Completed)
                </Button>
              )}
              <Button
                key="cancel"
                type="primary"
                onClick={cancelSelected}
                loading={isCancelling || isUDFCancelling}
                disabled={
                  selectedJobs.length === 0 && selectedUDFs.length === 0
                }
                danger
              >
                Cancel Selected
              </Button>
              <Tooltip title="Refresh Jobs">
                <Button
                  icon={
                    <RedoOutlined
                      spin={jobsLoading || udfsLoading || udfStatusesLoading}
                    />
                  }
                  onClick={() => {
                    refetchUDFs();
                    refetchUDFStatuses();
                    refetchJobs({
                      variables: {
                        deployment_type: DEPLOYMENT_TYPE,
                      },
                    });
                  }}
                />
              </Tooltip>
            </Space>
          </div>
          <Tabs items={tabItems} type="card" />
        </Spin>
      </div>
    </>
  );
};

export default Jobs;
