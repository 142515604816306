// Imports
import React, { useState, useMemo } from 'react';
import { Modal, Form, Input, Button, AutoComplete } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';

const WorkbookMoveModal = ({ workbook, visible, close, callback }) => {
  const [updateWorkbookById] =
    GraphQLServices.Workbooks.useUpdateWorkbookById();
  const { data } = GraphQLServices.Workbooks.useGetFolders();

  const [isMoving, setIsMoving] = useState(false);

  const [form] = Form.useForm();

  const folders = useMemo(
    _ => {
      if (data) {
        const folders = data.workbook_folders
          .filter(workbook_folder => workbook_folder.folder)
          .map(workbook_folder => workbook_folder.folder.trim());
        return Array.from(new Set(['default', ...folders]))
          .filter(folder => {
            return folder !== '';
          })
          .map(folder => ({
            value: folder,
          }));
      }
      return [];
    },
    [data]
  );

  const onFinish = values => {
    const { newFolder } = values;
    const { id } = workbook;
    setIsMoving(true);
    updateWorkbookById({
      variables: {
        id,
        folder: newFolder,
      },
    })
      .then(resp => {
        setIsMoving(false);
        if (callback) {
          callback(null, resp);
        }
      })
      .catch(err => {
        setIsMoving(false);
        if (callback) {
          callback(err, null);
        }
      });
  };

  const move = _ => {
    form.submit();
  };

  return (
    <Modal
      title={`Workbook Move: ${workbook.name}`}
      open={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button key="move" type="primary" onClick={move} loading={isMoving}>
          Move
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="table"
        layout="vertical"
        initialValues={workbook}
        onFinish={onFinish}
        colon={false}
      >
        <Form.Item label="Current Folder" name="folder">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="New Folder"
          name="newFolder"
          rules={[
            {
              required: true,
              message: 'Please input new folder name!',
            },
          ]}
        >
          <AutoComplete
            options={folders}
            filterOption={true}
            placeholder="Select existing folder, or enter new folder name"
          ></AutoComplete>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WorkbookMoveModal;
