// Imports
import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Spin,
  Tabs,
  Form,
  Divider,
  Row,
  Col,
  Button,
  AutoComplete,
  Modal,
  Input,
  Alert,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FilePond } from 'react-filepond';
import { useApolloClient } from '@apollo/client';

// App Imports
import GPUdb from '../../lib/GPUdb';
import useAnalytics from '../../hooks/useAnalytics';
import { displayError, humanFileSize } from '../../helper';
import GraphQLServices from '../../graphql/services';
import { GET_FOLDER_BY_NAME } from '../../graphql/schema/files';
import {
  FILE_STATUSES,
  KIFS_UPLOAD_CHUNK_SIZE,
  DEFAULT_TABPANE_HEIGHT,
  DEFAULT_TABPANE_NOHEAD_HEIGHT,
} from '../../constants';
import { APP_URL_API } from '../../setup/config';
import Spinner from '../../components/common/Spinner';
import ImportForm from './ImportForm';
import ImportHistory from './ImportHistory';
import KifsUploadImage from '../../images/import/kifs.svg';
import {
  DEFAULT_FORM_ITEM_PROPS,
  getFileFormat,
  getTableFromFile,
} from './utils';
import useEvent, { EVENT_TYPES } from '../../hooks/useEvent';

const { confirm } = Modal;

const Setup = ({
  form,
  setupForm,
  setFormValues,
  setStep,
  uploaderRef,
  files,
  setFiles,
}) => {
  const { data: { folders = [] } = {}, refetch: refetchFolders } =
    GraphQLServices.Files.useGetFolders();
  const [createFolder] = GraphQLServices.Files.useCreateFolder();

  const [isUploading, setIsUploading] = useState(false);
  const [folder, setFolder] = useState('');

  const graphqlClient = useApolloClient();
  const analytics = useAnalytics();

  const { emit } = useEvent(EVENT_TYPES.FILES_UPDATE);

  const freeData = useMemo(() => {
    if (folder && folders.length > 0) {
      const match = folders.find(item => {
        return item.name === folder;
      });
      if (match && match.data_limit && parseInt(match.data_limit) > -1) {
        return parseInt(match.data_limit) - parseInt(match.data_usage);
      }
    }
    return -1;
  }, [folders, folder]);

  const folderOptions = useMemo(
    _ => {
      return folders
        ? folders
            .map(folder => {
              return {
                value: folder.name,
              };
            })
            .sort((folder1, folder2) => {
              if (folder1.value.toLowerCase() > folder2.value.toLowerCase())
                return 1;
              if (folder1.value.toLowerCase() < folder2.value.toLowerCase())
                return -1;
              return 0;
            })
        : [];
    },
    [folders]
  );

  const onFinish = async values => {
    const { folder } = values;
    try {
      setIsUploading(true);
      setFolder(folder);
      const processedFiles = await uploaderRef.current.processFiles();
      setFiles(processedFiles);
    } catch (err) {
      displayError(err?.error?.body);
    } finally {
      setIsUploading(false);
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    setFolder(allValues.folder);
  };

  const upload = useCallback(
    async _ => {
      const folder = setupForm.getFieldValue('folder');

      // Can only upload to folder that starts with ~
      // if the folder already exists (system-generated)
      if (folder && folder.split('/')[0].startsWith('~')) {
        const resp = await graphqlClient.query({
          query: GET_FOLDER_BY_NAME,
          variables: {
            name: folder.split('/')[0],
          },
        });
        if (!resp?.data?.folder) {
          setupForm.setFields([
            {
              name: 'folder',
              errors: ['Folder name cannot start with ~'],
            },
          ]);
          return;
        }
      }

      const filenames = files.map(
        (file, idx) => setupForm.getFieldValue(`file_${idx}`) || file.filename
      );
      const existing = files
        .map((file, idx) => {
          return {
            ...file,
            uploadName: setupForm.getFieldValue(`file_${idx}`) || file.filename,
          };
        })
        .filter(file => {
          const currentFolder = folders.find(item => item.name === folder);
          if (currentFolder) {
            return currentFolder.files.find(file =>
              filenames.includes(file.name)
            );
          }
          return false;
        });
      if (existing.length > 0) {
        confirm({
          title: 'Duplicate Filename(s) Detected',
          icon: <ExclamationCircleOutlined />,
          content: `The following filename(s) in '${folder}' are already in use: ${existing
            .map(file => `'${file.uploadName}'`)
            .join(
              ', '
            )}. If you continue with the upload, those files will be overwritten and the previous data will be lost. Do you want to continue?`,
          okText: 'Yes',
          onOk() {
            setupForm.submit();
          },
          cancelText: 'No',
          centered: true,
        });
      } else {
        setupForm.submit();
      }
    },
    [setupForm, files, graphqlClient, folders]
  );

  const handleInit = e => {
    // console.info('handleInit');
  };

  const handleFilesUpdate = fileItems => {
    setFiles(fileItems);
  };

  const handleProcess = useCallback(
    async (
      fieldName,
      file,
      metadata,
      load,
      error,
      progress,
      abort,
      transfer,
      options
    ) => {
      const spaceRequired = files.reduce((acc, cur) => {
        return acc + cur.fileSize;
      }, 0);
      console.debug('Free', freeData, 'Requires', spaceRequired);

      if (freeData > -1 && spaceRequired > freeData) {
        error(
          `Upload requires ${humanFileSize(
            spaceRequired,
            true
          )} of free space. Only ${humanFileSize(
            freeData,
            true
          )} available in folder.`
        );
      } else {
        // Create a FileHandler
        const apiUrl = `${APP_URL_API}/proxy/dbapi`;
        const gpudb = new GPUdb(apiUrl, {
          timeout: 0,
        });
        const fileHandler = new GPUdb.FileHandler(gpudb);
        fileHandler.chunkSize = KIFS_UPLOAD_CHUNK_SIZE;

        const filename = files.map(
          (file, idx) => setupForm.getFieldValue(`file_${idx}`) || file.filename
        )[files.findIndex(thisFile => thisFile.filename === file.name)];

        if (!folder.startsWith('~')) {
          await createFolder({
            variables: {
              name: folder,
              no_error_if_exists: true,
            },
          });
        }

        Object.defineProperty(file, 'name', {
          writable: true,
          value: filename,
        });

        fileHandler.upload(
          file,
          folder,
          { file_encoding: 'base64' },
          status => {
            progress(true, status || 0, 100);
          },
          (err3, resp3) => {
            if (err3) {
              error(err3.message);
            } else {
              load(resp3);
              emit();

              analytics.track(analytics.EVENT_TYPES.UPLOADED_KIFS_FILE)({});

              // Only progress to next step when all uploads are complete
              const hasIncomplete = files.some(f => {
                return (
                  f.status !== FILE_STATUSES.PROCESSING_COMPLETE &&
                  file.name !== f.filename
                );
              });
              if (!hasIncomplete) {
                refetchFolders();
                const lastFile = files[files.length - 1];
                form.setFieldsValue({
                  dataSource: '',
                  filePath: `kifs://${folder}/${lastFile.filename}`,
                  filePaths: files.map(thisFile => {
                    return `kifs://${folder}/${thisFile.filename}`;
                  }),
                  fileFormat: getFileFormat(lastFile.filename),
                  tableName: getTableFromFile(lastFile.filename),
                });
                setupForm.setFieldsValue({
                  filePath: `kifs://${folder}/${lastFile.filename}`,
                });
                setFormValues({
                  filePath: `kifs://${folder}/${lastFile.filename}`,
                });
                form.validateFields();
                setStep(1);
              }
            }
          }
        );
      }

      return {
        abort: () => {
          abort();
        },
      };
    },
    [
      analytics,
      createFolder,
      emit,
      files,
      folder,
      form,
      freeData,
      refetchFolders,
      setFormValues,
      setStep,
      setupForm,
    ]
  );

  const hasIncomplete = useMemo(
    _ => {
      return files.some(file => {
        return file.status !== FILE_STATUSES.PROCESSING_COMPLETE;
      });
    },
    [files]
  );

  return (
    <Form
      form={setupForm}
      name="file"
      layout="horizontal"
      initialValues={{ folder }}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      preserve={false}
    >
      <Row gutter={0}>
        <Col span={4}>
          <img
            src={KifsUploadImage}
            style={{
              height: '60px',
              float: 'right',
              margin: '10px 40px 0px 0px',
            }}
            alt="KiFS Upload"
          />
        </Col>
        <Col span={16}>
          <p>
            Your uploaded file will be stored in the{' '}
            <strong>Kinetica Filesystem</strong> (KiFS). You can manage these
            files in the Files Explorer panel to the left.
          </p>
          <h3>Directions</h3>
          <p>
            Specify a destination folder and select a file to upload. After
            uploading is complete, click next to continue.
          </p>
          <Divider dashed />
        </Col>
      </Row>
      <Form.Item
        label="Folder"
        name="folder"
        rules={[
          {
            required: true,
            message: 'Please select a folder!',
          },
        ]}
        {...DEFAULT_FORM_ITEM_PROPS}
      >
        <AutoComplete
          options={folderOptions}
          placeholder="New or existing folder name"
          notFoundContent="No folders found"
          filterOption
        />
      </Form.Item>
      <Form.Item label="File" {...DEFAULT_FORM_ITEM_PROPS}>
        {freeData > -1 && (
          <Alert
            message={
              <>
                Available space:{' '}
                <strong>{humanFileSize(freeData, true)}</strong>
              </>
            }
            type="warning"
            showIcon={false}
            style={{ fontSize: '13px', marginBottom: 20 }}
            banner
          />
        )}
        <div
          style={{
            backgroundColor: '#F1F0EF',
            borderRadius: '5px',
            overflow: 'hidden',
          }}
        >
          <FilePond
            className="import-uploader"
            ref={uploaderRef}
            files={files}
            instantUpload={false}
            oninit={handleInit}
            onupdatefiles={handleFilesUpdate}
            allowMultiple={true}
            allowProcess={false}
            allowRemove={true}
            allowRevert={false}
            itemInsertLocation={'after'}
            chunkForce={true}
            chunkUploads={true}
            chunkSize={5000000}
            name="files"
            labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
            credits={false}
            server={{
              process: handleProcess,
            }}
          />
          {files.length > 0 &&
            files.some(
              file => file.status !== FILE_STATUSES.PROCESSING_COMPLETE
            ) && (
              <div style={{ padding: '0px 20px' }}>
                {files.map((file, idx) => {
                  return (
                    <Form.Item
                      key={idx}
                      label={file.filename}
                      name={`file_${idx}`}
                      labelCol={{ span: 24 }}
                      noStyle={
                        file.status === FILE_STATUSES.PROCESSING_COMPLETE
                      }
                    >
                      <Input
                        placeholder="File name override"
                        type={
                          file.status !== FILE_STATUSES.PROCESSING_COMPLETE
                            ? 'text'
                            : 'hidden'
                        }
                      />
                    </Form.Item>
                  );
                })}
              </div>
            )}
          {hasIncomplete && (
            <Button
              key="upload"
              type="primary"
              onClick={upload}
              loading={isUploading}
              style={{ margin: '0px 16px 20px', width: 'calc(100% - 32px)' }}
            >
              Upload
            </Button>
          )}
        </div>
      </Form.Item>
    </Form>
  );
};

const SourceUpload = () => {
  const [activeTabKey, setActiveTabKey] = useState('import');
  const { topBarCollapsed } = useSelector(state => state.app);
  const handleTabClick = key => {
    setActiveTabKey(key);
  };

  const tabItems = useMemo(
    _ => {
      return [
        {
          key: 'import',
          label: `Import`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportForm setActiveTabKey={setActiveTabKey}>
                  {props => {
                    return <Setup {...props} />;
                  }}
                </ImportForm>
              </div>
            </div>
          ),
        },
        {
          key: 'history',
          label: `History`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportHistory
                  pageSize={
                    topBarCollapsed
                      ? Math.floor((window.innerHeight - 400) / 50)
                      : Math.floor((window.innerHeight - 450) / 50)
                  }
                />
              </div>
            </div>
          ),
        },
      ];
    },
    [topBarCollapsed]
  );

  return (
    <div>
      <img
        src={KifsUploadImage}
        style={{
          height: '22px',
          display: 'inline-block',
          verticalAlign: 'sub',
          marginRight: '10px',
        }}
        alt="KiFS Upload"
      />
      <h2 style={{ display: 'inline-block' }}>File Upload Import</h2>
      <Spin indicator={<Spinner />} spinning={false}>
        <Tabs
          type="card"
          items={tabItems}
          activeKey={activeTabKey}
          onTabClick={handleTabClick}
        ></Tabs>
      </Spin>
    </div>
  );
};

export default SourceUpload;
