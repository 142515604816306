// Imports
import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import { Row, Col, Tag, Popover, Tooltip, Button, notification } from 'antd';
import { PlusOutlined, SyncOutlined, AlertOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import axios from 'axios';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import { GET_WORKSHEET_BY_ID } from '../../graphql/schema/worksheets';
import WorkbookCreateModal from '../../components/modal/WorkbookCreateModal';
import {
  NAV_ROUTE_DATAEXPLORE_WORKBOOK,
  WORKBOOKS_CATALOG_URL,
} from '../../constants';
import WorkbookExample from '../../images/workbook_example.png';
import GraphShortestPath from '../../images/workbooks/graph_shortest_path.png';
import OptimalChargingStation from '../../images/workbooks/optimal_charging_station.png';
import QuickStartGuide from '../../images/workbooks/quick_start_guide.png';
import RealTimeFinancialRisk from '../../images/workbooks/real_time_financial_risk.png';
import RealTimeTruckMonitoring from '../../images/workbooks/real_time_truck_monitoring.png';
import RealTimeEntityTracking from '../../images/workbooks/entity_tracking.png';
import SpatialAnalytics from '../../images/workbooks/spatial_analytics.png';
import TimeSeriesAnalysis from '../../images/workbooks/time_series_analysis.png';
import Background from '../../images/wallpaper.jpg';
import BackgroundClean from '../../images/wallpaper_clean.jpg';
import { DEPLOYMENT_TYPE, FREE_SAAS } from '../../setup/config';
import { GET_K8S_KINETICACLUSTERS } from '../../graphql/schema/k8s_kineticaclusters';
import { UserContext } from '../../context';

const BACKGROUND_SIZE = '100% 230%';

const Workbooks = () => {
  const { data: { workbooks = [] } = {}, refetch: refetchWorkbooks } =
    GraphQLServices.Workbooks.useGetWorkbooksOnly();
  const [updateExamples, { loading: updatingExamples }] =
    GraphQLServices.Workbooks.useUpdateExamples();

  const [showWorkbookCreateModal, setShowWorkbookCreateModal] = useState(false);
  const [exampleUpdateAvailable, setExampleUpdateAvailable] = useState(false);

  const userMe = useContext(UserContext);
  const analytics = useAnalytics();
  const graphqlClient = useApolloClient();
  const history = useHistory();

  const getExamplesTypeVersion = useCallback(
    async _ => {
      let type = 'default';
      if (FREE_SAAS) {
        type = 'free_saas';
      }

      const clusterResp = await graphqlClient.query({
        query: GET_K8S_KINETICACLUSTERS,
        variables: {
          deployment_type: DEPLOYMENT_TYPE,
        },
      });

      const cluster =
        clusterResp?.data?.k8s_kineticaclusters &&
        clusterResp?.data?.k8s_kineticaclusters.length > 0
          ? clusterResp?.data?.k8s_kineticaclusters[0]
          : null;
      const versionRegex = /(\d+\.\d+\.\d+)\.\d+/gi;
      const versionRegexMatches = versionRegex.exec(
        cluster?.status?.hmStatus?.version
      );
      const version =
        versionRegexMatches && versionRegexMatches.length === 2
          ? versionRegexMatches[1]
          : cluster?.status?.hmStatus?.version ?? '';

      return { type, version };
    },
    [graphqlClient]
  );

  useEffect(
    _ => {
      const controller = new AbortController();
      const fetchCatalog = async _ => {
        try {
          const {
            data: { catalog },
          } = await axios.get(WORKBOOKS_CATALOG_URL, {
            signal: controller.signal,
          });
          const { type, version } = await getExamplesTypeVersion();

          const examples = catalog.examples[version][type];
          const existing = workbooks.filter(workbook => workbook.is_example);

          const hasNewExample = examples.some(example => {
            return !existing.some(exist => {
              return exist?.metadata?.example_id === example.example_id;
            });
          });

          const hasNewUpdate = existing.some(exist => {
            return examples.some(example => {
              return (
                exist?.metadata?.example_id === example.example_id &&
                (!exist?.metadata?.revision ||
                  exist?.metadata?.revision < example.revision)
              );
            });
          });

          setExampleUpdateAvailable(hasNewExample || hasNewUpdate || false);
        } catch (error) {
          console.error(error);
          setExampleUpdateAvailable(false);
        }
      };
      if (workbooks.length > 0) {
        setTimeout(_ => {
          fetchCatalog();
        }, 2000);
      } else {
        setExampleUpdateAvailable(false);
      }
      return _ => {
        controller.abort();
      };
    },
    [getExamplesTypeVersion, workbooks]
  );

  const handleSampleWorkbook = workbook => e => {
    if (workbook.is_example) {
      analytics.track(analytics.EVENT_TYPES.OPENED_EXAMPLE_WORKBOOK)({
        title: workbook.name,
      });
      analytics.track(
        `${analytics.EVENT_TYPES.TRACK_EXAMPLE_WORKBOOK} ${workbook.name}`
      )({});
    }
    history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${workbook.id}`);
  };

  const handleWorkbookCreateCallback = async (err, resp) => {
    if (resp) {
      const block = resp?.data?.blockCreate;
      const { worksheet_id } = block;

      const worksheetResp = await graphqlClient.query({
        query: GET_WORKSHEET_BY_ID,
        variables: {
          id: worksheet_id,
        },
      });

      refetchWorkbooks().then(resp => {
        const currentWorkbook = resp?.data?.workbooks.find(
          workbook => workbook.id === worksheetResp?.data?.worksheet.workbook_id
        );
        setShowWorkbookCreateModal(false);

        analytics.track(analytics.EVENT_TYPES.CREATED_WORKBOOK)({});

        history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${currentWorkbook.id}`);
      });
    } else {
      console.error(err);
    }
  };

  const handleUpdateExamples = async _ => {
    const { type, version } = await getExamplesTypeVersion();

    const { data } = await updateExamples({
      variables: { type, version },
    });

    analytics.track(analytics.EVENT_TYPES.REFRESHED_EXAMPLE_WORKBOOKS)({});

    refetchWorkbooks();
    notification.success({
      message: 'Examples Refresh',
      description: (
        <>
          Successfully refreshed example workbooks:
          <ul style={{ margin: 5, padding: '5px 5px 5px 10px' }}>
            {data?.workbookUpdateExamples.workbooks.map(update => (
              <li key={update.id}>
                {update.name}{' '}
                {data?.workbookUpdateExamples.source && (
                  <Tag
                    style={{
                      padding: '2px 5px',
                      lineHeight: '12px',
                      fontSize: '11px',
                    }}
                  >
                    {data?.workbookUpdateExamples.source}
                  </Tag>
                )}
              </li>
            ))}
          </ul>
        </>
      ),
    });
  };

  const examples = useMemo(() => {
    if (workbooks) {
      // See if there is a quick start workbook
      const quickstart = workbooks.find(
        workbook =>
          workbook.name.toLowerCase().includes('quick start') &&
          workbook.is_example
      );
      if (quickstart) {
        const rest = workbooks.filter(
          workbook =>
            !workbook.name.toLowerCase().includes('quick start') &&
            workbook.is_example
        );
        return [quickstart, ...rest];
      }
      return workbooks.filter(workbook => workbook.is_example);
    }
    return [];
  }, [workbooks]);

  const recent = useMemo(() => {
    return workbooks
      ? workbooks
          .filter(
            workbook => workbook.user.id === userMe.id && !workbook.is_example
          )
          .sort((wb1, wb2) => {
            if (wb1.updated_at < wb2.updated_at) return 1;
            if (wb1.updated_at > wb2.updated_at) return -1;
            return 0;
          })
          .slice(0, 6)
      : [];
  }, [workbooks, userMe]);

  const getCoverImage = workbook => {
    const { name, metadata } = workbook;

    if (metadata?.thumbnail) {
      return metadata?.thumbnail;
    }

    const key =
      metadata?.legacy_name ||
      name.toLowerCase().replace(/ /g, '_').replace(/-/g, '_');
    switch (key) {
      case 'graph_shortest_path': {
        return GraphShortestPath;
      }
      case 'optimal_charging_station': {
        return OptimalChargingStation;
      }
      case 'quick_start_guide': {
        return QuickStartGuide;
      }
      case 'real_time_financial_risk': {
        return RealTimeFinancialRisk;
      }
      case 'real_time_truck_monitoring': {
        return RealTimeTruckMonitoring;
      }
      case 'real_time_entity_tracking': {
        return RealTimeEntityTracking;
      }
      case 'spatial_analytics': {
        return SpatialAnalytics;
      }
      case 'time_series_analysis': {
        return TimeSeriesAnalysis;
      }
      default: {
        return WorkbookExample;
      }
    }
  };

  const WORKBOOK_STYLE = {
    borderRadius: 5,
    backgroundColor: '#ffffff',
    width: '255px',
    margin: '0px 16px 16px 0px',
    float: 'left',
    overflow: 'hidden',
    cursor: 'pointer',
  };

  return (
    <div
      style={{
        padding: '20px 30px 10px',
        backgroundColor: '#ffffff',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        borderRadius: 10,
        width: '100%',
      }}
    >
      <Row gutter={0}>
        {recent.length === 0 ? (
          <Col span={19} lg={24}>
            <h3
              style={{
                fontSize: '30px',
                color: '#3700b399',
                marginBottom: 0,
                float: 'left',
              }}
            >
              Getting Started
            </h3>
            <span style={{ float: 'left', margin: '13px 0px 0px 20px' }}>
              <Tooltip title="Refresh to latest versions">
                <Button
                  type={exampleUpdateAvailable ? 'primary' : 'default'}
                  icon={
                    exampleUpdateAvailable ? (
                      <AlertOutlined />
                    ) : (
                      <SyncOutlined />
                    )
                  }
                  onClick={handleUpdateExamples}
                  loading={updatingExamples}
                  size="small"
                >
                  {exampleUpdateAvailable
                    ? 'Updates Available'
                    : 'Refresh Examples'}
                </Button>
              </Tooltip>
            </span>
            <div style={{ clear: 'both' }}></div>
            <h3
              style={{
                color: '#3700b399',
                marginBottom: 20,
                fontWeight: 300,
              }}
            >
              Learn more about Kinetica's real-time analytic capabilities by
              trying the following sample workbooks!
            </h3>
            {examples.map(workbook => {
              return (
                <Popover
                  key={workbook.id}
                  title={workbook.name}
                  content={
                    workbook.metadata?.example_id && (
                      <div style={{ width: 320 }}>
                        <p
                          style={{
                            fontSize: '12px',
                            display: 'block',
                            fontWeight: 200,
                            textAlign: 'justify',
                          }}
                        >
                          {workbook.description}
                        </p>
                        <div>
                          {workbook.metadata?.features.map(feature => (
                            <Tag
                              key={feature}
                              color="blue"
                              style={{ margin: 2 }}
                            >
                              {feature}
                            </Tag>
                          ))}
                        </div>
                        <i style={{ fontSize: '12px', fontWeight: 200 }}>
                          Approx. Time: {workbook.metadata?.approx_time}
                        </i>
                      </div>
                    )
                  }
                >
                  <div
                    className="example-workbook"
                    onClick={handleSampleWorkbook(workbook)}
                    style={WORKBOOK_STYLE}
                  >
                    <div
                      style={{
                        height: 90,
                        overflow: 'hidden',
                        padding: 10,
                        textAlign: 'center',
                        backgroundImage: `url(${BackgroundClean})`,
                        backgroundSize: BACKGROUND_SIZE,
                      }}
                    >
                      <img
                        src={getCoverImage(workbook)}
                        alt="Workbook Example"
                        style={{ height: 70 }}
                        loading="lazy"
                      />
                    </div>
                    <div
                      style={{
                        padding: '8px 20px',
                        backgroundColor: '#ffffff',
                        color: '#3700b399',
                        textAlign: 'center',
                      }}
                    >
                      {workbook.name}
                    </div>
                  </div>
                </Popover>
              );
            })}
            <div
              key="create"
              className="example-workbook"
              onClick={_ => setShowWorkbookCreateModal(true)}
              style={{
                ...WORKBOOK_STYLE,
                backgroundColor: '#ffffff33',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <PlusOutlined
                  style={{
                    fontSize: 30,
                    color: '#3700b399',
                    margin: '35px 30px 25px',
                    opacity: 0.5,
                  }}
                />
              </div>
              <div
                style={{
                  padding: '8px 20px',
                  color: '#3700b399',
                  textAlign: 'center',
                }}
              >
                Create New Workbook
              </div>
            </div>
          </Col>
        ) : (
          <Col span={19} lg={24}>
            <h3
              style={{
                fontSize: '26px',
                color: '#3700b399',
                marginBottom: 10,
                float: 'left',
              }}
            >
              Recent Workbooks
            </h3>
            <span style={{ float: 'left', margin: '10px 0px 0px 20px' }}>
              <Button
                icon={<PlusOutlined />}
                onClick={_ => setShowWorkbookCreateModal(true)}
                size="small"
              >
                New Workbook
              </Button>
            </span>
            <div style={{ clear: 'both' }}></div>
            {recent.map(workbook => {
              return (
                <div
                  key={workbook.id}
                  className="example-workbook"
                  onClick={handleSampleWorkbook(workbook)}
                  style={{
                    borderRadius: 5,
                    backgroundImage: `url(${BackgroundClean})`,
                    backgroundSize: BACKGROUND_SIZE,
                    margin: '0px 16px 16px 0px',
                    padding: '8px 20px',
                    float: 'left',
                    cursor: 'pointer',
                  }}
                >
                  <div
                    style={{
                      display: '-webkit-box',
                      color: '#3700b399',
                      textAlign: 'center',
                      width: '215px',
                      height: '44px',
                      overflow: 'hidden',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {workbook.name}
                  </div>
                </div>
              );
            })}
            <div style={{ clear: 'both' }}></div>
            <h3
              style={{
                fontSize: '22px',
                color: '#3700b399',
                marginBottom: 10,
                float: 'left',
              }}
            >
              Getting Started
            </h3>
            <span style={{ float: 'left', margin: '5px 0px 0px 20px' }}>
              <Tooltip title="Refresh to latest versions">
                <Button
                  type={exampleUpdateAvailable ? 'primary' : 'default'}
                  icon={
                    exampleUpdateAvailable ? (
                      <AlertOutlined />
                    ) : (
                      <SyncOutlined />
                    )
                  }
                  onClick={handleUpdateExamples}
                  loading={updatingExamples}
                  size="small"
                >
                  {exampleUpdateAvailable
                    ? 'Updates Available'
                    : 'Refresh Examples'}
                </Button>
              </Tooltip>
            </span>
            <div style={{ clear: 'both' }}></div>
            {examples.map(workbook => {
              return (
                <Popover
                  key={workbook.id}
                  title={workbook.name}
                  content={
                    workbook.metadata?.example_id && (
                      <div style={{ width: 320 }}>
                        <p
                          style={{
                            fontSize: '12px',
                            display: 'block',
                            fontWeight: 200,
                            textAlign: 'justify',
                          }}
                        >
                          {workbook.description}
                        </p>
                        <div>
                          {workbook.metadata?.features.map(feature => (
                            <Tag
                              key={feature}
                              color="blue"
                              style={{ margin: 2 }}
                            >
                              {feature}
                            </Tag>
                          ))}
                        </div>
                        <i style={{ fontSize: '12px', fontWeight: 200 }}>
                          Approx. Time: {workbook.metadata?.approx_time}
                        </i>
                      </div>
                    )
                  }
                >
                  <div
                    className="example-workbook"
                    onClick={handleSampleWorkbook(workbook)}
                    style={WORKBOOK_STYLE}
                  >
                    <div
                      style={{
                        height: 90,
                        overflow: 'hidden',
                        padding: 10,
                        textAlign: 'center',
                        backgroundImage: `url(${BackgroundClean})`,
                        backgroundSize: BACKGROUND_SIZE,
                      }}
                    >
                      <img
                        src={getCoverImage(workbook)}
                        alt="Workbook Example"
                        style={{ height: 70 }}
                        loading="lazy"
                      />
                    </div>
                    <div
                      style={{
                        padding: '8px 20px',
                        backgroundColor: '#ffffff',
                        color: '#3700b399',
                        textAlign: 'center',
                      }}
                    >
                      {workbook.name}
                    </div>
                  </div>
                </Popover>
              );
            })}
          </Col>
        )}
      </Row>
      {showWorkbookCreateModal && (
        <WorkbookCreateModal
          visible={showWorkbookCreateModal}
          close={_ => {
            setShowWorkbookCreateModal(false);
          }}
          callback={handleWorkbookCreateCallback}
        />
      )}
    </div>
  );
};

export default Workbooks;
