// Imports
import React from 'react';
import { Spin, Tabs } from 'antd';
import { useSelector } from 'react-redux';

// App Imports
import Spinner from '../../components/common/Spinner';
import ImportForm from './ImportForm';
import ImportHistory from './ImportHistory';
import WizardImage from '../../images/import/wizard.svg';
import {
  DEFAULT_TABPANE_HEIGHT,
  DEFAULT_TABPANE_NOHEAD_HEIGHT,
} from '../../constants';

const { TabPane } = Tabs;

const ImportWizard = () => {
  const { topBarCollapsed } = useSelector(state => state.app);

  return (
    <div>
      <img
        src={WizardImage}
        style={{
          height: '22px',
          display: 'inline-block',
          verticalAlign: 'sub',
          marginRight: '10px',
        }}
        alt="Wizard"
      />
      <h2 style={{ display: 'inline-block' }}>Import Wizard</h2>
      <Spin indicator={<Spinner />} spinning={false}>
        <Tabs type="card">
          <TabPane tab="Import" key="import">
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportForm />
              </div>
            </div>
          </TabPane>
          <TabPane tab="History" key="history">
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportHistory
                  pageSize={
                    topBarCollapsed
                      ? Math.floor((window.innerHeight - 400) / 50)
                      : Math.floor((window.innerHeight - 450) / 50)
                  }
                />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </Spin>
    </div>
  );
};

export default ImportWizard;
