// Imports
import React, { useState, useMemo } from 'react';
import { Modal, Form, Button, Select } from 'antd';
import { useApolloClient } from '@apollo/client';

// App Imports
import GraphQLServices from '../../graphql/services';
import { displaySuccess } from '../../helper';
import { validateBlockContent } from '../../containers/dataexplore/utils';

const { Option } = Select;

const WorksheetCopyModal = ({ worksheet, visible, close, callback }) => {
  const { data: { workbooksMeOnly: workbooks = [] } = {} } =
    GraphQLServices.Workbooks.useGetWorkbooksMeOnly();
  const [createWorksheet] = GraphQLServices.Worksheets.useCreateWorksheet();
  const [updateWorksheetById] =
    GraphQLServices.Worksheets.useUpdateWorksheetById();
  const [createBlock] = GraphQLServices.Blocks.useCreateBlock();
  const [updateBlock] = GraphQLServices.Blocks.useUpdateBlockById();
  const [createVisualization] =
    GraphQLServices.Visualizations.useCreateVisualization();

  const [isCopying, setIsCopying] = useState(false);

  const graphqlClient = useApolloClient();
  const [form] = Form.useForm();

  const workbookOptions = useMemo(
    _ => {
      if (worksheet) {
        return workbooks
          .filter(workbook => workbook.id !== worksheet.workbook_id)
          .map(workbook => {
            return (
              <Option key={workbook.id} value={workbook.id}>
                {workbook.name}
              </Option>
            );
          });
      }
      return [];
    },
    [workbooks, worksheet]
  );

  const onFinish = async values => {
    setIsCopying(true);

    const { targetWorkbookId } = values;
    const workbook = workbooks.find(
      workbook => workbook.id === targetWorkbookId
    );

    const lastWorksheet = workbook.worksheets.find(
      worksheet => !worksheet.next_worksheet_id
    );
    const { blocks } = worksheet;
    const processedBlocks = [];
    const visitedBlocks = [];
    let curBlock = blocks.find(b => b.previous_block_id === null);
    while (curBlock && !visitedBlocks.includes(curBlock.id)) {
      const type = curBlock.block_type.id;
      const content = curBlock.content ? JSON.parse(curBlock.content) : '';
      const config = curBlock.config ?? {};
      const newBlock = {
        id: curBlock.id,
        type: validateBlockContent(type, content) ? type : null,
        content,
        config,
        children: [{ text: '' }],
      };
      if (newBlock.type === null) {
        newBlock.storedBlock = { ...curBlock };
      }
      processedBlocks.push(newBlock);
      visitedBlocks.push(curBlock.id);
      const nextBlockId = curBlock.next_block_id;
      curBlock = blocks.find(b => b.id === nextBlockId);
    }

    const createWorksheetRep = await createWorksheet({
      variables: {
        name: `${worksheet.name} Copy`,
        description: worksheet.description,
        config: worksheet.config ?? {},
        previous_worksheet_id: lastWorksheet.id,
        next_worksheet_id: null,
        workbook_id: workbook.id,
      },
    });

    const { id: worksheet_id } = createWorksheetRep.data.worksheetCreate;

    await updateWorksheetById({
      variables: {
        id: lastWorksheet.id,
        next_worksheet_id: worksheet_id,
      },
    });

    await processedBlocks.reduce(
      async (promisedPreviousBlock, processedBlock) => {
        const previousBlockId = await promisedPreviousBlock;
        const blockIndex = blocks.findIndex(
          block => block.id === processedBlock.id
        );
        const block = blocks[blockIndex];
        const {
          id: block_id,
          name,
          description,
          content,
          config,
          data = {},
          block_type,
        } = block;
        const payload = {
          variables: {
            name,
            description,
            content,
            config,
            data,
            previous_block_id: previousBlockId,
            next_block_id: null,
            block_type_id: block_type.id,
            worksheet_id,
          },
        };
        const createBlockResp = await createBlock(payload);
        const { id } = createBlockResp?.data?.blockCreate;

        const vizByBlockResp = await graphqlClient.query({
          query: GraphQLServices.Visualizations.GET_VISUALIZATIONS_BY_BLOCK_ID,
          variables: {
            block_id,
          },
        });
        const visualizations = vizByBlockResp?.data?.visualizationsByBlockId;

        visualizations.forEach(async visualization => {
          const { name, description, config, visualization_type } =
            visualization;
          const payload = {
            variables: {
              name,
              description,
              config,
              block_id: id,
              visualization_type_id: visualization_type.id,
            },
          };
          await createVisualization(payload);
        });

        if (previousBlockId) {
          await updateBlock({
            variables: {
              id: previousBlockId,
              next_block_id: id,
            },
          });
        }

        return id;
      },
      null
    );

    setIsCopying(false);
    displaySuccess(
      `Worksheet copied successfully to workbook ${workbook.name}`
    );
    if (callback) {
      callback(null, true);
    }
  };

  const copy = _ => {
    form.submit();
  };

  return (
    <Modal
      title="Copy Worksheet"
      open={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button key="copy" type="primary" onClick={copy} loading={isCopying}>
          Copy
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="worksheet"
        layout="vertical"
        initialValues={worksheet}
        onFinish={onFinish}
        colon={false}
      >
        <Form.Item
          label="Target Workbook"
          name="targetWorkbookId"
          rules={[
            {
              required: true,
              message: 'Please select target workbook!',
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            style={{ width: '100%' }}
            placeholder="Select a workbook"
          >
            {workbookOptions}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WorksheetCopyModal;
