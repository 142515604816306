import * as monaco from 'monaco-editor';

export const conf = {
  comments: {
    lineComment: '--',
    blockComment: ['/*', '*/'],
  },
  brackets: [
    ['{', '}'],
    ['[', ']'],
    ['(', ')'],
  ],
  autoClosingPairs: [
    { open: '{', close: '}' },
    { open: '[', close: ']' },
    { open: '(', close: ')' },
    { open: '"', close: '"' },
    { open: "'", close: "'" },
  ],
  surroundingPairs: [
    { open: '{', close: '}' },
    { open: '[', close: ']' },
    { open: '(', close: ')' },
    { open: '"', close: '"' },
    { open: "'", close: "'" },
  ],
};

export const language = {
  defaultToken: '',
  tokenPostfix: '.sql',
  ignoreCase: true,

  brackets: [
    { open: '[', close: ']', token: 'delimiter.square' },
    { open: '(', close: ')', token: 'delimiter.parenthesis' },
  ],

  keywords: [
    'add',
    'add chunk index',
    'add chunk skip index',
    'add foreign key',
    'add index',
    'add partition',
    'all',
    'alter column',
    'alter data source',
    'alter external data source',
    'alter materialized view',
    'alter resource group',
    'alter role',
    'alter schema',
    'alter table',
    'alter user',
    'alter view',
    'and',
    'any',
    'asc',
    'asof',
    'attribute index',
    'automatic',
    'avg',
    'batch size',
    'batch_size',
    'begin',
    'between',
    'bigint',
    'binary',
    'blob',
    'bucket name',
    'by',
    'bytes',
    'cascade',
    'case',
    'chunk index',
    'chunk size',
    'chunk skip index',
    'column formats',
    'comment',
    'compression lz4',
    'compression lz4hc',
    'compression none',
    'compression snappy',
    'connection timeout',
    'copy data into',
    'copy into',
    'count',
    'create data source',
    'create external data source',
    'create external table',
    'create logical external table',
    'create logical view',
    'create materialized external table',
    'create materialized view',
    'create or replace logical external table',
    'create or replace logical view',
    'create or replace materialized external table',
    'create or replace materialized view',
    'create or replace procedure',
    'create or replace replicated logical external table',
    'create or replace replicated materialized external table',
    'create or replace replicated table',
    'create or replace replicated temp logical external table',
    'create or replace replicated temp materialized external table',
    'create or replace replicated temp table',
    'create or replace table',
    'create or replace temp logical external table',
    'create or replace temp materialized external table',
    'create or replace temp materialized view',
    'create or replace temp table',
    'create or replace view',
    'create procedure',
    'create replicated logical external table',
    'create replicated materialized external table',
    'create replicated table',
    'create replicated temp logical external table',
    'create replicated temp materialized external table',
    'create replicated temp table',
    'create resource group',
    'create role',
    'create schema',
    'create table',
    'create temp logical external table',
    'create temp materialized external table',
    'create temp materialized view',
    'create temp table',
    'create user',
    'create view',
    'cross join',
    'cube',
    'cume_dist',
    'current row',
    'data source',
    'date',
    'datetime',
    'decimal',
    'default',
    'default column formats',
    'default schema',
    'delegation token',
    'delete from',
    'delete partition',
    'delimiter',
    'dense_rank',
    'desc',
    'describe',
    'dict',
    'disk_optimized',
    'double',
    'drop column',
    'drop data source',
    'drop external data source',
    'drop foreign key',
    'drop index',
    'drop materialized view',
    'drop materialized view if exists',
    'drop procedure',
    'drop procedure if exists',
    'drop resource group',
    'drop role',
    'drop schema',
    'drop schema if exists',
    'drop table',
    'drop table if exists',
    'drop user',
    'drop view',
    'drop view if exists',
    'else',
    'end',
    'escape',
    'except',
    'except all',
    'execute as user',
    'execute for every',
    'execute procedure',
    'exists',
    'explain',
    'explain analyze',
    'explain analyze format json',
    'explain analyze format table',
    'explain format json',
    'explain format table',
    'explain logical',
    'explain logical format json',
    'explain logical format table',
    'explain physical',
    'explain physical format json',
    'explain physical format table',
    'explain verbose',
    'explain verbose analyze',
    'explain verbose analyze format json',
    'explain verbose analyze format table',
    'explain verbose format json',
    'explain verbose format table',
    'fields mapped by name',
    'fields mapped by position',
    'file paths',
    'first_value',
    'float',
    'following',
    'for',
    'foreign key',
    'format delimited text',
    'format parquet',
    'format text',
    'from',
    'from file',
    'from file paths',
    'full',
    'full outer join',
    'geometry',
    'grant',
    'grant all on',
    'grant connect on',
    'grant delete on',
    'grant execute procedure on',
    'grant insert on',
    'grant select on',
    'grant system admin to',
    'grant system read to',
    'grant system write to',
    'grant update on',
    'grant user admin to',
    'group by',
    'grouping',
    'grouping sets',
    'having',
    'header',
    'header delimiter',
    'identified by',
    'in',
    'includes header',
    'index',
    'ingestion mode',
    'init_with_now',
    'initial_clear',
    'inner join',
    'insert into',
    'int',
    'integer',
    'intersect',
    'intersect all',
    'interval',
    'ipv4',
    'is not null',
    'is null',
    'iter',
    'join',
    'kerberos keytab',
    'lag',
    'language sql',
    'last_value',
    'lead',
    'left join',
    'like',
    'limit',
    'load data into',
    'load into',
    'loading mode',
    'location',
    'long',
    'lz4',
    'lz4hc',
    'max',
    'max_cpu_concurrency',
    'max_memory',
    'max_scheduling_priority',
    'max_tier_priority',
    'mean',
    'min',
    'modify column',
    'move to',
    'not',
    'not between',
    'not exists',
    'not in',
    'not like',
    'not null',
    'ntile',
    'null',
    'nulls first',
    'nulls last',
    'numeric',
    'on',
    'on error',
    'on_error',
    'or',
    'order',
    'order by',
    'outer join',
    'over',
    'partition by',
    'partition by hash',
    'partition by interval',
    'partition by list',
    'partition by range',
    'partitions',
    'password',
    'percent_rank',
    'persist',
    'pivot',
    'preceding',
    'primary key',
    'primary_key',
    'quote',
    'ram',
    'ram using',
    'range',
    'rank',
    'rank after',
    'rank before',
    'rank first',
    'rank last',
    'real',
    'references',
    'refresh every',
    'refresh external table',
    'refresh materialized view',
    'refresh off',
    'refresh on change',
    'refresh on start',
    'refresh table',
    'region',
    'remove partition',
    'rename column',
    'rename to',
    'reset tier strategy',
    'resource group',
    'resource group default',
    'revert',
    'revoke',
    'revoke all on',
    'revoke connect on',
    'revoke delete on',
    'revoke execute procedure on',
    'revoke insert on',
    'revoke select on',
    'revoke system admin from',
    'revoke system read from',
    'revoke system write from',
    'revoke update on',
    'revoke user admin from',
    'right join',
    'rollup',
    'row_number',
    'rows',
    'select',
    'select distinct',
    'select top',
    'set',
    'set access mode no_access',
    'set access mode read_only',
    'set access mode read_write',
    'set access mode write_only',
    'set column',
    'set current schema',
    'set password',
    'set property',
    'set refresh every',
    'set refresh off',
    'set refresh on change',
    'set resource group',
    'set resource group default',
    'set schema',
    'set tier strategy',
    'set ttl',
    'set user',
    'shard key',
    'shard_key',
    'show',
    'show create schema',
    'show create table',
    'show create view',
    'show data source',
    'show external data source',
    'show procedure',
    'show resource group',
    'show resource group all',
    'show security',
    'show security for',
    'skip',
    'smallint',
    'snappy',
    'st_geometry',
    'starting',
    'starting at',
    'store_only',
    'string',
    'sum',
    'text',
    'text_search',
    'then',
    'tier limits',
    'tier strategy',
    'time',
    'timestamp',
    'tinyint',
    'to',
    'truncate table',
    'ttl',
    'type_date',
    'type_time',
    'type_timestamp',
    'unbounded following',
    'unbounded preceding',
    'union',
    'union all',
    'unpivot',
    'unsigned bigint',
    'unsigned long',
    'update',
    'use kerberos',
    'user',
    'using',
    'using table properties',
    'validate',
    'values',
    'varchar',
    'vram',
    'vram using',
    'wait timeout',
    'when',
    'where',
    'with',
    'with no revert',
    'with options',
    'with resource group',
    'with resource group default',
    'wkt',
  ],
  operators: [],
  // operators: [
  //   'AND',
  //   'BETWEEN',
  //   'IN',
  //   'LIKE',
  //   'NOT',
  //   'OR',
  //   'IS',
  //   'NULL',
  //   'INTERSECT',
  //   'UNION',
  //   'INNER',
  //   'JOIN',
  //   'LEFT',
  //   'OUTER',
  //   'RIGHT',
  // ],
  builtinFunctions: [
    'abs',
    'acos',
    'acosf',
    'acosh',
    'acoshf',
    'arg_max',
    'arg_min',
    'ascii',
    'asin',
    'asinf',
    'asinh',
    'asinhf',
    'atan',
    'atan2',
    'atan2f',
    'atanf',
    'atanh',
    'atanhf',
    'atn2',
    'atn2f',
    'attr',
    'avg',
    'cast',
    'cbrt',
    'cbrtf',
    'ceil',
    'ceiling',
    'char',
    'char1',
    'char128',
    'char16',
    'char2',
    'char256',
    'char32',
    'char4',
    'char64',
    'char8',
    'coalesce',
    'concat',
    'concat_truncate',
    'contains',
    'convert',
    'corr',
    'corrcoef',
    'correlation',
    'cos',
    'cosf',
    'cosh',
    'coshf',
    'cot',
    'cotf',
    'count',
    'cov',
    'covar',
    'covar_pop',
    'covar_samp',
    'covariance',
    'cube',
    'current_date',
    'current_datetime',
    'current_time',
    'current_timestamp',
    'current_user',
    'date',
    'date_bucket',
    'date_to_epoch_msecs',
    'date_to_epoch_secs',
    'date_trunc',
    'date_truncate',
    'dateadd',
    'datediff',
    'datetime',
    'datetime_from_date_time',
    'day',
    'day_of_week',
    'day_of_year',
    'dayname',
    'dayofmonth',
    'dayofweek',
    'dayofyear',
    'decimal',
    'decode',
    'degrees',
    'degreesf',
    'difference',
    'dist',
    'divz',
    'double',
    'edit_distance',
    'ends_with',
    'epoch_msecs_to_datetime',
    'epoch_secs_to_datetime',
    'exp',
    'expf',
    'float',
    'floor',
    'geodist',
    'geohash_decode_latitude',
    'geohash_decode_longitude',
    'geohash_encode',
    'greater',
    'greatest',
    'grouping',
    'grouping sets',
    'h3_celltoboundary',
    'h3_celltocenterchild',
    'h3_celltochildn',
    'h3_celltochildpos',
    'h3_celltochildrensize',
    'h3_celltofirstchild',
    'h3_celltolastchild',
    'h3_celltoparent',
    'h3_celltoxy',
    'h3_childpostocell',
    'h3_geomtocell',
    'h3_getresolution',
    'h3_h3tostring',
    'h3_isvalid',
    'h3_latlngtocell',
    'h3_resolution',
    'h3_stringtoh3',
    'h3_tocenterchild',
    'h3_tochildn',
    'h3_tochildpos',
    'h3_tochildrensize',
    'h3_tofirstchild',
    'h3_tolastchild',
    'h3_toparent',
    'h3_tostring',
    'h3_toxy',
    'h3_xytocell',
    'hash',
    'hour',
    'hypot',
    'hypotf',
    'if',
    'if_error',
    'if_inf',
    'if_infinity',
    'if_nan',
    'iferror',
    'ifinf',
    'ifinfinity',
    'ifnan',
    'ifnull',
    'initcap',
    'int',
    'interval',
    'ipv4_part',
    'is_infinity',
    'is_ipv4',
    'is_member',
    'is_nan',
    'is_null',
    'is_numeric',
    'is_rolemember',
    'isinfinity',
    'isipv4',
    'isnan',
    'isnull',
    'isnumeric',
    'ki_match_column',
    'ki_replicate',
    'ki_replicate_group_by',
    'ki_shard_key',
    'kurt',
    'kurt_pop',
    'kurt_samp',
    'kurtosis',
    'kurtosis_pop',
    'kurtosis_samp',
    'last_day',
    'lcase',
    'ldexp',
    'ldexpf',
    'least',
    'left',
    'length',
    'lesser',
    'ln',
    'lnf',
    'locate',
    'log',
    'log10',
    'log10f',
    'log1p',
    'log1pf',
    'log2',
    'log2f',
    'logf',
    'long',
    'lower',
    'lpad',
    'ltrim',
    'mask',
    'max',
    'max_consecutive_bits',
    'mean',
    'min',
    'minute',
    'mod',
    'month',
    'monthname',
    'months_between',
    'msec',
    'msecs_since_epoch',
    'next_day',
    'now',
    'nullif',
    'nvl',
    'nvl2',
    'obfuscate',
    'pi',
    'position',
    'pow',
    'power',
    'powerf',
    'powf',
    'quarter',
    'radians',
    'radiansf',
    'rand',
    'regexp_count',
    'regexp_instr',
    'regexp_like',
    'regexp_match',
    'regexp_replace',
    'regexp_substr',
    'regr_valx',
    'regr_valy',
    'remove_nullable',
    'replace',
    'replace_char',
    'reverse',
    'right',
    'rollup',
    'round',
    'rpad',
    'rtrim',
    'second',
    'sha256',
    'sign',
    'sin',
    'sinf',
    'sinh',
    'sinhf',
    'skew',
    'skew_pop',
    'skew_samp',
    'skewness',
    'skewness_pop',
    'skewness_samp',
    'soundex',
    'space',
    'split',
    'sqrt',
    'sqrtf',
    'st_addpoint',
    'st_aggregate_collect',
    'st_aggregate_intersection',
    'st_almostequals',
    'st_area',
    'st_azimuth',
    'st_boundary',
    'st_boundingdiagonal',
    'st_buffer',
    'st_bufferbycomponent',
    'st_centroid',
    'st_clip',
    'st_closestpoint',
    'st_collect',
    'st_collect_aggregate',
    'st_collectionextract',
    'st_collectionhomogenize',
    'st_concavehull',
    'st_contains',
    'st_containsproperly',
    'st_convexhull',
    'st_coorddim',
    'st_coveredby',
    'st_covers',
    'st_crosses',
    'st_dfullywithin',
    'st_difference',
    'st_dimension',
    'st_disjoint',
    'st_dissolve',
    'st_dissolveoverlapping',
    'st_distance',
    'st_distancepoints',
    'st_dwithin',
    'st_ellipse',
    'st_endpoint',
    'st_envdwithin',
    'st_envelope',
    'st_envintersects',
    'st_equals',
    'st_equalsexact',
    'st_erase',
    'st_expand',
    'st_expandbyrate',
    'st_exteriorring',
    'st_force2d',
    'st_force3d',
    'st_generatepoints',
    'st_geohash',
    'st_geometryfromtext',
    'st_geometryn',
    'st_geometrytype',
    'st_geometrytypeid',
    'st_geomfromgeohash',
    'st_geomfromh3',
    'st_geomfromtext',
    'st_h3',
    'st_hexgrid',
    'st_interiorringn',
    'st_intersection',
    'st_intersection_aggregate',
    'st_intersects',
    'st_isclosed',
    'st_iscollection',
    'st_isempty',
    'st_isring',
    'st_issimple',
    'st_isvalid',
    'st_isvalidreason',
    'st_length',
    'st_linefrommultipoint',
    'st_lineinterpolatepoint',
    'st_linelocatepoint',
    'st_linemerge',
    'st_linestringfromorderedpoints',
    'st_linestringfromorderedpoints3d',
    'st_linesubstring',
    'st_longestline',
    'st_makeenvelope',
    'st_makeline',
    'st_makepoint',
    'st_makepolygon',
    'st_maketriangle2d',
    'st_maketriangle3d',
    'st_makevalid',
    'st_maxdistance',
    'st_maxx',
    'st_maxy',
    'st_maxz',
    'st_minx',
    'st_miny',
    'st_minz',
    'st_multi',
    'st_multipleringbuffers',
    'st_ndims',
    'st_near',
    'st_normalize',
    'st_npoints',
    'st_nrings',
    'st_numgeometries',
    'st_numinteriorrings',
    'st_numpoints',
    'st_overlaps',
    'st_partition',
    'st_perimeter',
    'st_point',
    'st_pointfromgeohash',
    'st_pointgrid',
    'st_pointn',
    'st_points',
    'st_polygonize',
    'st_project',
    'st_removepoint',
    'st_removerepeatedpoints',
    'st_reverse',
    'st_rotate',
    'st_scale',
    'st_segmentize',
    'st_setpoint',
    'st_sharedpath',
    'st_shortestline',
    'st_simplify',
    'st_simplifypreservetopology',
    'st_snap',
    'st_split',
    'st_squaregrid',
    'st_startpoint',
    'st_symdifference',
    'st_touches',
    'st_translate',
    'st_trianglegrid',
    'st_union',
    'st_unioncollection',
    'st_update',
    'st_voronoipolygons',
    'st_within',
    'st_wkttowkb',
    'st_x',
    'st_xmax',
    'st_xmin',
    'st_y',
    'st_ymax',
    'st_ymin',
    'st_zmax',
    'st_zmin',
    'starts_with',
    'stddev',
    'stddev_pop',
    'stddev_samp',
    'strcmp',
    'stxy_contains',
    'stxy_containsproperly',
    'stxy_coveredby',
    'stxy_covers',
    'stxy_disjoint',
    'stxy_distance',
    'stxy_dwithin',
    'stxy_envdwithin',
    'stxy_envintersects',
    'stxy_geohash',
    'stxy_h3',
    'stxy_intersection',
    'stxy_intersects',
    'stxy_touches',
    'stxy_within',
    'substr',
    'substring',
    'sum',
    'system_user',
    'tan',
    'tanf',
    'tanh',
    'tanhf',
    'time',
    'time_bucket',
    'time_trunc',
    'time_truncate',
    'timeboundarydiff',
    'timestamp',
    'timestamp_from_date_time',
    'timestamp_trunc',
    'timestamp_truncate',
    'timestampadd',
    'timestampdiff',
    'to_char',
    'to_date',
    'to_datetime',
    'to_time',
    'to_timestamp',
    'trim',
    'truncate',
    'ucase',
    'ulong',
    'unix_timestamp',
    'upper',
    'user',
    'uuid',
    'var',
    'var_pop',
    'var_samp',
    'week',
    'week_to_epoch_msecs',
    'week_to_epoch_secs',
    'width_bucket',
    'year',
    'zeroifnull',
  ],
  builtinVariables: [
    // NOT SUPPORTED
  ],
  pseudoColumns: [
    // NOT SUPPORTED
  ],
  tokenizer: {
    root: [
      { include: '@comments' },
      { include: '@whitespace' },
      { include: '@pseudoColumns' },
      { include: '@numbers' },
      { include: '@strings' },
      { include: '@complexIdentifiers' },
      { include: '@scopes' },
      [/[;,.]/, 'delimiter'],
      [/[()]/, '@brackets'],
      [
        /[\w@#$]+/,
        {
          cases: {
            '@keywords': 'keyword',
            '@operators': 'operator',
            '@builtinVariables': 'predefined',
            '@builtinFunctions': 'predefined',
            '@default': 'identifier',
          },
        },
      ],
      [/[<>=!%&+\-*/|~^]/, 'operator'],
    ],
    whitespace: [[/\s+/, 'white']],
    comments: [
      [/--+.*/, 'comment'],
      [/\/\*/, { token: 'comment.quote', next: '@comment' }],
    ],
    comment: [
      [/[^*/]+/, 'comment'],
      // Not supporting nested comments, as nested comments seem to not be standard?
      // i.e. http://stackoverflow.com/questions/728172/are-there-multiline-comment-delimiters-in-sql-that-are-vendor-agnostic
      // [/\/\*/, { token: 'comment.quote', next: '@push' }],    // nested comment not allowed :-(
      [/\*\//, { token: 'comment.quote', next: '@pop' }],
      [/./, 'comment'],
    ],
    pseudoColumns: [
      [
        /[$][A-Za-z_][\w@#$]*/,
        {
          cases: {
            '@pseudoColumns': 'predefined',
            '@default': 'identifier',
          },
        },
      ],
    ],
    numbers: [
      [/0[xX][0-9a-fA-F]*/, 'number'],
      [/[$][+-]*\d*(\.\d*)?/, 'number'],
      [/((\d+(\.\d*)?)|(\.\d+))([eE][-+]?\d+)?/, 'number'],
    ],
    strings: [[/'/, { token: 'string', next: '@string' }]],
    string: [
      [/[^']+/, 'string'],
      [/''/, 'string'],
      [/'/, { token: 'string', next: '@pop' }],
    ],
    complexIdentifiers: [
      [/"/, { token: 'identifier.quote', next: '@quotedIdentifier' }],
    ],
    quotedIdentifier: [
      [/[^"]+/, 'identifier'],
      [/""/, 'identifier'],
      [/"/, { token: 'identifier.quote', next: '@pop' }],
    ],
    scopes: [
      // NOT SUPPORTED
    ],
  },
};

const functionSuggestions = language.builtinFunctions.map(fn => ({
  label: fn,
  kind: monaco.languages.CompletionItemKind.Function,
  insertText: fn,
}));

const keywordSuggestions = language.keywords.map(keyword => ({
  label: keyword,
  kind: monaco.languages.CompletionItemKind.Keyword,
  insertText: keyword,
}));

const operatorSuggestions = language.operators.map(operator => ({
  label: operator,
  kind: monaco.languages.CompletionItemKind.Operator,
  insertText: operator,
}));

export const defaultSuggestions = [
  ...functionSuggestions,
  ...keywordSuggestions,
  ...operatorSuggestions,
];

export function autocomplete(model, position) {
  return {
    suggestions: [
      ...functionSuggestions,
      ...keywordSuggestions,
      ...operatorSuggestions,
    ],
  };
}
