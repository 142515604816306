// Imports
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Layout } from 'antd';

// App Imports
import NavBar from '../../components/navbar/NavBar';
import ExplorerSider from '../common/ExplorerSider';
import Footer from '../../components/footer/Footer';
import { UserContext } from '../../context';
import ScrollToTop from '../../components/common/ScrollToTop';

const { Header, Content } = Layout;

export const StandardLayoutContent = ({ className, children }) => {
  return (
    <Content
      className={className}
      style={{
        padding: '0px 20px 0px',
        height: 'calc(100vh - 212px)',
        overflowY: 'auto',
      }}
    >
      <ScrollToTop style={{ height: '15px' }} />
      {children}
    </Content>
  );
};

const StandardLayout = ({ children }) => {
  const { settings = [] } = useContext(UserContext) ?? {};

  const { topBarCollapsed } = useSelector(state => state.app);

  const systemMessage = useMemo(
    _ => {
      const setting = settings.find(
        setting => setting.key === 'system_message'
      );
      if (setting && setting.value) {
        return setting.value;
      }
      return null;
    },
    [settings]
  );

  return (
    <Layout style={{ height: '100vh' }}>
      {!topBarCollapsed && (
        <Header
          className="header"
          style={{ padding: '0px', minWidth: '1280px' }}
        >
          <NavBar />
        </Header>
      )}
      <Layout hasSider>
        <ExplorerSider />
        <Content>
          <Layout
            style={{
              height: topBarCollapsed ? '100vh' : 'calc(100vh - 50px)',
              minWidth: '930px',
            }}
          >
            {systemMessage && (
              <Alert
                type="warning"
                message={systemMessage}
                style={{ height: '30px', borderBottom: '1px solid #cccccc' }}
                banner
              />
            )}
            {children}
            {!systemMessage && <Footer />}
          </Layout>
        </Content>
      </Layout>
    </Layout>
  );
};

export default StandardLayout;
