// Imports
import React, { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';

const WorkbookFolderEditModal = ({
  folder,
  workbooks,
  visible,
  close,
  callback,
}) => {
  const [updateWorkbookById] =
    GraphQLServices.Workbooks.useUpdateWorkbookById();

  const [isUpdating, setIsUpdating] = useState(false);

  const [form] = Form.useForm();

  const onFinish = values => {
    const { name: update } = values;
    Promise.all(
      workbooks.map(workbook => {
        const { id } = workbook;
        return updateWorkbookById({
          variables: {
            id,
            folder: update,
          },
        });
      })
    )
      .then(resp => {
        setIsUpdating(false);
        if (callback) {
          callback(null, update);
        }
      })
      .catch(err => {
        setIsUpdating(false);
        if (callback) {
          callback(err, null);
        }
      });
  };

  const update = _ => {
    form.submit();
  };

  return (
    <Modal
      title="Rename Folder"
      open={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="update"
          type="primary"
          onClick={update}
          loading={isUpdating}
        >
          Update
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="schema"
        layout="vertical"
        initialValues={{
          current: folder,
        }}
        onFinish={onFinish}
        colon={false}
      >
        <Form.Item label="Current" name="current">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="New"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input folder name!',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WorkbookFolderEditModal;
