// Imports
import React from 'react';
import { Space } from 'antd';

// App Imports

// Component
const Preferences = () => {
  return (
    <>
      <Space
        direction="vertical"
        size="large"
        style={{ width: '100%' }}
      ></Space>
    </>
  );
};

export default Preferences;
