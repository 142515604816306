// Imports
import { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { v4 as uuid } from 'uuid';

// App Imports
import GraphQLServices from '../../graphql/services';
import VizMap from './VizMap';
import { displayError, splitQuery } from '../../helper';
import Spinner from '../../components/common/Spinner';

const COMMENT_REGEX = /(--.*)/gim;

const VizMapQuery = ({ viz, query, handleUpdate, ...rest }) => {
  const [executeSql, { loading }] = GraphQLServices.SqlQueries.useExecuteSql();
  const [currentViz, setCurrentConfig] = useState(viz);

  const updateViz = config => {
    setCurrentConfig({
      ...currentViz,
      config,
    });
    handleUpdate(config);
  };

  useEffect(() => {
    const runQuery = async queries => {
      try {
        if (queries) {
          // Try only with the last query in SQL block
          const clean = queries.trim().replace(COMMENT_REGEX, '');
          const statements = splitQuery(clean).filter(s => s.length > 0);
          const query = statements[statements.length - 1];
          const table = `workbook_map_${uuid().split('-')[0]}`;
          const statement = `CREATE TEMP TABLE ${table} AS ( ${query.replace(
            /;$/,
            ''
          )} ) USING TABLE PROPERTIES ( TTL = 5 )`;
          await executeSql({
            variables: {
              statement,
            },
          });
          setCurrentConfig({
            ...currentViz,
            config: [
              {
                ...currentViz.config[0],
                table_schema: '',
                table_name: table,
              },
            ],
          });
        }
      } catch (error) {
        displayError(error);
      }
    };
    runQuery(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeSql, query]);

  return (
    <Spin indicator={<Spinner />} spinning={loading}>
      {currentViz?.config?.length > 0 && currentViz?.config[0]?.table_name ? (
        <VizMap viz={currentViz} handleUpdate={updateViz} {...rest} />
      ) : (
        <div style={{ height: 180 }}></div>
      )}
    </Spin>
  );
};

export default VizMapQuery;
