// Imports
import React, { useCallback, useMemo, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Space,
  Tooltip,
  Button,
  Spin,
  Table,
  Tabs,
  Tag,
  Popconfirm,
  Alert,
} from 'antd';
import { useApolloClient } from '@apollo/client';
import { RedoOutlined, PlusOutlined } from '@ant-design/icons';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../../components/common/Spinner';
import UserCreateModal from '../../components/modal/UserCreateModal';
import UserEditModal from '../../components/modal/UserEditModal';
import UserPasswordModal from '../../components/modal/UserPasswordModal';
import RoleCreateModal from '../../components/modal/RoleCreateModal';
import RoleEditModal from '../../components/modal/RoleEditModal';
import { GET_USER_BY_USERNAME } from '../../graphql/schema/users';
import { GET_ROLE_BY_NAME } from '../../graphql/schema/roles';
import Permission from '../../components/security/Permission';
import { UserContext, ClusterContext, useEveryPermission } from '../../context';
import {
  DEFAULT_TABPANE_HEIGHT,
  DEFAULT_TABPANE_NOHEAD_HEIGHT,
} from '../../constants';

const { TabPane } = Tabs;
const { Column } = Table;

// Component
const Users = () => {
  const {
    loading: usersLoading,
    data: { users = undefined } = {},
    refetch: refetchUsers,
  } = GraphQLServices.Users.useGetLocalUsers();
  const {
    loading: rolesLoading,
    data: { roles = undefined } = {},
    refetch: refetchRoles,
  } = GraphQLServices.Roles.useGetLocalRoles();

  const { clusters } = useContext(ClusterContext);

  const [removeUserById] = GraphQLServices.Users.useRemoveUserById();
  const [removeRoleById] = GraphQLServices.Roles.useRemoveRoleById();

  const [showUserCreateModal, setShowUserCreateModal] = useState(false);

  const [showUserEditModal, setShowUserEditModal] = useState(false);
  const [editUser, setEditUser] = useState(undefined);

  const [showUserPasswordModal, setShowUserPasswordModal] = useState(false);
  const [passwordUser, setPasswordUser] = useState(undefined);

  const [showRoleCreateModal, setShowRoleCreateModal] = useState(false);
  const [showRoleEditModal, setShowRoleEditModal] = useState(false);
  const [editRole, setEditRole] = useState(undefined);

  const userMe = useContext(UserContext) ?? {};

  const history = useHistory();
  const graphqlClient = useApolloClient();

  const { topBarCollapsed } = useSelector(state => state.app);

  const handleRefresh = useCallback(
    _ => {
      refetchUsers();
      refetchRoles();
    },
    [refetchUsers, refetchRoles]
  );

  const usersData = useMemo(
    _ => {
      if (users) {
        return users.map(user => {
          const { id, username, roles } = user;
          return {
            id,
            username,
            roles,
          };
        });
      }
      return [];
    },
    [users]
  );

  const rolesData = useMemo(
    _ => {
      if (roles) {
        return roles.map(role => {
          const { id, name, description, roles } = role;
          return {
            id,
            name,
            description,
            roles,
          };
        });
      }
      return [];
    },
    [roles]
  );

  const handleAddUserClick = _ => {
    setShowUserCreateModal(true);
  };

  const handleUserCreateCallback = useCallback(
    (err, resp) => {
      if (resp) {
        refetchUsers().then(resp => {
          setShowUserCreateModal(false);
        });
        refetchRoles();
      } else {
        console.error(err);
      }
    },
    [refetchUsers, refetchRoles]
  );

  const handleUserEditCallback = useCallback(
    (err, resp) => {
      if (resp) {
        refetchUsers().then(resp => {
          setShowUserEditModal(false);
        });
        refetchRoles();
      } else {
        console.error(err);
      }
    },
    [refetchUsers, refetchRoles]
  );

  const handleUserPasswordCallback = useCallback(
    (err, resp) => {
      if (resp) {
        refetchUsers().then(resp => {
          setShowUserPasswordModal(false);
        });
      } else {
        console.error(err);
      }
    },
    [refetchUsers]
  );

  const handleAddRoleClick = _ => {
    setShowRoleCreateModal(true);
  };

  const handleRoleCreateCallback = useCallback(
    (err, resp) => {
      if (resp) {
        refetchRoles().then(resp => {
          setShowRoleCreateModal(false);
        });
        refetchUsers();
      } else {
        console.error(err);
      }
    },
    [refetchRoles, refetchUsers]
  );

  const handleRoleEditCallback = useCallback(
    (err, resp) => {
      if (resp) {
        refetchRoles().then(resp => {
          setShowRoleEditModal(false);
        });
        refetchUsers();
      } else {
        console.error(err);
      }
    },
    [refetchRoles, refetchUsers]
  );

  const handleUserSetPassword = user => async e => {
    const { username } = user;
    const resp = await graphqlClient.query({
      query: GET_USER_BY_USERNAME,
      variables: {
        username,
      },
    });
    setPasswordUser(resp?.data?.user);
    setShowUserPasswordModal(true);
  };

  const handleUserEdit = user => async e => {
    const { username } = user;
    const resp = await graphqlClient.query({
      query: GET_USER_BY_USERNAME,
      variables: {
        username,
      },
    });
    setEditUser(resp?.data?.user);
    setShowUserEditModal(true);
  };

  const handleUserDelete = user => e => {
    const { id } = user;
    removeUserById({
      variables: {
        id,
      },
    }).then(resp => {
      refetchUsers();
    });
  };

  const handleRoleEdit = role => async e => {
    const { name } = role;
    const resp = await graphqlClient.query({
      query: GET_ROLE_BY_NAME,
      variables: {
        name,
      },
    });
    setEditRole(resp?.data?.role);
    setShowRoleEditModal(true);
  };

  const handleRoleDelete = role => e => {
    const { id } = role;
    removeRoleById({
      variables: {
        id,
      },
    }).then(resp => {
      refetchRoles();
    });
  };

  const cluster = useMemo(
    _ => {
      return clusters && clusters.length > 0 && clusters[0];
    },
    [clusters]
  );

  const pageSize = Math.floor((window.innerHeight - 420) / 41);

  const handleTabChange = tab => {
    history.push(`/users/${tab}`);
  };

  const [hasUserManagePermission] = useEveryPermission(['manage_user']);

  const { tabKey: currentTab = 'users' } = useParams();

  const canManageUserRole = useMemo(
    _ => {
      return cluster?.status?.phase === 'Running';
    },
    [cluster]
  );

  return (
    <>
      <div style={{ float: 'right' }}>
        <Space>
          <Tooltip title="Refresh">
            <Button
              icon={<RedoOutlined spin={false} />}
              onClick={handleRefresh}
            />
          </Tooltip>
        </Space>
      </div>
      <h2>Users & Roles</h2>
      <Tabs activeKey={currentTab} onChange={handleTabChange} type="card">
        {(_ => {
          const tabs = [];
          if (hasUserManagePermission) {
            tabs.push(
              <TabPane key="users" tab="Users">
                <Permission everyKeys={['manage_user']}>
                  <Spin indicator={<Spinner />} spinning={usersLoading}>
                    <div
                      style={{
                        padding: '20px',
                        backgroundColor: '#ffffff',
                        height: topBarCollapsed
                          ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                          : DEFAULT_TABPANE_HEIGHT,
                        overflowY: 'auto',
                      }}
                    >
                      {!canManageUserRole && (
                        <Alert
                          message={
                            'User management is limited while cluster is not running.'
                          }
                          style={{ marginBottom: '10px' }}
                          banner
                        />
                      )}
                      <div
                        style={{
                          marginBottom: '10px',
                          top: '0px',
                          left: '0px',
                          padding: '0px',
                          textAlign: 'right',
                        }}
                      >
                        <Space>
                          <Button
                            onClick={handleAddUserClick}
                            icon={<PlusOutlined />}
                            size="small"
                            disabled={!canManageUserRole}
                          >
                            Add User
                          </Button>
                        </Space>
                      </div>
                      {users && (
                        <Table
                          dataSource={usersData}
                          rowKey="username"
                          pagination={{
                            pageSize,
                          }}
                          scroll={{
                            x: 'max-content',
                          }}
                          size="small"
                        >
                          <Column
                            title="Username"
                            dataIndex="username"
                            key="username"
                            width={250}
                          />
                          <Column
                            title="Roles"
                            dataIndex="roles"
                            key="roles"
                            render={(text, record) => {
                              return record.roles.map(role => {
                                return <Tag key={role.name}>{role.name}</Tag>;
                              });
                            }}
                          />
                          <Column
                            title="Action"
                            key="action"
                            width="1%"
                            render={(text, record) => (
                              <Space>
                                <Button
                                  onClick={handleUserSetPassword(record)}
                                  size="small"
                                >
                                  Set Password
                                </Button>
                                <Button
                                  onClick={handleUserEdit(record)}
                                  size="small"
                                  disabled={!canManageUserRole}
                                >
                                  Edit
                                </Button>
                                <Popconfirm
                                  title="Are you sure you want to delete this user?"
                                  onConfirm={handleUserDelete(record)}
                                  disabled={
                                    record.username === userMe.username ||
                                    !canManageUserRole
                                  }
                                >
                                  <Button
                                    size="small"
                                    disabled={
                                      record.username === userMe.username ||
                                      !canManageUserRole
                                    }
                                  >
                                    Delete
                                  </Button>
                                </Popconfirm>
                              </Space>
                            )}
                          />
                        </Table>
                      )}
                    </div>
                  </Spin>
                </Permission>
              </TabPane>
            );
          }

          if (hasUserManagePermission) {
            tabs.push(
              <TabPane key="roles" tab="Roles">
                <Permission everyKeys={['manage_user']}>
                  <Spin indicator={<Spinner />} spinning={rolesLoading}>
                    <div
                      style={{
                        padding: '20px',
                        backgroundColor: '#ffffff',
                        height: topBarCollapsed
                          ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                          : DEFAULT_TABPANE_HEIGHT,
                        overflowY: 'auto',
                      }}
                    >
                      {!canManageUserRole && (
                        <Alert
                          message={
                            'Role management is limited while cluster is not running.'
                          }
                          style={{ marginBottom: '10px' }}
                          banner
                        />
                      )}
                      <div
                        style={{
                          marginBottom: '10px',
                          top: '0px',
                          left: '0px',
                          padding: '0px',
                          textAlign: 'right',
                        }}
                      >
                        <Space>
                          <Button
                            onClick={handleAddRoleClick}
                            icon={<PlusOutlined />}
                            size="small"
                            disabled={!canManageUserRole}
                          >
                            Add Role
                          </Button>
                        </Space>
                      </div>
                      {roles && (
                        <Table
                          dataSource={rolesData}
                          rowKey="name"
                          pagination={{
                            pageSize,
                          }}
                          scroll={{
                            x: 'max-content',
                          }}
                          size="small"
                        >
                          <Column
                            title="Name"
                            dataIndex="name"
                            key="name"
                            width={250}
                          />
                          <Column
                            title="Roles"
                            dataIndex="roles"
                            key="roles"
                            render={(text, record) => {
                              return record.roles.map(role => {
                                return <Tag key={role.name}>{role.name}</Tag>;
                              });
                            }}
                          />
                          <Column
                            title="Action"
                            key="action"
                            width="1%"
                            render={(text, record) => (
                              <Space>
                                <Button
                                  onClick={handleRoleEdit(record)}
                                  size="small"
                                  disabled={!canManageUserRole}
                                >
                                  Edit
                                </Button>
                                <Popconfirm
                                  title="Are you sure you want to delete this role?"
                                  onConfirm={handleRoleDelete(record)}
                                  disabled={!canManageUserRole}
                                >
                                  <Button
                                    size="small"
                                    disabled={!canManageUserRole}
                                  >
                                    Delete
                                  </Button>
                                </Popconfirm>
                              </Space>
                            )}
                          />
                        </Table>
                      )}
                    </div>
                  </Spin>
                </Permission>
              </TabPane>
            );
          }

          return tabs;
        })()}
      </Tabs>
      {showUserCreateModal && (
        <UserCreateModal
          cluster={cluster}
          width={window.innerWidth - 300}
          height={window.innerHeight - 200}
          visible={showUserCreateModal}
          close={_ => {
            setShowUserCreateModal(false);
          }}
          callback={handleUserCreateCallback}
        />
      )}
      {showUserEditModal && editUser && (
        <UserEditModal
          cluster={cluster}
          user={editUser}
          width={window.innerWidth - 300}
          height={window.innerHeight - 200}
          visible={showUserEditModal}
          close={_ => {
            setShowUserEditModal(false);
          }}
          callback={handleUserEditCallback}
        />
      )}
      {showUserPasswordModal && passwordUser && (
        <UserPasswordModal
          cluster={cluster}
          user={passwordUser}
          visible={showUserPasswordModal}
          close={_ => {
            setShowUserPasswordModal(false);
          }}
          callback={handleUserPasswordCallback}
        />
      )}
      {showRoleCreateModal && (
        <RoleCreateModal
          cluster={cluster}
          width={window.innerWidth - 300}
          height={window.innerHeight - 200}
          visible={showRoleCreateModal}
          close={_ => {
            setShowRoleCreateModal(false);
          }}
          callback={handleRoleCreateCallback}
        />
      )}
      {showRoleEditModal && editRole && (
        <RoleEditModal
          cluster={cluster}
          role={editRole}
          width={window.innerWidth - 300}
          height={window.innerHeight - 200}
          visible={showRoleEditModal}
          close={_ => {
            setShowRoleEditModal(false);
          }}
          callback={handleRoleEditCallback}
        />
      )}
    </>
  );
};

export default Users;
