// Imports
import { useEffect, useState } from 'react';

// App Imports
import GraphQLServices from '../graphql/services';
import { DEPLOYMENT_TYPE } from '../setup/config';

// 'aws' or 'azure'
const useCloudProvider = () => {
  const [cloudProvider, setCloudProdvider] = useState('');

  const { data: { k8s_kineticaclusters = [] } = {} } =
    GraphQLServices.K8sKineticaClusters.useGetK8sKineticaClusters({
      variables: {
        deployment_type: DEPLOYMENT_TYPE,
      },
    });
  useEffect(
    _ => {
      const provider =
        DEPLOYMENT_TYPE === 'cloud'
          ? k8s_kineticaclusters &&
            k8s_kineticaclusters.length > 0 &&
            k8s_kineticaclusters[0] &&
            k8s_kineticaclusters[0]?.status?.cloudProvider
            ? k8s_kineticaclusters[0]?.status?.cloudProvider.toLowerCase()
            : 'azure'
          : '';
      setCloudProdvider(provider);
    },
    [k8s_kineticaclusters]
  );
  return cloudProvider;
};

export default useCloudProvider;
