// Imports
import React, { useState, useMemo, useCallback } from 'react';
import { Space, Button, Popconfirm, Alert, Empty } from 'antd';
import {
  SettingOutlined,
  CloseOutlined,
  FileImageOutlined,
} from '@ant-design/icons';
import { ResponsiveLine } from '@nivo/line';
import shortid from 'shortid';

// App Imports
import VizTitleBar from './VizTitleBar';
import VizConfigDrawer from './VizConfigDrawer';
import { useIsReadOnly } from './utils';
import { formatChartTimestamp } from '../../formatter';
import { VIZ_X_AXIS_DATA_LIMIT } from '../../constants';
import { downloadPng } from '../../helper';

const parseUTC = value => new Date(`${value}Z`);
const isDatetimeCol = value => value === 'datetime';

const VizLineChart = ({
  viz,
  data,
  columns,
  handleUpdate,
  handleRemove,
  minHeight = 180,
}) => {
  const { fields } = viz?.visualization_type?.params;
  const { config } = viz;

  const [isVizConfigOpen, setIsVizConfigOpen] = useState(false);
  const readOnly = useIsReadOnly();

  const seriesField = fields.find(field => field.name === 'series_column');

  const xSorter = (a, b) => {
    if (!isNaN(a.x) && !isNaN(b.x)) {
      return a.x >= b.x;
    }
    return 0;
  };

  const chartData = useMemo(
    _ => {
      return config[seriesField.name]
        ? data.column_1
            .map((column_1, colIdx) => {
              return data.column_headers.reduce((acc, cur, headerIdx) => {
                if (cur === config.x_axis_column) {
                  if (isDatetimeCol(data['column_datatypes'][headerIdx])) {
                    acc['x'] = parseUTC(
                      data[`column_${headerIdx + 1}`][colIdx]
                    );
                  } else {
                    acc['x'] = data[`column_${headerIdx + 1}`][colIdx];
                  }
                }
                if (cur === config.y_axis_column) {
                  acc['y'] = data[`column_${headerIdx + 1}`][colIdx];
                }
                if (cur === config.series_column) {
                  acc['series'] = data[`column_${headerIdx + 1}`][colIdx];
                }
                return acc;
              }, {});
            })
            .map(record => {
              return {
                x: record.x || '',
                y: record.y || 0,
                series: record.series || '',
              };
            })
            .reduce((acc, cur) => {
              const series = acc.find(item => item.id === cur.series);
              if (!series) {
                acc.push({
                  id: cur.series,
                  data: [],
                });
              }
              return acc.map(item => {
                if (item.id === cur.series) {
                  return {
                    ...item,
                    data: [...item.data, cur],
                  };
                }
                return item;
              });
            }, [])
            .sort(xSorter)
        : [
            {
              id: 'series',
              data: data.column_1
                .map((column_1, colIdx) => {
                  return data.column_headers.reduce((acc, cur, headerIdx) => {
                    if (cur === config.x_axis_column) {
                      if (isDatetimeCol(data['column_datatypes'][headerIdx])) {
                        acc['x'] = parseUTC(
                          data[`column_${headerIdx + 1}`][colIdx]
                        );
                      } else {
                        acc['x'] = data[`column_${headerIdx + 1}`][colIdx];
                      }
                    }
                    if (cur === config.y_axis_column) {
                      acc['y'] = data[`column_${headerIdx + 1}`][colIdx];
                    }
                    return acc;
                  }, {});
                })
                .map(record => {
                  return {
                    x: record.x || '',
                    y: record.y || 0,
                  };
                })
                .sort(xSorter),
            },
          ];
    },
    [data, config, seriesField]
  );

  const handleOpenVizConfig = _ => {
    setIsVizConfigOpen(true);
  };

  const handleCloseVizConfig = _ => {
    setIsVizConfigOpen(false);
  };

  const handleUpdateVizConfig = values => {
    handleUpdate(values, _ => {
      setIsVizConfigOpen(false);
    });
  };

  const isTimeSeries = useMemo(
    _ => {
      return chartData
        ? chartData.some(item => {
            return item.data.every(record => record.x instanceof Date);
          })
        : false;
    },
    [chartData]
  );

  const limitExceeded = useMemo(
    _ => {
      return chartData.some(item =>
        isTimeSeries
          ? item.data.length > VIZ_X_AXIS_DATA_LIMIT * 10
          : item.data.length > VIZ_X_AXIS_DATA_LIMIT
      );
    },
    [chartData, isTimeSeries]
  );

  const isXNumeric = useMemo(
    _ => {
      return !chartData.some(item => item.data.some(item2 => isNaN(item2.x)));
    },
    [chartData]
  );

  const imageId = useMemo(_ => {
    return `image_${shortid.generate()}`;
  }, []);

  const handleDownloadPng = useCallback(
    _ => {
      downloadPng(imageId);
    },
    [imageId]
  );

  return (
    <div style={{ position: 'relative', minHeight }}>
      {!readOnly && (
        <div style={{ height: 30 }}>
          <Space style={{ float: 'right' }}>
            <Button
              icon={<FileImageOutlined />}
              onClick={handleDownloadPng}
              size="small"
            >
              Save PNG
            </Button>
            <Button
              icon={<SettingOutlined />}
              onClick={handleOpenVizConfig}
              size="small"
            >
              Config
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this visualization?"
              onConfirm={handleRemove}
            >
              <Button icon={<CloseOutlined />} size="small"></Button>
            </Popconfirm>
          </Space>
          {limitExceeded && (
            <Alert
              message={`X-Axis data points limit exceeded. Only ${
                isTimeSeries
                  ? VIZ_X_AXIS_DATA_LIMIT * 10
                  : VIZ_X_AXIS_DATA_LIMIT
              } will be displayed.`}
              style={{
                padding: '3px 10px',
                fontSize: '12px',
                width: 'calc(100% - 240px)',
              }}
              banner
            />
          )}
        </div>
      )}
      <div
        style={{
          position: 'relative',
        }}
      >
        <VizTitleBar config={config} />
        <div
          id={imageId}
          className="line-chart"
          style={{ height: minHeight - 20 }}
        >
          {config?.x_axis_column && config?.y_axis_column ? (
            <ResponsiveLine
              data={chartData.map(item => {
                return {
                  ...item,
                  data: item.data.slice(
                    0,
                    isTimeSeries
                      ? VIZ_X_AXIS_DATA_LIMIT * 10
                      : VIZ_X_AXIS_DATA_LIMIT
                  ),
                };
              })}
              margin={{
                top: 15,
                right: 25,
                bottom: isTimeSeries ? 70 : 65,
                left: 75,
              }}
              padding={0.1}
              enableGridX={true}
              colors={{ scheme: config.color_scheme || 'spectral' }}
              colorBy="index"
              axisTop={null}
              axisRight={null}
              useMesh={true}
              enableCrosshair={false}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 25,
                legend: config.x_axis_label || '',
                legendPosition: 'middle',
                legendOffset: 55,
                renderTick: props => {
                  const {
                    opacity,
                    textAnchor,
                    textBaseline,
                    textX,
                    textY,
                    lineX,
                    lineY,
                    value,
                    rotate,
                    x,
                    y,
                  } = props;
                  return (
                    <g transform={`translate(${x},${y})`} style={{ opacity }}>
                      <line
                        x1="0"
                        x2={lineX}
                        y1="0"
                        y2={lineY}
                        style={{
                          stroke: 'rgb(119, 119, 119)',
                          strokeWidth: '1px',
                        }}
                      ></line>
                      <text
                        dominantBaseline={textBaseline}
                        textAnchor={textAnchor}
                        transform={`translate(${textX},${textY}) rotate(${rotate})`}
                        style={{ fontSize: '11px' }}
                      >
                        {value instanceof Date ? (
                          <>
                            <title>
                              {formatChartTimestamp(value.getTime() / 1000)}
                            </title>
                            {formatChartTimestamp(value.getTime() / 1000)}
                          </>
                        ) : (
                          <>
                            <title>{value}</title>
                            {value.length > 14
                              ? `${value.substring(0, 12)}..`
                              : value}
                          </>
                        )}
                      </text>
                    </g>
                  );
                },
              }}
              axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: config.y_axis_label || '',
                legendOffset: -65,
                legendPosition: 'middle',
              }}
              xScale={{
                type: isTimeSeries ? 'time' : isXNumeric ? 'linear' : 'point',
              }}
              pointSize={isTimeSeries ? 3 : 6}
              yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
              }}
              lineWidth={isTimeSeries ? 1 : 2}
              legends={
                config[seriesField.name]
                  ? [
                      {
                        anchor: 'top-right',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: -15,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 14,
                        itemOpacity: 0.75,
                        symbolSize: 8,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemBackground: 'rgba(0, 0, 0, .03)',
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]
                  : []
              }
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Please Configure Chart"
            />
          )}
          {!readOnly && (
            <VizConfigDrawer
              title="Line Chart Configuration"
              fields={viz?.visualization_type?.params?.fields}
              viz={viz}
              config={viz?.config}
              options={{
                columns,
              }}
              isOpen={isVizConfigOpen}
              handleClose={handleCloseVizConfig}
              handleUpdate={handleUpdateVizConfig}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VizLineChart;
