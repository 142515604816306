// Imports
import { gql, useQuery, useMutation } from '@apollo/client';

export const GET_CREDENTIALS = gql`
  query {
    credentials {
      credential_name
      credential_type
      credential_identity
      credential
      additional_info
    }
  }
`;

export const CREATE_CREDENTIAL = gql`
  mutation CreateCredential(
    $name: String!
    $type: String!
    $identity: String!
    $secret: String!
    $security_protocol: String
    $sasl_mechanism: String
    $sasl_username: String
    $sasl_password: String
    $useremail: String
    $gcs_service_account_keys: JSON
  ) {
    credentialCreate(
      name: $name
      type: $type
      identity: $identity
      secret: $secret
      security_protocol: $security_protocol
      sasl_mechanism: $sasl_mechanism
      sasl_username: $sasl_username
      sasl_password: $sasl_password
      useremail: $useremail
      gcs_service_account_keys: $gcs_service_account_keys
    ) {
      credential_name
      credential_type
      credential_identity
      credential
      additional_info
    }
  }
`;

export const useGetCredentals = () => {
  return useQuery(GET_CREDENTIALS);
};

export const useCreateCredential = () => {
  return useMutation(CREATE_CREDENTIAL);
};
