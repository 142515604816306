// Imports
import React, { useMemo, useState } from 'react';
import { Form, Steps, Button, Popconfirm, notification } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// App Imports
import GraphQLServices from '../../graphql/services';
import CreateGraphConfigureForm from './CreateGraphConfigureForm';
import CreateGraphOptionsForm from './CreateGraphOptionsForm';
import { GPUDB_REQUEST_MAP } from '../../endpoints';
import {
  displaySuccess,
  buildGraphComponentParams as buildComponentParams,
  cleanGraphOptions as cleanOptions,
} from '../../helper';

const { Step } = Steps;

const STEPS = [
  {
    title: 'Configure',
    StepComponent: CreateGraphConfigureForm,
  },
  {
    title: 'Options',
    StepComponent: CreateGraphOptionsForm,
  },
];

const CREATE_GRAPH_ENDPOINT = '/create/graph';

const CreateGraphForm = ({ resetMode, showGraphPreview }) => {
  const { refetch: refetchGraphs } = GraphQLServices.Graphs.useGetGraphs();
  const { data: { graph_grammar: grammar = {} } = {} } =
    GraphQLServices.GraphGrammars.useGetGraphGrammarByEndpoint({
      variables: {
        endpoint: CREATE_GRAPH_ENDPOINT,
      },
    });
  const [createGraph] = GraphQLServices.Graphs.useCreateGraph();

  const [step, setStep] = useState(0);
  const [isCreating, setIsCreating] = useState(false);
  const [componentAdditions, setComponentAdditions] = useState({});
  const [componentSelections, setComponentSelections] = useState({});

  const [form] = Form.useForm();
  const history = useHistory();

  const { topBarCollapsed } = useSelector(state => state.app);

  const onFinish = async values => {
    const { graph_name = '', directed_graph, ...options } = values;
    const { nodes, edges, weights, restrictions } = componentAdditions;

    try {
      setIsCreating(true);
      const createGraphResp = await createGraph({
        variables: {
          graph_name,
          directed_graph,
          nodes: buildComponentParams(nodes),
          edges: buildComponentParams(edges),
          weights: buildComponentParams(weights),
          restrictions: buildComponentParams(restrictions),
          options: cleanOptions(options),
        },
      });

      if (createGraphResp?.errors) {
        throw new Error(createGraphResp?.errors[0]?.message);
      }

      displaySuccess(`Graph ${graph_name} created.`);
      refetchGraphs();
      resetMode();
    } catch (error) {
      // Graphql client should display error
    } finally {
      setIsCreating(false);
    }
  };

  const create = _ => {
    const errors = [];
    const graph_name = form.getFieldValue('graph_name') ?? '';
    const { nodes = [], edges = [] } = componentAdditions;

    if (graph_name.trim() === '') {
      errors.push('Graph Name is required.');
    }
    if (nodes.length === 0 && edges.length === 0) {
      errors.push('Nodes or Edges must be specified.');
    }

    if (errors.length > 0) {
      notification.open({
        message: 'Validation Error Occurred',
        description: errors.map(error => <div>{error}</div>),
        type: 'error',
      });
    } else {
      form.submit();
    }
  };

  const { fields } = GPUDB_REQUEST_MAP[CREATE_GRAPH_ENDPOINT];

  const initialValues = useMemo(
    _ => {
      if (grammar && Object.keys(grammar).length > 0) {
        const configDefaults = fields.reduce((acc, cur) => {
          if (
            typeof cur.type === 'string' &&
            cur.type !== 'options' &&
            cur?.value?.default !== ''
          ) {
            if (cur.type === 'boolean') {
              acc[cur.name] = cur?.value?.default === 'true';
            } else {
              acc[cur.name] = cur?.value?.default;
            }
          }
          return acc;
        }, {});
        const optionDefaults = grammar?.options
          .filter(option => {
            // Don't display internal options
            return (
              !option.internal ||
              (option.internal && option.internal !== 'true')
            );
          })
          .reduce((acc, cur) => {
            if (cur?.default !== '') {
              if (cur.type === 'boolean') {
                acc[cur.name] = cur.default === 'true';
              } else {
                acc[cur.name] = cur.default;
              }
            }
            return acc;
          }, {});

        return {
          ...configDefaults,
          ...optionDefaults,
        };
      }
      return {};
    },
    [grammar, fields]
  );

  const handleCancel = e => {
    history.push('/');
  };

  const handleStepClick = step => {
    setStep(step);
  };

  const docs = useMemo(
    _ => {
      return fields.reduce((acc, cur) => {
        if (cur.name !== 'options') {
          acc[cur.name] = cur.doc.replace(/@{link.*?}/g, '');
        } else {
          Object.keys(cur.value.valid_keys).forEach(option => {
            acc[option] = cur.value.valid_keys[option].doc.replace(
              /@{link.*?}/g,
              ''
            );
          });
        }
        return acc;
      }, {});
    },
    [fields]
  );

  if (!grammar || Object.keys(grammar).length === 0) {
    return <></>;
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={onFinish}
      colon={false}
    >
      <Steps
        current={step}
        onChange={handleStepClick}
        style={{ marginBottom: '20px' }}
      >
        {STEPS.map(({ title }, idx) => (
          <Step key={idx} title={title}></Step>
        ))}
      </Steps>
      <div
        style={{
          height: topBarCollapsed
            ? 'calc(100vh - 290px)'
            : 'calc(100vh - 340px)',
          margin: '30px 0px',
          overflow: 'auto',
        }}
      >
        {STEPS.map(({ StepComponent }, idx) => (
          <div key={idx} style={{ display: step === idx ? 'block' : 'none' }}>
            <StepComponent
              form={form}
              fields={fields}
              grammar={grammar}
              componentAdditions={componentAdditions}
              setComponentAdditions={setComponentAdditions}
              componentSelections={componentSelections}
              setComponentSelections={setComponentSelections}
              docs={docs}
            ></StepComponent>
          </div>
        ))}
      </div>
      <div>
        <Button
          icon={<LeftOutlined />}
          onClick={_ => setStep(Math.max(step - 1, 0))}
          style={{ float: 'left' }}
          disabled={step === 0}
        >
          Back
        </Button>
        <Button
          onClick={resetMode}
          style={{ float: 'left', marginLeft: '10px' }}
        >
          Change Mode
        </Button>
        {step === STEPS.length - 1 ? (
          <Button
            type="primary"
            onClick={create}
            style={{ float: 'right' }}
            loading={isCreating}
          >
            Create Graph
          </Button>
        ) : (
          <Button
            onClick={_ => setStep(Math.min(step + 1, 2))}
            style={{ float: 'right' }}
            disabled={step === STEPS.length - 1}
          >
            Next <RightOutlined />
          </Button>
        )}
        <Popconfirm
          title="Are you sure you want to cancel?"
          onConfirm={handleCancel}
          okText="Yes"
          cancelText="No"
        >
          <Button style={{ float: 'right', marginRight: '10px' }} danger>
            Cancel
          </Button>
        </Popconfirm>
      </div>
    </Form>
  );
};

export default CreateGraphForm;
