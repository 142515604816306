// Imports
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Space, Button, Popconfirm, Empty } from 'antd';
import {
  SettingOutlined,
  CloseOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { KineticaGraphViz } from '@kinetica/react-kinetica-graph-viz';
import { CSS2DRenderer } from 'three/addons/renderers/CSS2DRenderer.js';
import SpriteText from 'three-spritetext';
import shortid from 'shortid';

// App Imports
import VizTitleBar from './VizTitleBar';
import VizConfigDrawer from './VizConfigDrawer';
import { APP_URL_API } from '../../setup/config';
import { useIsReadOnly } from './utils';
import { Group } from 'three';

const API_URL = `${APP_URL_API}/proxy/dbapi`;

const VizGraph = ({
  viz,
  data,
  handleUpdate,
  handleRemove,
  minHeight = 400,
  marginTop = 0,
  runId,
}) => {
  const { config } = viz;

  const [id, setId] = useState(`graph_${Math.round(Math.random() * 999999)}`);

  const [isVizConfigOpen, setIsVizConfigOpen] = useState(false);
  const readOnly = useIsReadOnly();

  const handleOpenVizConfig = _ => {
    setIsVizConfigOpen(true);
  };

  const handleCloseVizConfig = _ => {
    setIsVizConfigOpen(false);
  };

  const handleUpdateVizConfig = values => {
    handleUpdate(values, _ => {
      setIsVizConfigOpen(false);
    });
  };

  const hasNodeEdge = useMemo(() => {
    const {
      node_table_name,
      node_id_column,
      node_name_column,
      edge_table_name,
      edge_source_column,
      edge_target_column,
    } = config;
    return (
      node_table_name &&
      (node_id_column || node_name_column) &&
      edge_table_name &&
      edge_source_column &&
      edge_target_column
    );
  }, [config]);

  const hasEdge = useMemo(() => {
    const { edge_table_name, edge_source_column, edge_target_column } = config;
    return (
      (data || edge_table_name) && edge_source_column && edge_target_column
    );
  }, [config, data]);

  const nodesFromData = useMemo(
    _ => {
      if (data) {
        const {
          edge_source_column,
          edge_target_column,
          limit = '1000',
        } = config;

        const sourceIdx = data.column_headers.findIndex(
          column => column === edge_source_column
        );
        const targetIdx = data.column_headers.findIndex(
          column => column === edge_target_column
        );

        const rowLimit = Math.min(data.column_1.length, Number(limit));

        const nodes_idx = new Set();
        for (let i = 0; i < rowLimit; i++) {
          if (sourceIdx >= 0) {
            nodes_idx.add(data[`column_${sourceIdx + 1}`][i]);
          }
          if (targetIdx >= 0) {
            nodes_idx.add(data[`column_${targetIdx + 1}`][i]);
          }
        }
        return Array.from(nodes_idx).map(id => ({
          id,
          label: '',
        }));
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, config.edge_source_column, config.edge_target_column, config.limit]
  );

  const edgesFromData = useMemo(
    _ => {
      if (data) {
        const {
          edge_source_column,
          edge_target_column,
          edge_label_column,
          limit = '1000',
        } = config;

        const sourceIdx = data.column_headers.findIndex(
          column => column === edge_source_column
        );
        const targetIdx = data.column_headers.findIndex(
          column => column === edge_target_column
        );
        const labelIdx = data.column_headers.findIndex(
          column => column === edge_label_column
        );

        const rowLimit = Math.min(data.column_1.length, Number(limit));

        const edges = [];
        for (let i = 0; i < rowLimit; i++) {
          if (sourceIdx >= 0 && targetIdx >= 0) {
            edges.push({
              source: data[`column_${sourceIdx + 1}`][i],
              target: data[`column_${targetIdx + 1}`][i],
              label: labelIdx >= 0 ? data[`column_${labelIdx + 1}`][i] : '',
            });
          }
        }
        return edges;
      }
      return [];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      config.edge_source_column,
      config.edge_target_column,
      config.edge_label_column,
      config.limit,
    ]
  );

  useEffect(() => {
    if (data) {
      updateGraph();
    }
  }, [data, nodesFromData, edgesFromData]);

  const handleGraphConfig = useCallback(
    graph => {
      graph
        .d3VelocityDecay(0.8)
        .height(minHeight - 20)
        .cooldownTicks(50)
        .backgroundColor('#F3F3F3')
        .nodeColor(node => '#4c00b033')
        .nodeThreeObject(node => {
          const group = new Group();

          const primary = new SpriteText(
            node.label
              ? `${node.name || node.id}\n${' '}`
              : `${node.name || node.id}`
          );
          primary.material.depthWrite = false; // make sprite background transparent
          primary.color = node.color;
          primary.textHeight = 4;
          group.add(primary);

          if (node.label) {
            const secondary = new SpriteText(`${' '}\n${node.label}`);
            secondary.material.depthWrite = false; // make sprite background transparent
            secondary.color = '#4c00b0';
            secondary.textHeight = 3;
            group.add(secondary);
          }

          return group;
        })
        .nodeThreeObjectExtend(true)
        .nodeResolution(10)
        .linkColor(link => {
          return '#FF00FFcc';
        })
        .linkThreeObject(link => {
          const sprite = new SpriteText(link.label);
          sprite.color = '#FF00FF';
          sprite.textHeight = 3;
          return sprite;
        })
        .linkPositionUpdate((sprite, { start, end }) => {
          const middlePos = Object.assign(
            ...['x', 'y', 'z'].map(c => ({
              [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
            }))
          );
          Object.assign(sprite.position, middlePos);
        })
        .linkThreeObjectExtend(true)
        .linkResolution(8)
        .linkWidth(0.5);
      setTimeout(() => {
        graph.zoomToFit(400);
      }, 1000);
    },
    [minHeight]
  );

  const updateGraph = () => {
    setId(`graph_${Math.round(Math.random() * 999999)}`);
  };

  useEffect(() => {
    console.debug('VizGraph Run ID', runId);
    setId(`graph_${Math.round(Math.random() * 999999)}`);
  }, [runId]);

  const options = useMemo(_ => {
    return {
      extraRenderers: [new CSS2DRenderer()],
    };
  }, []);

  const nodeTable = useMemo(
    _ => {
      return config.node_table_schema
        ? `${config.node_table_schema}.${config.node_table_name}`
        : config.node_table_name;
    },
    [config.node_table_schema, config.node_table_name]
  );

  const nodeColumns = useMemo(
    _ => {
      const columns = [];
      if (config.node_id_column) {
        columns.push(config.node_id_column);
      }
      if (config.node_name_column) {
        columns.push(config.node_name_column);
        if (config.node_label_column && columns.length === 1) {
          // Pad the name column as ID is always minimally required
          columns.push(config.node_name_column);
        }
      } else if (config.node_label_column) {
        columns.push(config.node_id_column);
      }
      if (config.node_label_column) {
        columns.push(config.node_label_column);
      }
      return columns;
    },
    [config.node_id_column, config.node_name_column, config.node_label_column]
  );

  const edgeTable = useMemo(
    _ => {
      return config.edge_table_schema
        ? `${config.edge_table_schema}.${config.edge_table_name}`
        : config.edge_table_name;
    },
    [config.edge_table_schema, config.edge_table_name]
  );

  const edgeColumns = useMemo(
    _ => {
      return config.edge_label_column
        ? [
            config.edge_source_column,
            config.edge_target_column,
            config.edge_label_column,
          ]
        : [config.edge_source_column, config.edge_target_column];
    },
    [
      config.edge_source_column,
      config.edge_target_column,
      config.edge_label_column,
    ]
  );

  const dataTable = useMemo(
    _ => {
      return config.edge_table_schema
        ? `${config.edge_table_schema}.${config.edge_table_name}`
        : config.edge_table_name;
    },
    [config.edge_table_schema, config.edge_table_name]
  );

  const imageId = useMemo(_ => {
    return `image_${shortid.generate()}`;
  }, []);

  const hasTitle = useMemo(
    _ => {
      return (
        config &&
        ((config.title && config.title !== '') ||
          (Array.isArray(config) &&
            config.length > 0 &&
            config[0].title &&
            config[0].title !== ''))
      );
    },
    [config]
  );

  const limit = useMemo(
    _ => {
      return config.limit ? Number(config.limit) : 1000;
    },
    [config]
  );

  return (
    <div
      style={{
        position: 'relative',
        minHeight: hasTitle ? minHeight - 30 : minHeight,
        marginTop,
      }}
    >
      {!readOnly && (
        <div style={{ height: 30 }}>
          <Space style={{ float: 'right' }}>
            <Button
              icon={<SettingOutlined />}
              onClick={handleOpenVizConfig}
              size="small"
            >
              Config
            </Button>
            {handleRemove && (
              <Popconfirm
                title="Are you sure you want to delete this visualization?"
                onConfirm={handleRemove}
              >
                <Button icon={<CloseOutlined />} size="small"></Button>
              </Popconfirm>
            )}
          </Space>
          <Space style={{ float: 'left' }}>
            <Button
              icon={<ReloadOutlined />}
              onClick={updateGraph}
              size="small"
            ></Button>
          </Space>
        </div>
      )}
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
          height: minHeight - 20,
        }}
      >
        <VizTitleBar config={config} />
        <div
          id={imageId}
          className="graph"
          style={{ height: hasTitle ? minHeight - 50 : minHeight - 20 }}
        >
          {hasNodeEdge && (
            <KineticaGraphViz
              id={id}
              server={API_URL}
              options={options}
              node_table={nodeTable}
              node_columns={nodeColumns}
              edge_table={edgeTable}
              edge_columns={edgeColumns}
              graph_config={handleGraphConfig}
              limit={limit}
            />
          )}
          {!hasNodeEdge && hasEdge && !data && (
            <KineticaGraphViz
              id={id}
              server={API_URL}
              options={options}
              data_table={dataTable}
              data_columns={edgeColumns}
              graph_config={handleGraphConfig}
              limit={limit}
            />
          )}
          {!hasNodeEdge && hasEdge && data && (
            <KineticaGraphViz
              id={id}
              options={options}
              nodes={nodesFromData}
              edges={edgesFromData}
              graph_config={handleGraphConfig}
              limit={limit}
            />
          )}
          {!hasNodeEdge && !hasEdge && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Please Configure Graph"
              style={{ margin: 0, padding: '32px 0' }}
            />
          )}
        </div>
        {!readOnly && (
          <VizConfigDrawer
            title="Graph Configuration"
            fields={viz?.visualization_type?.params?.fields}
            viz={viz}
            config={viz?.config}
            options={{}}
            isOpen={isVizConfigOpen}
            handleClose={handleCloseVizConfig}
            handleUpdate={handleUpdateVizConfig}
          />
        )}
      </div>
    </div>
  );
};

export default VizGraph;
