// Imports
import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tabs,
  Tooltip,
} from 'antd';
import {
  DeleteOutlined,
  // LockOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

// App Imports
import GraphQLServices from '../../graphql/services';
import { validMaskColumn, validObfuscateColumn } from './utils';
import {
  KINETICA_TYPES,
  KINETICA_PROPERTIES,
  TYPE_DIMENSION_REGEX,
} from '../../constants';

const { TabPane } = Tabs;
const { Option, OptGroup } = Select;

// Make sure to only enable properties when applicable.
// Check docs for most up to date information about what the
// restrictions are.
function filterProperties(column, context) {
  const props = KINETICA_PROPERTIES.map(prop => ({ ...prop }));
  if (column?.storage === 'store_only' || context.isEditing) {
    const primaryKey = props.find(prop => prop.value === 'primary_key');
    const shardKey = props.find(prop => prop.value === 'shard_key');
    primaryKey.disabled = true;
    shardKey.disabled = true;
  }

  if (
    ((column?.type?.[0] === 'int' && column?.type?.[1] === '') ||
      column?.type?.[0] === 'long' ||
      column?.type?.[1] === 'date' ||
      (column?.type?.[1] || '').startsWith('char')) &&
    column?.storage !== 'store_only'
  ) {
    const dict = props.find(prop => prop.value === 'dict');
    dict.disabled = false;
  } else {
    const dict = props.find(prop => prop.value === 'dict');
    dict.disabled = true;
  }

  if (
    column?.type?.[0] !== 'string' ||
    (column?.type?.[1] !== 'date' &&
      column?.type?.[1] !== 'datetime' &&
      column?.type?.[1] !== 'time')
  ) {
    const initNow = props.find(prop => prop.value === 'init_with_now');
    initNow.disabled = true;
  }

  if (
    column?.type?.[0] !== 'string' ||
    (column?.type?.[1] !== '' &&
      !(column?.type?.[1] || '').startsWith('char') &&
      column?.type?.[1] !== 'date' &&
      column?.type?.[1] !== 'datetime' &&
      column?.type?.[1] !== 'time' &&
      column?.type?.[1] !== 'ipv4' &&
      column?.type?.[1] !== 'uuid')
  ) {
    const textSearch = props.find(prop => prop.value === 'text_search');
    textSearch.disabled = true;
  }

  if (column?.type?.[1] !== 'uuid') {
    const initUuid = props.find(prop => prop.value === 'init_with_uuid');
    initUuid.disabled = true;
  }

  return props;
}

export default function ColumnDefinitionForm({
  columns,
  addColumn,
  removeColumn,
  updateColumn,
  permissions,
  updatePermission,
  isEditing,
}) {
  const [showColumnPermissionModal, setShowColumnPermissionModal] =
    useState(false);
  const [showRowPermissionModal, setShowRowPermissionModal] = useState(false);
  const [selectedColumnId /*, setSelectedColumnId */] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const { loading: usersLoading, data: usersData } =
    GraphQLServices.Users.useGetUsers();
  const { loading: rolesLoading, data: rolesData } =
    GraphQLServices.Roles.useGetRoles();

  const currentPageSize = Math.floor((window.innerHeight - 580) / 60);
  const [currentpage, setCurrentPage] = useState(1);

  const kineticaUsersAndRoles = useMemo(
    _ => {
      let users = [];
      if (usersLoading || !usersData.users) {
        users = [];
      } else {
        users = usersData.users.map(user => user?.kinetica_user);
      }

      let roles = [];
      if (rolesLoading || !rolesData.roles) {
        roles = [];
      } else {
        roles = rolesData.roles.map(role => role?.kinetica_role);
      }

      return users.concat(roles).filter(item => item != null);
    },
    [usersLoading, usersData, rolesLoading, rolesData]
  );

  const selectedColumn = columns.find(col => col.id === selectedColumnId);

  const handleColumnEnabledChange = useCallback(
    checked => {
      updatePermission(selectedUser, {
        columns: { [selectedColumn.id]: { enabled: checked } },
      });
    },
    [selectedUser, selectedColumn, updatePermission]
  );

  const handleColumnFilterChange = useCallback(
    evt => {
      updatePermission(selectedUser, {
        columns: { [selectedColumn.id]: { columnFilter: evt.target.value } },
      });
    },
    [selectedUser, selectedColumn, updatePermission]
  );

  const handleTransformTypeChange = useCallback(
    value => {
      updatePermission(selectedUser, {
        columns: { [selectedColumn.id]: { transformType: value } },
      });
    },
    [selectedUser, selectedColumn, updatePermission]
  );

  const handleMaskStartChange = useCallback(
    value => {
      updatePermission(selectedUser, {
        columns: { [selectedColumn.id]: { maskStart: value } },
      });
    },
    [selectedUser, selectedColumn, updatePermission]
  );

  const handleMaskLengthChange = useCallback(
    value => {
      updatePermission(selectedUser, {
        columns: { [selectedColumn.id]: { maskLength: value } },
      });
    },
    [selectedUser, selectedColumn, updatePermission]
  );

  const handleMaskCharacterChange = useCallback(
    evt => {
      updatePermission(selectedUser, {
        columns: {
          [selectedColumn.id]: { maskCharacter: evt.target.value },
        },
      });
    },
    [selectedUser, selectedColumn, updatePermission]
  );

  const handleRowEnabledChange = useCallback(
    checked => {
      updatePermission(selectedUser, {
        rowEnabled: checked,
      });
    },
    [selectedUser, updatePermission]
  );

  const handleRowFilterChange = useCallback(
    evt => {
      updatePermission(selectedUser, { rowFilter: evt.target.value });
    },
    [selectedUser, updatePermission]
  );

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleAddColumn = _ => {
    addColumn();
    setTimeout(_ => {
      const lastPage = Math.ceil((columns.length + 1) / currentPageSize);
      setCurrentPage(lastPage);
    }, 100);
  };

  const evaluateValue = value => {
    return value && value.length === 2
      ? value[1].match(TYPE_DIMENSION_REGEX)
        ? `${value[0]}_${value[1].match(TYPE_DIMENSION_REGEX)[1]}`
        : `${value[0]}_${value[1]}`
      : value;
  };

  const getTypeDimension = value => {
    return (
      value &&
      value.length === 2 &&
      ((value[1].match(TYPE_DIMENSION_REGEX) &&
        value[1].match(TYPE_DIMENSION_REGEX)[2]) ||
        (value[1] === 'vector' && 1))
    );
  };

  return (
    <div>
      <p>
        Manually configure the destination table columns or leave it blank to
        automatically infer from data.
      </p>
      <div style={{ marginBottom: '20px' }}>
        <Button
          type="primary"
          onClick={handleAddColumn}
          style={{ marginRight: '5px' }}
          icon={<PlusOutlined></PlusOutlined>}
        >
          Add Column
        </Button>
        {/* <Button
          onClick={_ => {
            setShowRowPermissionModal(true);
            setSelectedUser(kineticaUsersAndRoles?.[0]?.name);
          }}
          icon={<LockOutlined></LockOutlined>}
        >
          Row Permissions
        </Button> */}
      </div>
      <Table
        dataSource={columns}
        pagination={{
          pageSize: currentPageSize,
          showSizeChanger: false,
          current: currentpage,
          onChange: handlePageChange,
        }}
        scroll={{ x: 'max-content' }}
        size="small"
        locale={{
          emptyText:
            'Leave blank to automatically infer column definitions from data.',
        }}
      >
        <Table.Column
          title="Name"
          dataIndex="columnName"
          key="columnName"
          width={200}
          render={(value, record) => {
            return (
              <Input
                value={value}
                onChange={evt =>
                  updateColumn(record.id, { columnName: evt.target.value })
                }
              ></Input>
            );
          }}
        ></Table.Column>
        <Table.Column
          title="Type"
          dataIndex="type"
          key="type"
          width={130}
          render={(value, record) => {
            const typeDimension = getTypeDimension(value);
            return (
              <>
                <Select
                  value={evaluateValue(value)}
                  onChange={newValue => {
                    const typeDimension = getTypeDimension(newValue);
                    updateColumn(record.id, {
                      type: !typeDimension
                        ? newValue.split('_')
                        : `${newValue.split('_')}(${typeDimension})`,
                    });
                  }}
                  style={{ width: '100%' }}
                >
                  {KINETICA_TYPES.map(type => {
                    return (
                      <OptGroup
                        key={type.label.toLowerCase()}
                        label={type.label}
                      >
                        {type.children ? (
                          type.children.map(subtype => {
                            return (
                              <Option
                                key={
                                  type.label.toLowerCase() +
                                  '_' +
                                  subtype.label.toLowerCase()
                                }
                                value={`${type.value}_${subtype.value}`}
                              >
                                {subtype.value === ''
                                  ? type.label
                                  : subtype.label}
                              </Option>
                            );
                          })
                        ) : (
                          <Option
                            key={type.label.toLowerCase()}
                            value={`${type.value}_`}
                          >
                            {type.label}
                          </Option>
                        )}
                      </OptGroup>
                    );
                  })}
                </Select>
                {typeDimension && (
                  <Input
                    type="number"
                    onChange={evt => {
                      const typeArr = evaluateValue(value).split('_');
                      updateColumn(record.id, {
                        type: [
                          typeArr[0],
                          `${typeArr[1]}(${evt.target.value})`,
                        ],
                      });
                    }}
                    value={typeDimension}
                  />
                )}
              </>
            );
          }}
        ></Table.Column>
        <Table.Column
          title="Nullable"
          dataIndex="nullable"
          key="nullable"
          render={(value, record) => {
            return (
              <div style={{ textAlign: 'center' }}>
                <Checkbox
                  checked={value}
                  onChange={evt =>
                    updateColumn(record.id, { nullable: evt.target.checked })
                  }
                ></Checkbox>
              </div>
            );
          }}
          width={70}
        ></Table.Column>
        <Table.Column
          title={_ => (
            <span>
              Properties &nbsp;
              <Tooltip title="Extra properties that enable specific behaviors">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          )}
          dataIndex="properties"
          key="properties"
          render={(value, record) => {
            return (
              <div style={{ width: '420px' }}>
                <Checkbox.Group
                  value={value}
                  style={{ width: '100%' }}
                  onChange={newValue =>
                    updateColumn(record.id, { properties: newValue })
                  }
                >
                  {filterProperties(record, { isEditing }).map(prop => {
                    const { label, value, disabled } = prop;
                    return (
                      <div
                        key={value}
                        style={{
                          display: 'inline-block',
                          width: value === 'dict' ? '150px' : '130px',
                        }}
                      >
                        <Checkbox value={value} disabled={disabled}>
                          {label}
                        </Checkbox>
                      </div>
                    );
                  })}
                </Checkbox.Group>
              </div>
            );
          }}
          width={420}
        ></Table.Column>
        <Table.Column
          key="actions"
          width={50}
          render={(_, record) => {
            return (
              <div>
                {/* <Button
                  type="text"
                  icon={<LockOutlined></LockOutlined>}
                  onClick={_ => {
                    setSelectedColumnId(record.id);
                    setShowColumnPermissionModal(true);
                    setSelectedUser(kineticaUsersAndRoles?.[0]?.name);
                  }}
                ></Button> */}
                <Popconfirm
                  title="Are you sure you want to remove this column?"
                  placement="topRight"
                  onConfirm={_ => removeColumn(record.id)}
                >
                  <Button
                    type="text"
                    icon={<DeleteOutlined></DeleteOutlined>}
                  ></Button>
                </Popconfirm>
              </div>
            );
          }}
        ></Table.Column>
      </Table>
      <Form.Item
        name="_column_placeholder"
        rules={[
          _ => ({
            validator(_rule, _value) {
              const emptyColumnNames = columns?.some(col => !col.columnName);
              if (emptyColumnNames) {
                return Promise.reject(
                  'Please specify a name for each column entry.'
                );
              } else {
                return Promise.resolve();
              }
            },
          }),
          _ => ({
            validator(_rule, _value) {
              let missingType = columns
                ?.filter(col => !col.type)
                .map(col => col.columnName);
              if (missingType.length > 0) {
                if (missingType.some(col => !col)) {
                  missingType = missingType.filter(col => col);
                }

                if (missingType.length > 0) {
                  return Promise.reject(
                    `Please specify a type for the following column(s): ${missingType.join(
                      ', '
                    )}`
                  );
                } else {
                  return Promise.resolve();
                }
              } else {
                return Promise.resolve();
              }
            },
          }),
        ]}
        style={{ visibility: 'hidden', position: 'absolute' }}
      >
        <Input></Input>
      </Form.Item>
      <Modal
        title={
          selectedColumn?.columnName
            ? `Column (${selectedColumn?.columnName}) Permissions`
            : 'Column Permissions'
        }
        open={showColumnPermissionModal}
        onOk={_ => {
          setShowColumnPermissionModal(false);
          setSelectedUser(null);
        }}
        onCancel={_ => {
          setShowColumnPermissionModal(false);
          setSelectedUser(null);
        }}
        width={800}
      >
        {showColumnPermissionModal && (
          <Tabs
            tabPosition="left"
            activeKey={selectedUser}
            onTabClick={userName => setSelectedUser(userName)}
            style={{ maxHeight: '550px' }}
          >
            {kineticaUsersAndRoles.map(user => (
              <TabPane
                tab={user?.name}
                key={user?.name}
                disabled={user?.permissions.some(
                  perm => perm.permission === 'system_admin'
                )}
              >
                <Form layout="inline" style={{ marginBottom: '10px' }}>
                  <Form.Item label="Enabled">
                    <Switch
                      checked={
                        permissions?.[user?.name]?.columns &&
                        permissions?.[user?.name]?.columns?.[selectedColumn?.id]
                          ?.enabled
                      }
                      onChange={handleColumnEnabledChange}
                      disabled={user?.permissions.some(
                        perm => perm.permission === 'system_admin'
                      )}
                    ></Switch>
                  </Form.Item>
                </Form>
                <Form layout="vertical">
                  <Form.Item label="Filter">
                    <Input.TextArea
                      value={
                        permissions?.[user?.name]?.columns &&
                        permissions?.[user?.name]?.columns?.[selectedColumn?.id]
                          ?.columnFilter
                      }
                      onChange={handleColumnFilterChange}
                      disabled={
                        user?.permissions.some(
                          perm => perm.permission === 'system_admin'
                        ) ||
                        !(
                          permissions?.[user?.name]?.columns &&
                          permissions?.[user?.name]?.columns?.[
                            selectedColumn?.id
                          ]?.enabled
                        )
                      }
                    ></Input.TextArea>
                  </Form.Item>
                  <Form.Item label="Transform Type">
                    <Select
                      value={
                        (permissions?.[user?.name]?.columns &&
                          permissions?.[user?.name]?.columns[selectedColumn?.id]
                            ?.transformType) ||
                        ''
                      }
                      onChange={handleTransformTypeChange}
                      disabled={
                        user?.permissions.some(
                          perm => perm.permission === 'system_admin'
                        ) ||
                        !(
                          permissions?.[user?.name]?.columns &&
                          permissions?.[user?.name]?.columns?.[
                            selectedColumn?.id
                          ]?.enabled
                        )
                      }
                    >
                      <Option value="">None</Option>
                      <Option
                        value="obfuscate"
                        disabled={!validObfuscateColumn(selectedColumn?.type)}
                      >
                        Obfuscate
                      </Option>
                      <Option
                        value="mask"
                        disabled={!validMaskColumn(selectedColumn?.type)}
                      >
                        Mask
                      </Option>
                    </Select>
                  </Form.Item>
                  {permissions?.[user?.name]?.columns &&
                    permissions?.[user?.name]?.columns[selectedColumn?.id]
                      ?.transformType === 'mask' && (
                      <div>
                        <h3>Mask Options</h3>
                        <Form.Item label="Start Position">
                          <InputNumber
                            value={
                              permissions?.[user?.name]?.columns &&
                              permissions?.[user?.name]?.columns[
                                selectedColumn?.id
                              ]?.maskStart
                            }
                            min={0}
                            onChange={handleMaskStartChange}
                            disabled={
                              user?.permissions.some(
                                perm => perm.permission === 'system_admin'
                              ) ||
                              !validMaskColumn(selectedColumn?.type) ||
                              !(
                                permissions?.[user?.name]?.columns &&
                                permissions?.[user?.name]?.columns?.[
                                  selectedColumn?.id
                                ]?.enabled
                              )
                            }
                          ></InputNumber>
                        </Form.Item>
                        <Form.Item label="# of Masked Characters">
                          <InputNumber
                            value={
                              permissions?.[user?.name]?.columns &&
                              permissions?.[user?.name]?.columns[
                                selectedColumn?.id
                              ]?.maskLength
                            }
                            min={0}
                            onChange={handleMaskLengthChange}
                            disabled={
                              user?.permissions.some(
                                perm => perm.permission === 'system_admin'
                              ) ||
                              !validMaskColumn(selectedColumn?.type) ||
                              !(
                                permissions?.[user?.name]?.columns &&
                                permissions?.[user?.name]?.columns?.[
                                  selectedColumn?.id
                                ]?.enabled
                              )
                            }
                          ></InputNumber>
                        </Form.Item>
                        <Form.Item label="Mask Character">
                          <Input
                            value={
                              permissions?.[user?.name]?.columns &&
                              permissions?.[user?.name]?.columns[
                                selectedColumn?.id
                              ]?.maskCharacter
                            }
                            onChange={handleMaskCharacterChange}
                            disabled={
                              user?.permissions.some(
                                perm => perm.permission === 'system_admin'
                              ) ||
                              !validMaskColumn(selectedColumn?.type) ||
                              !(
                                permissions?.[user?.name]?.columns &&
                                permissions?.[user?.name]?.columns?.[
                                  selectedColumn?.id
                                ]?.enabled
                              )
                            }
                            maxLength={1}
                          ></Input>
                        </Form.Item>
                      </div>
                    )}
                </Form>
              </TabPane>
            ))}
          </Tabs>
        )}
      </Modal>
      <Modal
        title="Row Permissions"
        open={showRowPermissionModal}
        onOk={_ => {
          setShowRowPermissionModal(false);
          setSelectedUser(null);
        }}
        onCancel={_ => {
          setShowRowPermissionModal(false);
          setSelectedUser(null);
        }}
        width={800}
      >
        <Tabs
          tabPosition="left"
          activeKey={selectedUser || kineticaUsersAndRoles?.[0]?.name}
          onTabClick={userName => {
            setSelectedUser(userName);
          }}
          style={{ height: '400px' }}
        >
          {kineticaUsersAndRoles.map(user => (
            <TabPane
              tab={user.name}
              key={user.name}
              disabled={user.permissions.some(
                perm => perm.permission === 'system_admin'
              )}
            >
              <Form layout="inline" style={{ marginBottom: '10px' }}>
                <Form.Item label="Enabled">
                  <Switch
                    value={permissions?.[user.name]?.rowEnabled}
                    onChange={handleRowEnabledChange}
                    disabled={user.permissions.some(
                      perm => perm.permission === 'system_admin'
                    )}
                  ></Switch>
                </Form.Item>
              </Form>
              <Form layout="vertical">
                <p>
                  Specify rows the user has access to. This filter will apply to
                  all columns. The filter can be any expression that would be
                  valid in a SQL WHERE clause.
                </p>
                <Form.Item label="Filter">
                  <Input.TextArea
                    value={permissions?.[user.name]?.rowFilter}
                    onChange={handleRowFilterChange}
                    disabled={
                      user.permissions.some(
                        perm => perm.permission === 'system_admin'
                      ) || !permissions?.[user.name]?.rowEnabled
                    }
                  ></Input.TextArea>
                </Form.Item>
                <h3>Examples</h3>
                <div style={{ marginBottom: '5px' }}>
                  <code>order_ts &gt;= '2002-01-01'</code>
                </div>
                <div>
                  <code>username = USER()</code>
                </div>
              </Form>
            </TabPane>
          ))}
        </Tabs>
      </Modal>
    </div>
  );
}
