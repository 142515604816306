import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ style = {}, behavior = 'instant' }) => {
  const topRef = useRef(null);
  const { pathname } = useLocation();

  useEffect(
    _ => {
      setTimeout(() => {
        if (topRef.current) {
          topRef.current.scrollIntoView({
            behavior,
          });
        }
      }, 100);
    },
    [pathname, behavior]
  );

  return <div ref={topRef} style={style}></div>;
};

export default ScrollToTop;
