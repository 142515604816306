// Imports
import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spin, Tabs, Form, Row, Col, Input, Radio, Select, Empty } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import {
  DATASOURCE_LOCATIONS,
  DATASOURCE_LOCATION_GCS,
  DATASOURCE_GCS_AUTH_TYPES,
  DATASOURCE_GCS_AUTH_NO_AUTH,
  DATASOURCE_GCS_AUTH_USERID_KEY,
  DATASOURCE_GCS_AUTH_JSON_KEY,
  DEFAULT_TABPANE_HEIGHT,
  DEFAULT_TABPANE_NOHEAD_HEIGHT,
} from '../../constants';
import Spinner from '../../components/common/Spinner';
import ImportForm from './ImportForm';
import ImportHistory from './ImportHistory';
import GCSImage from '../../images/import/gcs.svg';
import { DEFAULT_FORM_ITEM_PROPS } from './utils';
import { HEADER_LOGO_STYLE } from './constants';

const Setup = ({
  form,
  setupForm,
  setIsCreating,
  setFormValues: setParentFormValues,
  setStep,
}) => {
  const { data: { datasources = [] } = {}, refetch: refetchDatasources } =
    GraphQLServices.Datasources.useGetDatasources();
  const [createDatasource] = GraphQLServices.Datasources.useCreateDatasource();

  const [formValues, setFormValues] = useState({
    gcs_auth_type: DATASOURCE_GCS_AUTH_NO_AUTH,
  });

  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(
    _ => {
      if (location.state) {
        if (location.state.datasource) {
          setupForm.setFieldsValue({
            mode: 'existing',
            datasource: location.state.datasource.datasource_name,
          });
          setFormValues({
            mode: 'existing',
            datasource: location.state.datasource.datasource_name,
          });
          setParentFormValues({
            datasource: location.state.datasource.datasource_name,
          });
        }
      }
    },
    [location, setParentFormValues, setupForm]
  );

  const onFinish = async values => {
    const variables = Object.keys(values).reduce((acc, cur) => {
      if (values[cur]) {
        acc[cur] = values[cur];
      }
      return acc;
    }, {});

    setIsCreating(true);

    createDatasource({
      variables: {
        ...variables,
        gcs_service_account_keys: variables.gcs_service_account_keys
          ? JSON.parse(variables.gcs_service_account_keys)
          : variables.gcs_service_account_keys,
      },
    })
      .then(resp => {
        const { datasource_name: datasource } = resp?.data?.datasourceCreate;
        setIsCreating(false);
        refetchDatasources().then(_ => {
          if (datasource) {
            setupForm.resetFields();
            setupForm.setFieldsValue({
              mode: 'existing',
              datasource,
            });
            form.setFieldsValue({
              dataSource: datasource,
            });

            analytics.track(analytics.EVENT_TYPES.CREATED_DATA_SOURCE)({});

            setStep(1);
          }
        });
      })
      .catch(err => {
        setIsCreating(false);
        refetchDatasources();
      });
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.mode) {
      setupForm.setFieldsValue({
        datasource: undefined,
      });
    }
    setFormValues({
      ...allValues,
      gcs_auth_type: allValues.gcs_auth_type || DATASOURCE_GCS_AUTH_NO_AUTH,
    });
    setParentFormValues({
      ...allValues,
    });
  };

  const handleDatasourceChange = datasource => {
    form.setFieldsValue({
      dataSource: datasource,
    });
  };

  const datasourceOptions = useMemo(
    _ => {
      return datasources
        ? datasources
            .filter(datasource => {
              return (
                datasource.storage_provider_type.toLowerCase() ===
                DATASOURCE_LOCATION_GCS
              );
            })
            .map(datasource => ({
              label: datasource.datasource_name,
              value: datasource.datasource_name,
            }))
        : [];
    },
    [datasources]
  );

  return (
    <Form
      form={setupForm}
      name="file"
      layout="horizontal"
      initialValues={{ mode: '', location: DATASOURCE_LOCATION_GCS }}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      preserve={false}
      {...DEFAULT_FORM_ITEM_PROPS}
    >
      <Row gutter={0}>
        <Col span={4}>
          <img
            src={GCSImage}
            style={{
              height: '60px',
              float: 'right',
              margin: '10px 40px 0px 0px',
            }}
            alt="Google Cloud Storage"
          />
        </Col>
        <Col span={18}>
          <h3>Directions</h3>
          <p>
            Optionally create a new Google Cloud Storage data source, select a
            data source (new or existing), and click Next (or Create) to
            continue.
          </p>
          <h3>What would you like to do?</h3>
          <Form.Item label="" name="mode">
            <Radio.Group>
              <Radio.Button value="new">Create New Data Source</Radio.Button>
              <Radio.Button value="existing">
                Select Existing Data Source
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {setupForm.getFieldValue('mode') === 'new' && (
        <>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input data source name!',
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: 'Please select data source location!',
              },
            ]}
            hidden
          >
            <Radio.Group options={DATASOURCE_LOCATIONS} optionType="button" />
          </Form.Item>
          <Form.Item
            label="Bucket Name"
            name="gcs_bucket_name"
            rules={[
              {
                required: true,
                message: 'Please input GCS Bucket Name!',
              },
            ]}
          >
            <Input placeholder="<gcs bucket name>" />
          </Form.Item>
          <Form.Item label="Project ID" name="gcs_project_id">
            <Input placeholder="<gcs project id>" />
          </Form.Item>
          <Form.Item
            label="Auth Type"
            name="gcs_auth_type"
            initialValue={DATASOURCE_GCS_AUTH_NO_AUTH}
          >
            <Radio.Group
              options={DATASOURCE_GCS_AUTH_TYPES}
              optionType="button"
            />
          </Form.Item>
          {formValues?.gcs_auth_type === DATASOURCE_GCS_AUTH_USERID_KEY && (
            <>
              <Form.Item label="Account ID" name="user_name">
                <Input placeholder="<gcs account id>" />
              </Form.Item>
              <Form.Item label="Private Key" name="password">
                <Input
                  type="password"
                  placeholder="<gcs account private key>"
                />
              </Form.Item>
            </>
          )}
          {formValues?.gcs_auth_type === DATASOURCE_GCS_AUTH_JSON_KEY && (
            <>
              <Form.Item
                label="Service Acct Key"
                name="gcs_service_account_keys"
              >
                <Input.TextArea
                  placeholder="<gcs account json key text>"
                  rows={3}
                />
              </Form.Item>
            </>
          )}
        </>
      )}
      {setupForm.getFieldValue('mode') === 'existing' && (
        <>
          <Form.Item
            label="Data Source"
            name="datasource"
            {...DEFAULT_FORM_ITEM_PROPS}
          >
            <Select
              onChange={handleDatasourceChange}
              placeholder="Select a data source"
              notFoundContent={
                <Empty
                  description="No Google Cloud Storage data source available"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              }
            >
              {datasourceOptions.map(option => {
                return (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

const SourceGcs = () => {
  const [activeTabKey, setActiveTabKey] = useState('import');
  const { topBarCollapsed } = useSelector(state => state.app);
  const location = useLocation();

  useEffect(
    _ => {
      if (location.state) {
        if (location.state.datasource) {
          setActiveTabKey('import');
        }
      }
    },
    [location]
  );

  const handleTabClick = key => {
    setActiveTabKey(key);
  };

  const tabItems = useMemo(
    _ => {
      return [
        {
          key: 'import',
          label: `Import`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportForm setActiveTabKey={setActiveTabKey}>
                  {props => {
                    return <Setup {...props} />;
                  }}
                </ImportForm>
              </div>
            </div>
          ),
        },
        {
          key: 'history',
          label: `History`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportHistory
                  pageSize={
                    topBarCollapsed
                      ? Math.floor((window.innerHeight - 400) / 50)
                      : Math.floor((window.innerHeight - 450) / 50)
                  }
                />
              </div>
            </div>
          ),
        },
      ];
    },
    [topBarCollapsed]
  );

  return (
    <div>
      <img
        src={GCSImage}
        style={HEADER_LOGO_STYLE}
        alt="Google Cloud Storage"
      />
      <h2 style={{ display: 'inline-block' }}>Google Cloud Storage Import</h2>
      <Spin indicator={<Spinner />} spinning={false}>
        <Tabs
          type="card"
          items={tabItems}
          activeKey={activeTabKey}
          onTabClick={handleTabClick}
        ></Tabs>
      </Spin>
    </div>
  );
};

export default SourceGcs;
