// Imports
import React, { useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Table,
  Tooltip,
  Select,
  Space,
} from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';

// App Imports
import {
  KINETICA_TYPES,
  KINETICA_PROPERTIES,
  DATASOURCE_LOCATION_KAFKA,
} from '../../constants';

const { Option, OptGroup } = Select;

// Make sure to only enable properties when applicable.
// Check docs for most up to date information about what the
// restrictions are.
function filterProperties(column, context) {
  const props = KINETICA_PROPERTIES.map(prop => ({ ...prop }));
  if (column?.storage === 'store_only' || context?.isEditing) {
    const primaryKey = props.find(prop => prop.value === 'primary_key');
    const shardKey = props.find(prop => prop.value === 'shard_key');
    primaryKey.disabled = true;
    shardKey.disabled = true;
  }

  if (
    ((column?.type?.[0] === 'int' && column?.type?.[1] === '') ||
      column?.type?.[0] === 'long' ||
      column?.type?.[1] === 'date' ||
      (column?.type?.[1] || '').startsWith('char')) &&
    column?.storage !== 'store_only'
  ) {
    const dict = props.find(prop => prop.value === 'dict');
    dict.disabled = false;
  } else {
    const dict = props.find(prop => prop.value === 'dict');
    dict.disabled = true;
  }

  if (
    column?.type?.[0] !== 'string' ||
    (column?.type?.[1] !== 'date' &&
      column?.type?.[1] !== 'datetime' &&
      column?.type?.[1] !== 'time')
  ) {
    const initNow = props.find(prop => prop.value === 'init_with_now');
    initNow.disabled = true;
  }

  if (
    column?.type?.[0] !== 'string' ||
    (column?.type?.[1] !== '' &&
      !(column?.type?.[1] || '').startsWith('char') &&
      column?.type?.[1] !== 'date' &&
      column?.type?.[1] !== 'datetime' &&
      column?.type?.[1] !== 'time' &&
      column?.type?.[1] !== 'ipv4' &&
      column?.type?.[1] !== 'uuid')
  ) {
    const textSearch = props.find(prop => prop.value === 'text_search');
    textSearch.disabled = true;
  }

  if (column?.type?.[1] !== 'uuid') {
    const initUuid = props.find(prop => prop.value === 'init_with_uuid');
    initUuid.disabled = true;
  }

  return props;
}

export default function TableConfigureStep({
  form,
  columns,
  addColumn,
  removeColumn,
  updateColumn,
  tableExists,
  previewColumns,
  loadingPreview,
}) {
  const [currentpage, setCurrentPage] = useState(1);

  const isKafka = useMemo(() => {
    const { type } = form.getFieldsValue();
    return type === DATASOURCE_LOCATION_KAFKA;
  }, [form]);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleAddColumn = _ => {
    addColumn();
    setTimeout(_ => {
      const currentPageSize = Math.floor((window.innerHeight - 630) / 61);
      const lastPage = Math.ceil((columns.length + 1) / currentPageSize);
      setCurrentPage(lastPage);
    }, 100);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      {tableExists && (
        <p>View the existing destination table column configuration below.</p>
      )}
      {!tableExists && (
        <p>
          Manually configure the destination table columns or run type
          inferencing to see and edit column types before loading the data.
          Leave the fields blank if you want Kinetica to automatically infer
          column types during import.
        </p>
      )}
      <div style={{ marginBottom: '20px' }}>
        <Space>
          <Button
            type="primary"
            onClick={handleAddColumn}
            style={{ marginRight: '5px' }}
            disabled={tableExists}
            icon={<PlusOutlined></PlusOutlined>}
          >
            Add Column
          </Button>
          {!isKafka && (
            <Popconfirm
              title={
                <>
                  To infer the column types, Kinetica will sample data from your
                  file.
                  <br />
                  This may take time depending on the size of the file.
                </>
              }
              onConfirm={previewColumns}
              disabled={tableExists}
            >
              <Button
                type="primary"
                style={{ marginRight: '5px' }}
                disabled={tableExists}
                loading={loadingPreview}
                icon={<FileSearchOutlined />}
              >
                Infer Column Types
              </Button>
            </Popconfirm>
          )}
        </Space>
      </div>
      <Table
        dataSource={columns}
        pagination={{
          pageSize: Math.floor((window.innerHeight - 630) / 61),
          showSizeChanger: false,
          current: currentpage,
          onChange: handlePageChange,
        }}
        scroll={{ x: 'max-content' }}
        size="small"
        locale={{
          emptyText:
            'Leave blank to automatically infer column definitions from data.',
        }}
        loading={loadingPreview}
      >
        <Table.Column
          title="Name"
          dataIndex="columnName"
          key="columnName"
          width={200}
          render={(value, record) => {
            return (
              <Input
                value={value}
                onChange={evt =>
                  updateColumn(record.id, { columnName: evt.target.value })
                }
                disabled={tableExists}
              ></Input>
            );
          }}
        ></Table.Column>
        <Table.Column
          title="Type"
          dataIndex="type"
          key="type"
          width={130}
          render={(value, record) => {
            return (
              <Select
                value={
                  value && value.length === 2
                    ? `${value[0]}_${value[1]}`
                    : value
                }
                onChange={newValue => {
                  updateColumn(record.id, { type: newValue.split('_') });
                }}
                style={{ width: '100%' }}
                disabled={tableExists}
              >
                {KINETICA_TYPES.map(type => {
                  return (
                    <OptGroup key={type.label.toLowerCase()} label={type.label}>
                      {type.children ? (
                        type.children.map(subtype => {
                          return (
                            <Option
                              key={
                                type.label.toLowerCase() +
                                '_' +
                                subtype.label.toLowerCase()
                              }
                              value={`${type.value}_${subtype.value}`}
                            >
                              {subtype.value === ''
                                ? type.label
                                : subtype.label}
                            </Option>
                          );
                        })
                      ) : (
                        <Option
                          key={type.label.toLowerCase()}
                          value={`${type.value}_`}
                        >
                          {type.label}
                        </Option>
                      )}
                    </OptGroup>
                  );
                })}
              </Select>
            );
          }}
        ></Table.Column>
        <Table.Column
          title="Nullable"
          dataIndex="nullable"
          key="nullable"
          render={(value, record) => {
            return (
              <div style={{ textAlign: 'center' }}>
                <Checkbox
                  checked={value}
                  onChange={evt =>
                    updateColumn(record.id, { nullable: evt.target.checked })
                  }
                  disabled={tableExists}
                ></Checkbox>
              </div>
            );
          }}
          width={70}
        ></Table.Column>
        <Table.Column
          title={_ => (
            <span>
              Properties &nbsp;
              <Tooltip title="Extra properties that enable specific behaviors">
                <QuestionCircleOutlined />
              </Tooltip>
            </span>
          )}
          dataIndex="properties"
          key="properties"
          render={(value, record) => {
            return (
              <div style={{ width: '420px' }}>
                <Checkbox.Group
                  value={value}
                  style={{ width: '100%' }}
                  onChange={newValue =>
                    updateColumn(record.id, { properties: newValue })
                  }
                  disabled={tableExists}
                >
                  {filterProperties(record).map(prop => {
                    const { label, value, disabled } = prop;
                    return (
                      <div
                        key={value}
                        style={{
                          display: 'inline-block',
                          width: value === 'dict' ? '150px' : '130px',
                        }}
                      >
                        <Checkbox value={value} disabled={disabled}>
                          {label}
                        </Checkbox>
                      </div>
                    );
                  })}
                </Checkbox.Group>
              </div>
            );
          }}
          width={420}
        ></Table.Column>
        <Table.Column
          key="actions"
          width={50}
          render={(_, record) => {
            return (
              <div>
                <Popconfirm
                  title="Are you sure you want remove this column?"
                  placement="topRight"
                  onConfirm={_ => removeColumn(record.id)}
                  disabled={tableExists}
                >
                  <Button
                    type="text"
                    icon={<DeleteOutlined></DeleteOutlined>}
                    disabled={tableExists}
                  ></Button>
                </Popconfirm>
              </div>
            );
          }}
        ></Table.Column>
      </Table>
      <Form form={form}>
        <Form.Item
          name="_column_placeholder"
          rules={[
            _ => ({
              validator(_rule, _value) {
                const emptyColumnNames = columns?.some(col => !col.columnName);
                if (emptyColumnNames) {
                  return Promise.reject(
                    'Please specify a name for each column entry.'
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
            _ => ({
              validator(_rule, _value) {
                let missingType = columns
                  ?.filter(col => !col.type)
                  .map(col => col.columnName);
                if (missingType.length > 0) {
                  if (missingType.some(col => !col)) {
                    missingType = missingType.filter(col => col);
                  }

                  if (missingType.length > 0) {
                    return Promise.reject(
                      `Please specify a type for the following column(s): ${missingType.join(
                        ', '
                      )}`
                    );
                  } else {
                    return Promise.resolve();
                  }
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
          style={{ visibility: 'hidden', position: 'absolute' }}
        >
          <Input></Input>
        </Form.Item>
      </Form>
    </div>
  );
}
