// Imports
import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Spin,
  Tabs,
  Form,
  Row,
  Col,
  Input,
  Radio,
  Select,
  Empty,
  Cascader,
} from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import {
  DATASOURCE_LOCATIONS,
  DATASOURCE_LOCATION_JDBC,
  DATASOURCE_JDBC_AUTH_PASSWORD,
  DATASOURCE_JDBC_AUTH_TYPES,
  DATASOURCE_JDBC_JAR_PATH_TYPES,
  DATASOURCE_JDBC_JAR_PATH_MANUAL,
  DATASOURCE_JDBC_JAR_PATH_KIFS,
  DEFAULT_TABPANE_HEIGHT,
  DEFAULT_TABPANE_NOHEAD_HEIGHT,
} from '../../constants';
import Spinner from '../../components/common/Spinner';
import ImportForm from './ImportForm';
import ImportHistory from './ImportHistory';
import JDBCImage from '../../images/import/java_jdbc.svg';
import { DEFAULT_FORM_ITEM_PROPS } from './utils';
import { getTimestampKey } from '../../helper';
import { HEADER_LOGO_STYLE } from './constants';

const Setup = ({
  form,
  setupForm,
  setIsCreating,
  setFormValues: setParentFormValues,
  setStep,
}) => {
  const { data: { datasources = [] } = {}, refetch: refetchDatasources } =
    GraphQLServices.Datasources.useGetDatasources();
  const [createCredential] = GraphQLServices.Credentials.useCreateCredential();
  const [createDatasource] = GraphQLServices.Datasources.useCreateDatasource();
  const { data: { folders = [] } = {} } = GraphQLServices.Files.useGetFolders();

  const [formValues, setFormValues] = useState({
    jdbc_auth_type: DATASOURCE_JDBC_AUTH_PASSWORD,
    jdbc_driver_jar_path_type: DATASOURCE_JDBC_JAR_PATH_MANUAL,
  });

  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(
    _ => {
      if (location.state) {
        if (location.state.datasource) {
          setupForm.setFieldsValue({
            mode: 'existing',
            datasource: location.state.datasource.datasource_name,
          });
          setFormValues({
            mode: 'existing',
            datasource: location.state.datasource.datasource_name,
          });
          setParentFormValues({
            datasource: location.state.datasource.datasource_name,
          });
        }
        if (location.state.table) {
          form.setFieldsValue({
            remoteTable: location.state.table,
          });
        }
      }
    },
    [location, form, setupForm, setParentFormValues]
  );

  const onFinish = async values => {
    const variables = Object.keys(values).reduce((acc, cur) => {
      if (values[cur]) {
        acc[cur] = values[cur];
      }
      return acc;
    }, {});

    if (variables.location?.includes(DATASOURCE_LOCATION_JDBC)) {
      variables.location = variables.location_jdbc;
    }

    setIsCreating(true);

    // Need to create credential first for jdbc
    if (
      variables.location?.includes(DATASOURCE_LOCATION_JDBC) &&
      variables?.jdbc_auth_type === DATASOURCE_JDBC_AUTH_PASSWORD &&
      variables.user_name &&
      variables.password
    ) {
      const { user_name, password } = variables;
      const name = `${variables.name}_credential_autogen_${getTimestampKey()}`;
      const variables3 = {
        name,
        type: DATASOURCE_LOCATION_JDBC,
        identity: user_name,
        secret: password,
      };
      const createCredResp = await createCredential({
        variables: variables3,
      });
      if (createCredResp?.errors) {
        setIsCreating(false);
        return;
      }

      variables.credential = name;
      variables.user_name = undefined;
      variables.password = undefined;
    }

    createDatasource({
      variables,
    })
      .then(resp => {
        const { datasource_name: datasource } = resp?.data?.datasourceCreate;
        setIsCreating(false);
        refetchDatasources().then(_ => {
          if (datasource) {
            setupForm.resetFields();
            setupForm.setFieldsValue({
              mode: 'existing',
              datasource,
            });
            form.setFieldsValue({
              dataSource: datasource,
              fileFormat: 'JSON',
            });

            analytics.track(analytics.EVENT_TYPES.CREATED_DATA_SOURCE)({});

            setStep(1);
          }
        });
      })
      .catch(err => {
        setIsCreating(false);
        refetchDatasources();
      });
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.mode) {
      setupForm.setFieldsValue({
        datasource: undefined,
      });
    }
    setFormValues({
      ...allValues,
      jdbc_auth_type: allValues.jdbc_auth_type || DATASOURCE_JDBC_AUTH_PASSWORD,
      jdbc_driver_jar_path_type:
        allValues.jdbc_driver_jar_path_type || DATASOURCE_JDBC_JAR_PATH_MANUAL,
    });
    setParentFormValues({
      ...allValues,
    });
  };

  const handleDatasourceChange = datasource => {
    form.setFieldsValue({
      dataSource: datasource,
      fileFormat: 'JSON',
    });
    setFormValues({
      datasource,
    });
  };

  const datasourceOptions = useMemo(
    _ => {
      return datasources
        ? datasources
            .filter(datasource => {
              return (
                datasource.storage_provider_type.toLowerCase().split(':')[0] ===
                DATASOURCE_LOCATION_JDBC
              );
            })
            .map(datasource => ({
              label: datasource.datasource_name,
              value: datasource.datasource_name,
            }))
        : [];
    },
    [datasources]
  );

  const handleFileChange = file => {
    if (file.length === 2) {
      form.setFieldsValue({
        jdbc_driver_jar_path: `kifs://${file[0]}/${file[1]}`,
      });
      setupForm.setFieldsValue({
        jdbc_driver_jar_path: `kifs://${file[0]}/${file[1]}`,
      });
      setFormValues({
        ...formValues,
        jdbc_driver_jar_path: `kifs://${file[0]}/${file[1]}`,
      });
    }
  };

  const handleFileFilter = (inputValue, path) => {
    return path.some(option =>
      option.value.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const fileOptions = useMemo(
    _ => {
      return folders
        ? folders
            .map(folder => {
              return {
                value: folder.name,
                label: folder.name,
                children: folder.files
                  .map(file => {
                    return {
                      value: file.name,
                      label: file.name,
                    };
                  })
                  .sort((file1, file2) => {
                    if (file1.value.toLowerCase() > file2.value.toLowerCase())
                      return 1;
                    if (file1.value.toLowerCase() < file2.value.toLowerCase())
                      return -1;
                    return 0;
                  }),
              };
            })
            .sort((folder1, folder2) => {
              if (folder1.value.toLowerCase() > folder2.value.toLowerCase())
                return 1;
              if (folder1.value.toLowerCase() < folder2.value.toLowerCase())
                return -1;
              return 0;
            })
        : [];
    },
    [folders]
  );

  return (
    <Form
      form={setupForm}
      name="file"
      layout="horizontal"
      initialValues={{
        mode: '',
        location: DATASOURCE_LOCATION_JDBC,
      }}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      preserve={false}
      {...DEFAULT_FORM_ITEM_PROPS}
    >
      <Row gutter={0}>
        <Col span={4}>
          <img
            src={JDBCImage}
            style={{
              height: '60px',
              float: 'right',
              margin: '10px 40px 0px 0px',
            }}
            alt="Kafka"
          />
        </Col>
        <Col span={18}>
          <h3>Directions</h3>
          <p>
            Optionally create a new JDBC data source, select a data source (new
            or existing), and click Next (or Create) to continue.
          </p>
          <h3>What would you like to do?</h3>
          <Form.Item label="" name="mode">
            <Radio.Group>
              <Radio.Button value="new">Create New Data Source</Radio.Button>
              <Radio.Button value="existing">
                Select Existing Data Source
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {setupForm.getFieldValue('mode') === 'new' && (
        <>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input data source name!',
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: 'Please select data source location!',
              },
            ]}
            hidden
          >
            <Radio.Group options={DATASOURCE_LOCATIONS} optionType="button" />
          </Form.Item>
          <Form.Item
            label="URL"
            name="location_jdbc"
            rules={[
              {
                required: true,
                message: 'Please input JDBC connection URL!',
              },
            ]}
          >
            <Input placeholder="<jdbc url connection string>" />
          </Form.Item>
          <Form.Item
            label="Auth Type"
            name="jdbc_auth_type"
            initialValue={DATASOURCE_JDBC_AUTH_PASSWORD}
            hidden
          >
            <Radio.Group
              options={DATASOURCE_JDBC_AUTH_TYPES}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          {formValues?.jdbc_auth_type === DATASOURCE_JDBC_AUTH_PASSWORD && (
            <>
              <Row>
                <Col span={12}>
                  <Form.Item
                    label="Username"
                    name="user_name"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input placeholder="<jdbc connection username>" />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item
                    label="Password"
                    name="password"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                  >
                    <Input
                      type="password"
                      placeholder="<jdbc connection password>"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Driver Class" name="jdbc_driver_class_name">
                <Input placeholder="<jdbc driver class name>" />
              </Form.Item>
              <Form.Item
                label="JAR Path Type"
                name="jdbc_driver_jar_path_type"
                initialValue={DATASOURCE_JDBC_JAR_PATH_MANUAL}
              >
                <Radio.Group
                  options={DATASOURCE_JDBC_JAR_PATH_TYPES}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
              <Form.Item
                label="Driver JAR Path"
                name="jdbc_driver_jar_path"
                hidden={
                  formValues?.jdbc_driver_jar_path_type ===
                  DATASOURCE_JDBC_JAR_PATH_KIFS
                }
              >
                <Input placeholder="<path to jdbc driver jar>" />
              </Form.Item>
              {formValues?.jdbc_driver_jar_path_type ===
                DATASOURCE_JDBC_JAR_PATH_KIFS && (
                <Form.Item
                  label="Driver JAR Path"
                  name="jdbc_driver_jar_path_kifs"
                  rules={[
                    {
                      required: true,
                      message: 'Please select a file!',
                    },
                  ]}
                >
                  <Cascader
                    options={fileOptions}
                    onChange={handleFileChange}
                    showSearch={{ handleFileFilter }}
                    placeholder="Please search and select a file"
                    allowClear={false}
                  />
                </Form.Item>
              )}
            </>
          )}
        </>
      )}
      {setupForm.getFieldValue('mode') === 'existing' && (
        <>
          <Form.Item
            label="Data Source"
            name="datasource"
            {...DEFAULT_FORM_ITEM_PROPS}
          >
            <Select
              onChange={handleDatasourceChange}
              placeholder="Select a data source"
              notFoundContent={
                <Empty
                  description="No JDBC data source available"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              }
            >
              {datasourceOptions.map(option => {
                return (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

const SourceJdbc = () => {
  const [activeTabKey, setActiveTabKey] = useState('import');
  const { topBarCollapsed } = useSelector(state => state.app);
  const location = useLocation();

  useEffect(
    _ => {
      if (location.state) {
        if (location.state.datasource) {
          setActiveTabKey('import');
        }
      }
    },
    [location]
  );

  const handleTabClick = key => {
    setActiveTabKey(key);
  };

  const tabItems = useMemo(
    _ => {
      return [
        {
          key: 'import',
          label: `Import`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportForm setActiveTabKey={setActiveTabKey}>
                  {props => {
                    return <Setup {...props} />;
                  }}
                </ImportForm>
              </div>
            </div>
          ),
        },
        {
          key: 'history',
          label: `History`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportHistory
                  pageSize={
                    topBarCollapsed
                      ? Math.floor((window.innerHeight - 400) / 50)
                      : Math.floor((window.innerHeight - 450) / 50)
                  }
                />
              </div>
            </div>
          ),
        },
      ];
    },
    [topBarCollapsed]
  );

  return (
    <div>
      <img src={JDBCImage} style={HEADER_LOGO_STYLE} alt="Kafka Stream" />
      <h2 style={{ display: 'inline-block' }}>JDBC Import</h2>
      <Spin indicator={<Spinner />} spinning={false}>
        <Tabs
          type="card"
          items={tabItems}
          activeKey={activeTabKey}
          onTabClick={handleTabClick}
        ></Tabs>
      </Spin>
    </div>
  );
};

export default SourceJdbc;
