import React, { useCallback, useMemo, useState } from 'react';
import { Button, Dropdown, Popconfirm, Tooltip, Divider } from 'antd';
import {
  BulbFilled,
  DeleteOutlined,
  DownOutlined,
  PlusOutlined,
  UpOutlined,
  ApartmentOutlined,
  DoubleRightOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  ShrinkOutlined,
  ExpandAltOutlined,
  FullscreenOutlined,
  VerticalAlignTopOutlined,
  VerticalAlignBottomOutlined,
  FieldTimeOutlined,
  // FormatPainterOutlined,
} from '@ant-design/icons';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import debounce from 'debounce';

import {
  BLOCK_TYPES,
  WORKSHEET_BLOCK_COUNT_LIMIT,
  MOUSE_CLICK_DEBOUNCE,
} from '../../constants';
import { useIsReadOnly } from './utils';
import { sleep } from '../../helper';

export default function BlockToolbar({
  element,
  blocks,
  showExplainPlan,
  // formatSQL,
  runAllFromBlock,
  handleFullscreen,
  toggleSqlVisibility,
  toggleBlockCollapsed = element => {},
  toggleBlockVisibility = element => {},
  toggleSqlGpt = element => {},
  toggleQueryStatus = element => {},
  top = '-16px',
  loading = false,
  setLoading = loading => {},
  queryID = null,
  queryRunning = false,
  queryResponse = null,
  enableSQLAssistant = true,
}) {
  const editor = useSlateStatic();
  const readOnly = useIsReadOnly();

  const [adding, setAdding] = useState(false);

  const handleAdd = useCallback(
    async node => {
      setAdding(true);
      const path = ReactEditor.findPath(editor, element);
      const afterPath = [path[0] + 1];
      Transforms.insertNodes(editor, node, { at: afterPath });
      await sleep(300);
      setAdding(false);
    },
    [editor, element]
  );

  const handleAddSQL = useCallback(
    _ => {
      handleAdd({
        type: BLOCK_TYPES.SQL,
        content: '',
        children: [{ text: '' }],
      });
    },
    [handleAdd]
  );

  const handleAddText = useCallback(
    _ => {
      handleAdd({
        type: BLOCK_TYPES.TEXT,
        content: [{ type: 'paragraph', children: [{ text: '' }] }],
        children: [{ text: '' }],
      });
    },
    [handleAdd]
  );

  const handleAddImage = useCallback(
    _ => {
      handleAdd({
        type: BLOCK_TYPES.IMAGE,
        content: {},
        children: [{ text: '' }],
      });
    },
    [handleAdd]
  );

  const handleAddHtml = useCallback(
    _ => {
      handleAdd({
        type: BLOCK_TYPES.HTML,
        content: {},
        children: [{ text: '' }],
      });
    },
    [handleAdd]
  );

  const handleAddMap = useCallback(
    _ => {
      handleAdd({
        type: BLOCK_TYPES.MAP,
        content: {},
        children: [{ text: '' }],
      });
    },
    [handleAdd]
  );

  const handleAddGraph = useCallback(
    _ => {
      handleAdd({
        type: BLOCK_TYPES.GRAPH,
        content: {},
        children: [{ text: '' }],
      });
    },
    [handleAdd]
  );

  const handleDelete = useCallback(
    async _ => {
      setLoading(true);
      await sleep(300);
      const path = ReactEditor.findPath(editor, element);
      Transforms.delete(editor, { at: path });
      Transforms.select(editor, {
        anchor: { path: [0], offset: 0 },
        focus: { path: [0], offset: 0 },
      });
      setLoading(false);
    },
    [editor, element, setLoading]
  );

  const handleMoveUp = useCallback(
    async _ => {
      setLoading(true);
      const path = ReactEditor.findPath(editor, element);
      const newPath = [Math.max(0, path[0] - 1)];
      Transforms.moveNodes(editor, { at: path, to: newPath });
      await sleep(300);
      setLoading(false);
    },
    [editor, element, setLoading]
  );

  const debounceMoveUp = debounce(handleMoveUp, MOUSE_CLICK_DEBOUNCE);

  const handleMoveDown = useCallback(
    async _ => {
      setLoading(true);
      const path = ReactEditor.findPath(editor, element);
      const newPath = [path[0] + 1];
      Transforms.moveNodes(editor, { at: path, to: newPath });
      await sleep(300);
      setLoading(false);
    },
    [editor, element, setLoading]
  );

  const debounceMoveDown = debounce(handleMoveDown, MOUSE_CLICK_DEBOUNCE);

  const handleExplainPlan = useCallback(
    _ => {
      showExplainPlan(false);
    },
    [showExplainPlan]
  );

  const handleRunAnalyzePlan = useCallback(
    _ => {
      showExplainPlan(true);
    },
    [showExplainPlan]
  );

  // const handleFormatSQL = _ => {
  //   formatSQL();
  // };

  const addMenu = useMemo(
    _ => {
      return {
        items: [
          {
            key: 'sql',
            label: 'Add SQL Below',
            onClick: handleAddSQL,
          },
          {
            key: 'text',
            label: 'Add Text Below',
            onClick: handleAddText,
          },
          {
            key: 'image',
            label: 'Add Image Below',
            onClick: handleAddImage,
          },
          {
            key: 'html',
            label: 'Add HTML Below',
            onClick: handleAddHtml,
          },
          {
            key: 'map',
            label: 'Add Map Below',
            onClick: handleAddMap,
          },
          {
            key: 'graph',
            label: 'Add Graph Below',
            onClick: handleAddGraph,
          },
        ],
      };
    },
    [
      handleAddGraph,
      handleAddHtml,
      handleAddImage,
      handleAddMap,
      handleAddSQL,
      handleAddText,
    ]
  );

  const explainPlanMenu = useMemo(
    _ => {
      return {
        items: [
          {
            key: 'explain',
            label: 'Explain Plan',
            onClick: handleExplainPlan,
          },
          {
            key: 'analyze',
            label: 'Run and Analyze',
            onClick: handleRunAnalyzePlan,
          },
        ],
      };
    },
    [handleExplainPlan, handleRunAnalyzePlan]
  );

  const buttonStyle = {
    marginRight: '5px',
    height: '24px',
    lineHeight: '20px',
  };

  const isCodeBlock = useMemo(
    _ => {
      return element?.type === BLOCK_TYPES.SQL;
    },
    [element]
  );

  const { isSqlVisible, isBlockVisible, isBlockCollapsed, isSqlGptEnabled } =
    useMemo(
      _ => {
        const {
          isSqlVisible = true,
          isBlockVisible = true,
          isSqlGptEnabled = false,
          isBlockCollapsed = false,
        } = element?.config ?? {};
        return {
          isSqlVisible,
          isBlockVisible,
          isSqlGptEnabled,
          isBlockCollapsed,
        };
      },
      [element]
    );

  const displaySqlVisibility = visible => {
    return visible ? <ExpandAltOutlined /> : <ShrinkOutlined />;
  };
  const displayBlockVisilityIcon = visible => {
    return visible ? <EyeOutlined /> : <EyeInvisibleOutlined />;
  };

  const blockLimitReached = useMemo(
    _ => {
      return blocks && blocks.length >= WORKSHEET_BLOCK_COUNT_LIMIT;
    },
    [blocks]
  );

  const disabled = useMemo(
    _ => {
      return adding || loading;
    },
    [adding, loading]
  );

  return (
    <div
      className="noprint"
      style={{
        position: 'absolute',
        right: '5px',
        pointerEvents: 'all',
      }}
    >
      <div
        style={{
          position: 'relative',
          float: 'right',
          top,
          zIndex: 1,
        }}
      >
        {queryID && !queryResponse && (
          <>
            <Tooltip title={`View job status for Query ID: ${queryID}`}>
              <Button
                icon={<FieldTimeOutlined />}
                onClick={toggleQueryStatus}
                size="small"
                style={{
                  ...buttonStyle,
                  fontSize: '12px',
                }}
              ></Button>
            </Tooltip>
            <Divider type="vertical" style={{ marginLeft: 3 }} />
          </>
        )}
        {!readOnly && (
          <>
            {isCodeBlock && (
              <Tooltip title="Expand/Collapse SQL in Share/Embed Mode">
                <Button
                  icon={displaySqlVisibility(isSqlVisible)}
                  size="small"
                  style={
                    !isSqlVisible
                      ? { ...buttonStyle, color: 'red' }
                      : buttonStyle
                  }
                  onClick={toggleSqlVisibility(element)}
                  disabled={loading}
                ></Button>
              </Tooltip>
            )}
            <Tooltip title="Show/Hide Block in Share/Embed Mode">
              <Button
                icon={displayBlockVisilityIcon(isBlockVisible)}
                size="small"
                style={
                  !isBlockVisible
                    ? { ...buttonStyle, color: 'red' }
                    : buttonStyle
                }
                onClick={toggleBlockVisibility(element)}
                disabled={loading}
              ></Button>
            </Tooltip>
            <Divider type="vertical" style={{ marginLeft: 3 }} />
          </>
        )}
        {isCodeBlock && (
          <>
            {/* <Tooltip title="Format SQL">
                  <Button
                    icon={<FormatPainterOutlined />}
                    size="small"
                    style={buttonStyle}
                    onClick={handleFormatSQL}
                  ></Button>
                </Tooltip> */}
            {!readOnly && enableSQLAssistant && (
              <Tooltip
                title={
                  isSqlGptEnabled ? 'Toggle SQL-GPT Off' : 'Toggle SQL-GPT On'
                }
              >
                <Button
                  icon={isSqlGptEnabled ? <BulbFilled /> : <BulbFilled />}
                  size="small"
                  onClick={toggleSqlGpt(element)}
                  style={{
                    ...buttonStyle,
                    color: isSqlGptEnabled ? '#4a00e066' : '#dddddd',
                    borderColor: '#d9d9d9',
                  }}
                  disabled={loading}
                ></Button>
              </Tooltip>
            )}
            {!readOnly && (
              <Tooltip title="Explain Plan">
                <Dropdown
                  menu={explainPlanMenu}
                  trigger={['click']}
                  disabled={loading}
                >
                  <Button
                    icon={<ApartmentOutlined />}
                    size="small"
                    style={buttonStyle}
                    disabled={loading}
                  ></Button>
                </Dropdown>
              </Tooltip>
            )}
            <Tooltip title="Run All From Here">
              <Button
                icon={<DoubleRightOutlined />}
                size="small"
                style={buttonStyle}
                onClick={runAllFromBlock(element.id)}
                disabled={loading}
              ></Button>
            </Tooltip>
            {!readOnly && <Divider type="vertical" style={{ marginLeft: 3 }} />}
          </>
        )}
        {!readOnly && (
          <>
            {handleFullscreen && (
              <>
                <Tooltip title="Fullscreen Mode">
                  <Button
                    icon={<FullscreenOutlined />}
                    size="small"
                    style={buttonStyle}
                    onClick={handleFullscreen}
                    disabled={loading}
                  ></Button>
                </Tooltip>
                <Divider type="vertical" style={{ marginLeft: 3 }} />
              </>
            )}
            <Tooltip
              title={
                blockLimitReached
                  ? `Worksheet responsiveness may decrease with more than ${WORKSHEET_BLOCK_COUNT_LIMIT} blocks. Creating a new worksheet is recommended.`
                  : 'Add New Block'
              }
              overlayInnerStyle={{
                color: '#333333',
              }}
              color={blockLimitReached ? '#ffe58f' : '#ffffff'}
            >
              <Dropdown menu={addMenu} trigger={['click']} disabled={disabled}>
                <Button
                  icon={<PlusOutlined />}
                  size="small"
                  style={buttonStyle}
                  disabled={disabled}
                ></Button>
              </Dropdown>
            </Tooltip>
            <Tooltip
              title={!isBlockCollapsed ? 'Collapse Block' : 'Expand Block'}
            >
              <Button
                icon={
                  !isBlockCollapsed ? (
                    <VerticalAlignTopOutlined />
                  ) : (
                    <VerticalAlignBottomOutlined />
                  )
                }
                size="small"
                onClick={toggleBlockCollapsed(element)}
                style={buttonStyle}
                disabled={loading}
              ></Button>
            </Tooltip>
            <Tooltip title="Move Up">
              <Button
                icon={<UpOutlined />}
                size="small"
                onClick={debounceMoveUp}
                style={buttonStyle}
                disabled={
                  disabled || ReactEditor.findPath(editor, element)[0] === 0
                }
              ></Button>
            </Tooltip>
            <Tooltip title="Move Down">
              <Button
                icon={<DownOutlined />}
                size="small"
                onClick={debounceMoveDown}
                style={buttonStyle}
                disabled={
                  disabled ||
                  ReactEditor.findPath(editor, element)[0] === blocks.length - 1
                }
              ></Button>
            </Tooltip>
            <Popconfirm
              title={`Delete block ${
                ReactEditor.findPath(editor, element)[0] + 1
              }?`}
              onConfirm={handleDelete}
              disabled={disabled || blocks.length <= 1}
            >
              <Tooltip title="Delete Block" placement="right">
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  style={buttonStyle}
                  disabled={disabled || blocks.length <= 1}
                ></Button>
              </Tooltip>
            </Popconfirm>
          </>
        )}
      </div>
    </div>
  );
}
