// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_JOBS = gql`
  query Jobs($deployment_type: String) {
    endpoint_jobs {
      job_id
      status
      endpoint_name
      time_received
      auth_id
      source_ip
      user_data
      worker_statuses {
        rank
        status
      }
      flag
    }
    k8s_kineticaclusteradmins(deployment_type: $deployment_type) {
      kind
      apiVersion
      metadata
      spec
      status
    }
    k8s_kineticaclusterbackups(deployment_type: $deployment_type) {
      kind
      apiVersion
      metadata
      spec
      status
    }
    k8s_kineticaclusterrestores(deployment_type: $deployment_type) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const GET_ENDPOINT_JOBS = gql`
  query EndpointJobs {
    endpoint_jobs {
      job_id
      status
      endpoint_name
      time_received
      auth_id
      source_ip
      user_data
      worker_statuses {
        rank
        status
      }
      flag
    }
  }
`;

export const useGetJobs = options => {
  return useQuery(GET_JOBS, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useGetEndpointJobs = options => {
  return useQuery(GET_ENDPOINT_JOBS, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};
