// Imports
import React, {
  useMemo,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { Menu, Badge, Tooltip } from 'antd';
import {
  HomeOutlined,
  BulbOutlined,
  SwapOutlined,
  ControlOutlined,
  LogoutOutlined,
  UserOutlined,
  AppstoreOutlined,
  LockOutlined,
  LoadingOutlined,
  UnorderedListOutlined,
  BarChartOutlined,
  QuestionCircleOutlined,
  SlackOutlined,
  SaveOutlined,
  FileTextOutlined,
  GithubOutlined,
  LayoutOutlined,
  ShrinkOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';

// App Imports
import GraphQLServices from '../../graphql/services';
import { ClusterContext, useEveryPermission } from '../../context';
import UserPasswordModal from '../../components/modal/UserPasswordModal';
import UserUsageModal from '../../components/modal/UserUsageModal';
import KineticaLogo from '../../images/logo.svg';
import {
  NAV_ROUTE_MAPPING,
  LICENSE_EXPIRATION_WARNING_DAYS,
  TEST_IDS,
} from '../../constants';
import { DEPLOYMENT_TYPE, DEDICATED_SAAS } from '../../setup/config';
import { GET_LOCAL_USER_ME } from '../../graphql/schema/users';
import { dtId, getWarehouseStatus } from '../../helper';
import useCloudProvider from '../../hooks/useCloudProvider';
import { UserContext } from '../../context';
import { setTopBarCollapsed } from '../../store/app/actions';

const BRAND_WIDTH = 224;

const NavBar = () => {
  const userMe = useContext(UserContext);

  const { clusters } = useContext(ClusterContext);

  const [showUserPasswordModal, setShowUserPasswordModal] = useState(false);
  const [passwordUser, setPasswordUser] = useState(undefined);
  // const [releaseVersions, setReleaseVersions] = useState(null);
  const [showUserUsageModal, setShowUserUsageModal] = useState(false);
  const [upgrades, setUpgrades] = useState([]);

  const [hasWarehousePermission] = useEveryPermission(['manage_warehouse']);
  const [hasUpgradePermission] = useEveryPermission(['manage_warehouse']);
  const [hasUserPermission] = useEveryPermission(['manage_user']);

  const location = useLocation();
  const history = useHistory();
  const graphqlClient = useApolloClient();
  const cloudProvider = useCloudProvider();

  const dispatch = useDispatch();

  const isCloud = DEPLOYMENT_TYPE === 'cloud';

  useEffect(
    _ => {
      setTimeout(_ => {
        const fetch = async () => {
          const { data: { upgrades } = {} } = await graphqlClient.query({
            query: GraphQLServices.Upgrades.GET_UPGRADES,
            variables: {
              deployment_type: DEPLOYMENT_TYPE,
            },
          });
          setUpgrades(upgrades);
        };
        fetch();
      }, 3000);
    },
    [graphqlClient]
  );

  const docs = useMemo(() => {
    return !cloudProvider.toLowerCase().includes('onprem')
      ? `${cloudProvider}/`
      : '';
  }, [cloudProvider]);

  const setCollapsed = collapsed => _ => {
    dispatch(setTopBarCollapsed(collapsed));
  };

  const handleLogout = useCallback(
    _ => {
      history.push('/logout');
    },
    [history]
  );

  const handleUserUsage = useCallback(_ => {
    setShowUserUsageModal(true);
  }, []);

  const handleUserSetPassword = useCallback(
    async e => {
      const resp = await graphqlClient.query({
        query: GET_LOCAL_USER_ME,
      });
      setPasswordUser(resp?.data?.userMe);
      setShowUserPasswordModal(true);
    },
    [graphqlClient]
  );

  const handleUserPasswordCallback = useCallback(
    (err, resp) => {
      if (resp) {
        setShowUserPasswordModal(false);
      } else {
        console.error(err);
      }
    },
    [setShowUserPasswordModal]
  );

  const iconStyle = useMemo(_ => {
    return {
      fontSize: '18px',
      marginRight: '5px',
      verticalAlign: 'text-top',
    };
  }, []);
  const subIconStyle = useMemo(_ => {
    return {
      fontSize: '18px',
      marginRight: '0px',
      verticalAlign: 'text-top',
    };
  }, []);

  const menuSelectedKeys = [
    NAV_ROUTE_MAPPING[
      Object.keys(NAV_ROUTE_MAPPING)
        .sort(function (a, b) {
          return b.length - a.length;
        })
        .find(route => {
          return location.pathname.includes(route);
        })
    ],
  ];

  const upgradeAvailable = useMemo(
    _ => {
      return upgrades
        ? upgrades.some(
            upgrade => upgrade?.availableUpgradeVersions?.length > 0
          )
        : false;
      // return (
      //   releaseVersions &&
      //   releaseVersions.length > 0 &&
      //   releaseVersions[0] &&
      //   releaseVersions[0].length > 0
      // );
    },
    [upgrades]
  );

  const getStatusIndictor = useCallback(
    clusters => {
      return (
        clusters &&
        clusters.map((cluster, idx) => {
          const { phase, processing, status, color } =
            getWarehouseStatus(cluster);
          return processing ? (
            <span
              key={`${idx}_${cluster?.spec?.gpudbCluster?.clusterName}`}
              style={{ color }}
            >
              <LoadingOutlined style={{ color, ...iconStyle }} />
              {phase.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ')}
            </span>
          ) : (
            <Badge
              key={`${idx}_${cluster?.spec?.gpudbCluster?.clusterName}`}
              status={status}
              text={phase}
              style={{
                color: status === 'success' ? '#ffffff99' : color,
              }}
            />
          );
        })
      );
    },
    [iconStyle]
  );

  const cluster = useMemo(
    _ => {
      return clusters && clusters.length > 0 && clusters[0];
    },
    [clusters]
  );

  const version = useMemo(() => {
    if (cluster) {
      const versionRegex = /(\d+\.\d+\.\d+\.\d+)\.\d+/gi;
      const versionRegexMatches = versionRegex.exec(
        cluster?.status?.hmStatus?.version
      );
      return versionRegexMatches && versionRegexMatches.length === 2
        ? versionRegexMatches[1]
        : cluster?.status?.hmStatus?.version ?? <i>Unavailable</i>;
    }
    return <i>?</i>;
  }, [cluster]);

  const managementMenuItems = useMemo(
    _ => {
      const items = [];
      if (isCloud && hasWarehousePermission) {
        items.push({
          key: '/warehoure',
          label: (
            <Link to="/warehouse" {...dtId(TEST_IDS.NAV_MANAGEMENT_WAREHOUSE)}>
              <AppstoreOutlined style={iconStyle} />
              Cluster
            </Link>
          ),
        });
        // items.push({
        //   key: '/metrics',
        //   label: (
        //     <Link to="/metrics" {...dtId(TEST_IDS.NAV_METRICS)}>
        //       <BarChartOutlined style={iconStyle} />
        //       Metrics
        //     </Link>
        //   ),
        // });
      }
      if (isCloud && hasUserPermission) {
        items.push({
          key: '/users',
          label: (
            <Link to="/users">
              <UserOutlined style={iconStyle} />
              Users & Roles
            </Link>
          ),
        });
      }
      if (hasWarehousePermission) {
        items.push({
          key: '/settings',
          label: (
            <Link to="/settings">
              <LayoutOutlined style={iconStyle} />
              Workbench
            </Link>
          ),
        });
      }

      return items;
    },
    [hasWarehousePermission, hasUserPermission, iconStyle, isCloud]
  );

  const primaryMenuItems = useMemo(
    _ => {
      const items = [
        {
          key: '/home',
          label: (
            <Link to="/home" {...dtId(TEST_IDS.NAV_HOME)}>
              <HomeOutlined style={iconStyle} />
              Home
            </Link>
          ),
        },
        {
          key: '/dataexplore',
          label: (
            <Link to="/dataexplore" {...dtId(TEST_IDS.NAV_DATA_EXPLORATION)}>
              <BulbOutlined style={iconStyle} />
              Explore
            </Link>
          ),
        },
        {
          key: '/importexport',
          label: (
            <Link to="/importexport" {...dtId(TEST_IDS.NAV_IMPORT)}>
              <SwapOutlined style={iconStyle} />
              Import
            </Link>
          ),
        },
        {
          key: '/jobs',
          label: (
            <Link to="/jobs" {...dtId(TEST_IDS.NAV_JOBS)}>
              <UnorderedListOutlined style={iconStyle} />
              Jobs
            </Link>
          ),
        },
      ];

      if (hasWarehousePermission || hasUserPermission) {
        items.push({
          key: '/management',
          label: (
            <span {...dtId(TEST_IDS.NAV_MANAGEMENT)}>
              <ControlOutlined style={iconStyle} /> Manage
            </span>
          ),
          children: managementMenuItems,
          popupOffset: [-20, 0],
        });
      }

      return items;
    },
    [hasUserPermission, hasWarehousePermission, iconStyle, managementMenuItems]
  );

  const helpMenuItems = useMemo(
    _ => {
      const items = [
        {
          key: 'docs',
          label: (
            <>
              <FileTextOutlined style={iconStyle} />
              <a
                href={`https://docs.kinetica.com/7.2/${docs}`}
                target="_blank"
                rel="noreferrer"
              >
                Documentation
              </a>
            </>
          ),
        },
        {
          key: 'github',
          label: (
            <>
              <GithubOutlined style={iconStyle} />
              <a
                href="https://github.com/kineticadb"
                target="_blank"
                rel="noreferrer"
              >
                Kinetica GitHub
              </a>
            </>
          ),
        },
        {
          key: 'youtube',
          label: (
            <>
              <YoutubeOutlined style={iconStyle} />
              <a
                href="https://youtube.com/@KineticaDB/videos"
                target="_blank"
                rel="noreferrer"
              >
                Kinetica YouTube
              </a>
            </>
          ),
        },
        {
          key: 'slack',
          label: (
            <>
              <SlackOutlined style={iconStyle} />
              <a
                href="https://kinetica.com/slack"
                target="_blank"
                rel="noreferrer"
              >
                Kinetica Community Slack
              </a>
            </>
          ),
        },
        {
          key: 'version',
          label: (
            <>
              <SaveOutlined style={iconStyle} />
              Version {version}
            </>
          ),
          style: { cursor: 'not-allowed' },
        },
      ];

      return items;
    },
    [docs, iconStyle, version]
  );

  const userMenuItems = useMemo(
    _ => {
      const items = [
        {
          key: 'usage',
          label: (
            <span {...dtId(TEST_IDS.NAV_USER_USAGE)}>
              <BarChartOutlined style={iconStyle} />
              Usage
            </span>
          ),
          onClick: handleUserUsage,
        },
      ];

      if (isCloud) {
        items.push({
          key: 'password',
          label: (
            <span {...dtId(TEST_IDS.NAV_USER_PASSWORD)}>
              <LockOutlined style={iconStyle} />
              Password
            </span>
          ),
          onClick: handleUserSetPassword,
        });
      }

      items.push({
        key: 'logout',
        label: (
          <span {...dtId(TEST_IDS.NAV_USER_LOGOUT)}>
            <LogoutOutlined style={iconStyle} />
            Logout
          </span>
        ),
        onClick: handleLogout,
      });

      return items;
    },
    [handleLogout, handleUserSetPassword, handleUserUsage, isCloud, iconStyle]
  );

  const license = useMemo(
    _ => {
      return cluster.license_info;
    },
    [cluster]
  );

  const expiring = useMemo(
    _ => {
      if (license) {
        const cutoffDate = addDays(new Date(), LICENSE_EXPIRATION_WARNING_DAYS);
        const expireDate = parseISO(license.expiration);
        return isBefore(expireDate, cutoffDate);
      }
      return false;
    },
    [license]
  );

  const expired = useMemo(
    _ => {
      if (license) {
        const expireDate = parseISO(license.expiration);
        return isBefore(expireDate, new Date());
      }
      return false;
    },
    [license]
  );

  const secondaryMenuItems = useMemo(
    _ => {
      const items = [];

      if (
        isCloud &&
        upgradeAvailable &&
        hasUpgradePermission &&
        !DEDICATED_SAAS
      ) {
        items.push({
          key: '/warehouse/upgrade',
          label: (
            <Link to="/warehouse/upgrade">
              <Tooltip title="New Version Available!">
                <Badge
                  status="warning"
                  text="Upgrade"
                  style={{ color: 'rgba(255, 255, 255, 0.65)' }}
                />
              </Tooltip>
            </Link>
          ),
        });
      }

      if (
        isCloud &&
        hasWarehousePermission &&
        expiring &&
        license.key !== 'payg'
      ) {
        items.push({
          key: '/warehouse/license',
          label: (
            <Tooltip
              title={
                expired
                  ? `License expired on ${format(
                      parseISO(license.expiration),
                      'MM/dd/yyyy'
                    )}`
                  : `License expiring on ${format(
                      parseISO(license.expiration),
                      'MM/dd/yyyy'
                    )}`
              }
            >
              <Badge
                status="error"
                text="License"
                style={{
                  color: 'rgba(255, 255, 255, 0.65)',
                  cursor: 'default',
                }}
              />
            </Tooltip>
          ),
          style: { backgroundColor: '#c62828' },
        });
      }

      if (isCloud && hasWarehousePermission) {
        items.push({
          key: '/warehouse/overview',
          label: (
            <Link to="/warehouse/overview">{getStatusIndictor(clusters)}</Link>
          ),
        });
      } else {
        items.push({
          key: '/warehouse/overview',
          label: getStatusIndictor(clusters),
        });
      }

      items.push({
        key: 'help',
        label: (
          <span>
            <QuestionCircleOutlined style={subIconStyle} />
          </span>
        ),
        children: helpMenuItems,
        popupOffset: [-20, 0],
      });

      items.push({
        key: 'user',
        label: (
          <span {...dtId(TEST_IDS.NAV_USER)}>
            <UserOutlined style={subIconStyle} /> {userMe?.username}
          </span>
        ),
        children: userMenuItems,
        style: { minWidth: 116 },
        popupOffset: [-20, 0],
      });

      return items;
    },
    [
      clusters,
      userMe,
      hasUpgradePermission,
      hasWarehousePermission,
      upgradeAvailable,
      getStatusIndictor,
      helpMenuItems,
      userMenuItems,
      subIconStyle,
      isCloud,
      expiring,
      expired,
      license,
    ]
  );

  return (
    <>
      {clusters &&
        clusters.map((cluster, idx) => {
          const { status, color } = getWarehouseStatus(cluster);
          return status !== 'success' ? (
            <div
              key={`${idx}_${cluster?.spec?.gpudbCluster?.clusterName}`}
              style={{
                height: '5px',
                position: 'absolute',
                zIndex: 1,
                width: '100%',
                backgroundColor: color,
              }}
            ></div>
          ) : null;
        })}
      <div
        style={{
          width: `${BRAND_WIDTH + 25}px`,
          display: 'block',
          float: 'left',
        }}
      >
        <Link to="/" {...dtId(TEST_IDS.NAV_LOGO)}>
          <img
            src={KineticaLogo}
            alt="Kinetica Logo"
            style={{
              height: '26px',
              position: 'relative',
              top: '-3px',
              margin: '0px 20px',
            }}
          />
        </Link>
        <ShrinkOutlined
          onClick={setCollapsed(true)}
          style={{
            fontSize: '20px',
            margin: '15px 18px 0 0',
            borderRadius: '12px',
            color: '#ffffff33',
            float: 'right',
            zIndex: 1000,
          }}
        />
      </div>
      <div
        style={{
          width: `560px`,
          display: 'block',
          float: 'left',
        }}
      >
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={menuSelectedKeys}
          items={primaryMenuItems}
        ></Menu>
      </div>
      <div
        style={{
          width: `calc(100% - ${BRAND_WIDTH + 560 + 25}px)`,
          display: 'block',
          float: 'right',
        }}
      >
        <Menu
          theme="dark"
          mode="horizontal"
          className="right-menu"
          selectedKeys={menuSelectedKeys}
          items={secondaryMenuItems}
          disabledOverflow={true}
          style={{ float: 'right' }}
        ></Menu>
      </div>
      {showUserPasswordModal && passwordUser && cluster && (
        <UserPasswordModal
          cluster={cluster}
          user={passwordUser}
          mypassword={true}
          visible={showUserPasswordModal}
          close={_ => {
            setShowUserPasswordModal(false);
          }}
          callback={handleUserPasswordCallback}
        />
      )}
      {showUserUsageModal && cluster && (
        <UserUsageModal
          visible={showUserUsageModal}
          close={_ => {
            setShowUserUsageModal(false);
          }}
        />
      )}
    </>
  );
};

export default NavBar;
