// Imports
import React, { useEffect, useRef } from 'react';
import {
  notification,
  Radio,
  Select,
  Input,
  Tooltip,
  Button,
  Divider,
  message,
} from 'antd';
import { format } from 'date-fns';
import { InfoCircleOutlined } from '@ant-design/icons';
import { toPng } from 'html-to-image';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  DEFAULT_TABPANE_HEIGHT,
  DEFAULT_TABPANE_NOHEAD_HEIGHT,
} from './constants';

const { Option } = Select;

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

const NUMERIC_COLUMN_TYPES = [
  'int',
  'int8',
  'int16',
  'long',
  'float',
  'double',
  'decimal',
  'ulong',
];

export const isNumericColumnType = type => {
  return NUMERIC_COLUMN_TYPES.indexOf(type) > -1;
};

export const isNumericTimestampColumnType = type => {
  return [...NUMERIC_COLUMN_TYPES, 'timestamp'].indexOf(type) > -1;
};

export const processExecuteSqlResults = result => {
  return result.column_1.map((val, idx) => {
    return result.column_headers.reduce((acc, cur, idx2) => {
      acc['__key'] = idx;
      acc[cur] = result[`column_${idx2 + 1}`][idx];
      return acc;
    }, {});
  });
};

export const objHasMatch = (obj, filterStr, keys = []) => {
  return obj
    ? Object.keys(obj).some(key => {
        if (Array.isArray(obj[key])) {
          return obj[key].some(obj2 => {
            return objHasMatch(obj2, filterStr, keys);
          });
        } else if (typeof obj[key] === 'string') {
          if (keys.length > 0) {
            return (
              keys.includes(key) &&
              obj[key].toLowerCase().includes(filterStr.toLowerCase())
            );
          } else {
            return obj[key].toLowerCase().includes(filterStr.toLowerCase());
          }
        } else {
          return objHasMatch(obj[key], filterStr, keys);
        }
      })
    : false;
};

export const objHasMatchArray = (obj, filters = [], keys = []) => {
  return obj
    ? Object.keys(obj).some(key => {
        if (Array.isArray(obj[key])) {
          return obj[key].some(obj2 => {
            return objHasMatchArray(obj2, filters, keys);
          });
        } else if (typeof obj[key] === 'string') {
          if (keys.length > 0) {
            return (
              keys.includes(key) &&
              filters.every(filter =>
                obj[key].toLowerCase().includes(filter.toLowerCase())
              )
            );
          } else {
            return filters.every(filter =>
              obj[key].toLowerCase().includes(filter.toLowerCase())
            );
          }
        } else {
          return objHasMatchArray(obj[key], filters, keys);
        }
      })
    : false;
};

export const displaySuccess = description => {
  notification.success({
    message: 'Success',
    description,
  });
};

const generateErrorDescription = content => {
  return (
    <>
      {content}
      <Divider style={{ margin: '15px 0' }} dashed />
      <CopyToClipboard
        key="copytoclipboard"
        text={content.join('\n\n')}
        onCopy={() => {
          message.success('Copied to clipboard!');
        }}
      >
        <Button key="submit" type="default" size="small">
          Copy To Clipboard
        </Button>
      </CopyToClipboard>
    </>
  );
};

const ERROR_MESSAGE_DEFAULTS = {
  message: 'Error has occurred',
  duration: 0,
  style: {
    width: 460,
  },
};

export const displayError = error => {
  if (error.errors) {
    if (Array.isArray(error.errors)) {
      notification.error({
        ...ERROR_MESSAGE_DEFAULTS,
        description: generateErrorDescription(
          error.errors.map((err, idx) => {
            return (
              <div key={idx} style={{ marginBottom: '10px' }}>
                {err?.message}
              </div>
            );
          })
        ),
      });
    } else {
      notification.error({
        ...ERROR_MESSAGE_DEFAULTS,
        description: generateErrorDescription([error.errors.toString()]),
      });
    }
  } else {
    notification.error({
      ...ERROR_MESSAGE_DEFAULTS,
      description: generateErrorDescription([error.toString()]),
    });
  }
};

export const displayWarning = warning => {
  if (Array.isArray(warning)) {
    notification.warning({
      message: 'Warning',
      description: warning.map((warn, idx) => {
        return (
          <div key={idx} style={{ marginBottom: '10px' }}>
            {warn}
          </div>
        );
      }),
    });
  } else {
    notification.warning({
      message: 'Warning',
      description: warning.toString(),
    });
  }
};

export const padZero = (str, len) => {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
};

export const invertColor = (hex, bw) => {
  if (!hex) {
    return '000000';
  }
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '000000' : 'FFFFFF';
  }
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  return padZero(r) + padZero(g) + padZero(b);
};

export const getPermissionList = (obj = {}) => {
  const set = Object.keys(obj).reduce((acc, cur) => {
    if (cur === 'permissions') {
      return acc.concat(obj[cur]);
    } else if (cur === 'roles') {
      return acc.concat(obj[cur].map(role => getPermissionList(role)).flat());
    }
    return acc;
  }, []);
  return Array.from(new Set(set));
};

export const getSystemPermissionList = (obj = {}) => {
  const set = Object.keys(obj).reduce((acc, cur) => {
    if (cur === 'system_permissions') {
      return acc.concat(obj[cur]);
    } else if (cur === 'kinetica_roles') {
      return acc.concat(
        obj[cur]
          .map(kinetica_role => getSystemPermissionList(kinetica_role))
          .flat()
      );
    }
    return acc;
  }, []);
  return Array.from(new Set(set));
};

export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;
  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );
  return bytes.toFixed(dp) + ' ' + units[u];
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const dtId = id => ({ 'data-test-id': id });

const usePrevious = (value, initialValue) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useEffectDebugger = (
  effectHook,
  dependencies,
  dependencyNames = []
) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectHook, dependencies);
};

export const checkPassword = password => {
  const strUpperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const strLowerCase = 'abcdefghijklmnopqrstuvwxyz';
  const strNumber = '0123456789';

  const countContain = (strPassword, strCheck) => {
    let nCount = 0;
    for (let i = 0; i < strPassword.length; i++) {
      if (strCheck.indexOf(strPassword.charAt(i)) > -1) {
        nCount++;
      }
    }
    return nCount;
  };

  if (password.length < 8) {
    return false;
  }
  if (countContain(password, strUpperCase) < 1) {
    return false;
  }
  if (countContain(password, strLowerCase) < 1) {
    return false;
  }
  if (countContain(password, strNumber) < 1) {
    return false;
  }

  return true;
};

export const getWarehouseStatus = cluster => {
  const { phase = 'Unknown' } = cluster?.status;
  const processing =
    (phase.endsWith('ing') && phase !== 'Running') || phase === 'InProgress';

  const COLORS = {
    warning: '#faad14',
    success: '#5bd000',
    info: '#1890ff',
  };

  let status = 'default';
  if (phase === 'Running') {
    status = 'success';
  } else if (phase.includes('Upgrading')) {
    status = 'info';
  } else {
    status = 'warning';
  }

  const color = COLORS[status];

  const disableSuspend =
    phase === 'Pausing' ||
    phase === 'Paused' ||
    phase === 'Suspending' ||
    phase === 'Suspended' ||
    processing;
  const disableResume =
    (phase !== 'Paused' && phase !== 'Suspended') || processing;

  const isUpgrading = phase.includes('Upgrading');

  let mappedPhase;
  switch (phase) {
    case 'Pausing': {
      mappedPhase = 'Suspending';
      break;
    }
    case 'Paused': {
      mappedPhase = 'Suspended';
      break;
    }
    default: {
      mappedPhase = phase;
    }
  }

  return {
    phase: mappedPhase,
    processing,
    status,
    color,
    disableSuspend,
    disableResume,
    isUpgrading,
  };
};

export const getTimestampKey = _ => {
  return format(new Date(), 'yyyyMMdd_HHmmss');
};

export const getGraphComponent = (field, grammar, mapping) => {
  return grammar.components?.find(component => {
    if (mapping[field.name]) {
      return component.name.toLowerCase() === mapping[field.name].toLowerCase();
    }
    return component.name.toLowerCase() === field.name;
  });
};

export const isGraphComponent = (field, grammar, mapping) => {
  return getGraphComponent(field, grammar, mapping) != null;
};

export const getFieldInfo = doc => {
  return doc ? (
    <span style={{ marginLeft: '5px' }}>
      <Tooltip
        title={doc}
        overlayInnerStyle={{
          width: '400px',
          fontSize: '14px',
          padding: '10px 15px',
        }}
      >
        <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.40)' }} />
      </Tooltip>
    </span>
  ) : (
    ''
  );
};

export const getGraphField = (field, doc) => {
  if (['boolean'].includes(field.type)) {
    return (
      <Radio.Group buttonStyle="solid">
        <Radio.Button value={true}>True</Radio.Button>
        <Radio.Button value={false}>False</Radio.Button>
      </Radio.Group>
    );
  } else if (['int'].includes(field.type)) {
    return <Input type="number" />;
  } else if (field.value?.valid_choices) {
    return (
      <Select style={{ fontSize: '13px' }}>
        {Object.keys(field.value.valid_choices).map(choice => (
          <Option key={choice} value={choice}>
            {choice}
          </Option>
        ))}
      </Select>
    );
  }
  return <Input type="text" />;
};

export const formatGraphIdentifiers = identifiers => {
  return identifiers.join(', ');
};

export const buildGraphComponentParams = (component = []) => {
  const output = [];
  component.forEach((configuration, index) => {
    configuration.set.forEach(identifier => {
      if (identifier.value) {
        output.push(identifier.value + ' AS ' + identifier.name);
      }
    });
    if (index < component.length - 1) {
      output.push('');
    }
  });
  return output;
};

export const cleanGraphOptions = options => {
  return Object.keys(options).reduce((acc, cur) => {
    if (typeof options[cur] !== 'undefined' && options[cur] !== '') {
      if (typeof options[cur] === 'boolean') {
        acc[cur] = options[cur].toString();
      } else {
        acc[cur] = options[cur];
      }
    }
    return acc;
  }, {});
};

export const download = (dataUrl, fileName) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = dataUrl;
  link.click();
};

export const downloadPng = (imageId, exludes = ['no-png']) => {
  toPng(document.getElementById(imageId), {
    cacheBust: true,
    filter: node => {
      return !exludes.some(classname => node.classList?.contains(classname));
    },
  })
    .then(function (dataUrl) {
      download(dataUrl, imageId);
    })
    .catch(err => {
      console.error(err);
    });
};

export const getTabContentHeight = (topBarCollapsed, embed) => {
  return topBarCollapsed
    ? embed
      ? `calc(100vh - ${115 - 50}px)`
      : DEFAULT_TABPANE_NOHEAD_HEIGHT
    : embed
    ? `calc(100vh - ${115}px)`
    : DEFAULT_TABPANE_HEIGHT;
};

// https://wyattades.github.io/java-to-javascript/
export const splitQuery = function (input) {
  var ReferenceCount = (function () {
    function ReferenceCount() {
      this.i = 0;
    }

    ReferenceCount.prototype.count = function () {
      return this.i;
    };

    ReferenceCount.prototype.Increment = function () {
      ++this.i;
    };

    return ReferenceCount;
  })();

  var Splitter = (function () {
    class Splitter {
      indexOf(s, find, start) {
        if (typeof find === 'object') {
          const match = find.exec(s.substring(start));
          if (match) {
            return start + match.index + 1;
          }
          return s.length + 1;
        }
        var f = s.indexOf(find, start);
        return f < 0 ? s.length + 1 : f + find.length;
      }
      findUnescapedQuote(query, quote, start) {
        let len = query.length;
        let i = this.indexOf(query, quote, start);
        if (i <= len && '\\' === query.charAt(i - 2)) {
          i = start;
          let e = this.indexOf(query, '\\', i);
          let q = this.indexOf(query, quote, i);
          while (e < q && q <= len) {
            i = e + 1;
            e = this.indexOf(query, '\\', i);
            q = this.indexOf(query, quote, i);
          }
          i = q;
        }
        return i;
      }
      nextQuery(query, start, parameterCount) {
        const whiteSpaceExpr = '(?:\\s|\\/\\*.*?\\*\\/)+';
        const createProcedurePattern = new RegExp(
          '\\bCREATE' +
            whiteSpaceExpr +
            '(?:OR' +
            whiteSpaceExpr +
            'REPLACE' +
            whiteSpaceExpr +
            ')?PROCEDURE\\b',
          'i'
        );
        const caseStartPattern = new RegExp('\\bCASE\\b', 'i');
        const endPattern = new RegExp('\\bEND\\b', 'i');

        if (start < 0 || start >= query.length) {
          return -1;
        }
        let len = query.length;
        let i = start;
        let caseCount = 0;
        let procedureCount = 0;
        while (i < len) {
          let sc = this.indexOf(query, ';', i);
          let qm = this.indexOf(query, '?', i);
          let cp = this.indexOf(query, createProcedurePattern, i);
          if (sc >= len && qm > len && cp > len && procedureCount < 1) {
            i = len;
            break;
          }
          let sq = this.indexOf(query, "'", i);
          let dq = this.indexOf(query, "'", i);
          let lc = this.indexOf(query, '--', i);
          let cc = this.indexOf(query, '/*', i);
          let ca = this.indexOf(query, caseStartPattern, i);
          let ed = this.indexOf(query, endPattern, i);
          if (
            qm < sc &&
            qm < sq &&
            qm < dq &&
            qm < lc &&
            qm < cc &&
            qm < cp &&
            qm < ca &&
            qm < ed
          ) {
            parameterCount.Increment();
            i = qm;
          } else {
            if (
              cp < sc &&
              cp < sq &&
              cp < dq &&
              cp < lc &&
              cp < ca &&
              cp < ed
            ) {
              ++procedureCount;
              i = cp;
            } else {
              if (
                ca < sc &&
                ca < sq &&
                ca < dq &&
                ca < lc &&
                ca < cc &&
                ca < ed
              ) {
                if (procedureCount > 0) {
                  ++caseCount;
                }
                i = ca;
              } else {
                if (ed < sc && ed < sq && ed < dq && ed < lc && ed < cc) {
                  if (caseCount > 0) {
                    --caseCount;
                  } else {
                    if (procedureCount > 0) {
                      --procedureCount;
                    }
                  }
                  i = ed;
                } else {
                  if (sq < sc && sq < dq && sq < lc && sq < cc) {
                    i = this.findUnescapedQuote(query, "'", sq);
                    if (i > len) {
                      throw new Error(
                        'Unmatched single quote at position ' + sq
                      );
                    }
                  } else {
                    if (dq < sc && dq < lc && dq < cc) {
                      i = this.findUnescapedQuote(query, "'", dq);
                      if (i > len) {
                        throw new Error(
                          'Unmatched double quote at position ' + dq
                        );
                      }
                    } else {
                      if (lc < sc && lc < cc) {
                        i = Math.min(
                          this.indexOf(query, '\n', lc),
                          this.indexOf(query, '\r', lc)
                        );
                      } else {
                        if (cc < sc) {
                          i = this.indexOf(query, '*/', cc);
                          if (i > len) {
                            throw new Error(
                              'Unended comment at position ' + cc
                            );
                          }
                        } else {
                          i = sc;
                          if (procedureCount < 1) {
                            break;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        if (procedureCount > 0) {
          throw new Error('No END found for procedure');
        }
        return Math.min(i, len);
      }
    }
    return Splitter;
  })();

  var splitter = new Splitter();
  var refCount = new ReferenceCount();
  var queries = [];
  var currentSplitIndex = 0;

  while (splitter.nextQuery(input, currentSplitIndex, refCount) !== -1) {
    var query = input.substring(
      currentSplitIndex,
      splitter.nextQuery(input, currentSplitIndex, refCount)
    );

    queries.push(query.trim());

    currentSplitIndex = splitter.nextQuery(input, currentSplitIndex, refCount);
  }

  return queries;
};
