// Imports
import numeral from 'numeral';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import fromUnixTime from 'date-fns/fromUnixTime';
import formatDistance from 'date-fns/formatDistance';
import { MAX_JS_NUMBER_PRECISION_LENGTH } from './constants';

export const formatFriendlyNumberWhole = value => {
  return value !== '' ? numeral(value).format('0,0') : value;
};

export const formatFriendlyNumberDecimal = value => {
  return value !== '' ? numeral(value).format('0,0.0[00]') : value;
};

export const formatMemory = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const formatCapitalizeFirstLetter = value => {
  if (typeof value !== 'string') return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const formatFriendlyColumnName = name => {
  return name
    .split('_')
    .map(val => formatCapitalizeFirstLetter(val))
    .join(' ');
};

export const formatTimestamp = value => {
  return format(fromUnixTime(Number(value)), 'yyyy-MM-dd hh:mm:ss aaa');
};

export const formatChartTimestamp = value => {
  return format(fromUnixTime(Number(value)), 'yy-MM-dd hh:mm aaa');
};

export const formatTimestampShort = value => {
  return format(fromUnixTime(Number(value)), 'yyyy-MM-dd HH:mm');
};

export const formatTimestampDistance = value => {
  return formatDistance(fromUnixTime(Number(value) / 1000), new Date());
};

export const formatK8sTimestamp = value => {
  return format(parseISO(value), 'yyyy-MM-dd HH:mm:ss');
};
export const formatK8sTimestampNoSecond = value => {
  return format(parseISO(value), 'yyyy-MM-dd HH:mm');
};

export const bytesToMegabytes = bytes => {
  if (bytes === 0) return '0.00';
  return (bytes / Math.pow(1024, 2)).toFixed(2);
};

export const capitalize = value => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const capitalizeSnakecase = string => {
  return string
    .split('_')
    .map(value => {
      return capitalize(value);
    })
    .join(' ');
};

export const truncateLong = input => {
  if (input != null) {
    var inputLen = input.toString().length;
    if (inputLen > MAX_JS_NUMBER_PRECISION_LENGTH) {
      // Need to truncate
      return (
        input.toString().substring(0, MAX_JS_NUMBER_PRECISION_LENGTH) +
        '##########'
      ).substring(0, inputLen);
    }
    return input;
  }
  return null;
};

export const formatWorkerStatuses = statuses => {
  const hasStatus =
    statuses.length > 0 && statuses.some(info => info.status !== null);
  const workersRunning = statuses.filter(worker => worker.status === 'running');
  return hasStatus ? (
    `${workersRunning.length}/${statuses.length} running`
  ) : (
    <span style={{ color: '#CCCCCC' }}>unknown</span>
  );
};

export const formatTimeReceived = ts => {
  return (
    <div
      style={{
        letterSpacing: '-0.5px',
        fontSize: '12px',
        lineHeight: '14px',
        width: 75,
      }}
    >
      {formatTimestamp(ts)}
    </div>
  );
};
