// Imports
import React, { useState, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spin, Tabs, Form, Row, Col, Input, Radio, Select, Empty } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import {
  DATASOURCE_LOCATIONS,
  DATASOURCE_LOCATION_CONFLUENT,
  DATASOURCE_CONFLUENT_PREFIX,
  DATASOURCE_CONFLUENT_AUTH_NO_AUTH,
  DATASOURCE_CONFLUENT_AUTH_SASL_SSL,
  DATASOURCE_CONFLUENT_AUTH_TYPES,
  DEFAULT_TABPANE_HEIGHT,
  DEFAULT_TABPANE_NOHEAD_HEIGHT,
} from '../../constants';
import Spinner from '../../components/common/Spinner';
import ImportForm from './ImportForm';
import ImportHistory from './ImportHistory';
import ConfluentImage from '../../images/import/confluent.svg';
import ConfluentFullImage from '../../images/import/confluent_full.svg';
import { DEFAULT_FORM_ITEM_PROPS } from './utils';
import { getTimestampKey } from '../../helper';
import { HEADER_LOGO_STYLE } from './constants';

const Setup = ({
  form,
  setupForm,
  setIsCreating,
  setFormValues: setParentFormValues,
  setStep,
}) => {
  const { data: { datasources = [] } = {}, refetch: refetchDatasources } =
    GraphQLServices.Datasources.useGetDatasources();
  const [createCredential] = GraphQLServices.Credentials.useCreateCredential();
  const [createDatasource] = GraphQLServices.Datasources.useCreateDatasource();

  const [formValues, setFormValues] = useState({
    confluent_auth_type: DATASOURCE_CONFLUENT_AUTH_NO_AUTH,
  });

  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(
    _ => {
      if (location.state) {
        if (location.state.datasource) {
          setupForm.setFieldsValue({
            mode: 'existing',
            datasource: location.state.datasource.datasource_name,
          });
          setFormValues({
            mode: 'existing',
            datasource: location.state.datasource.datasource_name,
          });
          setParentFormValues({
            datasource: location.state.datasource.datasource_name,
          });
        }
      }
    },
    [location, setParentFormValues, setupForm]
  );

  const onFinish = async values => {
    const variables = Object.keys(values).reduce((acc, cur) => {
      if (values[cur]) {
        acc[cur] = values[cur];
      }
      return acc;
    }, {});

    if (variables.location?.includes(DATASOURCE_LOCATION_CONFLUENT)) {
      variables.location =
        DATASOURCE_CONFLUENT_PREFIX + variables.location_confluent;
    }

    setIsCreating(true);

    // Need to create credential first
    if (variables?.confluent_auth_type === DATASOURCE_CONFLUENT_AUTH_SASL_SSL) {
      const name = `${variables.name}_credential_autogen_${getTimestampKey()}`;
      const variables2 = {
        name,
        type: DATASOURCE_LOCATION_CONFLUENT,
        identity: '',
        secret: '',
        security_protocol: DATASOURCE_CONFLUENT_AUTH_SASL_SSL.toUpperCase(),
        sasl_mechanism: 'PLAIN',
        sasl_username: variables.user_name,
        sasl_password: variables.password,
      };
      const createCredResp = await createCredential({
        variables: variables2,
      });
      if (createCredResp?.errors) {
        setIsCreating(false);
        return;
      }

      variables.credential = name;
      variables.user_name = undefined;
      variables.password = undefined;
    }

    // Create schema registry credential
    if (
      variables?.schema_registry_user_name !== '' &&
      variables?.schema_registry_password !== ''
    ) {
      const name = `${
        variables.name
      }_sr_credential_autogen_${getTimestampKey()}`;
      const variables3 = {
        name,
        type: DATASOURCE_LOCATION_CONFLUENT,
        identity: '',
        secret: '',
        security_protocol: DATASOURCE_CONFLUENT_AUTH_SASL_SSL.toUpperCase(),
        sasl_mechanism: 'PLAIN',
        sasl_username: variables.schema_registry_user_name,
        sasl_password: variables.schema_registry_password,
      };
      const createCredResp = await createCredential({
        variables: variables3,
      });
      if (createCredResp?.errors) {
        setIsCreating(false);
        return;
      }

      variables.schema_registry_credential = name;
      variables.schema_registry_user_name = undefined;
      variables.schema_registry_password = undefined;
    }

    createDatasource({
      variables,
    })
      .then(resp => {
        const { datasource_name: datasource } = resp?.data?.datasourceCreate;
        setIsCreating(false);
        refetchDatasources().then(_ => {
          if (datasource) {
            setupForm.resetFields();
            setupForm.setFieldsValue({
              mode: 'existing',
              datasource,
            });
            form.setFieldsValue({
              dataSource: datasource,
              fileFormat: 'JSON',
            });

            analytics.track(analytics.EVENT_TYPES.CREATED_DATA_SOURCE)({});

            setStep(1);
          }
        });
      })
      .catch(err => {
        setIsCreating(false);
        refetchDatasources();
      });
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.mode) {
      setupForm.setFieldsValue({
        datasource: undefined,
      });
    }
    setFormValues({
      ...allValues,
      confluent_auth_type:
        allValues.confluent_auth_type || DATASOURCE_CONFLUENT_AUTH_NO_AUTH,
    });
    setParentFormValues({
      ...allValues,
    });
  };

  const handleDatasourceChange = datasource => {
    form.setFieldsValue({
      dataSource: datasource,
      fileFormat: 'JSON',
    });
    setFormValues({
      datasource,
    });
  };

  const datasourceOptions = useMemo(
    _ => {
      return datasources
        ? datasources
            .filter(datasource => {
              return (
                datasource.storage_provider_type.toLowerCase() ===
                DATASOURCE_LOCATION_CONFLUENT
              );
            })
            .map(datasource => ({
              label: datasource.datasource_name,
              value: datasource.datasource_name,
            }))
        : [];
    },
    [datasources]
  );

  return (
    <Form
      form={setupForm}
      name="file"
      layout="horizontal"
      initialValues={{ mode: '', location: DATASOURCE_LOCATION_CONFLUENT }}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      preserve={false}
      {...DEFAULT_FORM_ITEM_PROPS}
    >
      <Row gutter={0}>
        <Col span={4}>
          <img
            src={ConfluentFullImage}
            style={{
              height: '90px',
              maxWidth: '100%',
              float: 'right',
              margin: '0px 0px 0px 0px',
            }}
            alt="Confluent"
          />
        </Col>
        <Col span={18}>
          <h3>Directions</h3>
          <p>
            Optionally create a new Confluent data source, select a data source
            (new or existing), and click Next (or Create) to continue.
          </p>
          <h3>What would you like to do?</h3>
          <Form.Item label="" name="mode">
            <Radio.Group>
              <Radio.Button value="new">Create New Data Source</Radio.Button>
              <Radio.Button value="existing">
                Select Existing Data Source
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {setupForm.getFieldValue('mode') === 'new' && (
        <>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input data source name!',
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: 'Please select data source location!',
              },
            ]}
            hidden
          >
            <Radio.Group options={DATASOURCE_LOCATIONS} optionType="button" />
          </Form.Item>
          <Form.Item
            label="URL"
            name="location_confluent"
            rules={[
              {
                required: true,
                message: 'Please input Confluent host:port!',
              },
            ]}
          >
            <Input
              addonBefore={DATASOURCE_CONFLUENT_PREFIX}
              placeholder="<host>:<port>"
            />
          </Form.Item>
          <Form.Item
            label="Topic Name"
            name="kafka_topic_name"
            rules={[
              {
                required: true,
                message: 'Please input Confluent Topic Name!',
              },
            ]}
          >
            <Input placeholder="<confluent topic name>" />
          </Form.Item>
          <Form.Item
            label="Auth Type"
            name="confluent_auth_type"
            initialValue={DATASOURCE_CONFLUENT_AUTH_NO_AUTH}
          >
            <Radio.Group
              options={DATASOURCE_CONFLUENT_AUTH_TYPES}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
          {formValues?.confluent_auth_type ===
            DATASOURCE_CONFLUENT_AUTH_SASL_SSL && (
            <Row>
              <Col span={12}>
                <Form.Item
                  label="API Key"
                  name="user_name"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Input placeholder="<confluent sasl api key>" />
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Secret"
                  name="password"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Input
                    type="password"
                    placeholder="<confluent sasl secret>"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={4}></Col>
            <Col span={20}>
              <h4>Schema Registry</h4>
            </Col>
          </Row>
          <Form.Item
            label="URL"
            name="schema_registry_location"
            rules={[
              {
                required: false,
                message: 'Please input schema registry host!',
              },
            ]}
          >
            <Input placeholder="<host>" />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item
                label="API Key"
                name="schema_registry_user_name"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: formValues.schema_registry_location,
                    message: 'Please input schema registry api key!',
                    whitespace: true,
                  },
                ]}
              >
                <Input placeholder="<schema registry sasl api key>" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="Secret"
                name="schema_registry_password"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    required: formValues.schema_registry_location,
                    message: 'Please input schema registry secret!',
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  type="password"
                  placeholder="<schema registry sasl secret>"
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      {setupForm.getFieldValue('mode') === 'existing' && (
        <>
          <Form.Item
            label="Data Source"
            name="datasource"
            {...DEFAULT_FORM_ITEM_PROPS}
          >
            <Select
              onChange={handleDatasourceChange}
              placeholder="Select a data source"
              notFoundContent={
                <Empty
                  description="No Confluent data source available"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              }
            >
              {datasourceOptions.map(option => {
                return (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

const SourceConfluent = () => {
  const [activeTabKey, setActiveTabKey] = useState('import');
  const { topBarCollapsed } = useSelector(state => state.app);
  const location = useLocation();

  useEffect(
    _ => {
      if (location.state) {
        if (location.state.datasource) {
          setActiveTabKey('import');
        }
      }
    },
    [location]
  );

  const handleTabClick = key => {
    setActiveTabKey(key);
  };

  const tabItems = useMemo(
    _ => {
      return [
        {
          key: 'import',
          label: `Import`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportForm setActiveTabKey={setActiveTabKey}>
                  {props => {
                    return <Setup {...props} />;
                  }}
                </ImportForm>
              </div>
            </div>
          ),
        },
        {
          key: 'history',
          label: `History`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportHistory
                  pageSize={
                    topBarCollapsed
                      ? Math.floor((window.innerHeight - 400) / 50)
                      : Math.floor((window.innerHeight - 450) / 50)
                  }
                />
              </div>
            </div>
          ),
        },
      ];
    },
    [topBarCollapsed]
  );

  return (
    <div>
      <img src={ConfluentImage} style={HEADER_LOGO_STYLE} alt="Confluent" />
      <h2 style={{ display: 'inline-block' }}>Confluent Import</h2>
      <Spin indicator={<Spinner />} spinning={false}>
        <Tabs
          type="card"
          items={tabItems}
          activeKey={activeTabKey}
          onTabClick={handleTabClick}
        ></Tabs>
      </Spin>
    </div>
  );
};

export default SourceConfluent;
