// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_DATASOURCES = gql`
  query Datasources {
    datasources {
      datasource_name
      additional_info
      storage_provider_type
    }
  }
`;

export const GET_DATASOURCE_BY_NAME = gql`
  query GetDatasourceByName($datasource_name: String!) {
    datasource(datasource_name: $datasource_name) {
      datasource_name
      additional_info
      storage_provider_type
      load_history {
        table_schema
        table_name
        user_name
        jobid
        start_time
        end_time
        rows_processed
        rows_inserted
        rows_skipped
        event_message
        num_errors
        file_short_name
        file_line_num
      }
      tables
    }
  }
`;

export const GET_DATASOURCE_TABLE_COLUMNS_BY_NAME = gql`
  query GetDatasourceTableColumnsByName(
    $datasource_name: String!
    $remote_query: String!
  ) {
    datasourceTableColumns(
      datasource_name: $datasource_name
      remote_query: $remote_query
    ) {
      datasource_name
      remote_query
      columns
    }
  }
`;

export const VERIFY_DATASOURCE_REMOTE_QUERY_BY_NAME = gql`
  query VerifyDatasourceRemoteQueryByName(
    $datasource_name: String!
    $remote_query: String!
  ) {
    datasourceVerifyRemoteQuery(
      datasource_name: $datasource_name
      remote_query: $remote_query
    ) {
      datasource_name
      remote_query
    }
  }
`;

export const CREATE_DATASOURCE = gql`
  mutation CreateDatasource(
    $name: String!
    $location: String!
    $user_name: String
    $password: String
    $skip_validation: Boolean
    $connection_timeout: Int
    $wait_timeout: Int
    $s3_bucket_name: String
    $s3_region: String
    $s3_encryption_customer_key: String
    $s3_encryption_customer_algorithm: String
    $hdfs_kerberos_keytab: String
    $hdfs_delegation_token: String
    $hdfs_use_kerberos: Boolean
    $azure_storage_account_name: String
    $azure_container_name: String
    $azure_tenant_id: String
    $azure_sas_token: String
    $azure_oauth_token: String
    $gcs_bucket_name: String
    $gcs_project_id: String
    $gcs_service_account_keys: JSON
    $kafka_topic_name: String
    $jdbc_driver_class_name: String
    $jdbc_driver_jar_path: String
    $credential: String
    $schema_registry_location: String
    $schema_registry_credential: String
  ) {
    datasourceCreate(
      name: $name
      location: $location
      user_name: $user_name
      password: $password
      skip_validation: $skip_validation
      connection_timeout: $connection_timeout
      wait_timeout: $wait_timeout
      s3_bucket_name: $s3_bucket_name
      s3_region: $s3_region
      s3_encryption_customer_key: $s3_encryption_customer_key
      s3_encryption_customer_algorithm: $s3_encryption_customer_algorithm
      hdfs_kerberos_keytab: $hdfs_kerberos_keytab
      hdfs_delegation_token: $hdfs_delegation_token
      hdfs_use_kerberos: $hdfs_use_kerberos
      azure_storage_account_name: $azure_storage_account_name
      azure_container_name: $azure_container_name
      azure_tenant_id: $azure_tenant_id
      azure_sas_token: $azure_sas_token
      azure_oauth_token: $azure_oauth_token
      gcs_bucket_name: $gcs_bucket_name
      gcs_project_id: $gcs_project_id
      gcs_service_account_keys: $gcs_service_account_keys
      kafka_topic_name: $kafka_topic_name
      jdbc_driver_class_name: $jdbc_driver_class_name
      jdbc_driver_jar_path: $jdbc_driver_jar_path
      credential: $credential
      schema_registry_location: $schema_registry_location
      schema_registry_credential: $schema_registry_credential
    ) {
      datasource_name
      additional_info
      storage_provider_type
    }
  }
`;

export const UPDATE_DATASOURCE_BY_NAME = gql`
  mutation UpdateDatasourceByName(
    $name: String!
    $location: String!
    $user_name: String
    $password: String
    $skip_validation: Boolean
    $connection_timeout: Int
    $wait_timeout: Int
    $s3_bucket_name: String
    $s3_region: String
    $s3_encryption_customer_key: String
    $s3_encryption_customer_algorithm: String
    $hdfs_kerberos_keytab: String
    $hdfs_delegation_token: String
    $hdfs_use_kerberos: Boolean
    $azure_storage_account_name: String
    $azure_container_name: String
    $azure_tenant_id: String
    $azure_sas_token: String
    $azure_oauth_token: String
    $gcs_bucket_name: String
    $gcs_project_id: String
    $gcs_service_account_keys: JSON
    $kafka_topic_name: String
    $jdbc_driver_class_name: String
    $jdbc_driver_jar_path: String
    $credential: String
    $schema_registry_location: String
    $schema_registry_credential: String
  ) {
    datasourceUpdateByName(
      name: $name
      location: $location
      user_name: $user_name
      password: $password
      skip_validation: $skip_validation
      connection_timeout: $connection_timeout
      wait_timeout: $wait_timeout
      s3_bucket_name: $s3_bucket_name
      s3_region: $s3_region
      s3_encryption_customer_key: $s3_encryption_customer_key
      s3_encryption_customer_algorithm: $s3_encryption_customer_algorithm
      hdfs_kerberos_keytab: $hdfs_kerberos_keytab
      hdfs_delegation_token: $hdfs_delegation_token
      hdfs_use_kerberos: $hdfs_use_kerberos
      azure_storage_account_name: $azure_storage_account_name
      azure_container_name: $azure_container_name
      azure_tenant_id: $azure_tenant_id
      azure_sas_token: $azure_sas_token
      azure_oauth_token: $azure_oauth_token
      gcs_bucket_name: $gcs_bucket_name
      gcs_project_id: $gcs_project_id
      gcs_service_account_keys: $gcs_service_account_keys
      kafka_topic_name: $kafka_topic_name
      jdbc_driver_class_name: $jdbc_driver_class_name
      jdbc_driver_jar_path: $jdbc_driver_jar_path
      credential: $credential
      schema_registry_location: $schema_registry_location
      schema_registry_credential: $schema_registry_credential
    ) {
      datasource_name
      additional_info
      storage_provider_type
    }
  }
`;

export const DELETE_DATASOURCE_BY_NAME = gql`
  mutation DeleteDatasourceByName($datasource_name: String!) {
    datasourceDeleteByName(datasource_name: $datasource_name) {
      datasource_name
    }
  }
`;

export const useGetDatasources = () => {
  return useQuery(GET_DATASOURCES);
};

export const useGetDatasourceByName = options => {
  return useQuery(GET_DATASOURCE_BY_NAME, options);
};

export const useGetDatasourceTableColumnsByName = options => {
  return useQuery(GET_DATASOURCE_TABLE_COLUMNS_BY_NAME, options);
};

export const useVerifyDatasourceTableColumnsByName = options => {
  return useQuery(VERIFY_DATASOURCE_REMOTE_QUERY_BY_NAME, options);
};

export const useCreateDatasource = () => {
  return useMutation(CREATE_DATASOURCE);
};

export const useUpdateDatasourceByName = () => {
  return useMutation(UPDATE_DATASOURCE_BY_NAME);
};

export const useDeleteDatasourceByName = () => {
  return useMutation(DELETE_DATASOURCE_BY_NAME);
};
