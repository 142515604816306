// Imports
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import {
  Modal,
  Form,
  Input,
  Button,
  Space,
  Empty,
  Card,
  Row,
  Col,
  Divider,
  Tooltip,
  Spin,
  AutoComplete,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  TableOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import shortid from 'shortid';
import { useApolloClient } from '@apollo/client';

// App Imports
import GraphQLServices from '../../graphql/services';
import { displaySuccess } from '../../helper';
import Spinner from '../common/Spinner';

const { TextArea } = Input;

const initRulesDefinitionEntry = _ => {
  return {
    id: shortid.generate(),
    value: '',
  };
};

const initCommentsDefinitionEntry = _ => {
  return {
    id: shortid.generate(),
    name: '',
    description: '',
  };
};

const initSamplesDefinitionEntry = _ => {
  return {
    id: shortid.generate(),
    question: '',
    answer: '',
  };
};

const initTableDefinition = _ => {
  return {
    id: shortid.generate(),
    type: 'table',
    config: {
      table: '',
      comment: '',
      rules: [],
      comments: [],
    },
  };
};

const initRulesDefinition = _ => {
  return {
    id: shortid.generate(),
    type: 'rules',
    config: {
      rules: [initRulesDefinitionEntry()],
    },
  };
};

const initSamplesDefinition = _ => {
  return {
    id: shortid.generate(),
    type: 'samples',
    config: {
      samples: [initSamplesDefinitionEntry()],
    },
  };
};

const ContextCreateModal = ({
  context_name,
  readonly = false,
  visible,
  close,
  callback,
}) => {
  const [createContext] = GraphQLServices.Contexts.useCreateContext();
  const [deleteContextByName] =
    GraphQLServices.Contexts.useDeleteContextByName();

  const { data: { tableColumnNames = [] } = {} } =
    GraphQLServices.Tables.useGetTableColumnNames();

  const [isCreating, setIsCreating] = useState(false);
  const [definitions, setDefinitions] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const [form] = Form.useForm();
  const graphqlClient = useApolloClient();
  const configBottomRef = useRef(null);

  const [contextResp, setContextResp] = useState({
    loading: false,
    data: null,
    errors: null,
  });

  useEffect(() => {
    const fetch = async name => {
      try {
        setIsUpdating(true);
        const {
          data = null,
          loading = false,
          errors = null,
        } = await graphqlClient.query({
          query: GraphQLServices.Contexts.GET_CONTEXT_BY_NAME,
          variables: {
            context_name,
          },
        });
        setContextResp({ data, loading, errors });
      } catch (err) {
        console.error(err);
      } finally {
        setIsUpdating(false);
      }
    };
    if (context_name) {
      fetch(context_name);
    }
  }, [context_name, graphqlClient]);

  const { data: editContext } = useMemo(
    _ => {
      if (contextResp.data) {
        return {
          ...contextResp,
          data: contextResp.data.context,
        };
      }
      return contextResp;
    },
    [contextResp]
  );

  const editMode = useMemo(() => {
    return editContext !== null;
  }, [editContext]);

  const scrollToLast = _ => {
    setTimeout(() => {
      if (configBottomRef.current) {
        configBottomRef.current.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  const handleAddTable = useCallback(
    _ => {
      setDefinitions([...definitions, initTableDefinition()]);
      scrollToLast();
    },
    [definitions]
  );

  const handleAddRules = useCallback(
    _ => {
      setDefinitions([...definitions, initRulesDefinition()]);
      scrollToLast();
    },
    [definitions]
  );

  const handleAddSamples = useCallback(
    _ => {
      setDefinitions([...definitions, initSamplesDefinition()]);
      scrollToLast();
    },
    [definitions]
  );

  const getDefinitionIndex = useCallback(
    definition => {
      return definitions.findIndex(item => item.id === definition.id);
    },
    [definitions]
  );

  const updateDefinitions = useCallback(
    updatedDefinition => {
      const definitionIndex = getDefinitionIndex(updatedDefinition);
      const updatedDefinitions = [...definitions];
      updatedDefinitions[definitionIndex] = updatedDefinition;
      return updatedDefinitions;
    },
    [definitions, getDefinitionIndex]
  );

  const handleAddRulesEntry = definition => _ => {
    setDefinitions(
      updateDefinitions({
        ...definition,
        config: {
          ...definition.config,
          rules: [...definition.config.rules, initRulesDefinitionEntry()],
        },
      })
    );
  };

  const handleUpdateRulesEntry = (definition, rule) => evt => {
    const updatedRule = {
      ...rule,
      value: evt.target.value,
    };
    const ruleIndex = definition.config.rules.findIndex(
      item => item.id === rule.id
    );
    const updatedRules = [...definition.config.rules];
    updatedRules[ruleIndex] = updatedRule;

    setDefinitions(
      updateDefinitions({
        ...definition,
        config: {
          ...definition.config,
          rules: updatedRules,
        },
      })
    );
  };

  const handleDeleteRulesEntry = (definition, rule) => _ => {
    setDefinitions(
      updateDefinitions({
        ...definition,
        config: {
          ...definition.config,
          rules: definition.config.rules.filter(item => item.id !== rule.id),
        },
      })
    );
  };

  const handleAddSamplesEntry = definition => _ => {
    setDefinitions(
      updateDefinitions({
        ...definition,
        config: {
          ...definition.config,
          samples: [...definition.config.samples, initSamplesDefinitionEntry()],
        },
      })
    );
  };

  const handleUpdateSamplesEntry = (definition, sample, field) => evt => {
    const updatedSample = {
      ...sample,
      [field]: evt.target.value,
    };
    const sampleIndex = definition.config.samples.findIndex(
      item => item.id === sample.id
    );
    const updatedSamples = [...definition.config.samples];
    updatedSamples[sampleIndex] = updatedSample;

    setDefinitions(
      updateDefinitions({
        ...definition,
        config: {
          ...definition.config,
          samples: updatedSamples,
        },
      })
    );
  };

  const handleDeleteSamplesEntry = (definition, sample) => _ => {
    setDefinitions(
      updateDefinitions({
        ...definition,
        config: {
          ...definition.config,
          samples: definition.config.samples.filter(
            item => item.id !== sample.id
          ),
        },
      })
    );
  };

  const handleAddTableRulesEntry = definition => _ => {
    setDefinitions(
      updateDefinitions({
        ...definition,
        config: {
          ...definition.config,
          rules: [...definition.config.rules, initRulesDefinitionEntry()],
        },
      })
    );
  };

  const handleUpdateTableRulesEntry = (definition, rule) => evt => {
    const updatedRule = {
      ...rule,
      value: evt.target.value,
    };
    const ruleIndex = definition.config.rules.findIndex(
      item => item.id === rule.id
    );
    const updatedRules = [...definition.config.rules];
    updatedRules[ruleIndex] = updatedRule;

    setDefinitions(
      updateDefinitions({
        ...definition,
        config: {
          ...definition.config,
          rules: updatedRules,
        },
      })
    );
  };

  const handleDeleteTableRulesEntry = (definition, rule) => _ => {
    setDefinitions(
      updateDefinitions({
        ...definition,
        config: {
          ...definition.config,
          rules: definition.config.rules.filter(item => item.id !== rule.id),
        },
      })
    );
  };

  const handleAddCommentsEntry = definition => _ => {
    setDefinitions(
      updateDefinitions({
        ...definition,
        config: {
          ...definition.config,
          comments: [
            ...definition.config.comments,
            initCommentsDefinitionEntry(),
          ],
        },
      })
    );
  };

  const handleUpdateCommentsEntry = (definition, comment, field) => evt => {
    const updatedComment = {
      ...comment,
      [field]: evt.target ? evt.target.value : evt,
    };
    const commentIndex = definition.config.comments.findIndex(
      item => item.id === comment.id
    );
    const updatedComments = [...definition.config.comments];
    updatedComments[commentIndex] = updatedComment;

    setDefinitions(
      updateDefinitions({
        ...definition,
        config: {
          ...definition.config,
          comments: updatedComments,
        },
      })
    );
  };

  const handleDeleteCommentsEntry = (definition, comment) => _ => {
    setDefinitions(
      updateDefinitions({
        ...definition,
        config: {
          ...definition.config,
          comments: definition.config.comments.filter(
            item => item.id !== comment.id
          ),
        },
      })
    );
  };

  const handleDelete = useCallback(
    definition => _ => {
      setDefinitions([
        ...definitions.filter(item => item.id !== definition.id),
      ]);
    },
    [definitions]
  );

  const onFinish = useCallback(
    values => {
      const { context_name: update_context_name } = values;

      if (definitions.length === 0) {
        return;
      }

      setIsCreating(true);
      createContext({
        variables: {
          context_name: update_context_name,
          definitions,
          overwrite: editMode,
        },
      })
        .then(async resp => {
          setIsCreating(false);
          if (resp?.data?.contextCreate) {
            if (
              editMode &&
              context_name &&
              context_name !== update_context_name.trim()
            ) {
              await deleteContextByName({
                variables: {
                  context_name,
                },
              });
            }
            displaySuccess(
              editMode
                ? `Context ${update_context_name} updated.`
                : `Context ${update_context_name} created.`
            );
            if (callback) {
              callback(null, resp);
            }
          }
        })
        .catch(err => {
          setIsCreating(false);
          if (callback) {
            callback(err, null);
          }
        });
    },
    [
      callback,
      createContext,
      definitions,
      editMode,
      deleteContextByName,
      context_name,
    ]
  );

  const onValuesChange = useCallback(
    (changedValues, allValues) => {
      const { definitions: { table, comment } = {} } = changedValues;
      if (table) {
        const definitionIDs = Object.keys(table);
        if (definitionIDs.length > 0) {
          const definition = definitions.find(
            definition => definition.id === definitionIDs[0]
          );
          setDefinitions(
            updateDefinitions({
              ...definition,
              config: {
                ...definition.config,
                table: table[definitionIDs[0]],
              },
            })
          );
        }
      } else if (comment) {
        const definitionIDs = Object.keys(comment);
        if (definitionIDs.length > 0) {
          const definition = definitions.find(
            definition => definition.id === definitionIDs[0]
          );
          setDefinitions(
            updateDefinitions({
              ...definition,
              config: {
                ...definition.config,
                comment: comment[definitionIDs[0]],
              },
            })
          );
        }
      }
    },
    [definitions, updateDefinitions]
  );

  const create = _ => {
    form.submit();
  };

  const createLabel = label => (
    <div className="ant-col ant-form-item-label">
      <label className="ant-form-item-no-colon">{label}</label>
    </div>
  );

  const maxRules = useMemo(
    _ => {
      return (
        definitions.filter(definition => definition.type === 'rules').length ===
        1
      );
    },
    [definitions]
  );

  const maxSamples = useMemo(
    _ => {
      return (
        definitions.filter(definition => definition.type === 'samples')
          .length === 1
      );
    },
    [definitions]
  );

  const createStateDefinitions = contexts => {
    return contexts.map(context => {
      const { object_type = '' } = context;
      if (object_type === 'table') {
        const {
          object_name: table,
          object_description: comment,
          object_comments: comments,
          object_rules: rules,
        } = context;
        return {
          id: shortid.generate(),
          type: object_type,
          config: {
            table,
            comment,
            rules: rules.map(rule => {
              return {
                id: shortid.generate(),
                value: rule,
              };
            }),
            comments: comments.map(comment => {
              const { name, description } = comment;
              return {
                id: shortid.generate(),
                name,
                description,
              };
            }),
          },
        };
      } else if (object_type === 'rules') {
        const { object_rules: rules } = context;
        return {
          id: shortid.generate(),
          type: object_type,
          config: {
            rules: rules.map(rule => {
              return {
                id: shortid.generate(),
                value: rule,
              };
            }),
          },
        };
      } else if (object_type === 'samples') {
        const { object_samples: samples } = context;
        return {
          id: shortid.generate(),
          type: object_type,
          config: {
            samples: samples.map(sample => {
              const { question, answer } = sample;
              return {
                id: shortid.generate(),
                question,
                answer,
              };
            }),
          },
        };
      }
      return {};
    });
  };

  const filterTable = items => {
    return items.filter(item => item.type === 'table');
  };

  const filterRules = items => {
    return items.filter(item => item.type === 'rules');
  };

  const filterSamples = items => {
    return items.filter(item => item.type === 'samples');
  };

  const createFormDefinitions = useCallback(definitions => {
    return {
      table: filterTable(definitions).reduce((acc, cur) => {
        acc[cur.id] = cur.config.table;
        return acc;
      }, {}),
      comment: filterTable(definitions).reduce((acc, cur) => {
        acc[cur.id] = cur.config.comment;
        return acc;
      }, {}),
      comments: filterTable(definitions).reduce((acc, cur) => {
        acc[cur.id] = cur.config.comments.reduce((acc, cur) => {
          const { id, name, description } = cur;
          acc[id] = {
            name,
            description,
          };
          return acc;
        }, {});
        return acc;
      }, {}),
      tableRules: filterTable(definitions).reduce((acc, cur) => {
        acc[cur.id] = cur.config.rules.reduce((acc, cur) => {
          const { id, value } = cur;
          acc[id] = value;
          return acc;
        }, {});
        return acc;
      }, {}),
      rules: filterRules(definitions).reduce((acc, cur) => {
        acc[cur.id] = cur.config.rules.reduce((acc, cur) => {
          const { id, value } = cur;
          acc[id] = value;
          return acc;
        }, {});
        return acc;
      }, {}),
      samples: filterSamples(definitions).reduce((acc, cur) => {
        acc[cur.id] = cur.config.samples.reduce((acc, cur) => {
          const { id, question, answer } = cur;
          acc[id] = {
            question,
            answer,
          };
          return acc;
        }, {});
        return acc;
      }, {}),
    };
  }, []);

  const initialValues = useMemo(
    _ => {
      if (context_name && editContext) {
        const stateDefinitions = createStateDefinitions(editContext);
        setDefinitions(stateDefinitions);
        const formDefinitions = createFormDefinitions(stateDefinitions);
        return { context_name, definitions: formDefinitions };
      } else if (context_name) {
        return null;
      } else {
        return {};
      }
    },
    [context_name, createFormDefinitions, editContext]
  );

  const handleValueFilter = (inputValue, option) => {
    return option.value.includes(inputValue);
  };

  const tables = useMemo(
    _ => {
      return tableColumnNames.map(({ schema, name }) => ({
        value: `${schema}.${name}`,
      }));
    },
    [tableColumnNames]
  );

  const columnsLookup = useMemo(
    _ => {
      return tableColumnNames.reduce((acc, { schema, name, columns }) => {
        acc[`${schema}.${name}`] = columns.map(value => ({ value }));
        return acc;
      }, {});
    },
    [tableColumnNames]
  );

  return (
    <Modal
      title={
        context_name
          ? readonly
            ? `Context Info: ${context_name}`
            : `Configure Context: ${context_name}`
          : 'Create Context'
      }
      open={visible}
      footer={
        !readonly
          ? [
              <Button key="cancel" onClick={close}>
                Cancel
              </Button>,
              <Button
                key="create"
                type="primary"
                onClick={create}
                loading={isCreating}
                disabled={definitions.length === 0}
              >
                {editMode ? 'Update' : 'Create'}
              </Button>,
            ]
          : [
              <Button key="close" onClick={close}>
                Close
              </Button>,
            ]
      }
      width={Math.min(1000, window.innerWidth - 250)}
      bodyStyle={{
        height: window.innerHeight - 300,
        minHeight: 300,
        padding: '20px 40px',
      }}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={isUpdating}>
        {initialValues && (
          <Form
            form={form}
            name="context"
            layout="vertical"
            initialValues={initialValues}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            colon={false}
            preserve={false}
            disabled={readonly}
          >
            {!readonly && (
              <>
                <Form.Item
                  label="Context Name"
                  name="context_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter context name!',
                      whitespace: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Divider dashed />
              </>
            )}
            {!readonly && (
              <Space style={{ float: 'right' }}>
                <Button
                  type="primary"
                  onClick={handleAddTable}
                  icon={<PlusOutlined />}
                  size="small"
                  ghost
                >
                  Add Table
                </Button>
                <Tooltip
                  title={
                    maxSamples && 'Only 1 set of rules is allowed per context'
                  }
                >
                  <Button
                    type="primary"
                    onClick={handleAddRules}
                    icon={<PlusOutlined />}
                    disabled={maxRules}
                    size="small"
                    ghost
                  >
                    Add Rules
                  </Button>
                </Tooltip>
                <Tooltip
                  title={
                    maxSamples && 'Only 1 set of samples is allowed per context'
                  }
                >
                  <Button
                    type="primary"
                    onClick={handleAddSamples}
                    icon={<PlusOutlined />}
                    disabled={maxSamples}
                    size="small"
                    ghost
                  >
                    Add Samples
                  </Button>
                </Tooltip>
              </Space>
            )}
            <h3>Definition</h3>
            {definitions.length > 0 ? (
              <div
                style={{
                  overflowY: 'auto',
                  height: readonly
                    ? window.innerHeight - 350
                    : window.innerHeight - 500,
                  minHeight: readonly ? 250 : 100,
                }}
              >
                <Space
                  direction="vertical"
                  size="large"
                  style={{ width: '100%' }}
                >
                  {definitions.map((definition, idx) => {
                    switch (definition.type) {
                      case 'table': {
                        return (
                          <Card
                            key={definition.id}
                            title={
                              <>
                                <TableOutlined style={{ marginRight: 10 }} />
                                {definition.type.toUpperCase()}
                              </>
                            }
                            headStyle={{
                              color: '#8795F3',
                              backgroundColor: '#EFF1FD',
                              borderRadius: 0,
                            }}
                            bodyStyle={{
                              padding: 20,
                              backgroundColor: '#ffffff',
                            }}
                            style={{
                              border: '15px solid #F7F8FE',
                              backgroundColor: '#8795F366',
                              borderRadius: 10,
                              padding: 1,
                            }}
                            extra={
                              !readonly && (
                                <DeleteOutlined
                                  onClick={handleDelete(definition)}
                                  style={{ color: '#cccccc' }}
                                />
                              )
                            }
                            size="small"
                            bordered={false}
                          >
                            {idx === definitions.length - 1 && (
                              <div ref={configBottomRef}></div>
                            )}
                            <Form.Item
                              label="Name"
                              name={['definitions', 'table', definition.id]}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter table name!',
                                  whitespace: true,
                                },
                              ]}
                            >
                              <AutoComplete
                                options={tables}
                                filterOption={handleValueFilter}
                                allowClear={true}
                                dropdownMatchSelectWidth={false}
                              />
                            </Form.Item>
                            <Form.Item
                              label="Description"
                              name={['definitions', 'comment', definition.id]}
                            >
                              <TextArea rows={2} />
                            </Form.Item>
                            {createLabel('Rules')}
                            {definition.config.rules.map((rule, rule_idx) => {
                              return (
                                <Row key={rule.id} gutter={5}>
                                  <Col span={readonly ? 24 : 22}>
                                    <Form.Item
                                      style={{ marginBottom: 5 }}
                                      name={[
                                        'definitions',
                                        'tableRules',
                                        definition.id,
                                        rule.id,
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please enter table rule!',
                                          whitespace: true,
                                        },
                                      ]}
                                    >
                                      <Input
                                        value={rule.value}
                                        onChange={handleUpdateTableRulesEntry(
                                          definition,
                                          rule
                                        )}
                                      />
                                    </Form.Item>
                                  </Col>
                                  {!readonly && (
                                    <Col span={2}>
                                      <Button
                                        type="text"
                                        onClick={handleDeleteTableRulesEntry(
                                          definition,
                                          rule
                                        )}
                                        icon={
                                          <DeleteOutlined
                                            style={{ color: '#cccccc' }}
                                          />
                                        }
                                        block
                                      ></Button>
                                    </Col>
                                  )}
                                </Row>
                              );
                            })}
                            {!readonly ? (
                              <Button
                                onClick={handleAddTableRulesEntry(definition)}
                                icon={<PlusOutlined />}
                                style={{
                                  marginBottom: 20,
                                  color: '#999999',
                                  backgroundColor: '#f6f6f6',
                                  fontWeight: 300,
                                  border: 0,
                                }}
                                block
                              >
                                Add Rule
                              </Button>
                            ) : (
                              <div style={{ marginBottom: 20 }}></div>
                            )}
                            {createLabel('Columns')}
                            {definition.config.comments.length > 0 && (
                              <>
                                <Row
                                  gutter={20}
                                  style={{
                                    marginBottom: 5,
                                    color: '#999999',
                                    fontWeight: 300,
                                  }}
                                >
                                  <Col span={8}>Name</Col>
                                  <Col span={14}>Description</Col>
                                </Row>
                                {definition.config.comments.map(
                                  (comment, comment_idx) => {
                                    return (
                                      <Row key={comment.id} gutter={5}>
                                        <Col span={8}>
                                          <Form.Item
                                            style={{ marginBottom: 5 }}
                                            name={[
                                              'definitions',
                                              'comments',
                                              definition.id,
                                              comment.id,
                                              'name',
                                            ]}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  'Please enter column name!',
                                                whitespace: true,
                                              },
                                            ]}
                                          >
                                            <AutoComplete
                                              value={comment.name}
                                              options={
                                                columnsLookup[
                                                  definition.config.table
                                                ]
                                              }
                                              filterOption={handleValueFilter}
                                              allowClear={true}
                                              dropdownMatchSelectWidth={false}
                                              onChange={handleUpdateCommentsEntry(
                                                definition,
                                                comment,
                                                'name'
                                              )}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col span={readonly ? 16 : 14}>
                                          <Form.Item
                                            style={{ marginBottom: 5 }}
                                            name={[
                                              'definitions',
                                              'comments',
                                              definition.id,
                                              comment.id,
                                              'description',
                                            ]}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  'Please enter column description!',
                                                whitespace: true,
                                              },
                                            ]}
                                          >
                                            <Input
                                              value={comment.description}
                                              onChange={handleUpdateCommentsEntry(
                                                definition,
                                                comment,
                                                'description'
                                              )}
                                            />
                                          </Form.Item>
                                        </Col>
                                        {!readonly && (
                                          <Col span={2}>
                                            <Button
                                              type="text"
                                              onClick={handleDeleteCommentsEntry(
                                                definition,
                                                comment
                                              )}
                                              icon={
                                                <DeleteOutlined
                                                  style={{ color: '#cccccc' }}
                                                />
                                              }
                                              block
                                            ></Button>
                                          </Col>
                                        )}
                                      </Row>
                                    );
                                  }
                                )}
                              </>
                            )}
                            {!readonly ? (
                              <Button
                                onClick={handleAddCommentsEntry(definition)}
                                icon={<PlusOutlined />}
                                style={{
                                  marginBottom: 5,
                                  color: '#999999',
                                  backgroundColor: '#f6f6f6',
                                  fontWeight: 300,
                                  border: 0,
                                }}
                                block
                              >
                                Add Column Description
                              </Button>
                            ) : (
                              <div style={{ marginBottom: 5 }}></div>
                            )}
                          </Card>
                        );
                      }
                      case 'rules': {
                        return (
                          <Card
                            key={definition.id}
                            title={
                              <>
                                <UnorderedListOutlined
                                  style={{ marginRight: 10 }}
                                />
                                {definition.type.toUpperCase()}
                              </>
                            }
                            headStyle={{
                              color: '#C480F2',
                              backgroundColor: '#F7EEFD',
                              borderRadius: 0,
                            }}
                            bodyStyle={{
                              padding: 20,
                              backgroundColor: '#ffffff',
                            }}
                            style={{
                              border: '15px solid #FBF7FE',
                              backgroundColor: '#C480F266',
                              borderRadius: 10,
                              padding: 1,
                            }}
                            extra={
                              !readonly && (
                                <DeleteOutlined
                                  onClick={handleDelete(definition)}
                                  style={{ color: '#cccccc' }}
                                />
                              )
                            }
                            size="small"
                            bordered={false}
                          >
                            {idx === definitions.length - 1 && (
                              <div ref={configBottomRef}></div>
                            )}
                            {definition.config.rules.map((rule, rule_idx) => {
                              return (
                                <Row key={rule.id} gutter={5}>
                                  <Col span={readonly ? 24 : 22}>
                                    <Form.Item
                                      style={{ marginBottom: 5 }}
                                      name={[
                                        'definitions',
                                        'rules',
                                        definition.id,
                                        rule.id,
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Please enter rule!',
                                          whitespace: true,
                                        },
                                      ]}
                                    >
                                      <Input
                                        value={rule.value}
                                        onChange={handleUpdateRulesEntry(
                                          definition,
                                          rule
                                        )}
                                      />
                                    </Form.Item>
                                  </Col>
                                  {!readonly && (
                                    <Col span={2}>
                                      <Button
                                        type="text"
                                        onClick={handleDeleteRulesEntry(
                                          definition,
                                          rule
                                        )}
                                        icon={
                                          <DeleteOutlined
                                            style={{ color: '#cccccc' }}
                                          />
                                        }
                                        block
                                      ></Button>
                                    </Col>
                                  )}
                                </Row>
                              );
                            })}
                            {!readonly ? (
                              <Button
                                onClick={handleAddRulesEntry(definition)}
                                icon={<PlusOutlined />}
                                style={{
                                  marginBottom: 5,
                                  color: '#999999',
                                  backgroundColor: '#f6f6f6',
                                  fontWeight: 300,
                                  border: 0,
                                }}
                                block
                              >
                                Add Rule
                              </Button>
                            ) : (
                              <div style={{ marginBottom: 5 }}></div>
                            )}
                          </Card>
                        );
                      }
                      case 'samples': {
                        return (
                          <Card
                            key={definition.id}
                            title={
                              <>
                                <UnorderedListOutlined
                                  style={{ marginRight: 10 }}
                                />
                                {definition.type.toUpperCase()}
                              </>
                            }
                            headStyle={{
                              color: '#C480F2',
                              backgroundColor: '#F7EEFD',
                              borderRadius: 0,
                            }}
                            bodyStyle={{
                              padding: 20,
                              backgroundColor: '#ffffff',
                            }}
                            style={{
                              border: '15px solid #FBF7FE',
                              backgroundColor: '#C480F266',
                              borderRadius: 10,
                              padding: 1,
                            }}
                            extra={
                              !readonly && (
                                <DeleteOutlined
                                  onClick={handleDelete(definition)}
                                  style={{ color: '#cccccc' }}
                                />
                              )
                            }
                            size="small"
                            bordered={false}
                          >
                            {idx === definitions.length - 1 && (
                              <div ref={configBottomRef}></div>
                            )}
                            {definition.config.samples.length > 0 && (
                              <>
                                <Row
                                  gutter={20}
                                  style={{
                                    marginBottom: 5,
                                    color: '#999999',
                                    fontWeight: 300,
                                  }}
                                >
                                  <Col span={8}>Question</Col>
                                  <Col span={14}>Answer</Col>
                                </Row>
                                {definition.config.samples.map(
                                  (sample, sample_idx) => {
                                    return (
                                      <Row key={sample.id} gutter={5}>
                                        <Col span={8}>
                                          <Form.Item
                                            style={{ marginBottom: 5 }}
                                            name={[
                                              'definitions',
                                              'samples',
                                              definition.id,
                                              sample.id,
                                              'question',
                                            ]}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  'Please enter sample question!',
                                                whitespace: true,
                                              },
                                            ]}
                                          >
                                            <TextArea
                                              value={sample.question}
                                              onChange={handleUpdateSamplesEntry(
                                                definition,
                                                sample,
                                                'question'
                                              )}
                                              rows={3}
                                            />
                                          </Form.Item>
                                        </Col>
                                        <Col span={readonly ? 16 : 14}>
                                          <Form.Item
                                            style={{ marginBottom: 5 }}
                                            name={[
                                              'definitions',
                                              'samples',
                                              definition.id,
                                              sample.id,
                                              'answer',
                                            ]}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  'Please enter sample answer!',
                                                whitespace: true,
                                              },
                                            ]}
                                          >
                                            <TextArea
                                              value={sample.answer}
                                              onChange={handleUpdateSamplesEntry(
                                                definition,
                                                sample,
                                                'answer'
                                              )}
                                              rows={3}
                                            />
                                          </Form.Item>
                                        </Col>
                                        {!readonly && (
                                          <Col span={2}>
                                            <Button
                                              type="text"
                                              onClick={handleDeleteSamplesEntry(
                                                definition,
                                                sample
                                              )}
                                              icon={
                                                <DeleteOutlined
                                                  style={{ color: '#cccccc' }}
                                                />
                                              }
                                              block
                                            ></Button>
                                          </Col>
                                        )}
                                      </Row>
                                    );
                                  }
                                )}
                              </>
                            )}
                            {!readonly ? (
                              <Button
                                onClick={handleAddSamplesEntry(definition)}
                                icon={<PlusOutlined />}
                                style={{
                                  marginBottom: 5,
                                  color: '#999999',
                                  backgroundColor: '#f6f6f6',
                                  fontWeight: 300,
                                  border: 0,
                                }}
                                block
                              >
                                Add Sample
                              </Button>
                            ) : (
                              <div style={{ marginBottom: 5 }}></div>
                            )}
                          </Card>
                        );
                      }
                      default:
                        return <></>;
                    }
                  })}
                </Space>
              </div>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Define context by adding table and/or samples"
              />
            )}
          </Form>
        )}
      </Spin>
    </Modal>
  );
};

export default ContextCreateModal;
