// Imports
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

// App Imports
import rootStore from './store';
import App from './containers/App';
import { APP_URL_GRAPHQL, APP_URL_API } from './setup/config';
import { routes } from './setup/routes';
import { displayError } from './helper';

const GRAPHQL_ERROR_OPERATION_WHITELIST = [
  'UserMe',
  'ExecuteSql',
  'K8sKineticaClusters',
  'K8sKineticaWorkbenches',
  'K8sKineticaClusterBackups',
  'K8sKineticaClusterRestores',
  'K8sVeleroRestores',
  'Upgrades',
  'GetTableByName',
  'GetWms',
  'DataTables',
  'DataObjects',
  'SlowDataObjects',
  'Schemas',
  'Tables',
  'Graphs',
  'Datasources',
  'Folders',
  'Users',
  'Roles',
  'Jobs',
];

// Instantiate required constructor fields
const cache = new InMemoryCache({
  typePolicies: {
    table: {
      keyFields: ['schema', 'name'],
    },
  },
});

// Apollo client links
const httpLink = createHttpLink({
  uri: APP_URL_GRAPHQL,
});
const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(async ({ message, locations, path }) => {
      if (message.toLowerCase().includes('insufficient credentials')) {
        // Credentials no longer valid so log user out
        const { data } = await axios.get(`${APP_URL_API}/auth/logout`);
        if (data.success) {
          window.location.reload();
        }
      } else if (
        !GRAPHQL_ERROR_OPERATION_WHITELIST.includes(operation?.operationName)
      ) {
        // Ignore network errors
        if (message.toLowerCase().includes('econnrefused')) {
          displayError('Unable to connect to the database');
        } else if (!message.includes('getaddrinfo')) {
          displayError(message);
        }
      } else if (
        operation?.operationName !== 'UserMe' &&
        message.toLowerCase().includes('invalid user session')
      ) {
        window.location.reload();
      }
      console.error(
        `GraphQL: ${operation?.operationName || 'Unknown'}, ${message}`,
        locations,
        path
      );
    });
  }
  if (networkError) {
    console.error(
      `Network: ${operation?.operationName || 'Unknown'}, ${
        networkError.message
      }`
    );
  }
});

const link = from([errorLink, httpLink]);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  cache,
  link,
  name: 'workbench-web-client',
  version: '1.0',
  queryDeduplication: false,
  defaultOptions,
});

// Render App
ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={rootStore}>
      <Router basename={routes.context}>
        <App />
      </Router>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);
