// Imports
import { Spin, Button, Tag } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

// App Imports
import Spinner from '../../components/common/Spinner';
import { BLOCK_TYPES } from '../../constants';

const getBlockTypeName = id => {
  return Object.keys(BLOCK_TYPES).find(key => {
    return BLOCK_TYPES[key] === id;
  });
};

const deepSearchItems = (object, key, predicate) => {
  let ret = [];
  if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) {
    ret = [...ret, object];
  }
  if (Object.keys(object).length) {
    for (let i = 0; i < Object.keys(object).length; i++) {
      let value = object[Object.keys(object)[i]];
      if (typeof value === 'object' && value != null) {
        let o = deepSearchItems(object[Object.keys(object)[i]], key, predicate);
        if (o != null && o instanceof Array) {
          ret = [...ret, ...o];
        }
      }
    }
  }
  return ret;
};

const getFirstText = content => {
  const matches = deepSearchItems(content, 'text', (k, v) => true);
  return matches.map(match => match['text']).join(' ');
};

const CollapsedBlock = ({
  element,
  handleRun = _ => {},
  loading = false,
  queryRunning = false,
}) => {
  const tagStyle = { width: 60, textAlign: 'center' };

  const renderElement = element => {
    if (element.content) {
      if (element.type === BLOCK_TYPES.TEXT) {
        return (
          <>
            <Tag style={tagStyle}>{getBlockTypeName(element.type)}</Tag>
            {getFirstText(element.content)}
          </>
        );
      } else if (typeof element.content === 'string') {
        return (
          <>
            <Tag style={tagStyle}>{getBlockTypeName(element.type)}</Tag>
            {element.content}
          </>
        );
      } else if (typeof element.content === 'object') {
        return (
          <>
            <Tag style={tagStyle}>{getBlockTypeName(element.type)}</Tag>
            {JSON.stringify(element.content)}
          </>
        );
      }
    }
    return 'No content';
  };

  return (
    <Spin indicator={<Spinner />} spinning={loading || queryRunning}>
      <div
        style={{
          fontFamily: 'monospace',
          fontSize: '13px',
          margin: '3px 5px 3px 40px',
          padding: '7px 15px 7px 10px',
          color: '#cccccc',
          backgroundColor: '#f6f6f6',
          borderRadius: '5px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {renderElement(element)}
      </div>
      <div
        style={{
          position: 'absolute',
          top: '2px',
          left: '0px',
        }}
      >
        <div
          style={{
            position: 'sticky',
            float: 'right',
            width: 'auto',
            top: '0px',
            left: '0px',
            zIndex: 1,
            pointerEvents: 'all',
          }}
        >
          {getBlockTypeName(element.type) === 'SQL' && (
            <Button
              type="text"
              onClick={handleRun}
              icon={
                <PlayCircleOutlined
                  style={{ fontSize: '25px', color: '#3700b399' }}
                />
              }
            ></Button>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default CollapsedBlock;
