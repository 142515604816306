// Imports
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Modal, Tag } from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  ExportOutlined,
  DiffOutlined,
  DesktopOutlined,
  SwapOutlined,
} from '@ant-design/icons';

// App Imports
import GraphQLServices from '../../../graphql/services';
import {
  NAV_ROUTE_DATAEXPLORE_WORKBOOK,
  NAV_ROUTE_DATAEXPLORE,
  EXPLORER_PANEL_WIDTH,
} from '../../../constants';
import { routes } from '../../../setup/routes';
import {
  useExportJsonWorkbook,
  useExportSqlWorkbook,
} from '../../../containers/dataexplore/utils';
import MultiSelectContext from '../MultiSelectContext';
import useAnalytics from '../../../hooks/useAnalytics';

const { confirm } = Modal;

const WorkbookObject = ({
  nodeData,
  refresh,
  editClick,
  current,
  handleWorkbookMove,
  handleMultiSelect,
}) => {
  const [removeWorkbookById] =
    GraphQLServices.Workbooks.useRemoveWorkbookById();
  const exportJsonWorkbook = useExportJsonWorkbook();
  const exportSqlWorkbook = useExportSqlWorkbook();

  const history = useHistory();

  const analytics = useAnalytics();

  const handleWorkbookContextOpen =
    (workbook, isMultiSelect = false) =>
    e => {
      if (!isMultiSelect) {
        if (workbook.is_example) {
          analytics.track(analytics.EVENT_TYPES.OPENED_EXAMPLE_WORKBOOK)({
            title: workbook.name,
          });
          analytics.track(
            `${analytics.EVENT_TYPES.TRACK_EXAMPLE_WORKBOOK} ${workbook.name}`
          )({});
        }
        history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${workbook.id}`);
      }
    };

  const handleWorkbookContextOpenNew =
    (workbook, isMultiSelect = false) =>
    e => {
      if (!isMultiSelect) {
        if (workbook.is_example) {
          analytics.track(analytics.EVENT_TYPES.OPENED_EXAMPLE_WORKBOOK)({
            title: workbook.name,
          });
          analytics.track(
            `${analytics.EVENT_TYPES.TRACK_EXAMPLE_WORKBOOK} ${workbook.name}`
          )({});
        }
        window.open(
          `${routes.context}${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${workbook.id}`,
          `${workbook.id}`,
          'noopener'
        );
      }
    };

  const removeWorkbook = useCallback(
    workbook => {
      removeWorkbookById({
        variables: {
          id: workbook.id,
        },
      })
        .then(resp => {
          refresh();
          if (current && current === workbook.id) {
            history.push(NAV_ROUTE_DATAEXPLORE);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    [refresh, removeWorkbookById, current, history]
  );

  const handleWorkbookContextDelete = workbook => e => {
    confirm({
      title: `Do you want to delete workbook ${workbook.name}?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        removeWorkbook(workbook);
      },
      onCancel() {
        // Do nothing
      },
      width: 600,
      centered: true,
    });
  };

  const handleWorkbookContextExportJson = workbook => e => {
    confirm({
      title: `Do you want to export ${workbook.name} as Workbook JSON?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      onOk() {
        exportJsonWorkbook(workbook);
      },
      cancelText: 'No',
      onCancel() {
        // Do nothing
      },
      width: 600,
      centered: true,
    });
  };

  const handleWorkbookContextExportSql = workbook => e => {
    confirm({
      title: `Do you want to export ${workbook.name} as Standard SQL?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      onOk() {
        exportSqlWorkbook(workbook);
      },
      cancelText: 'No',
      onCancel() {
        // Do nothing
      },
      width: 600,
      centered: true,
    });
  };

  const workbookContextMenu = node => {
    const items = [
      {
        key: 'open',
        label: 'Open',
        icon: <EyeOutlined />,
        onClick: handleWorkbookContextOpen(node.source),
      },
      {
        key: 'opennew',
        label: 'New Window',
        icon: <DesktopOutlined />,
        onClick: handleWorkbookContextOpenNew(node.source),
      },
      {
        key: 'export',
        label: 'Export',
        icon: <ExportOutlined />,
        children: [
          {
            key: 'exportjson',
            label: 'Workbook JSON',
            onClick: handleWorkbookContextExportJson(node.source),
          },
          {
            key: 'exportsql',
            label: 'Standard SQL',
            onClick: handleWorkbookContextExportSql(node.source),
          },
        ],
      },
    ];

    if (!node.sharedSection && !node.source.is_example) {
      const addons = [
        {
          type: 'divider',
        },
        {
          key: 'edit',
          label: 'Edit',
          icon: <EditOutlined />,
          onClick: editClick(node.source),
        },
        {
          key: 'move',
          label: 'Move',
          icon: <SwapOutlined />,
          onClick: _ => handleWorkbookMove(node.source),
        },
        {
          key: 'delete',
          label: 'Delete',
          icon: <DeleteOutlined />,
          onClick: handleWorkbookContextDelete(node.source),
          style: { color: '#C62828' },
        },
        {
          type: 'divider',
        },
        {
          key: 'multiselect',
          label: 'Multi-Select',
          icon: <DiffOutlined />,
          onClick: _ => handleMultiSelect(node),
          style: { color: '#3700b3' },
        },
      ];
      items.push(...addons);
    }

    return {
      items,
    };
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect => (
        <Dropdown
          menu={workbookContextMenu(nodeData)}
          trigger={['contextMenu']}
        >
          <div
            onClick={handleWorkbookContextOpen(nodeData.source, isMultiSelect)}
            onContextMenu={_ => {}}
          >
            <span
              style={{
                maxWidth: `${
                  EXPLORER_PANEL_WIDTH -
                  // Reserve width based on if open or shared tag is being shown.
                  ((current && current === nodeData.id) ||
                  (nodeData.source.is_shared && !nodeData.sharedSection)
                    ? 140 - (isMultiSelect ? 24 : 0)
                    : 80 - (isMultiSelect ? 24 : 0))
                }px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                verticalAlign: 'text-top',
              }}
            >
              {nodeData.title}
            </span>
            {!(current && current === nodeData.id) &&
              nodeData.source.is_shared &&
              !nodeData.sharedSection && (
                <Tag
                  color="#1890FF"
                  style={{
                    marginLeft: '5px',
                    padding: '2px 5px 2px 4px',
                    lineHeight: '11px',
                    verticalAlign: 'top',
                    marginTop: '4px',
                  }}
                >
                  Shared
                </Tag>
              )}
            {current && current === nodeData.id && (
              <Tag
                color="#3700b366"
                style={{
                  marginLeft: '5px',
                  padding: '2px 5px 2px 4px',
                  lineHeight: '11px',
                  verticalAlign: 'top',
                  marginTop: '4px',
                }}
              >
                Open
              </Tag>
            )}
          </div>
        </Dropdown>
      )}
    </MultiSelectContext.Consumer>
  );
};

export default WorkbookObject;
