// Imports
import React, { useCallback, useState } from 'react';
import { Modal, Button, Upload, notification, Input, Divider } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

// App Imports
import GraphQLServices from '../../graphql/services';
import { parseSqlData } from './helper';
import { BLOCK_TYPES } from '../../constants';

const { Dragger } = Upload;

const escapeSpecialChars = value => {
  return value.replace(/"/g, '\\"');
};

const WorkbookImportModal = ({ visible, close, callback }) => {
  const { data: { userMe = {} } = {} } =
    GraphQLServices.Users.useGetLocalUserMe();
  const [importWorkbook] = GraphQLServices.Workbooks.useImportWorkbook();
  const [createWorkbook] = GraphQLServices.Workbooks.useCreateWorkbook();
  const [createWorksheet] = GraphQLServices.Worksheets.useCreateWorksheet();
  const [updateWorksheetById] =
    GraphQLServices.Worksheets.useUpdateWorksheetById();
  const [createBlock] = GraphQLServices.Blocks.useCreateBlock();
  const [updateBlock] = GraphQLServices.Blocks.useUpdateBlockById();

  const graphqlClient = useApolloClient();

  const [isImporting, setIsImporting] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [urlToImport, setUrlToImport] = useState('');

  const parseTextBlock = content => {
    return `[${content
      .map(line => {
        return `{"type":"paragraph","children":[{"text":"${escapeSpecialChars(
          line
        )}"}]}`;
      })
      .join(',')}]`;
  };

  const parseSqlBlock = content => {
    return `"${content.map(line => line.replace(/"/gi, '\\"')).join('\\n')}"`;
  };

  const handleUpload = () => {
    if (fileToUpload.name.endsWith('.json')) {
      setIsImporting(true);
      fileToUpload.text().then(data => {
        importWorkbook({
          variables: {
            data,
            overrides: {
              workbook: {
                is_shared: false,
                is_example: false,
              },
            },
          },
        })
          .then(resp => {
            if (resp?.data?.workbookImport) {
              notification.success({
                message: 'Workbook Imported',
                description: `Successfully imported workbook: ${resp.data.workbookImport[0].name}`,
              });
              if (callback) {
                callback(null, resp);
              }
              setIsImporting(false);
              setFileToUpload(null);
            } else {
              if (!resp?.errors) {
                notification.error({
                  message: 'Workbook Import Error',
                  description: 'Unexpected Error',
                  duration: 0,
                });
              }
              if (callback) {
                callback(new Error('Unexpected Error'), null);
              }
              setIsImporting(false);
            }
          })
          .catch(err => {
            if (callback) {
              callback(err, null);
            }
            setIsImporting(false);
          });
      });
    } else if (fileToUpload.name.endsWith('.sql')) {
      setIsImporting(true);
      fileToUpload.text().then(async data => {
        try {
          const wb = parseSqlData(data);

          // Create workbook
          const createWorkbookResp = await createWorkbook({
            variables: {
              name: wb.name,
              description: wb.name || `Description for ${wb.name}`,
              config: {},
              user_id: userMe.id,
            },
          });
          const workbook = createWorkbookResp?.data?.workbookCreate;

          const { id: workbookId } = workbook;

          // Create worksheets
          let prevWorksheetId = null;
          for (const ws of wb.worksheets) {
            const createWorksheetResp = await createWorksheet({
              variables: {
                name: `${ws.name}`,
                description: ws.description || `Description for ${ws.name}`,
                config: {},
                previous_worksheet_id: prevWorksheetId,
                next_worksheet_id: null,
                workbook_id: workbookId,
              },
            });

            const { id: worksheetId } =
              createWorksheetResp?.data?.worksheetCreate;

            let prevBlockId = null;
            for (const [bIdx, b] of ws.blocks.entries()) {
              const createBlockResp = await createBlock({
                variables: {
                  name: `Block ${bIdx + 1}`,
                  description: `Description for Block ${bIdx + 1}`,
                  content:
                    b.type === 'sql'
                      ? parseSqlBlock(b.content)
                      : parseTextBlock(b.content),
                  config: {},
                  data: {},
                  previous_block_id: prevBlockId,
                  next_block_id: null,
                  block_type_id:
                    b.type === 'sql' ? BLOCK_TYPES.SQL : BLOCK_TYPES.TEXT,
                  worksheet_id: worksheetId,
                },
              });

              const { id: blockId } = createBlockResp?.data?.blockCreate;

              if (prevBlockId) {
                await updateBlock({
                  variables: {
                    id: prevBlockId,
                    next_block_id: blockId,
                  },
                });
              }
              prevBlockId = blockId;
            }

            if (prevWorksheetId) {
              await updateWorksheetById({
                variables: {
                  id: prevWorksheetId,
                  next_worksheet_id: worksheetId,
                },
              });
            }

            prevWorksheetId = worksheetId;
          }

          notification.success({
            message: 'Workbook Imported',
            description: `Successfully imported workbook: ${workbook.name}`,
          });
          if (callback) {
            callback(null, createWorkbookResp);
          }
        } catch (error) {
          notification.error({
            message: 'Workbook Import',
            description: error.message,
            duration: 0,
          });
          if (callback) {
            callback(error, null);
          }
        } finally {
          setIsImporting(false);
          setFileToUpload(null);
        }
      });
    } else {
      alert('Invalid file extension');
    }
  };

  const handleUrl = async () => {
    if (urlToImport.endsWith('.json')) {
      setIsImporting(true);

      const resp = await graphqlClient.query({
        query: GraphQLServices.FetchURLs.FETCH_URL,
        variables: {
          url: urlToImport,
        },
      });

      const {
        fetch_url: { data: json },
      } = resp.data;
      const data = JSON.stringify(json);

      importWorkbook({
        variables: {
          data,
          overrides: {
            workbook: {
              is_shared: false,
              is_example: false,
            },
          },
        },
      })
        .then(resp => {
          if (resp?.data?.workbookImport) {
            notification.success({
              message: 'Workbook Imported',
              description: `Successfully imported workbook: ${resp.data.workbookImport[0].name}`,
            });
            if (callback) {
              callback(null, resp);
            }
            setIsImporting(false);
            setUrlToImport('');
          } else {
            if (!resp?.errors) {
              notification.error({
                message: 'Workbook Import Error',
                description: 'Unexpected Error',
                duration: 0,
              });
            }
            if (callback) {
              callback(new Error('Unexpected Error'), null);
            }
            setIsImporting(false);
          }
        })
        .catch(err => {
          if (callback) {
            callback(err, null);
          }
          setIsImporting(false);
        });
    } else if (urlToImport.endsWith('.sql')) {
      try {
        setIsImporting(true);
        const resp = await fetch(urlToImport);
        const data = await resp.text();
        const wb = parseSqlData(data);

        // Create workbook
        const createWorkbookResp = await createWorkbook({
          variables: {
            name: wb.name,
            description: wb.name || `Description for ${wb.name}`,
            config: {},
            user_id: userMe.id,
          },
        });
        const workbook = createWorkbookResp?.data?.workbookCreate;

        const { id: workbookId } = workbook;

        // Create worksheets
        let prevWorksheetId = null;
        for (const ws of wb.worksheets) {
          const createWorksheetResp = await createWorksheet({
            variables: {
              name: `${ws.name}`,
              description: ws.description || `Description for ${ws.name}`,
              config: {},
              previous_worksheet_id: prevWorksheetId,
              next_worksheet_id: null,
              workbook_id: workbookId,
            },
          });

          const { id: worksheetId } =
            createWorksheetResp?.data?.worksheetCreate;

          let prevBlockId = null;
          for (const [bIdx, b] of ws.blocks.entries()) {
            const createBlockResp = await createBlock({
              variables: {
                name: `Block ${bIdx + 1}`,
                description: `Description for Block ${bIdx + 1}`,
                content:
                  b.type === 'sql'
                    ? parseSqlBlock(b.content)
                    : parseTextBlock(b.content),
                config: {},
                data: {},
                previous_block_id: prevBlockId,
                next_block_id: null,
                block_type_id:
                  b.type === 'sql' ? BLOCK_TYPES.SQL : BLOCK_TYPES.TEXT,
                worksheet_id: worksheetId,
              },
            });

            const { id: blockId } = createBlockResp?.data?.blockCreate;

            if (prevBlockId) {
              await updateBlock({
                variables: {
                  id: prevBlockId,
                  next_block_id: blockId,
                },
              });
            }
            prevBlockId = blockId;
          }

          if (prevWorksheetId) {
            await updateWorksheetById({
              variables: {
                id: prevWorksheetId,
                next_worksheet_id: worksheetId,
              },
            });
          }

          prevWorksheetId = worksheetId;
        }

        notification.success({
          message: 'Workbook Imported',
          description: `Successfully imported workbook: ${workbook.name}`,
        });
        if (callback) {
          callback(null, createWorkbookResp);
        }
      } catch (error) {
        notification.error({
          message: 'Workbook Import',
          description: error.message,
          duration: 0,
        });
        if (callback) {
          callback(error, null);
        }
      } finally {
        setIsImporting(false);
        setUrlToImport('');
      }
    } else {
      alert('Invalid file extension');
    }
  };

  const handleImportWorkbook = _ => {
    if (urlToImport) {
      handleUrl();
    } else if (fileToUpload) {
      handleUpload();
    }
  };

  const beforeUpload = useCallback(file => {
    setFileToUpload(file);
    return false;
  }, []);

  const handleClose = useCallback(
    _ => {
      setFileToUpload(null);
      if (close) {
        close();
      }
    },
    [close]
  );

  const handleImportUrlUpdate = evt => {
    setUrlToImport(evt.target.value);
  };

  const onRemove = file => {
    setFileToUpload(null);
  };

  return (
    <Modal
      title="Import Workbook (JSON/SQL)"
      open={visible}
      footer={[
        <Button key="cancel" onClick={handleClose}>
          Cancel
        </Button>,
        <Button
          key="import"
          type="primary"
          onClick={handleImportWorkbook}
          loading={isImporting}
          disabled={!fileToUpload && !urlToImport}
        >
          Import
        </Button>,
      ]}
      onCancel={handleClose}
      width={750}
      destroyOnClose
      centered
    >
      <Dragger
        multiple={false}
        maxCount={1}
        fileList={fileToUpload ? [fileToUpload] : []}
        beforeUpload={beforeUpload}
        onRemove={onRemove}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Please select a Workbook (JSON/SQL) file to upload.
        </p>
      </Dragger>
      <Divider dashed>or</Divider>
      <Input
        value={urlToImport}
        onChange={handleImportUrlUpdate}
        addonBefore="URL"
        placeholder="Enter the address of your workbook file"
      />
    </Modal>
  );
};

export default WorkbookImportModal;
