// Imports
import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// App Imports
import ConfigureModelForm from './ConfigureModelForm';

// Component
const ConfigureModel = () => {
  const { modelName } = useParams();
  const { topBarCollapsed } = useSelector(state => state.app);

  return (
    <>
      <h2>Configure Model</h2>
      <div
        style={{
          padding: '20px 20px 0px',
          backgroundColor: '#ffffff',
          height: topBarCollapsed
            ? 'calc(100vh - 110px)'
            : 'calc(100vh - 160px)',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            top: '0px',
            left: '0px',
            padding: '10px',
          }}
        >
          <ConfigureModelForm name={modelName} />
        </div>
      </div>
    </>
  );
};

export default ConfigureModel;
